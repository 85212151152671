import { GET_ACTIVE_VOYAGE_INFO_FOR_DRAWER } from '../queries/active-voyage-info-for-drawer.get.query';
import { Query } from '_gql/graphql';
import { ApolloError, useQuery } from '@apollo/client';
import { VoyageWaypointMapper } from '../mappers/active-voyage-info-for-drawer.mapper';
export const useActiveVoyageInfoForDrawer = (imoNumber: string) => {
  const query = useQuery<Query>(GET_ACTIVE_VOYAGE_INFO_FOR_DRAWER, {
    variables: {
      imoNumber: imoNumber,
    },
  });
  return query;
};

export const useActiveVoyageWaypoint = (imoNumber: string) => {
  try {
    const mockedVoyageWaypoints = VoyageWaypointMapper.dto({ imoNumber });
    return {
      data: { waypoints: mockedVoyageWaypoints.waypoints },
      loading: false,
      error: null as unknown as ApolloError,
    };
  } catch (error: unknown) {
    // can be simplified once we are returning from gql
    const errorMsg = (error as Error).message;
    const apolloError = new ApolloError({ errorMessage: errorMsg });
    return {
      data: undefined,
      loading: false,
      error: apolloError,
    };
  }
};
