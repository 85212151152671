import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ColorBar } from './color-bar.component';
import { theme } from 'styles/theme';
import { BoundaryChartData } from '../models/vessel-performance-trends.model';
import { VesselTrendChartMapper } from '../mappers/vessel-trend-chart.mapper';

export const ColorBarSide = (props: {
  data: BoundaryChartData[];
  side: string;
  height: number;
  negative?: boolean;
}) => {
  const { data, height, side, negative } = props;
  const chartData = negative
    ? VesselTrendChartMapper.formatNegativeBoundaryChartData(data)
    : data;
  const xDataKey = side === 'left' ? 'positionLeft' : 'positionRight';
  const chartMargin =
    side === 'left' ? { top: 6, left: -6 } : { top: 6, right: 23 };

  const domainY: number[] = [
    chartData[0]?.domainY[0] ?? 0,
    chartData[0]?.domainY[1] ?? 90,
  ];

  return (
    <ResponsiveContainer height={height}>
      <BarChart data={chartData} margin={chartMargin}>
        <XAxis
          dataKey={xDataKey}
          textAnchor='end'
          domain={[0, 1]}
          type='number'
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='maxValue'
          tickLine={false}
          tick={false}
          tickMargin={10}
          domain={domainY}
          type='number'
          axisLine={false}
          dx={-12}
          width={82}
        />
        {negative ? (
          <>
            <Bar
              dataKey='cValue'
              stackId='a'
              fill={theme.colors?.eRating}
              shape={<ColorBar dataKey='cValue' />}
            />

            <Bar
              dataKey='bValue'
              stackId='a'
              fill={theme.colors?.bRating}
              shape={<ColorBar dataKey='bValue' />}
            />
            <Bar
              dataKey='aValue'
              stackId='a'
              fill={theme.colors?.aRating}
              shape={<ColorBar dataKey='aValue' />}
            />
          </>
        ) : (
          <>
            <Bar
              dataKey='aValue'
              stackId='a'
              fill={theme.colors?.aRating}
              shape={<ColorBar dataKey='aValue' />}
            />
            <Bar
              dataKey='bValue'
              stackId='a'
              fill={theme.colors?.bRating}
              shape={<ColorBar dataKey='bValue' />}
            />
            <Bar
              dataKey='cValue'
              stackId='a'
              fill={theme.colors?.eRating}
              shape={<ColorBar dataKey='cValue' />}
            />
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
