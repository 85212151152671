import {
  DateRange as DateRangeComponent,
  PickersShortcutsItem,
} from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { DateRange } from 'shared/utils/date-utc-helper';

export const defaultDateRange = DateRange.getYearToDate();
export const shortcutsItems: PickersShortcutsItem<DateRangeComponent<Dayjs>>[] =
  [
    {
      label: 'Year to Date',
      getValue: () => DateRange.getYearToDate(),
    },
    {
      label: 'Last 30 Days',
      getValue: () => DateRange.getLast30Days(),
    },
    {
      label: `${DateRange.lastYear()}`,
      getValue: () => DateRange.getLastYear(),
    },
    {
      label: `Q1 - ${DateRange.currentYear()}`,
      getValue: () => DateRange.getQ1CurrentYear(),
    },
    {
      label: `Q2 - ${DateRange.currentYear()}`,
      getValue: () => DateRange.getQ2CurrentYear(),
    },
    {
      label: `Q3 - ${DateRange.currentYear()}`,
      getValue: () => DateRange.getQ3CurrentYear(),
    },
    {
      label: 'Current Quarter',
      getValue: () => DateRange.getCurrentQuarter(),
    },
  ].filter(({ getValue }) => {
    const [, endDate] = getValue();
    return DateRange.isEndDateAfterToday(endDate, DateRange.startOftoday());
  });

export const isValid = (date: Dayjs | null) => {
  return DateRange.isValidForDateRangePicker(date);
};

export const isValidRange = (range: DateRangeComponent<Dayjs> | null) => {
  return (
    range !== null &&
    DateRange.isValidRangeForDateRangePicker(range[0], range[1])
  );
};
