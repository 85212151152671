import { gql } from '@apollo/client';

export const GET_ACTIVE_VOYAGE_POSITIONS = gql`
  query GetActiveVoyagePositions($imoNumber: String!) {
    vessels(where: { imoNumber: { eq: $imoNumber } }) {
      imoNumber
      activeVoyagePositions {
        positions {
          latitude
          longitude
        }
      }
    }
  }
`;
