import { Box, ToggleButtonGroup } from '@mui/material';
import { typography } from 'styles/typography';
import CiiDataToggleButton from '../cii-ratings/cii-data-toggles/cii-data-toggle-button.component';
import { DataToggleButton } from 'routes/environmental-monitor/view-models/data-toggle-button.viewModel';

function FuelEuGhGFuelExploreData(
  props: Readonly<{
    dataToggles: DataToggleButton[];
    onClick: (key: string) => void;
  }>
) {
  const handleToggleClick = (key: string) => {
    props.onClick(key);
  };

  return (
    <>
      {props.dataToggles.length > 0 && (
        <Box>
          <Box
            sx={{
              paddingTop: `${typography.spacing['4']}px`,
              paddingBottom: `${typography.spacing['2']}px`,
            }}
          >
            Select up to three additional data points.
          </Box>
          <ToggleButtonGroup
            color='primary'
            aria-label='data toggle button group'
            sx={{
              gap: '12px',
              mt: '20px',
              display: 'flex',
              width: '700px',
              flexWrap: 'wrap',
              marginTop: '20px',
            }}
          >
            {props.dataToggles.map((dt) => {
              return (
                <CiiDataToggleButton
                  key={dt.key}
                  circleFill={dt.circleFill}
                  selectedColor={dt.selectedColor}
                  title={dt.title}
                  name={dt.name}
                  isSelected={dt.isSelected}
                  onClick={() => {
                    handleToggleClick(dt.key);
                  }}
                />
              );
            })}
          </ToggleButtonGroup>
        </Box>
      )}
    </>
  );
}

export default FuelEuGhGFuelExploreData;
