export enum SearchType {
  vessel = 'VESSEL',
  port = 'PORT',
}

export enum BaseMapType {
  dark = 'Dark Gray Canvas',
  imagery = 'Imagery',
  oceans = 'Oceans',
}

export enum MapType {
  fleet = 'FLEET',
  vessel = 'VESSEL',
}
