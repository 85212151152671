import {
  DropdownGroup,
  DropdownGroupingCategory,
} from '../vesselGroupingsDropdown/models/vesselGroupings.viewModel';
import { TEXT } from 'shared/constants/text';

export const MAX_SUBGROUPS =
  TEXT.VESSEL_PERFORMANCE.GROUPING_FILTER_CHART.MAX_SUBGROUPS;

export const GroupingFilterTitle =
  TEXT.VESSEL_PERFORMANCE.GROUPING_FILTER_CHART.TITLE;

export const GroupingFilterSubTitle =
  TEXT.VESSEL_PERFORMANCE.GROUPING_FILTER_CHART.SUBTITLE;

export const InitializeSubgroups = (
  groupName: string,
  groups: DropdownGroupingCategory[],
  max: number
) => {
  const displayGroup = groups.filter(
    (x) => x.groupingDisplayName === groupName
  );

  const subgroups = displayGroup[0]?.groupings ?? ([] as DropdownGroup[]);
  subgroups.forEach((element, index) => {
    element.isChecked = index < max;
    element.isDisabled = index >= max;
  });

  return subgroups;
};

export const UpdateCurrentSubgroups = (
  subgroupName: string,
  currentSubgroups: DropdownGroup[],
  max: number
) => {
  let subgroups: DropdownGroup[] = [];
  const index = currentSubgroups.findIndex(
    (x) => x.groupingName === subgroupName
  );
  if (index > -1) {
    subgroups = currentSubgroups.slice();
    const subgroup = subgroups[index];
    if (subgroup) {
      subgroup.isChecked = !subgroup.isChecked;
      let checkedCount = 0;
      subgroups.forEach((x) => (x.isChecked ? checkedCount++ : checkedCount));
      subgroups.forEach(
        (x) => (x.isDisabled = checkedCount < max ? false : !x.isChecked)
      );
    }
  }

  return subgroups;
};

export const FilterGroups = (
  globalFilterNames: string[],
  allVesselGroups: DropdownGroupingCategory[]
): DropdownGroupingCategory[] => {
  if (globalFilterNames.length === 1 && globalFilterNames[0] === '')
    return allVesselGroups;

  const displayVesselGroups: DropdownGroupingCategory[] = [];

  globalFilterNames.forEach((name) => {
    // global filter names can be group names or subgroup names. look for groups
    const groupIndex = allVesselGroups.findIndex(
      (group) => group.groupingDisplayName === name.trim()
    );
    if (groupIndex > -1) {
      const group = allVesselGroups[groupIndex];
      if (group) {
        displayVesselGroups.push(group);
      }
    }

    // now look for subgroups
    const groupIndexForSubgroup = allVesselGroups.findIndex((group) =>
      group.groupings.some((x) => x.groupingName === name.trim())
    );
    if (groupIndexForSubgroup > -1) {
      const group = allVesselGroups[groupIndexForSubgroup];
      if (group) {
        const displayIndex = displayVesselGroups.findIndex(
          (x) => x.groupingDisplayName === group.groupingDisplayName
        );
        if (displayIndex > -1) {
          const newDisplayGroup = displayVesselGroups[displayIndex];
          const displaySubgroupToAdd = group.groupings.find(
            (x) => x.groupingName === name.trim()
          );
          if (newDisplayGroup && displaySubgroupToAdd) {
            newDisplayGroup.groupings.push(displaySubgroupToAdd);
          }
        } else {
          const newGroup = { ...group };
          const newGroupings = newGroup.groupings.filter(
            (x) => x.groupingName === name.trim()
          );
          newGroup.groupings = newGroupings;
          displayVesselGroups.push(newGroup);
        }
      }
    }
  });

  return displayVesselGroups;
};
