import { useEffect } from 'react';
import { useGetUser } from 'shared/services/user.service';
import { SignOutButton } from './SignOutButton';

/**
 * Renders buttons based on authentication status.
 */

export const AuthenticatedContent = () => {
  const { loading, error, data } = useGetUser();

  useEffect(() => {
    if (loading || error) return;

    window.aptrinsicSettings(
      data?.id,
      data?.firstName ?? '',
      data?.lastName ?? '',
      data?.email,
      data?.tenantId,
      data?.tenantName
    );

    console.debug('%cuserInfo', 'background-color: yellow', {
      user: data,
    });
  }, [loading, data, error]);

  return <SignOutButton />;
};
