import { ChartKeyItem } from 'shared/components/chart-key/chart-key.component';
import { theme } from 'styles/theme';

const ghgIntensity: ChartKeyItem = {
  id: 1,
  name: 'GHG Intensity',
  fill: theme.colors.white,
};
const ghgIntensityTarget: ChartKeyItem = {
  id: 2,
  name: 'GHG Intensity Target',
  fill: theme.environmentalMonitor.colors.cyan,
};
const fossilFuel: ChartKeyItem = {
  id: 3,
  name: 'Fossil',
  key: 'fossilFuel',
  fill: theme.colors.blueGray,
};
const bioFuel: ChartKeyItem = {
  id: 4,
  name: 'BioFuels',
  key: 'bioFuel',
  fill: theme.colors.purple,
};
const eFuel: ChartKeyItem = {
  id: 5,
  name: 'e-Fuels',
  key: 'eFuel',
  fill: theme.colors.magenta,
};

export interface IFuelEuGhgIntensityTrendChartKeyItems {
  ghgIntensity: ChartKeyItem;
  ghgIntensityTarget: ChartKeyItem;
  fossilFuel: ChartKeyItem;
  bioFuel: ChartKeyItem;
  eFuel: ChartKeyItem;
}

export const FuelEuGhgIntensityTrendChartKeyItems: IFuelEuGhgIntensityTrendChartKeyItems =
  {
    ghgIntensity: ghgIntensity,
    ghgIntensityTarget: ghgIntensityTarget,
    fossilFuel: fossilFuel,
    bioFuel: bioFuel,
    eFuel: eFuel,
  };
