import { GridApiPro } from '@mui/x-data-grid-pro';
import { createContext } from 'react';

export type GridDataProperties = {
  // The section of the grid
  sectionId: string;

  // The reference to the grid
  apiRef: React.MutableRefObject<GridApiPro>;
};

export type GridProperties = GridDataProperties & {
  // The name of the grid
  name: string;
};

export interface ExcelExportContextType {
  cardData?: any;
  filteredData?: { [key: string]: GridDataProperties };
  tilesData?: any;
  additionalIntroductoryRowsData?: { [key: string]: string[][] };
  setCardData: Function;
  setFilteredData: (grid: GridProperties) => void;
  setTilesData: Function;
  setAdditionalIntroductoryRowsData?: (
    sectionId: string,
    data: string[][]
  ) => void;
}

export const defaultContext: ExcelExportContextType = {
  cardData: [],
  filteredData: {},
  tilesData: [],
  additionalIntroductoryRowsData: {},
  setCardData: () => {},
  setFilteredData: () => {},
  setTilesData: () => {},
  setAdditionalIntroductoryRowsData: () => {},
};

export const ExcelExportContext =
  createContext<ExcelExportContextType>(defaultContext);
