import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { Section } from 'shared/components/section/section.component';
import { VesselVoyageMap } from './vessel-voyage-map';
import { useParams } from 'react-router-dom';
import { useVesselCurrentPositionService } from '../../../dashboard/services/vesselcurrentposition.get.service';
import { useTransformedVesselData } from 'shared/services/vesselData.service';

export function VesselVoyageRoute() {
  const { id } = useParams();

  const service = useVesselCurrentPositionService(900000); // Poll every 15 minutes
  const vesselPositions = useTransformedVesselData(service, id);
  const title = vesselPositions?.[0]?.VesselName ?? '';

  return (
    <PageWrapper data-testid={'vessel-voyage-section'}>
      <PageHeader title='Active Voyage Monitor' />
      <Section
        title={`${title} Map`}
        childComponent={
          <VesselVoyageMap VesselPosition={vesselPositions}></VesselVoyageMap>
        }
        preventUnmount
      />
    </PageWrapper>
  );
}
