import { KpiStatus, Query } from '_gql/graphql';

export const VesselSummaryData = (data: Query | undefined) => {
  const overallPassScore = data?.vessels?.[0]?.reports?.overallPassScore;
  const overallFailScore = data?.vessels?.[0]?.reports?.overallFailScore;
  const overallPassScorePercentage =
    data?.vessels?.[0]?.reports?.overallPassScorePercentage;
  const status = data?.vessels?.[0]?.reports?.status ?? KpiStatus.Unknown;

  return {
    overallPassScorePercentage,
    overallPassScore,
    overallFailScore,
    status,
  };
};
