import React from 'react';
import { useLocation, Link as ReactRouterLink } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { ROUTES } from 'router/router';
import { theme } from 'styles/theme';

interface BreadcrumbProps {
  vesselName?: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = (
  props: BreadcrumbProps
) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  if (pathnames.length <= 1) {
    return null;
  }

  const vesselName = props?.vesselName ?? '';
  const breadcrumbNamesAdded: string[] = [];

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const lastPathName = pathnames[pathnames.length - 1];
        if (lastPathName === undefined) {
          return null;
        }

        const isDetailPath = /\d/.test(value);
        const last = index === pathnames.length - 1 || isDetailPath;
        const route = Object.values(ROUTES).find((r) => {
          if (isDetailPath) {
            return r.path.startsWith(to) && r.path.endsWith(':id');
          }

          return r.path === to;
        });

        const breadcrumbName = isDetailPath
          ? vesselName
          : route?.breadcrumbName;

        if (!breadcrumbName) {
          return null;
        }

        if (breadcrumbNamesAdded.includes(breadcrumbName)) {
          return null;
        }
        breadcrumbNamesAdded.push(breadcrumbName);

        return last ? (
          <Typography color={theme.palette.text.primary} key={to}>
            {breadcrumbName}
          </Typography>
        ) : (
          <Link component={'button'} key={to}>
            <ReactRouterLink
              style={{ color: theme.palette.text.primary }}
              children={breadcrumbName}
              to={to}
            />
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
