import React from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
import { SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';

export interface ChartKeyItem {
  id: number;
  key?: string | undefined;
  name: string;
  fill: string;
  border?: string;
  backgroundImage?: string;
  borderWidth?: string;
}

export interface ChartKeyProps {
  title?: string | undefined;
  items: ChartKeyItem[];
  sx?: SxProps;
  icon?: 'circle' | 'square';
}

const keyStyle: SxProps = {
  ml: 4,
  mb: 3,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const iconStyle: SxProps = {
  ml: 1.5,
  mr: 0.5,
  fontSize: '18px',
};

export const ChartKey = (props: ChartKeyProps): EmotionJSX.Element => {
  return (
    <Box sx={{ ...keyStyle, ...props.sx }}>
      <Typography variant='menuItem'>{props.title ?? 'Key'}</Typography>
      {props.items?.map((item) => (
        <React.Fragment key={item.id}>
          {props.icon === 'square' ? (
            <SquareIcon
              sx={{
                ...iconStyle,
                color: item.fill,
                backgroundImage: item.backgroundImage ?? 'none',
                border: item.border ?? '0px',
                padding: '0px',
                borderWidth: item.borderWidth ?? '0px',
                mr: '8px',
              }}
            />
          ) : (
            <CircleIcon
              sx={{
                ...iconStyle,
                color: item.fill,
                backgroundImage: item.backgroundImage ?? 'none',
                border: item.border ?? '0px',
                padding: '0px',
                borderWidth: item.borderWidth ?? '0px',
                mr: '8px',
              }}
            />
          )}
          <Typography variant='body2' data-testid={`${item.key}-chart-key`}>
            {item.name}
          </Typography>
        </React.Fragment>
      ))}
    </Box>
  );
};
