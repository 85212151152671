import { SxProps } from '@mui/system';

export const modalStyle = (backgroundColor: string): SxProps => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '900px',
  maxHeight: '900px',
  bgcolor: backgroundColor,
  borderRadius: '8px',
  border: 'none',
  outline: 'none',
});
