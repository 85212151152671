import { VesselPerformanceIndicators } from '_gql/graphql';
import { VesselPerformanceKpiCardData } from '../../models/vesselPerformance.viewModel';
import { formatNumberForDisplay } from 'shared/utils/display-utils';
import { kpiStatusToEnum } from 'shared/utils/status-utils';
import { KpiData } from 'shared/components/status-kpi-card/status-kpi-card.component';

export class IndividualVesselPerformanceKPIMapper {
  public static kpisToDomain = (
    dto: VesselPerformanceIndicators
  ): VesselPerformanceKpiCardData => {
    return {
      deltaSpeed: this.deltaSpeedToDomain(dto?.deltaSpeed),
      deltaPower: this.deltaPowerToDomain(dto?.deltaPower),
      foulingResistance: this.foulingResistanceToDomain(dto?.foulingResistance),
      mesfoc: this.meSFOCToDomain(dto?.mesfoc),
      deltaPowerDailyFuelPenalty: this.deltaPowerDailyFuelPenaltyToDomain(
        dto?.deltaPowerFuelPenalty
      ),
      deltaPowerDailyFuelPenaltySum: this.deltaPowerTotalFuelPenaltyToDomain(
        dto?.deltaPowerFuelPenalty
      ),
      deltaPowerDailyCO2Penalty: this.deltaPowerDailyCO2PenaltyToDomain(
        dto?.deltaPowerDailyCO2Penalty
      ),
      deltaPowerDailyCO2PenaltySum: this.deltaPowerTotalCO2PenaltyToDomain(
        dto?.deltaPowerDailyCO2Penalty
      ),
    };
  };

  public static foulingResistanceToDomain = (
    dto: VesselPerformanceIndicators['foulingResistance']
  ): KpiData => {
    return {
      primaryValue: formatNumberForDisplay(dto?.value?.rahFoulingPercent, 0, 0),
      status: kpiStatusToEnum(dto?.value?.status),
    };
  };

  public static meSFOCToDomain = (
    dto: VesselPerformanceIndicators['mesfoc']
  ): KpiData => {
    return {
      primaryValue: formatNumberForDisplay(
        dto?.value?.sfocDeviationPercent,
        0,
        0
      ),
      status: kpiStatusToEnum(dto?.value?.status),
    };
  };

  public static deltaPowerToDomain = (
    dto: VesselPerformanceIndicators['deltaPower']
  ): KpiData => {
    return {
      primaryValue: formatNumberForDisplay(dto?.value?.deltaPower, 0, 0),
      status: kpiStatusToEnum(dto?.value?.status),
    };
  };

  public static deltaSpeedToDomain = (
    dto: VesselPerformanceIndicators['deltaSpeed']
  ): KpiData => {
    return {
      primaryValue: formatNumberForDisplay(dto?.value?.deltaSpeed, 0, 0),
      status: kpiStatusToEnum(dto?.value?.status),
    };
  };

  public static deltaPowerDailyFuelPenaltyToDomain = (
    dto: VesselPerformanceIndicators['deltaPowerFuelPenalty']
  ): KpiData => {
    return {
      primaryValue: formatNumberForDisplay(
        dto?.value?.deltaPowerDailyFuelPenalty,
        2
      ),
      status: kpiStatusToEnum(dto?.value?.dailyStatus),
    };
  };

  public static deltaPowerTotalFuelPenaltyToDomain = (
    dto: VesselPerformanceIndicators['deltaPowerFuelPenalty']
  ): KpiData => {
    return {
      primaryValue: formatNumberForDisplay(
        dto?.value?.deltaPowerDailyFuelPenaltySum,
        2
      ),
      status: kpiStatusToEnum(dto?.value?.totalStatus),
    };
  };

  public static deltaPowerDailyCO2PenaltyToDomain = (
    dto: VesselPerformanceIndicators['deltaPowerDailyCO2Penalty']
  ): KpiData => {
    return {
      primaryValue: formatNumberForDisplay(
        dto?.value?.deltaPowerDailyCO2Penalty,
        2
      ),
      status: kpiStatusToEnum(dto?.value?.dailyStatus),
    };
  };

  public static deltaPowerTotalCO2PenaltyToDomain = (
    dto: VesselPerformanceIndicators['deltaPowerDailyCO2Penalty']
  ): KpiData => {
    return {
      primaryValue: formatNumberForDisplay(
        dto?.value?.deltaPowerDailyCO2PenaltySum,
        2
      ),
      status: kpiStatusToEnum(dto?.value?.totalStatus),
    };
  };
}
