import { createContext } from 'react';
import { DropdownGroupingCategory } from 'shared/components/vesselGroupingsDropdown/models/vesselGroupings.viewModel';
import { DownloadListVessel } from '../../routes/reports/components/generateReport/download-common';

export interface TopNavigationType {
  vesselGroup: string[];
  vesselGroups: DropdownGroupingCategory[];
  dateRange: string[];
  vessels: DownloadListVessel[];
  vesselName: string[];
  setDateRange: Function;
  setVesselGroup: Function;
  setVesselGroups: (vesselGroups: DropdownGroupingCategory[]) => void;
  setVessels: Function;
  setVesselName: Function;
}

export const defaultContext: TopNavigationType = {
  dateRange: [],
  vesselGroup: [],
  vesselGroups: [],
  vesselName: [],
  vessels: [],
  setVesselGroup: () => null,
  setVesselGroups: () => null,
  setDateRange: () => {},
  setVessels: () => {},
  setVesselName: () => {},
};

export const TopNavigationContext =
  createContext<TopNavigationType>(defaultContext);
