import { useEffect, useContext } from 'react';
import { ServiceResult } from 'shared/models/serviceResult.model';
import { SearchType } from 'shared/components/esri-map/utils/constants';
import { MapViewContext } from 'shared/models/mapView.context.model';
import { useVoyageInfoForDrawerDashboard } from '../repositories/voyage-info-for-drawer.get.repository';
import { ActiveVoyageInfoForDashboardDrawerMapper } from '../mappers/voyage-info-for-drawer.mapper';

export const useGetActiveVoyageInfoForDrawerDashboardService =
  (): ServiceResult<any> => {
    const { searchInfo } = useContext(MapViewContext);
    const response = useVoyageInfoForDrawerDashboard(searchInfo.searchId ?? '');
    const activeVoyagePositionsData = response.data?.vessels;

    let data: any = null;
    const loading = response.loading as boolean;
    const error = response.error;
    const refectch = response.refetch;

    useEffect(() => {
      if (searchInfo.searchType === SearchType.vessel)
        refectch({ imoNumber: searchInfo.searchId ?? '' });
    }, [searchInfo.searchId, searchInfo.searchType, refectch]);

    if (activeVoyagePositionsData && activeVoyagePositionsData.length > 0) {
      data = ActiveVoyageInfoForDashboardDrawerMapper.fromDTO(
        activeVoyagePositionsData[0]
      );
    }
    return { data, loading, error };
  };
