import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { Section } from 'shared/components/section/section.component';
import { DashboardMap } from './dashboard-map';
import { ActiveVesselsGrid } from './components/active-vessels.component';
import { useVesselCurrentPositionService } from './services/vesselcurrentposition.get.service';
import { useTransformedVesselData } from 'shared/services/vesselData.service';
import { ExportableSections } from 'shared/utils/valid-sections';

export const constants = {
  HEADER_TITLE: 'My Dashboard',
  SECTION_ACTIVE_VESSELS: 'Fleet Map View',
  SECTION_ACTIVE_VESSELS_TABLE: 'Fleet Breakdown by Vessels',
  SECTION_ACTIVE_VESSELS_TABLE_ID:
    ExportableSections['Dashboard-Fleet Breakdown by Vessels'],
};

export function DashboardRoute() {
  const service = useVesselCurrentPositionService(900000); // Poll every 15 minutes
  const data = useTransformedVesselData(service);

  return (
    <PageWrapper data-testid={'dashboard-section'}>
      <PageHeader title={constants.HEADER_TITLE} />
      <Section
        title={constants.SECTION_ACTIVE_VESSELS}
        childComponent={<DashboardMap data={data} />}
        preventUnmount
      />
      <Section
        id={constants.SECTION_ACTIVE_VESSELS_TABLE_ID}
        title={constants.SECTION_ACTIVE_VESSELS_TABLE}
        exportSection
        exportOptions={{ includePDF: false }}
        childComponent={<ActiveVesselsGrid />}
        preventUnmount={true}
      />
    </PageWrapper>
  );
}
