export interface IUserDomain {
  id: string | undefined;
  fullName: string;
  tenantId: string;
  tenantName: string;
  permissions: IUserPermission[] | null;
  roles: string[] | null;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IUserPermission {
  module: PermissionModule;
  permissions: string[];
}

export type UserDomain = IUserDomain;
export type UserPermission = IUserPermission;

export enum PermissionModule {
  EnvironmentalMonitor = 'EnvironmentalMonitor',
  VesselDataMonitor = 'VesselDataMonitor',
  VesselPerformanceMonitor = 'VesselPerformanceMonitor',
  VoyagePlanningTracking = 'VoyagePlanningTracking',
}
