import { TEXT } from 'shared/constants/text';
import { formatNumberForDisplay } from 'shared/utils/display-utils';
import {
  KpiData,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { RAGDisplayProps } from 'shared/components/status-card/ragIndicator.component';
import { Status } from 'shared/models/status.type';
import { UTCDate } from 'shared/utils/date-utc-helper';

export interface VesselPerformanceRow {
  id: string;
  vesselImo: string;
  dateModified: UTCDate | undefined;
  name: string;
  className: string;
  lastCleaning: UTCDate | undefined | null;
  foulingResistance: RAGDisplayProps;
  dPower: RAGDisplayProps;
  dSpeed: RAGDisplayProps;
  dMainEngineSFOC: RAGDisplayProps;
  dPowerDailyFuelPenalty: RAGDisplayProps;
  dPowerTotalFuelPenalty: RAGDisplayProps;
  dPowerTotalDailyCO2Penalty: RAGDisplayProps;
  dPowerTotalCO2Penalty: RAGDisplayProps;
}

export interface VesselPerformanceData {
  value: string;
  status: Status;
}

export interface VesselPerformanceKpiCardData {
  deltaSpeed: KpiData;
  deltaPower: KpiData;
  foulingResistance: KpiData;
  mesfoc: KpiData;
  deltaPowerDailyFuelPenalty: KpiData;
  deltaPowerDailyFuelPenaltySum: KpiData;
  deltaPowerDailyCO2Penalty: KpiData;
  deltaPowerDailyCO2PenaltySum: KpiData;
}

const date = new UTCDate();

export const rows: VesselPerformanceRow[] = [
  {
    id: '1',
    dateModified: date,
    vesselImo: '1',
    name: 'Kota Lembah',
    className: 'Suezmax',
    lastCleaning: new UTCDate('3/12/2022'),
    foulingResistance: { value: '11', status: 'success' },
    dPower: { value: '35', status: 'error' },
    dSpeed: { value: '-2', status: 'success' },
    dMainEngineSFOC: { value: '10', status: 'warning' },
    dPowerDailyFuelPenalty: { value: '6.7', status: 'success' },
    dPowerTotalFuelPenalty: { value: '154.1', status: 'error' },
    dPowerTotalDailyCO2Penalty: { value: '21.5', status: 'success' },
    dPowerTotalCO2Penalty: { value: '494.1', status: 'success' },
  },
  {
    id: '2',
    dateModified: date,
    vesselImo: '2',
    name: 'Testeroni Pepperoni II',
    className: 'Suezmax',
    lastCleaning: new UTCDate('9/12/2022'),
    foulingResistance: { value: '-15', status: 'success' },
    dPower: { value: '12', status: 'warning' },
    dSpeed: { value: '-3', status: 'success' },
    dMainEngineSFOC: { value: '9', status: 'warning' },
    dPowerDailyFuelPenalty: { value: '1.5', status: 'success' },
    dPowerTotalFuelPenalty: { value: '94.7', status: 'success' },
    dPowerTotalDailyCO2Penalty: { value: '4.7', status: 'success' },
    dPowerTotalCO2Penalty: { value: '305.5', status: 'success' },
  },
  {
    id: '3',
    dateModified: date,
    vesselImo: '3',
    name: "St. Brendan's Gra II",
    className: 'LNG',
    lastCleaning: new UTCDate('11/1/2021'),
    foulingResistance: { value: '0', status: 'success' },
    dPower: { value: '0', status: 'success' },
    dSpeed: { value: '-2', status: 'success' },
    dMainEngineSFOC: { value: '5', status: 'success' },
    dPowerDailyFuelPenalty: { value: '0.0', status: 'success' },
    dPowerTotalFuelPenalty: { value: '0.0', status: 'success' },
    dPowerTotalDailyCO2Penalty: { value: '0.0', status: 'success' },
    dPowerTotalCO2Penalty: { value: '0.0', status: 'success' },
  },
  {
    id: '4',
    dateModified: date,
    vesselImo: '4',
    name: 'Josie Jane Lulu',
    className: 'LNG',
    lastCleaning: new UTCDate('12/14/2021'),
    foulingResistance: { value: '0', status: 'success' },
    dPower: { value: '0', status: 'success' },
    dSpeed: { value: '0', status: 'success' },
    dMainEngineSFOC: { value: '10', status: 'warning' },
    dPowerDailyFuelPenalty: { value: '0.0', status: 'success' },
    dPowerTotalFuelPenalty: { value: '0.0', status: 'success' },
    dPowerTotalDailyCO2Penalty: { value: '0.0', status: 'success' },
    dPowerTotalCO2Penalty: { value: '0.0', status: 'success' },
  },
  {
    id: '5',
    dateModified: date,
    vesselImo: '5',
    name: 'African Grouse',
    className: 'US Flag',
    lastCleaning: new UTCDate('9/14/2022'),
    foulingResistance: { value: '11', status: 'success' },
    dPower: { value: '13', status: 'warning' },
    dSpeed: { value: '1', status: 'success' },
    dMainEngineSFOC: { value: '3', status: 'success' },
    dPowerDailyFuelPenalty: { value: '1.5', status: 'success' },
    dPowerTotalFuelPenalty: { value: '44.2', status: 'success' },
    dPowerTotalDailyCO2Penalty: { value: '4.9', status: 'success' },
    dPowerTotalCO2Penalty: { value: '141.6', status: 'success' },
  },
  {
    id: '6',
    dateModified: date,
    vesselImo: '6',
    name: 'Galaxy Globe',
    className: 'Aframax',
    lastCleaning: new UTCDate('9/14/2022'),
    foulingResistance: { value: '15', status: 'success' },
    dPower: { value: '14', status: 'warning' },
    dSpeed: { value: '0', status: 'success' },
    dMainEngineSFOC: { value: '6', status: 'success' },
    dPowerDailyFuelPenalty: { value: '1.3', status: 'success' },
    dPowerTotalFuelPenalty: { value: '45.2', status: 'error' },
    dPowerTotalDailyCO2Penalty: { value: '4.0', status: 'warning' },
    dPowerTotalCO2Penalty: { value: '144.9', status: 'error' },
  },
  {
    id: '7',
    dateModified: date,
    vesselImo: '7',
    name: 'Kota Puri',
    className: 'Aframax',
    lastCleaning: new UTCDate('8/25/2022'),
    foulingResistance: { value: '-2', status: 'success' },
    dPower: { value: '1', status: 'success' },
    dSpeed: { value: '2', status: 'success' },
    dMainEngineSFOC: { value: '5', status: 'success' },
    dPowerDailyFuelPenalty: { value: '-0.4', status: 'success' },
    dPowerTotalFuelPenalty: { value: '-5.6', status: 'success' },
    dPowerTotalDailyCO2Penalty: { value: '-1.3', status: 'success' },
    dPowerTotalCO2Penalty: { value: '-18.0', status: 'success' },
  },
  {
    id: '8',
    dateModified: date,
    vesselImo: '8',
    name: 'Polar Endeavour',
    className: 'Aframax',
    lastCleaning: new UTCDate('8/25/2022'),
    foulingResistance: { value: '-3', status: 'success' },
    dPower: { value: '-10', status: 'success' },
    dSpeed: { value: '3', status: 'success' },
    dMainEngineSFOC: { value: '10', status: 'warning' },
    dPowerDailyFuelPenalty: { value: '-1.2', status: 'success' },
    dPowerTotalFuelPenalty: { value: '-7.3', status: 'success' },
    dPowerTotalDailyCO2Penalty: { value: '-3.9', status: 'success' },
    dPowerTotalCO2Penalty: { value: '-23.6', status: 'success' },
  },
];

export const deltaPowerTotalFuelPenaltyTitle = 'Total Excess Fuel';
export const deltaPowerTotalCo2PenaltyTitle = 'Total Excess CO₂';

const statusCardVariant = 'large';
export const getKpiCardFuelProps = (
  data: RAGDisplayProps
): StatusKpiCardProps => {
  return {
    variant: statusCardVariant,
    kpiData: {
      primaryValue: formatNumberForDisplay(data.value, 2),
      status: data.status,
    },
    title: deltaPowerTotalFuelPenaltyTitle,
    units: ` ${TEXT.UNIT_MEASUREMENT.METRIC_TONNES}`,
  } as StatusKpiCardProps;
};

export const getKpiCardCo2Props = (
  data: RAGDisplayProps
): StatusKpiCardProps => {
  return {
    variant: statusCardVariant,
    kpiData: {
      primaryValue: formatNumberForDisplay(data.value),
      status: data.status,
    },
    title: deltaPowerTotalCo2PenaltyTitle,
    units: ` ${TEXT.UNIT_MEASUREMENT.METRIC_TONNES}`,
  } as StatusKpiCardProps;
};
