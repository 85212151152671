import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import { DownloadListVessel } from './download-common';
import { useStatementOfFactReportPdf } from 'routes/reports/services/statement-of-fact-report-pdf.service';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { exportPDF } from 'shared/utils/export-report-pdf.utils';
import { IDownloadContextBase } from 'shared/models/reportDownload.contextBase.model';

const PdfDownloadContext = createContext<IDownloadContextBase>({
  handleGenerateLineItems: () => {},
  selectedVessels: [],
  showDownloadModal: false,
  imoNumber: '',
  vesselName: '',
  handleDownload: () => {},
  handleClose: () => {},
});

type PdfDownloadContextProviderProps = {
  children: ReactNode;
};

export const PdfDownloadContextProvider = ({
  children,
}: PdfDownloadContextProviderProps) => {
  const [selectedVessels, setSelectedVessels] = useState<DownloadListVessel[]>(
    []
  );
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [imoNumber, setImoNumber] = useState<string>('');
  const [vesselName, setVesselName] = useState<string>('');

  const { dateRange } = useContext(TopNavigationContext);
  const [startDate, endDate] = useMemo(() => {
    const dateRangeString = `${dateRange}`;
    return dateRangeString.split('-');
  }, [dateRange]);

  const { data: statementOfFactData, loading: statementOfFactLoading } =
    useStatementOfFactReportPdf(
      new DateRange(new UTCDate(startDate), new UTCDate(endDate)),
      imoNumber
    );

  useEffect(() => {
    if (statementOfFactData && statementOfFactLoading === false) {
      exportPDF(
        statementOfFactData?.statementOfFactReportPdf?.file?.fileContent,
        `${vesselName}_${startDate}-${endDate}`
      );
      setImoNumber(''); // Reset imoNumber
    }
  }, [
    endDate,
    startDate,
    statementOfFactData,
    statementOfFactLoading,
    vesselName,
  ]);

  const handleGenerateLineItems = (vessels: DownloadListVessel[]) => {
    setSelectedVessels(vessels);
  };

  const handleDownload = (key: string, vesselName: string) => {
    setShowDownloadModal(true);
    setImoNumber(key);
    setVesselName(vesselName);
  };

  const handleClose = () => {
    setShowDownloadModal(false);
  };

  const contextValue: IDownloadContextBase = {
    handleGenerateLineItems,
    selectedVessels,
    showDownloadModal,
    imoNumber,
    vesselName,
    handleDownload,
    handleClose,
  };

  return (
    <PdfDownloadContext.Provider value={contextValue}>
      {children}
    </PdfDownloadContext.Provider>
  );
};

export { PdfDownloadContext };
