import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { theme } from 'styles/theme';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  padding: '0px',
}));

export const WayPointCard = (props: any) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.background.componentLight,
        borderRadius: '8px',
        mb: '8px',
      }}
    >
      <CardActions
        disableSpacing
        sx={{ height: '48px', p: '12px', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '800',
              lineHeight: '150%' /* 24px */,
              letterSpacing: '0.15px',
              color: '#EFEFF0',
            }}
          >
            {props.index}
          </Typography>
          <LocationOnIcon
            sx={{
              ml: '2px',
              mr: '16px',
              color: 'rgba(129, 199, 132, 1)',
              fontSize: '16px',
            }}
          ></LocationOnIcon>
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: '400',
              color: '#EFEFF0',
            }}
          >
            {props.location}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: '400',
              color: '#EFEFF0',
              mr: '12px',
            }}
          >
            {props.arrivalDate ?? '---'}
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon sx={{ color: 'rgba(255, 255, 255, 0.56)' }} />
          </ExpandMore>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>{props.children}</CardContent>
      </Collapse>
    </Card>
  );
};
