import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';

export enum PortThemesName {
  light = 'light',
  dark = 'dark',
  imagery = 'imagery',
}

export const PortThemes = {
  [PortThemesName.light]: {
    PortRenderer: new SimpleRenderer({
      symbol: new SimpleFillSymbol({
        color: '#333333',
        outline: {
          width: 1,
          color: '#063970',
        },
      }),
    }),
  },

  [PortThemesName.dark]: {
    PortRenderer: new SimpleRenderer({
      symbol: new SimpleFillSymbol({
        color: '#737880',
        outline: {
          width: 1,
          color: 'white',
        },
      }),
    }),
  },

  [PortThemesName.imagery]: {
    PortRenderer: new SimpleRenderer({
      symbol: new SimpleFillSymbol({
        color: '#737880',
        outline: {
          width: 1,
          color: 'white',
        },
      }),
    }),
  },
};
