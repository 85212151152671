import { gql } from '@apollo/client';

export const GET_VESSEL_EVENT_DATA = gql`
  query GetVesselEventsData(
    $vesselId: String!
    $vesselImo: String!
    $dateRange: DateRangeInput!
  ) {
    vesselEvents(
      vesselId: $vesselId
      vesselImo: $vesselImo
      dateRange: $dateRange
    ) {
      id
      vesselId
      vesselImo
      eventName
      eventType
      startDate
      endDate
      location
      company
      portAndOtherCosts
      diverCost
      totalOosPeriod
      notes
      paybackPeriod
      bunkerPrice
      achievedImprovement
      dailyFuelSavings
      dailyFuelSavingsDollar
      daysToRecover
      totalEventCost
    }
  }
`;

export const CREATE_VESSEL_EVENT = gql`
  mutation CreateEvent($event: VesselEventInput!) {
    createVesselEvent(vesselEvent: $event) {
      id
      vesselId
      vesselImo
      eventName
      eventType
      startDate
      endDate
      location
      company
      portAndOtherCosts
      diverCost
      totalOosPeriod
      notes
      paybackPeriod
      bunkerPrice
    }
  }
`;

export const UPDATE_VESSEL_EVENT = gql`
  mutation UpdateEvent($event: VesselEventInput!) {
    updateVesselEvent(vesselEvent: $event) {
      id
      vesselId
      vesselImo
      eventName
      eventType
      startDate
      endDate
      location
      company
      portAndOtherCosts
      diverCost
      totalOosPeriod
      notes
      paybackPeriod
      bunkerPrice
    }
  }
`;

export const DELETE_VESSEL_EVENT = gql`
  mutation DeleteEvent($vesselEventId: String!) {
    deleteVesselEvent(vesselEventId: $vesselEventId)
  }
`;
