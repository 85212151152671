import { SxProps } from '@mui/material';
import { theme } from 'styles/theme';

export const InputStyles: SxProps = {
  color: theme.palette.text.primary,
  backgroundColor: theme.validatedModal.inputBackground,
  borderRadius: '8px',
  border: 'none',
  width: '100%',
  '& .MuiInputBase-root:not(.MuiInputBase-multiline)': {
    height: '30px',
  },
  '& .MuiInputBase-multiline textarea': {
    maxHeight: '100px',
    overflowY: 'auto !important',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255,255,255,0.08)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '4px',
    },
  },
  '& .MuiInput-input': {
    paddingLeft: '12px',
    paddingRight: '12px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiInputLabel-shrink': {
    fontSize: '14px',
    left: '12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiFormLabel-root': {
    paddingTop: '4px',
    fontSize: '14px',
    color: theme.palette.text.secondary,
    transformOrigin: 'center left',
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.error.light,
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
  },
};

export const SelectStyles: SxProps = {
  color: theme.palette.text.primary,
  backgroundColor: theme.validatedModal.inputBackground,
  borderRadius: '8px',
  border: 'none',
  height: '48px',
  width: '100%',
  '& .MuiInputBase-root': {
    mt: 0,
    '&:before, &:after': {
      border: 'none',
      borderRadius: '8px',
    },
    '&:hover:not(.Mui-disabled, .Mui-error):before': {
      border: 'none',
    },
    '& input': {
      height: '24px',
      m: 0,
      border: 'none',
    },
  },
  '& .MuiInputLabel-shrink': {
    fontSize: '14px',
    color: theme.palette.text.primary,
  },
  '& .MuiFormLabel-root': {
    pt: '12px',
    fontSize: '14px',
    color: theme.palette.text.secondary,
    transformOrigin: 'center left',
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    },
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.error.light,
  },

  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    pl: '14px',
    pb: '8px',
    pt: '17px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: '48px',
    top: '0px',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
  },
  '& .MuiSelect-iconStandard': {
    right: '7px',
  },
};

export const AutocompleteStyles: SxProps = {
  color: theme.palette.text.primary,
  backgroundColor: theme.validatedModal.inputBackground,
  borderRadius: '8px',
  border: 'none',
  height: '48px',
  width: '100%',
  '& .MuiInputBase-root': {
    mt: 0,
    '&:before, &:after': {
      border: 'none',
      borderRadius: '8px',
    },
    '&:hover:not(.Mui-disabled, .Mui-error):before': {
      border: 'none',
    },
    '& input': {
      height: '24px',
      m: 0,
      mt: '14px',
      ml: 1.5,
      border: 'none',
    },
  },
  '& .MuiInputLabel-shrink': {
    fontSize: '14px',
    color: theme.palette.text.primary,
  },
  '& .MuiInput-input': {
    paddingLeft: '12px',
    paddingRight: '12px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiFormLabel-root': {
    pt: 0.5,
    pl: 2,
    fontSize: '14px',
    color: theme.palette.text.secondary,
    transformOrigin: 'center left',
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    },
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.error.light,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: '48px',
    top: '0px',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
  },
  '& .MuiAutocomplete-endAdornment': {
    right: '6px',
    top: '10px',
  },
  '.MuiAutocomplete-option': {
    pl: 1.5,
  },
};

export const EmergencyHomeIconStyles: SxProps = {
  color: theme.palette.error.main,
  width: '12px',
  mr: '3px',
  position: 'relative',
  top: '-1px',
};
