import { Box, Skeleton } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LocationsContextWrapper } from 'shared/components/contexts/locationsWrapper.component';
import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { Section } from 'shared/components/section/section.component';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { useGetVesselPerformance } from '../services/vessel-performance.service';
import { EventModal } from './components/events/event-modal.component';
import { useGetEvents } from './components/events/services/events.service';
import { PerformanceTrends } from './components/vessel-performance-trends.component';
import { VesselPerformanceEventsTable } from './components/vesselPerformanceEventsTable.component';
import { PerformanceKPIs } from './performanceKPIs';
import { ExportableSections } from 'shared/utils/valid-sections';

export const constants = {
  // sections
  INDV_PERFORMANCE_MONITOR_PAGE_TITLE: 'Performance Monitor: Vessel Overview',
  PERFORMANCE_KPIS_SECTION_TITLE: 'Vessel KPIs',
  LOG_KPIS_SECTION_ID: ExportableSections['VPM-Vessel Events Log'],
  VESSEL_PERFOMANCE_TREND_SECTION_TITLE: 'Vessel KPI Trends',
  EVENTS_SECTION_TITLE: "Vessel's Performance Improvement Events Log",

  // charts
  FOULING_RES_CHART_TITLE: "Vessel's Fouling Resistance & Event Impact Trend",
  DELTA_POWER_CHART_TITLE: "Vessel's Excess Power & Event Impact Trend",
  DELTA_SPEED_CHART_TITLE: "Vessel's Speed Loss & Event Impact Trend",
  DELTA_ENGINE_SFOC_TITLE: "Vessel's Main Engine SFOC & Event Impact Trend",
  INDV_VESSEL_EVENT_INSIGHT_TITLE: 'Vessel Events Insights',

  // y-axis labels
  ADDITIONAL_RESISTANCE_LABEL: 'Fouling Resistance (%)',
  DELTA_POWER_LABEL: 'Excess Power (%)',
  DELTA_SPEED_LABEL: 'Speed Loss (%)',
  DELTA_ENGINE_SFOC_LABEL: 'Specific Fuel Oil Consumption (%)',
};
export function IndividualVesselPerformance() {
  const { id, vesselImo } = useParams();
  const { setVesselName } = useContext(TopNavigationContext);
  const { utcDateRange } = useContext(NavBarContext);

  const { data, loading, error } = useGetVesselPerformance(
    utcDateRange,
    vesselImo ? [vesselImo] : []
  );

  const vesselData = data?.find((vessel) => vessel.id === id);

  useEffect(() => {
    setVesselName(vesselData?.name ?? '');
  }, [setVesselName, vesselData?.name]);

  const { eventLoading, eventError, eventsData } = useGetEvents(
    id,
    vesselImo,
    utcDateRange
  );

  if (error) {
    // placeholder to show an error when we navigate to an invalid route
    return (
      <PageWrapper>
        <Box>Vessel not found.</Box>
      </PageWrapper>
    );
  }

  const SkeletonPlaceholder = () => (
    <Skeleton
      sx={{ mt: 3, height: '50vh' }}
      variant='rounded'
      animation='pulse'
    ></Skeleton>
  );

  return (
    <LocationsContextWrapper>
      <PageWrapper>
        <PageHeader
          title={`${vesselData?.name ?? ''} ${
            constants.INDV_PERFORMANCE_MONITOR_PAGE_TITLE
          }`}
        />
        <Section
          id={ExportableSections['VPM-Fleet KPIs']}
          title={constants.PERFORMANCE_KPIS_SECTION_TITLE}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            introductoryRows: { includeVesselName: true },
            includeCardData: true,
          }}
          childComponent={
            loading ? (
              <SkeletonPlaceholder />
            ) : (
              <PerformanceKPIs vesselImo={vesselImo ?? ''} />
            )
          }
        ></Section>
        <Section
          title={constants.VESSEL_PERFOMANCE_TREND_SECTION_TITLE}
          exportOptions={{ includePDF: false }}
          childComponent={
            loading ? (
              <SkeletonPlaceholder />
            ) : (
              <PerformanceTrends
                dateRange={utcDateRange}
                vesselImo={vesselImo ?? ''}
                vesselId={id ?? ''}
              />
            )
          }
        ></Section>
        <Section
          id={constants.LOG_KPIS_SECTION_ID}
          title={constants.EVENTS_SECTION_TITLE}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            introductoryRows: { includeVesselName: true },
          }}
          customButtons={<EventModal />}
          preventUnmount={true}
          childComponent={
            <VesselPerformanceEventsTable
              eventsData={eventsData}
              error={eventError}
              loading={eventLoading}
            />
          }
        ></Section>
      </PageWrapper>
    </LocationsContextWrapper>
  );
}
