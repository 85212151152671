import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from '../../../auth/config';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

export const SignInButton = () => {
  const { instance, inProgress } = useMsal();
  const defaultFontSize = 16;

  const handleLogin = () => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }

    const loginPromise = instance.loginRedirect(loginRequest);

    loginPromise
      .then(() => {
        console.info('msal: loginRedirect success');
      })
      .catch((e) => {
        console.error('msal: loginRedirect failure', e);
      });
  };

  return (
    <IconButton
      size='large'
      aria-label='account of current user'
      aria-controls='menu-appbar'
      aria-haspopup='true'
      onClick={() => handleLogin()}
      color='inherit'
    >
      <Typography
        variant='subtitle2'
        component='div'
        noWrap={true}
        sx={{ m: 1, fontSize: defaultFontSize, fontWeight: 400 }}
      >
        Sign in
      </Typography>
    </IconButton>
  );
};
