import { Box, ListItem, Typography } from '@mui/material';
import { getIcon } from './vessel-details-tile.viewModel';
import Tooltip from '@mui/material/Tooltip';
import { FieldValidation } from '_gql/graphql';

const toolTipStyle = {
  display: 'flex',
  width: '79px',
  padding: '4px 8px',
  justifyContent: 'center',
  alignItems: 'center',
};

export const VesselDetailsTile = (props: FieldValidation) => {
  const toolTipMessage = props.validation?.reason;
  const validValue = props.validation == null;

  return (
    <ListItem
      sx={{
        display: 'flex',
        height: '73px',
        py: '12px',
        px: '0px',
        alignItems: 'flex-start',
        gap: '20px',
        alignSelf: 'stretch',
        label: props.fieldName,
      }}
      key={props.fieldName}
    >
      {toolTipMessage && props.validation?.code ? (
        <Tooltip
          sx={{ toolTipStyle }}
          title={toolTipMessage}
          placement='top'
          arrow
        >
          {getIcon(validValue, props.isEmission === true)}
        </Tooltip>
      ) : (
        <>{getIcon(validValue, props.isEmission === true)}</>
      )}

      <Box sx={{ flexDirection: 'column', alignSelf: 'center', py: '12px' }}>
        <Typography fontSize='16px' fontWeight={700} lineHeight={'20px'}>
          {props.fieldName}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography fontSize='16px' fontWeight={300} lineHeight={'20px'}>
            {props.fieldValue ?? '---'}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};
