import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { GET_FLEET_KPIS_FOR_DASHBOARD_TABLE } from '../queries/fleet-kpis-table.get.query';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetFleetKPIDashboardTableData = (
  dateRange: DateRange | null,
  vesselImos: string[] = []
) => {
  return useQuery<Query>(GET_FLEET_KPIS_FOR_DASHBOARD_TABLE, {
    skip: !dateRange?.dateRangeAsString,
    errorPolicy: 'all', // Shows data even if there is an error
    variables: {
      ...dateRange?.dateRangeAsString,
      vesselImos,
    },
  });
};
