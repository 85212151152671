import { gql } from '@apollo/client';

export const GET_ACTIVE_VOYAGE_INFO_FOR_DRAWER = gql`
  query GetActiveVoyageMonitorInfoForDrawer($imoNumber: String!) {
    vessels(where: { imoNumber: { eq: $imoNumber } }) {
      id
      name
      imoNumber
      currentPosition {
        id
        latitude
        longitude
        heading
        timestamp
      }
      activeVoyage {
        voyageNumber
        origin
        lastDepartureLocation
        nextDestinationLocation
        departureTimeFromLastDepartureLocation
        waypoints {
          waypointNumber
          dateTime
          latitude
          longitude
          sailType
        }
      }
    }
  }
`;
