import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ReferenceAreaData } from '../models/vessel-performance-trends.model';
import { theme } from 'styles/theme';

export const ColorBarGradientArea = (props: {
  data: ReferenceAreaData[];
  domainY: number[];
}) => {
  const chartData = props.data;
  const colors = [
    theme.colors?.aRating,
    theme.colors?.bRating,
    theme.colors?.eRating,
  ];

  return (
    <ResponsiveContainer height={272}>
      <AreaChart data={chartData} margin={{ top: 6, bottom: 0, right: 22 }}>
        <XAxis
          dataKey={'position'}
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='maxValue'
          tickLine={false}
          tick={false}
          tickCount={8}
          tickMargin={0}
          type='number'
          domain={props.domainY}
          axisLine={false}
          dx={-12}
          width={75}
        />
        {colors.map((fill, index) => {
          return (
            <svg key={index}>
              <defs>
                return (
                <linearGradient
                  key={fill}
                  id={'grad-' + fill}
                  x1='0%'
                  y1='0%'
                  x2='100%'
                  y2='0%'
                >
                  <stop
                    offset='0%'
                    style={{ stopColor: fill, stopOpacity: 0.5 }}
                  />
                  <stop
                    offset='33%'
                    style={{
                      stopColor: theme.background.page,
                      stopOpacity: 0,
                    }}
                  />
                  <stop
                    offset='66%'
                    style={{
                      stopColor: theme.background.page,
                      stopOpacity: 0,
                    }}
                  />
                  <stop
                    offset='100%'
                    style={{ stopColor: fill, stopOpacity: 0.5 }}
                  />
                </linearGradient>
                );
              </defs>
            </svg>
          );
        })}

        <Area
          type='monotone'
          dataKey='aValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[0] + ')'}
        />
        <Area
          type='monotone'
          dataKey='bValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[1] + ')'}
        />
        <Area
          type='monotone'
          dataKey='cValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[2] + ')'}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
