import { useState, useMemo, useCallback } from 'react';

export enum Status {
  success = 'success',
  warning = 'warning',
  error = 'error',
  primary = 'primary',
  graph = 'graph',
  unknown = 'unknown',
}

export enum FilterValue {
  ON_TARGET = Status.success,
  AT_RISK = Status.warning,
  WARNING = Status.error,
}

interface Filterable {
  [key: string]: any;
}

export const useFilter = <T>(
  data: T[],
  keyExtractor: (item: T) => Filterable
) => {
  const [activeFilters, setActiveFilters] = useState<FilterValue[]>([]);

  const filteredData = useMemo(() => {
    if (!activeFilters.length) {
      return data;
    }

    return data.filter((item: T) => {
      const filterableItem = keyExtractor(item);
      const statuses = Object.values(filterableItem).map((property: any) =>
        property?.status?.toLowerCase()
      );

      return activeFilters.some((filter: FilterValue) =>
        statuses.includes((Status as any)[filter])
      );
    });
  }, [activeFilters, data, keyExtractor]);

  // Use useCallback for handleFilter to memoize the function
  const handleFilter = useCallback((filterValues: FilterValue[]) => {
    setActiveFilters(filterValues);
  }, []);

  return {
    activeFilters,
    filteredData,
    handleFilter,
  };
};
