export const mockDailyPenaltyData = [
  {
    date: '23 Jan 2023', // For testing that we're not rendering duplicated dates
  },
  {
    date: '23 Jan 2023',
    'Vessel Class A': 14,
    'Vessel Class B': 18,
    'Vessel Class C': 14,
  },
  {
    date: '23 Feb 2023',
    'Vessel Class A': 21,
    'Vessel Class B': 24,
    'Vessel Class C': 11,
  },
  {
    date: '23 Mar 2023',
    'Vessel Class A': 15,
    'Vessel Class B': 20,
    'Vessel Class C': 21,
  },
  {
    date: '23 Apr 2023',
    'Vessel Class A': 16,
    'Vessel Class B': 6,
    'Vessel Class C': 26,
  },
  {
    date: '23 Jun 2023',
    'Vessel Class A': 12,
    'Vessel Class B': 8,
    'Vessel Class C': 22,
  },
  {
    date: '23 Jul 2023',
    'Vessel Class A': 8,
    'Vessel Class B': 14,
    'Vessel Class C': 17,
  },
  {
    date: '23 Aug 2023',
    'Vessel Class A': 5,
    'Vessel Class B': 18,
    'Vessel Class C': 11,
  },
  {
    date: '23 Sep 2023',
    'Vessel Class A': 12,
    'Vessel Class B': 21,
    'Vessel Class C': 14,
  },
  {
    date: '23 Oct 2023',
    'Vessel Class A': 16,
    'Vessel Class B': 15,
    'Vessel Class C': 4,
  },
  {
    date: '23 Nov 2023',
    'Vessel Class A': 13,
    'Vessel Class B': 10,
    'Vessel Class C': 16,
  },
  {
    date: '23 Dec 2023',
    'Vessel Class A': 18,
    'Vessel Class B': 18,
    'Vessel Class C': 22,
  },
  {
    date: '23 Jan 2024',
    'Vessel Class A': 22,
    'Vessel Class B': 28,
    'Vessel Class C': 26,
  },
  {
    date: '23 Jan 2024', // For testing that we're not rendering duplicated dates
  },
];
