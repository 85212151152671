import { gql } from '@apollo/client';

const VesselReportTileFragment = gql`
  fragment reportTileData on FieldValidation {
    fieldName
    fieldValue
    isEmission
    validation {
      code
      reason
    }
  }
`;

export const GET_VESSEL_REPORT_TILE = gql`
  ${VesselReportTileFragment}
  query GetVesselReportTile(
    $vesselIMO: String!
    $generatedReportId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vessels(where: { imoNumber: { eq: $vesselIMO } }) {
      id
      imoNumber
      reports(startDate: $startDate, endDate: $endDate) {
        id
        vesselReports(
          where: { generatedReportId: { eq: $generatedReportId } }
        ) {
          generalTileData {
            ...reportTileData
          }
          speedAndPerformanceTileData {
            ...reportTileData
          }
          voyageTileData {
            ...reportTileData
          }
          climateTilesData {
            seaState {
              ...reportTileData
            }
            weatherCondition {
              ...reportTileData
            }
          }
          consumables {
            ...reportTileData
          }
        }
      }
    }
  }
`;
