import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { ChartKeyProps } from 'shared/components/chart-key/chart-key.component';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { theme } from 'styles/theme';

export const FormatUnixDate = (value: number): string => {
  return new UTCDate(value).formatDMY();
};

export const FormatTooltip = (
  value: ValueType,
  name: NameType,
  xAxisName: string,
  title: string
) => {
  let formattedValue = null;
  let formattedName = null;

  switch (name) {
    case xAxisName: {
      formattedValue = FormatUnixDate(value as number);
      break;
    }
    case title: {
      formattedValue = Math.round(value as number).toString();
      formattedName = name + ' (%)';
      break;
    }
    default: {
      formattedValue = value;
      break;
    }
  }

  return [formattedValue, formattedName ?? name];
};

export const ChartKeyItems = (title: string): ChartKeyProps => {
  const secondaryPurple = theme.vesselPerformance.charts.secondaryPurple;
  const secondaryMagenta = theme.vesselPerformance.charts.secondaryMagenta;
  const secondaryGray = theme.vesselPerformance.charts.secondaryGray;
  return {
    items: [
      { id: 1, name: 'Trends', fill: theme.palette.action.active },
      { id: 2, name: 'Events', fill: theme.palette.text.primary },
      { id: 3, name: title, fill: secondaryMagenta },
      { id: 4, name: 'Missing Data Points', fill: secondaryPurple },
      { id: 5, name: 'Outlier Data', fill: secondaryGray },
    ],
  };
};

export const ChartTicks = (
  startDate: UTCDate | undefined,
  endDate: UTCDate | undefined,
  tickCount: number
): number[] => {
  const diff = endDate?.dateDayjs?.diff(startDate?.dateDayjs, 'day') ?? NaN;
  // Calculate the ideal interval between ticks, distributing the remainder.
  const interval = Math.floor(diff / (tickCount - 1));
  const remainder = diff % (tickCount - 1);
  const ticks = [];
  let currentTick = startDate?.dateDayjs;
  for (let i = 0; i < tickCount; i++) {
    // Push the current tick value.
    ticks.push(currentTick?.unix()!);
    // Calculate the next tick, adding one more day for each remainder.
    const nextInterval = interval + (i < remainder ? 1 : 0);
    currentTick = currentTick?.add(nextInterval, 'day');
  }
  // Replace the last tick with the exact end date to avoid rounding errors.
  ticks[tickCount - 1] = endDate?.dateDayjs?.unix()!;
  return ticks;
};
