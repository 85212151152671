import { FC, useState, MouseEvent } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonCircle from 'shared/components/button-circle/button-circle.component';
import { CiiDataToggleButtonProps } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import { Box } from '@mui/system';
import { theme } from 'styles/theme';
import { ExpandMore } from '@mui/icons-material';
import { typography } from 'styles/typography';
import { Checkbox, ToggleButton } from '@mui/material';
import * as ViewModel from 'routes/environmental-monitor/view-models/cii-explore-data.viewModel';

const CiiDataToggleDropdownButton: FC<CiiDataToggleButtonProps> = ({
  name,
  options,
  optionSelected,
  circleFill,
  selectedColor,
  title,
  isSelected,
  onClick,
}) => {
  const handleToggle = (option: string, value: boolean) => {
    onClick(`${name}.${option}`, value);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (selected: boolean, option: string) => {
    setAnchorEl(null);
    if (!option) return;

    handleToggle(option, !selected);
  };

  return (
    <>
      <ToggleButton
        onClick={handleMenuClick}
        value={title}
        sx={{
          textTransform: 'none',
          paddingY: `${typography.spacing['1']}px`,
          paddingLeft: `${typography.spacing['2']}px`,
          paddingRight: '12px', // 12 due to size of ExpandMore icon
          background: isSelected
            ? selectedColor
            : theme.environmentalMonitor.toggleButton.backgroundColor,
          boxShadow: isSelected
            ? theme.environmentalMonitor.toggleButton.boxShadow
            : 'none',
          border: '0',
          borderRadius: `${typography.shape.borderRadius}px`,
          color: theme.palette.primary.contrastText,
          fontWeight: 500,
          marginRight: '1px',
        }}
      >
        <div>
          {typeof circleFill === 'string' ? (
            <ButtonCircle fill={circleFill} border={selectedColor} />
          ) : (
            <ButtonCircle
              fill={circleFill.inner}
              border={circleFill.border}
              dashed={circleFill.borderStyle === 'dashed'}
            />
          )}
        </div>
        <Box
          sx={{
            paddingLeft: `${typography.spacing['1']}px`,
            paddingRight: '4px', // 4 due to size of ExpandMore icon
          }}
        >
          {title}
        </Box>
        <Box onClick={handleMenuClick} sx={{ lineHeight: '1' }}>
          <ExpandMore />
        </Box>
      </ToggleButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(false, '')}
      >
        {options?.map((option: string) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuClose(optionSelected === option, option)}
            sx={{ textTransform: 'none' }}
          >
            <Checkbox checked={optionSelected === option} sx={{ pl: 0 }} />
            {ViewModel.formatTitle(option, ' (t)')}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CiiDataToggleDropdownButton;
