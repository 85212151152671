import OpenIcon from 'assets/alert-status-open-icon.svg';
import ClosedIcon from 'assets/alert-status-closed-icon.svg';
import ArchivedIcon from 'assets/alert-status-archived-icon.svg';
import { theme } from 'styles/theme';
import { StatusKpiCardValues } from 'shared/components/status-kpi-card/status-kpi-card.viewModel';

export const getAlertStatusColor = (status: string): StatusKpiCardValues => {
  switch (status) {
    case 'success': // open
      return { color: theme.alerts.status.open, icon: OpenIcon };
    case 'error': // closed
      return { color: theme.alerts.status.closed, icon: ClosedIcon };
    case 'primary': // archived
      return { color: theme.alerts.status.archived, icon: ArchivedIcon };
    default:
      return { color: theme.palette.divider };
  }
};
