import { ThemeProvider } from '@mui/material/styles';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { Section } from 'shared/components/section/section.component';
import { theme } from 'styles/theme';
import { VesselDetailHeader } from './vessel-detail-header.component';
import { VesselKPI } from './vessel-kpi.component';
import { VoyageComparison } from './voyage-comparison/voyage-comparison.component';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { ExportableSections } from 'shared/utils/valid-sections';
import { ItineraryComparison } from '../vesselPlannedItinerary/plannedItinerary.component';
import { VesselEUkpi } from './vessel-eu.component';

export function VesselDetail() {
  return (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <VesselDetailHeader />
        <Section
          id={ExportableSections['EM-Vessel KPIs']}
          title={vesselPageTitles.imoOverview}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            includeCardData: true,
            introductoryRows: { includeVesselName: true },
          }}
          childComponent={<VesselKPI />}
        />
        <Section
          id={ExportableSections['EU-Overview']}
          title={vesselPageTitles.euOverview}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            includeCardData: true,
            introductoryRows: { includeVesselName: true },
          }}
          childComponent={<VesselEUkpi />}
        />
        <Section
          id={vesselPageTitles.voyageLegsComparisonId}
          title={vesselPageTitles.voyageLegEmissionComparison}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            introductoryRows: { includeVesselName: true },
          }}
          childComponent={<VoyageComparison />}
          preventUnmount={true}
        />

        <Section
          id={'EM-Planned Itinerary'}
          title={'Vessel Planned Itinerary'}
          exportSection={true}
          exportOptions={{
            includePDF: false,
            introductoryRows: { includeVesselName: true },
          }}
          childComponent={<ItineraryComparison />}
        />
      </PageWrapper>
    </ThemeProvider>
  );
}
