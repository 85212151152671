import { useQuery } from '@apollo/client';
import { GET_VESSEL_REPORT_TILE } from '../queries/vessel-report-tile.query';
import { Query } from '_gql/graphql';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetVesselReportTile = (
  vesselIMO: string | undefined,
  generatedReportId: string | undefined,
  dateRange: DateRange
) => {
  const dateRangeAsString = dateRange.dateRangeAsString;
  const query = useQuery<Query>(GET_VESSEL_REPORT_TILE, {
    skip: !(vesselIMO && generatedReportId && dateRangeAsString),
    variables: {
      vesselIMO,
      generatedReportId,
      ...dateRangeAsString,
    },
  });
  return query;
};
