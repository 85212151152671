import { MDFMap } from 'shared/components/esri-map/map.component';
import { DrawerInsideMap } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer.component';
import { MapDrawerComponent } from './components/drawer/drawer-component';
import { PortsLayer } from 'shared/components/esri-map/utils/PortsLayer';
import { unlocodes } from 'routes/voyage-monitor/voyage-monitor-map';
import { IVesselPosition } from 'shared/components/esri-map/utils/vesselLayer';

export function DashboardMap({ data }: { data: IVesselPosition[] }) {
  return (
    <MDFMap displaysearch={true} data={data}>
      <PortsLayer data={unlocodes}></PortsLayer>
      <DrawerInsideMap>
        <MapDrawerComponent></MapDrawerComponent>
      </DrawerInsideMap>
    </MDFMap>
  );
}
