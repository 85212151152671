import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVesselKPIs } from 'routes/environmental-monitor/services/vessel.service';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { sectionContext } from 'shared/components/section/section.component';
import {
  GridItemKpiContainer,
  KpiCardGridContainer,
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { TEXT } from 'shared/constants/text';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { CIIRatingsChart } from './cii-ratings/cii-ratings-chart.component';
import { AERChartMui } from './aer/aer-chart-mui.component';

export const VesselKPI = () => {
  const { id } = useParams();
  const { utcDateRange } = useContext(NavBarContext);
  const { data, loading, error } = useGetVesselKPIs(id, utcDateRange);

  const AERCardProps: StatusKpiCardProps = {
    title: 'AER',
    footerType: 'required',
    kpiData: data?.aer,
    footerUnits: TEXT.FORMULAS.AER,
    sectionVisibility: { footer: true },
  };

  const CIIRatingCardProps: StatusKpiCardProps = {
    title: 'CII Rating',
    footerType: 'required',
    kpiData: data?.ciiRating,
    units: data?.ciiRating?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
  };

  const EEOICardProps: StatusKpiCardProps = {
    title: 'EEOI',
    footerType: 'target',
    kpiData: data?.eeoi,
    footerUnits: TEXT.FORMULAS.EEOI,
    sectionVisibility: { footer: true },
  };

  const CIIYearToDateCardProps: StatusKpiCardProps = {
    title: 'CII Year to Date',
    footerType: 'required',
    kpiData: data?.ciiYearToDate,
    units: data?.ciiYearToDate?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
    fixedTimeline: true,
  };

  const CIIForecastCardProps: StatusKpiCardProps = {
    title: 'CII End of Year Forecast',
    footerType: 'required',
    kpiData: data?.ciiForecast,
    units: data?.ciiForecast?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    sectionVisibility: { footer: true },
    fixedTimeline: true,
  };

  const kpiData = [
    AERCardProps,
    CIIRatingCardProps,
    EEOICardProps,
    CIIYearToDateCardProps,
    CIIForecastCardProps,
  ];

  const { setCardData } = useContext(ExcelExportContext);
  const section = useContext(sectionContext);

  useEffect(() => {
    setCardData(kpiData, section);
  }, [loading]);

  if (loading) {
    return <KpiCardSkeleton kpiCards={kpiData} />;
  }

  if (error) {
    return <KpiCardError kpiCards={kpiData} />;
  }

  return (
    <Box>
      <KpiCardGridContainer>
        {kpiData.map((cardProps) => (
          <GridItemKpiContainer key={cardProps.title}>
            <StatusKpiCard {...cardProps} />
          </GridItemKpiContainer>
        ))}
      </KpiCardGridContainer>
      <Box display='flex' flexDirection='column' sx={{ marginTop: 2 }}>
        <CIIRatingsChart />
        <AERChartMui />
      </Box>
    </Box>
  );
};
