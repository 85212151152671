interface FeatureCollection {
  features: Feature[];
}

export interface PortData {
  Port_Name: string;
  UNLOCODE: string;
}

export const EMPTY_PORT_DATA: PortData = {
  Port_Name: '',
  UNLOCODE: '',
};

interface Feature {
  properties: PortData;
}

export const fetchPorts = async (): Promise<string[]> => {
  try {
    const response = await fetch('/portpolygons.json');
    const data: FeatureCollection = await response.json();
    const features = data.features.map(
      (feature) => feature.properties.Port_Name
    );

    //sort alphabetically by port name
    features.sort((a: string, b: string) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    return features;
  } catch (error) {
    console.log('Error fetching portpolygons', error);
    return [];
  }
};

export const fetchPortData = async (): Promise<PortData[]> => {
  try {
    const response = await fetch('/portpolygons.json');
    const data: FeatureCollection = await response.json();
    const features: PortData[] = data.features
      .map((feature) => {
        return {
          Port_Name: feature.properties.Port_Name,
          UNLOCODE: feature.properties.UNLOCODE,
        };
      })
      .filter((feature) => feature.Port_Name && feature.UNLOCODE); // make sure the port name and UNLOCODE are not empty

    //sort alphabetically by port name
    features.sort((a: PortData, b: PortData) => {
      if (a.Port_Name < b.Port_Name) return -1;
      if (a.Port_Name > b.Port_Name) return 1;
      return 0;
    });

    return features;
  } catch (error) {
    console.log('Error fetching portpolygons', error);
    return [];
  }
};
