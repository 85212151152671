import { gql } from '@apollo/client';

export const GET_FLEET_PERFORMANCE_KPIS = gql`
  query FleetPerformanceKpisQuery(
    $vesselImos: [String!]
    $dateRange: DateRangeInput
  ) {
    fleetPerformanceIndicators(vesselImos: $vesselImos, dateRange: $dateRange) {
      fleetTotals {
        # Delta Power Daily Fuel Penalty
        deltaPowerTotalFuelPenalty {
          value
          status
        }
        # Delta Power Daily Fuel Penalty
        deltaPowerTotalCo2Penalty {
          value
          status
        }
      }
    }
  }
`;
