import { ServiceResult } from 'shared/models/serviceResult.model';
import { AerMapper } from '../mappers/vessel-aer.mapper';
import { VesselCiiRatingsDataMapper } from '../mappers/vessel-cii-ratings.mapper';
import { VesselKPIDataMapper } from '../mappers/vessel-kpi-data.mapper';
import { VesselMapper } from '../mappers/vessel.mapper';
import { VoyageComparisonMapper } from '../mappers/voyage-comparison.mapper';
import { Aer } from '../models/calculations.model';
import { IFleetVessel } from '../models/fleet-vessel.model';
import { Cii } from '../models/vessel-cii-rating.model';
import { VesselKpiDomain } from '../models/vessel-kpi.model';
import { IVesselDomain } from '../models/vessel.model';
import {
  IEnvironmentalMonitorDomain,
  IPortDataDomain,
} from '../models/voyage-comparison.model';
import {
  useGetFleetVessels,
  useGetVesselAerData,
  useGetVesselCiiData,
  useGetVesselData,
  useGetVesselKPIData,
  useGetVoyageComparisonData,
  useGetVoyageComparisonLastPort,
  useUpdatedCiiEoyProjectionsData,
} from '../repositories/vessel.repository';
import { generatePlaceholderData } from './vessel.service-util';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';

export const useGetVesselById = (
  id: string | undefined
): ServiceResult<IVesselDomain> => {
  const response = useGetVesselData(id);
  const loading = response?.loading;
  const error = response?.error;
  const data = VesselMapper.fromDTO(response?.data?.vessel ?? undefined);
  return { loading, error, data };
};

export const useGetVessels = (
  dateRange: DateRange | null,
  vesselImos: string[] = []
): ServiceResult<IFleetVessel[]> => {
  const response = useGetFleetVessels(dateRange, vesselImos);
  const loading = response?.loading;
  const error = response?.error;
  const vessels = response?.data?.fleet.environmentalMonitorVessels ?? [];
  const data = VesselMapper.fromFleetDTO(vessels);

  return { loading, error, data };
};

export const useGetVesselAer = (
  id: string | undefined,
  dateRange: DateRange | null
): ServiceResult<Aer> => {
  try {
    const response = useGetVesselAerData(id, dateRange);
    const vessel = response.data?.vessel;
    const aerRequired = vessel?.calculations?.aer?.required ?? undefined;
    const aerCalculations = AerMapper.fromDTO(
      vessel?.calculations?.aer?.data ?? []
    );

    if (aerCalculations.length === 0) {
      return {
        loading: Boolean(response?.loading || !id),
        data: undefined,
        error: response?.error,
      };
    }

    const placeholderData = generatePlaceholderData();

    // If there is only one data point, we use that value for second item in order to show the point in graph
    if (aerCalculations.length === 1 && placeholderData[1] !== undefined) {
      placeholderData[1].value = aerCalculations[0]?.value ?? 0;
    }

    const aerCalculationsMap = new Map(
      aerCalculations.map((item) => [new UTCDate(item.date).formatDMY(), item])
    );

    const mergedData = placeholderData.map((placeholderItem) => {
      const placeholderDate = new UTCDate(placeholderItem.date).formatDMY();

      if (aerCalculationsMap.has(placeholderDate)) {
        return {
          date: placeholderItem.date,
          deviation: aerCalculationsMap.get(placeholderDate)?.deviation ?? 0,
          value: aerCalculationsMap.get(placeholderDate)?.value ?? 0,
        };
      } else {
        return placeholderItem;
      }
    });

    const aer = AerMapper.toDomain(aerRequired, mergedData);

    return {
      loading: Boolean(response?.loading || !id),
      error: response?.error ?? undefined,
      data: aer,
    };
  } catch (error) {
    console.log(error);

    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};

export const useGetVesselCii = (
  id: string | undefined,
  dateRange: DateRange | null
): ServiceResult<Cii> => {
  try {
    const response = useGetVesselCiiData(id, dateRange);
    const vessel = response?.data?.vessel;
    const required = vessel?.calculations.cii?.required ?? undefined;
    const ciiRatingsData = vessel?.calculations.cii?.data ?? [];
    const boundaries = vessel?.calculations.cii?.boundaries ?? undefined;
    const projections = vessel?.calculations.cii?.projections ?? undefined;
    const projection = projections?.[0] ?? undefined;
    const dataToggles = vessel?.calculations.cii?.dataToggles ?? undefined;

    return {
      loading: response?.loading,
      error: response?.error,
      data: VesselCiiRatingsDataMapper.toDomain(
        required,
        ciiRatingsData,
        boundaries,
        projection,
        dataToggles
      ),
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};

export const useGetVesselKPIs = (
  id: string | undefined,
  dateRange: DateRange | null
): ServiceResult<VesselKpiDomain> => {
  try {
    const response = useGetVesselKPIData(id, dateRange);
    const vessel = response?.data?.vessel;
    const data = VesselKPIDataMapper.fromDTO(vessel?.kpis ?? undefined);
    const loading = response?.loading ?? false;
    const error = response?.error;

    return {
      data,
      loading,
      error,
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};

export const useGetVoyageComparison = (
  id: string | undefined,
  dateRange: DateRange | null
): ServiceResult<IEnvironmentalMonitorDomain> => {
  try {
    const response = useGetVoyageComparisonData(id, dateRange);
    const voyageComparison = VoyageComparisonMapper.fromDTO(
      response.data?.vessel?.environmentalMonitor ?? undefined
    );
    return {
      data: voyageComparison,
      loading: response.loading,
      error: response.error,
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};

export function useUpdatedCiiEoyProjections() {
  const { mutation, data, error, loading } = useUpdatedCiiEoyProjectionsData();
  const mappedData = VesselMapper.fromUpdatedCiiProjectionsDTO(
    data?.updatedCiiProjections.cii?.projections ?? undefined
  );

  return {
    mutation,
    data: mappedData,
    error,
    loading,
  };
}

export const useGetVoyageComparisonLastPortData = (
  id: string | undefined,
  dateRange: DateRange | null
): ServiceResult<IPortDataDomain> => {
  try {
    const response = useGetVoyageComparisonLastPort(id, dateRange);
    const portData = VoyageComparisonMapper.lastDeparturePortFromDTO(
      response.data?.vessel?.environmentalMonitor ?? undefined
    );
    return {
      data: portData,
      loading: response.loading,
      error: response.error,
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};
