import { Typography, Box, Link } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import { useTheme } from '@mui/material/styles';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { useContext } from 'react';
import { UTCDate } from 'shared/utils/date-utc-helper';

interface ReportIssueItemProps {
  issues: number;
  reportedDate: UTCDate | undefined;
  vesselId: string;
  generatedReportId: string;
  reportType: string;
}

export const ReportIssueItem = ({
  issues,
  reportedDate,
  vesselId,
  generatedReportId,
  reportType,
}: ReportIssueItemProps) => {
  const theme = useTheme();

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const jsDate = reportedDate?.date;
  const month = months[jsDate?.getMonth() ?? 0];
  const { utcDateRange } = useContext(NavBarContext);

  const reportDateFormatted = `${('0' + jsDate?.getDate()).slice(
    -2
  )} ${month} ${jsDate?.getFullYear()}`;

  const reportTimeFormatted = jsDate?.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  const startDateRange = utcDateRange?.startDate?.formatYMD();
  const endDateRange = utcDateRange?.endDate?.formatYMD();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.action.hover,
        alignItems: 'center',
        '&:hover': { bgcolor: theme.palette.action.selected },
      }}
    >
      <Typography
        variant='body2'
        sx={{
          padding: '16px',
          width: '92px',
        }}
      >
        {issues} issues
      </Typography>
      <Typography
        variant='body2'
        sx={{
          padding: '16px 8px',
          width: '104px',
        }}
      >
        {reportDateFormatted}
      </Typography>

      <Typography
        variant='body2'
        sx={{
          padding: '16px 8px',
          width: '120px',
        }}
      >
        {reportTimeFormatted}
      </Typography>

      <Typography
        variant='body2'
        sx={{
          padding: '16px 8px',
          width: '128px',
        }}
        align='center'
      >
        {reportType}
      </Typography>
      <Link
        variant='body2'
        href={
          generatedReportId
            ? `/vessel-data-monitor/vessel-data-summary/${vesselId}?start=${startDateRange}&end=${endDateRange}#${generatedReportId}?fail-emission`
            : `/vessel-data-monitor/vessel-data-summary/${vesselId}?start=${startDateRange}&end=${endDateRange}`
        }
        target='_blank'
        sx={{
          display: 'flex',
          gap: '4px',
          padding: '16px 16px 16px 8px',
          textWrap: 'nowrap',
          textDecorationColor: theme.palette.text.primary,
        }}
        color={theme.palette.text.primary}
      >
        View Details
        <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
      </Link>
    </Box>
  );
};
