import { FleetKpiChartMapper } from '../mappers/fleet-kpi-chart.mapper';
import { useGetGlobalGroupPenaltyData } from '../repositories/group-penalty-chart.repository';
import { DropdownGroupingCategory } from 'shared/components/vesselGroupingsDropdown/models/vesselGroupings.viewModel';
import { DateRange } from 'shared/utils/date-utc-helper';
import { useGlobalVesselGroupParameters } from './global-vessel-group-parameter.service';

export const useGetFleetKpiFuelPenalty = (
  dateRange: DateRange | null,
  vesselGroupSelection: DropdownGroupingCategory[] | undefined,
  subGroupIds: string[] | undefined
) => {
  const initialEmptyGlobal = useGlobalVesselGroupParameters();
  vesselGroupSelection =
    vesselGroupSelection === undefined
      ? initialEmptyGlobal
      : vesselGroupSelection;

  const response = useGetGlobalGroupPenaltyData(
    dateRange,
    vesselGroupSelection
  );

  const loading = response?.loading;
  const error = response?.error;
  const dto = response.data?.vesselGroupPenalties;

  if (!dto || dto.length === 0) {
    return { loading, error, data: undefined };
  }

  // in case extra data is returned, filter on the subGroupIds
  const filteredDto = dto?.filter((item) =>
    subGroupIds?.includes(item.groupId)
  );

  const data = FleetKpiChartMapper.toDomain(
    filteredDto,
    'fuel',
    dateRange?.startDate ?? null,
    dateRange?.endDate ?? null
  );

  return { loading, error, data };
};

export const useGetFleetKpiCO2Penalty = (
  dateRange: DateRange | null,
  vesselGroupSelection: DropdownGroupingCategory[] | undefined,
  subGroupIds: string[] | undefined
) => {
  const response = useGetGlobalGroupPenaltyData(
    dateRange,
    vesselGroupSelection
  );
  const loading = response?.loading;
  const error = response?.error;
  const dto = response.data?.vesselGroupPenalties;

  if (!dto || dto.length === 0) {
    return { loading, error, data: undefined };
  }

  // in case extra data is returned, filter on the subGroupIds
  const filteredDto = dto?.filter((item) =>
    subGroupIds?.includes(item.groupId)
  );

  const data = FleetKpiChartMapper.toDomain(
    filteredDto,
    'co2',
    dateRange?.startDate ?? null,
    dateRange?.endDate ?? null
  );

  return { loading, error, data };
};
