import { Box, Typography, Divider } from '@mui/material';
const scrollableBox = {
  overflowY: 'scroll',
  '&::-webkit-scrollbar': { width: '4px' },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255,255,255,0.08)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '4px',
  },
};

export const MapDrawerContentLayout = (props: any) => {
  return (
    <>
      <Box sx={{ m: '24px', mb: '16px' }}>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.1px',
            color: 'rgba(255, 255, 255, 0.70)',
            fontFeatureSettings: "'clig' off, 'liga' off",
          }}
        >
          {props.title2}
        </Typography>
        <Typography
          sx={{
            fontSize: 28,
            fontWeight: 400,
            lineHeight: '32px',
            color: 'text.secondary',
            fontFeatureSettings: "'clig' off, 'liga' off",
          }}
        >
          {props.title1}
        </Typography>
      </Box>

      <Divider
        sx={{ m: '0 24px', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}
      />
      <Box sx={{ ...scrollableBox, mt: 0, overflow: 'auto', height: '100%' }}>
        {props.children}
      </Box>
    </>
  );
};
