import { useContext, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import Toolbar from '@mui/material/Toolbar';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { AuthenticatedContent } from 'routes/login/components/AuthenticatedContent';
import { VesselGroupingsDropdown } from 'shared/components/vesselGroupingsDropdown/vesselGroupingsDropdown.component';
import { DateRange as DateRangeComponent } from 'shared/components/date-range/dateRange.component';
import { theme } from 'styles/theme';
import { StyledToolbar } from './styledToolbar.component';
import { useSearchParams } from 'react-router-dom';
import { UTCDate, DateRange } from 'shared/utils/date-utc-helper';
import { defaultDateRange } from 'shared/components/date-range/dateRange.viewModel';

type TopNavProps = {
  open: boolean;
};
export const TopNavigation: React.FC<TopNavProps> = ({ open }) => {
  const [searchParams] = useSearchParams();
  const startParam = searchParams.get('start');
  const endParam = searchParams.get('end');
  const queryStartDate = startParam != null ? new UTCDate(startParam) : null;
  const queryEndDate = endParam != null ? new UTCDate(endParam) : null;

  const { setUTCDateRange, utcDateRange } = useContext(NavBarContext);

  const initialStartDate =
    queryStartDate?.dateDayjs ??
    utcDateRange?.startDate?.dateDayjs ??
    defaultDateRange[0];
  const initialEndDate =
    queryEndDate?.dateDayjs ??
    utcDateRange?.endDate?.dateDayjs ??
    defaultDateRange[1];

  const initialDateRange: MuiDateRange<Dayjs> = [
    initialStartDate,
    initialEndDate,
  ];

  const handleDateChange = (newValue: MuiDateRange<Dayjs>) => {
    setUTCDateRange(
      new DateRange(new UTCDate(newValue[0]), new UTCDate(newValue[1]))
    );
  };

  useEffect(() => {
    handleDateChange(initialDateRange);
  }, []);

  const drawerIndex = theme.zIndex.drawer;

  return (
    <AppBar
      position='static'
      sx={{
        zIndex: drawerIndex - 1,
        backgroundColor: theme.background.page,
      }}
    >
      <StyledToolbar disableGutters open={open}>
        <VesselGroupingsDropdown />
        <Box sx={{ mx: 4, ml: 1.5, width: '100%' }}>
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <DateRangeComponent
              initialRange={initialDateRange}
              handler={handleDateChange}
            />
            <AuthenticatedContent />
          </Toolbar>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
};
