import { formatNumber } from 'shared/utils/float-utils';
import { IPlannedItineraryResults } from 'routes/environmental-monitor/models/plannedItinerary.model';
export const formatPlannedItineraryData = (
  itineraries: IPlannedItineraryResults[]
) => {
  return itineraries.map((itinerary) => {
    itinerary.ghgMrvRemarks = `EU CO₂ (t,mrv): ${formatNumber(
      itinerary.co2Mrv,
      2
    )}\nEU CH₄ (t,mrv): ${formatNumber(
      itinerary.ch4Mrv,
      2
    )}\nEU N₂O (t,mrv): ${formatNumber(
      itinerary.n2OMrv,
      2
    )}\nEU Fuel Slip (t,mrv): ${formatNumber(itinerary.fuelSlippage, 2)}
    \nEst. YTD GHGmrv (tCO₂eq): ${formatNumber(itinerary.estGhgMrv, 2)}`;
    return itinerary;
  });
};
