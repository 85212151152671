import { SxProps } from '@mui/material/styles';
import { MenuProps as MenuPropsType } from '@mui/material';
import { theme } from 'styles/theme';

const hoverColor = theme.palette.action.hover;
export const SelectStyles: SxProps = {
  color: theme.palette.text.primary,
  fontWeight: 500,
  pr: 2,
  border: 'none',
  borderRadius: '8px',
  '& .MuiSelect-select': {
    py: 1,
    px: 2,
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
      bgcolor: theme.vesselPerformance.charts.filterBackground,
    },
    '&:hover fieldset': {
      bgcolor: hoverColor,
    },
    '&.Mui-focused fieldset': {
      bgcolor: theme.vesselPerformance.charts.filterBackgroundSelected,
    },
  },
  '& .MuiSvgIcon-root': { color: theme.palette.text.primary, mr: 1 },
  '&:hover': { backgroundColor: hoverColor },
};

export const MenuProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};
