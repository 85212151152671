import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { mockDailyPenaltyData } from '../mocks/vesselPerformanceKpiDailyPenalty.mock';
import { GET_FLEET_PERFORMANCE_KPIS } from '../queries/vessel-performance-kpi.query';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetVesselPerformanceKpisData = (
  dateRange: DateRange | null,
  vesselImos: string[]
) => {
  return useQuery<Query>(GET_FLEET_PERFORMANCE_KPIS, {
    skip: !dateRange?.getDateRangeStruct || !vesselImos,
    variables: {
      ...dateRange?.getDateRangeStruct,
      vesselImos,
    },
  });
};

export const useGetVesselPerformanceKpiFuelPenaltyData = () => {
  return mockDailyPenaltyData;
};

export const useGetVesselPerformanceKpiCO2PenaltyData = () => {
  return mockDailyPenaltyData;
};
