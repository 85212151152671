import { useEffect, useContext } from 'react';
import { SearchType } from 'shared/components/esri-map/utils/constants';
import { MapViewContext } from 'shared/models/mapView.context.model';
import { useActiveVoyageInfoForDrawer } from '../repositories/active-voyage-info-for-drawer.get.repository';
import { ActiveVoyageInfoForDrawerMapper } from '../mappers/active-voyage-info-for-drawer.mapper';
interface DataFetchingResult {
  data: any;
  loading: boolean;
  error: Error | null;
}

export const useGetActiveVoyageInfoForDrawerService =
  (): DataFetchingResult => {
    const { searchInfo } = useContext(MapViewContext);
    const response = useActiveVoyageInfoForDrawer(searchInfo.searchId ?? '');
    const activeVoyagePositionsData = response.data?.vessels;

    let data: any = null;
    const loading = response.loading as boolean;
    const error = response.error as Error;
    const refectch = response.refetch;

    useEffect(() => {
      if (searchInfo.searchType === SearchType.vessel)
        refectch({ imoNumber: searchInfo.searchId ?? '' });
    }, [searchInfo.searchId, searchInfo.searchType, refectch]);

    if (activeVoyagePositionsData && activeVoyagePositionsData.length > 0) {
      data = ActiveVoyageInfoForDrawerMapper.fromDTO(
        activeVoyagePositionsData[0]
      );
    }
    return { data, loading, error };
  };
