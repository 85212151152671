import { CellHeader } from 'routes/vessel-data-monitor/components/fleetDataComparison/table/util/fleet-data-comparison-table.util';
import { theme } from 'styles/theme';

import * as ExcelJs from 'exceljs';
import { StatusKpiCardProps } from 'shared/components/status-kpi-card/status-kpi-card.component';

import { getCardTitles } from 'shared/components/status-kpi-card/kpi-card-footer/kpi-card-footer';
import { formatNumber } from '../../../float-utils';
import { SectionName } from '../../../valid-sections';
import { setCellBackgroundColor, getStatusColor } from './styles';
import { WORKSHEET } from 'shared/utils/worksheet';

export const AddHeaderRows = (
  cellheaders: CellHeader[] | undefined,
  worksheet: ExcelJs.Worksheet
) => {
  const headerValues = cellheaders?.map((item) => item.label) || [];
  const row = worksheet.addRow(headerValues);
  row.eachCell((cell) => setCellBackgroundColor(cell, theme.excelColour.grey));
};

export const checkCards = (
  worksheet: ExcelJs.Worksheet,
  cardData: StatusKpiCardProps[],
  sectionName: SectionName
) => {
  if (sectionName === 'Alerts Summary') {
    Object.values(cardData).forEach((card) => {
      AddCardsDataAlerts(worksheet, card.title, card.value ?? 0);
    });
    return;
  }

  kpiExport(worksheet, cardData);
  worksheet.addRow(WORKSHEET.EMPTY_ROW);
};

const kpiExport = (
  worksheet: ExcelJs.Worksheet,
  cardData: StatusKpiCardProps[]
) => {
  Object.entries(cardData).forEach(([_, card]) => {
    if (card?.sectionVisibility?.footer) {
      const unitValue = card.kpiData?.unit ?? card?.units;
      const unit = unitValue ? ` (${unitValue})` : '';
      const cardHeaders: CellHeader[] = [{ id: 1, label: card.title + unit }];
      const footerTitles = getCardTitles(card);
      const footerUnit = card.footerUnits ? ` (${card.footerUnits})` : '';
      cardHeaders.push(
        ...[
          { id: 2, label: footerTitles[0]?.title + footerUnit },
          { id: 3, label: footerTitles[1]?.title + footerUnit },
        ]
      );

      // add Cards data to worksheet
      AddHeaderRows(cardHeaders, worksheet);
      AddCardsData(worksheet, card || '');
      worksheet.addRow(WORKSHEET.EMPTY_ROW);
    } else {
      AddCardsDataBasic(worksheet, card.title, card ?? '');
    }
  });
};

const AddCardsDataBasic = (
  worksheet: ExcelJs.Worksheet,
  title: string,
  value: StatusKpiCardProps
) => {
  if (typeof value === 'object' && value !== null) {
    const unit = value.kpiData?.unit ?? value?.units;
    const unitTitle = unit ? ` (${unit})` : '';
    title = title + unitTitle;
    const row = worksheet.addRow([title, value.kpiData?.primaryValue]);
    setCellBackgroundColor(row.getCell(1), theme.excelColour.grey);
    setCellBackgroundColor(
      row.getCell(2),
      getStatusColor(value.kpiData?.status)
    );
    return;
  }

  worksheet.addRow([title, value]);
};

const AddCardsData = (
  worksheet: ExcelJs.Worksheet,
  value: StatusKpiCardProps | string | number
) => {
  if (typeof value === 'object' && value !== null) {
    const footerTitles = getCardTitles(value);
    const noDecimals = footerTitles[0]?.noDecimals;
    const row = worksheet.addRow([
      formatNumber(value.kpiData?.primaryValue),
      formatNumber(value.kpiData?.secondaryValue ?? '', noDecimals),
      formatNumber(value.kpiData?.tertiaryValue ?? '', noDecimals),
    ]);

    setCellBackgroundColor(
      row.getCell(1),
      getStatusColor(value.kpiData?.status)
    );
  }
};

const AddCardsDataAlerts = (
  worksheet: ExcelJs.Worksheet,
  title: string,
  value: number | string
) => {
  const row = worksheet.addRow([title, value]);
  setCellBackgroundColor(row.getCell(1), theme.excelColour.grey);
  setCellBackgroundColor(row.getCell(2), getStatusColor(title));
};
export { getStatusColor };
