import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { GET_FLEET_PERFORMANCE_DATA } from '../queries/vessel-performance.query';
import { DateRange } from 'shared/utils/date-utc-helper';

export function useGetVesselPerformanceData(
  dateRange: DateRange | null,
  vesselImos: string[]
) {
  return useQuery<Query>(GET_FLEET_PERFORMANCE_DATA, {
    skip: !dateRange?.getDateRangeStruct,
    variables: {
      ...dateRange?.getDateRangeStruct,
      vesselImos,
    },
    errorPolicy: 'all',
  });
}
