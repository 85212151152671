import { GET_VOYAGE_INFO_FOR_DRAWER_DASHBOARD } from '../queries/voyage-info-for-drawer.get.query';
import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { AlertStatus } from 'routes/alerts/services/alert-status';

export const useVoyageInfoForDrawerDashboard = (imoNumber: string) => {
  const query = useQuery<Query>(GET_VOYAGE_INFO_FOR_DRAWER_DASHBOARD, {
    variables: {
      imoNumber: imoNumber,
      alertStatuses: [AlertStatus.Open],
      isArchived: false,
      isRead: false,
    },
  });
  return query;
};
