import { useQuery } from '@apollo/client';
import { Query } from '../../../../_gql/graphql';
import { GET_ACTIVE_VOYAGE_POSITIONS } from '../queries/getActiveVoyagePositions';

export const useActiveVoyagePositions = (imoNumber: string) => {
  const query = useQuery<Query>(GET_ACTIVE_VOYAGE_POSITIONS, {
    variables: {
      imoNumber: imoNumber,
    },
  });
  return query;
};
