import { Box, FormHelperText, FormHelperTextProps } from '@mui/material';
import { EmergencyHomeIcon } from 'assets/emergency-home-icon';
import { theme } from 'styles/theme';
import { EmergencyHomeIconStyles } from './input-styles';

interface MDFFormHelperTextProps extends FormHelperTextProps {
  errorMessage: string | undefined;
}

export const MDFFormHelperText = (props: MDFFormHelperTextProps) => {
  const { sx, errorMessage } = props;
  return (
    <FormHelperText variant='standard' sx={{ display: 'flex', ...sx }}>
      <Box
        component='span'
        sx={{
          color: theme.palette.error.light,
          display: 'flex',
          flexGrow: 1,
          width: 0,
        }}
      >
        <EmergencyHomeIcon sx={EmergencyHomeIconStyles} />
        {errorMessage}
      </Box>
    </FormHelperText>
  );
};
