import { FleetUtilizationCharts } from './charts';
import { FleetUtilizationGrid } from './grid';
import { useGetFleetUtilization } from 'routes/environmental-monitor/services/fleet.service';
import { ErrorComponent } from 'shared/components/error/error.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';

export const FleetUtilizationContainer = ({ dateRange, vesselImos }: any) => {
  const { data, loading, error, empty } = useGetFleetUtilization(
    dateRange,
    vesselImos
  );
  const charts = (
    <FleetUtilizationCharts
      data={data?.fleetUtilization}
    ></FleetUtilizationCharts>
  );

  const errorEmptyComponent = <ErrorComponent error={error} empty={empty} />;
  const contentToDisplay = loading ? (
    <LoadingComponent height='215px' />
  ) : empty || error ? (
    errorEmptyComponent
  ) : (
    charts
  );

  return (
    <CardContainer>
      {contentToDisplay}
      <FleetUtilizationGrid
        data={data?.vesselUtilization ?? []}
        loading={loading}
      ></FleetUtilizationGrid>
    </CardContainer>
  );
};
