import { MouseEventHandler } from 'react';
import { UTCDate } from 'shared/utils/date-utc-helper';

export interface IAlert {
  alertId: string;
  reportedOn: UTCDate | null;
  reportedOnTimestamp: number | undefined;
  status: string;
  vesselName: string;
  products: string;
  alertDetails: string;
  description: string;
  isRead: boolean;
  isArchived: boolean;
  isSelected: boolean;
  comments: IAlertComment[];
  reportedOnTotal?: number;
}

interface IDatapoint {
  key: string;
  alerts: number;
  friendlyDate: string;
  timestamp: number | undefined;
}

export type Datapoint = IDatapoint;

export interface IAlertComment {
  id: string;
  date: string;
  text: string;
  userId: string | undefined;
  authorName: string | undefined;
  handleEdit?: MouseEventHandler<HTMLLIElement>;
  handleDelete?: MouseEventHandler<HTMLLIElement>;
}

export enum AlertAction {
  None = 'None',
  Select = 'Select',
  Read = 'Read',
  Unread = 'Unread',
  NewComment = 'New Comment',
  EditComment = 'Edit Comment',
  DeleteComment = 'Delete Comment',
  Close = 'Close',
  Reopen = 'Reopen',
  Archive = 'Archive',
  Unarchive = 'Unarchive',
}
