import React from 'react';
import { FormControl, ToggleButtonGroup, Typography } from '@mui/material';
import { StyledToggleButtonVesselDataOverview } from '../styles/styled-components';

import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
export interface VesselDataFilterProps {
  vesselDataFilter: string[];
  handleFilterChange: (
    event: React.MouseEvent<HTMLElement>,
    newVesselDataFilter: string[]
  ) => void;
}

export const TableFilter: React.FC<VesselDataFilterProps> = ({
  vesselDataFilter,
  handleFilterChange,
}) => {
  return (
    <FormControl
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '20px',
        alignItems: 'baseline',
      }}
    >
      <ToggleButtonGroup
        color='primary'
        value={vesselDataFilter}
        onChange={handleFilterChange}
        aria-label='filter button group'
        sx={{ gap: '16px' }}
      >
        <StyledToggleButtonVesselDataOverview value='passed'>
          <CheckCircleIcon color='success' />
          <Typography variant='menuItem'>Passed</Typography>
        </StyledToggleButtonVesselDataOverview>
        <StyledToggleButtonVesselDataOverview value='fail-emission'>
          <ErrorIcon color='error' />
          <Typography variant='menuItem'>
            Flagged - Emissions Regulation Data
          </Typography>
        </StyledToggleButtonVesselDataOverview>
        <StyledToggleButtonVesselDataOverview value='fail-non-emission'>
          <ErrorOutlineIcon color='error' />
          <Typography variant='menuItem'>
            Flagged - Non Emissions Regulation Data
          </Typography>
        </StyledToggleButtonVesselDataOverview>
      </ToggleButtonGroup>
    </FormControl>
  );
};
