import { EventType } from 'routes/vessel-performance/individual-vessel-performance/components/events/models/constants';

export const convertToEventTypeEnum = (str: string | undefined): EventType => {
  if (str && Object.values(EventType).includes(str as EventType)) {
    return str as EventType;
  }

  const eventType = EventType[str as keyof typeof EventType];
  return eventType;
};
