import { useQuery } from '@apollo/client';
import { GET_VESSEL_EXCEL_REPORT } from '../queries/vessel-excel-report.query';
import { Query } from '_gql/graphql';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetVesselExcelReport = (
  dateRange: DateRange,
  reportType: string | undefined,
  vesselIMO: string | undefined,
  loadReport: boolean | undefined
) => {
  const dateRangeAsString = dateRange.dateRangeAsString;
  const query = useQuery<Query>(GET_VESSEL_EXCEL_REPORT, {
    skip: !(dateRangeAsString && reportType && vesselIMO && loadReport),
    variables: {
      ...dateRangeAsString,
      reportType,
      vesselIMO,
    },
  });
  return query;
};
