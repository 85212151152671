import { useContext, useState } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  List,
  ListItemText,
  MenuItem,
  SxProps,
  useTheme,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DropdownGroupingCategory } from './models/vesselGroupings.viewModel';
import { NavBarContext } from 'shared/models/navBar.context.model';

interface VesselGroupingsDropdownCategoryProps {
  vesselGrouping: DropdownGroupingCategory;
  updateGroupings: (vesselGrouping: DropdownGroupingCategory) => void;
}

export const VesselGroupingsDropdownCategory = (
  props: VesselGroupingsDropdownCategoryProps
) => {
  const theme = useTheme();
  const { vesselGrouping, updateGroupings } = props;
  const [open, setOpen] = useState(false);
  const { imoNumbers } = useContext(NavBarContext);

  const handleExpand = () => {
    setOpen(!open);
  };

  const handleGroupingCategoryClick = () => {
    if (
      !vesselGrouping.isChecked &&
      vesselGrouping.groupings.every((g) => !g.isChecked || g.isDisabled)
    ) {
      // check everything
      vesselGrouping.isChecked = true;
      vesselGrouping.groupings.map((g) => {
        if (!g.isDisabled) {
          g.isChecked = true;
        }
        return g;
      });
    } else {
      // uncheck everything
      vesselGrouping.isChecked = false;
      vesselGrouping.groupings.map((g) => (g.isChecked = false));
    }
    updateGroupings(vesselGrouping);
  };

  const handleGroupingClick = (id: string) => {
    const index = vesselGrouping.groupings.findIndex((g) => g.id === id);
    if (index === -1) return;
    const grouping = vesselGrouping.groupings[index];
    if (grouping !== undefined) {
      grouping.isChecked = !vesselGrouping.groupings[index]?.isChecked;
    }

    // Toggle main checkbox if all are checked/unchecked
    if (
      vesselGrouping.groupings.filter((g) => g.isChecked === true).length ===
      vesselGrouping.groupings.length
    ) {
      vesselGrouping.isChecked = true;
    } else {
      vesselGrouping.isChecked = false;
    }
    if (
      vesselGrouping.groupings.filter((g) => g.isChecked === false).length ===
      vesselGrouping.groupings.length
    ) {
      vesselGrouping.isChecked = false;
    }

    updateGroupings(vesselGrouping);
  };

  const isIndeterminate =
    vesselGrouping.groupings.filter((g) => g.isChecked).length !==
      vesselGrouping.groupings.length &&
    vesselGrouping.groupings.filter((g) => !g.isChecked).length !==
      vesselGrouping.groupings.length;

  const categoryIsDisabled =
    !vesselGrouping.isChecked &&
    vesselGrouping.groupings.filter((g) => g.isDisabled).length ===
      vesselGrouping.groupings.length;

  const ExpandArrowStyling: SxProps = {
    mt: 1,
  };

  return (
    <MenuItem
      value={vesselGrouping.groupingDisplayName}
      disableRipple
      sx={{
        p: 0,
        m: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 0.75,
          pl: 1.5,
          pr: 2,
          m: 0,
          width: '100%',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        <Checkbox
          checked={vesselGrouping.isChecked}
          indeterminate={isIndeterminate}
          disabled={categoryIsDisabled}
          onChange={() => handleGroupingCategoryClick()}
        />
        <ListItemText
          primary={vesselGrouping.groupingDisplayName}
          onClick={handleExpand}
          sx={{ p: 1 }}
        />
        {open ? (
          <ExpandLess sx={ExpandArrowStyling} onClick={handleExpand} />
        ) : (
          <ExpandMore sx={ExpandArrowStyling} onClick={handleExpand} />
        )}
      </Box>
      <Collapse in={open} timeout='auto' unmountOnExit sx={{ width: '100%' }}>
        <List sx={{ display: 'flex', flexDirection: 'column', p: 0, ml: 4 }}>
          {vesselGrouping.groupings.map((grouping) => (
            <FormControlLabel
              sx={{
                m: 1,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
              control={
                <Checkbox
                  checked={grouping.isChecked}
                  onChange={() => handleGroupingClick(grouping.id)}
                />
              }
              label={
                <>
                  {grouping.groupingName}
                  <Box component='span' sx={{ px: 1, fontSize: 12 }}>
                    {`(${
                      grouping.imoNumbers.filter((x) => imoNumbers.includes(x))
                        .length
                    })`}
                  </Box>
                </>
              }
              key={`vessel-grouping-category-${grouping.id}`}
            />
          ))}
        </List>
      </Collapse>
    </MenuItem>
  );
};
