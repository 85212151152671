import { useGetAllVesselGroupingData } from '../repositories/vesselGroupings.get.repository';
import { VesselGroupingsDrodownMapper } from '../mappers/vesselGroupingsDropdown.mapper';
import { useMemo } from 'react';

export const useGetAllVesselGroupings = () => {
  const response = useGetAllVesselGroupingData();
  const loading = response?.loading;
  const error = response?.error;

  const vessels = useMemo(
    () =>
      response?.data?.vessels?.filter(
        (v) => v.imoNumber !== undefined && v.imoNumber !== null
      ),
    [response?.data?.vessels]
  );

  const vesselGroupings = useMemo(
    () => VesselGroupingsDrodownMapper.fromDTO(vessels),
    [vessels]
  );

  return { loading, error, data: vesselGroupings };
};
