import { gql } from '@apollo/client';

export const GET_FEATURE_FLAG = gql`
  query FeatureFlag($featureFlag: String!) {
    featureFlag(flagName: $featureFlag) {
      isEnabled
    }
  }
`;

export const GET_FEATURE_FLAGS = gql`
  query FeatureFlags($featureFlags: [String!]!) {
    featureFlags(flagNames: $featureFlags) {
      name
      isEnabled
    }
  }
`;
