import { User } from '_gql/graphql';
import { UserDomain, UserPermission } from '../models/user.model';
import { AccountInfo } from '@azure/msal-browser';

export class UserMapper {
  private static extractTenantId = (accountInfo: AccountInfo): string => {
    const claims = UserMapper.getClaims(accountInfo);
    return (claims['extension_TenantID'] as string) ?? '';
  };

  private static extractTenantName = (accountInfo: AccountInfo): string => {
    const claims = UserMapper.getClaims(accountInfo);
    return (claims['extension_TenantName'] as string) ?? '';
  };

  private static extractPermissions = (
    accountInfo: AccountInfo
  ): UserPermission[] | null => {
    const claims = UserMapper.getClaims(accountInfo);
    const extensionPermissions = claims['extension_Permissions'];
    const permissions: UserPermission[] = JSON.parse(
      extensionPermissions as string
    );
    return permissions;
  };

  private static extractRoles = (accountInfo: AccountInfo): string[] | null => {
    const claims = UserMapper.getClaims(accountInfo);
    const extensionRoles = claims['extension_Roles'] as string;
    const roles: string[] = JSON.parse(extensionRoles);
    return roles;
  };

  private static extractEmail = (accountInfo: AccountInfo): string => {
    const claims = UserMapper.getClaims(accountInfo);
    const extensionEmails = claims['emails'] as string[];
    return extensionEmails ? extensionEmails[0] ?? '' : '';
  };

  private static getClaims(accountInfo: AccountInfo) {
    return accountInfo.idTokenClaims ?? {};
  }

  public static toDomain(
    data: User | undefined,
    accountInfo: AccountInfo | undefined
  ): UserDomain {
    return {
      id: data?.id ?? undefined,
      fullName: `${data?.name?.first} ${data?.name?.last}`,
      tenantId: accountInfo ? UserMapper.extractTenantId(accountInfo) : '',
      tenantName: accountInfo ? UserMapper.extractTenantName(accountInfo) : '',
      permissions: accountInfo
        ? UserMapper.extractPermissions(accountInfo)
        : null,
      roles: accountInfo ? UserMapper.extractRoles(accountInfo) : null,
      email: accountInfo ? UserMapper.extractEmail(accountInfo) : '',
      firstName: data?.name?.first ?? '',
      lastName: data?.name?.last ?? '',
    };
  }
}
