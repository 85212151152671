import { Box, Typography, Divider } from '@mui/material';

export const MapDrawerBodyLayout = (props: any) => {
  return (
    <Box>
      {(props.title || props.subtitle) && (
        <Box
          sx={{
            m: '16px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 400,
              lineHeight: '150%',
              color: 'text.secondary',
              letterSpacing: '0.15px',
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            {props.title}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '24px',
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '143%',
              color: 'text.secondary',
              letterSpacing: '0.17px',
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            {props.subtitle ? props.subtitle : ''}
          </Typography>
        </Box>
      )}
      {props.children}
      {props.hasBoundary && (
        <Divider
          sx={{
            m: '16px 24px',
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          }}
        />
      )}
    </Box>
  );
};
