import { kpiStatusToEnum } from 'shared/utils/status-utils';
import { FleetPerformanceIndicators } from '_gql/graphql';
import { VesselPerformanceData } from '../models/vesselPerformance.viewModel';

export class VesselPerformanceKPIMapper {
  public static toDomain = (
    dto: FleetPerformanceIndicators | undefined
  ): {
    deltaPowerTotalFuelPenalty: VesselPerformanceData;
    deltaPowerTotalCo2Penalty: VesselPerformanceData;
  } => {
    return {
      deltaPowerTotalFuelPenalty: {
        value: dto?.fleetTotals?.deltaPowerTotalFuelPenalty?.value ?? '',
        status: kpiStatusToEnum(
          dto?.fleetTotals?.deltaPowerTotalFuelPenalty?.status
        ),
      },
      deltaPowerTotalCo2Penalty: {
        value: dto?.fleetTotals?.deltaPowerTotalCo2Penalty?.value ?? '',
        status: kpiStatusToEnum(
          dto?.fleetTotals?.deltaPowerTotalCo2Penalty?.status
        ),
      },
    };
  };
}
