import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVesselById } from 'routes/environmental-monitor/services/vessel.service';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import { TopNavigationContext } from 'shared/models/topNav.context.model';

export function VesselDetailHeader() {
  const { id } = useParams();
  const { loading, data } = useGetVesselById(id);
  const title = data?.vesselName ?? '';
  const { setVesselName } = useContext(TopNavigationContext);
  useEffect(() => {
    if (title && title !== '') {
      setVesselName(title);
    }
  }, [title]);
  if (loading) {
    return <LoadingComponent height='200px' />;
  }

  return (
    <PageHeader
      title={title}
      heading={`- ${vesselPageTitles.environmentalMonitorVesselOverview}`}
    />
  );
}
