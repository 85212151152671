import {
  useGetVesselPerformanceTrendFoulingData,
  useGetVesselPerformanceTrendPowerData,
  useGetVesselPerformanceTrendSpeedData,
  useGetVesselPerformanceTrendSfocData,
} from '../repositories/vessel-trend-chart.repository';
import { VesselTrendChartMapper } from '../mappers/vessel-trend-chart.mapper';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetVesselPerformanceTrendFouling = (
  dateRange: DateRange | null,
  vesselImo: string,
  vesselId: string
) => {
  const response = useGetVesselPerformanceTrendFoulingData(
    dateRange ?? new DateRange(),
    vesselImo,
    vesselId
  );

  const loading = response?.loading;
  const error = response?.error;
  const data = VesselTrendChartMapper.foulingResistanceToDomain({
    foulingResistance:
      response.data?.vesselPerformanceIndicators?.foulingResistance,
    vesselEvents: response.data?.vesselEvents,
    dateRange,
  });

  return { loading, error, data };
};

export const useGetVesselPerformanceTrendPower = (
  dateRange: DateRange | null,
  vesselImo: string,
  vesselId: string
) => {
  const response = useGetVesselPerformanceTrendPowerData(
    dateRange,
    vesselImo,
    vesselId
  );

  const loading = response?.loading;
  const error = response?.error;
  const data = VesselTrendChartMapper.deltaPowerToDomain({
    deltaPower: response.data?.vesselPerformanceIndicators?.deltaPower,
    vesselEvents: response.data?.vesselEvents,
    dateRange,
  });

  return { loading, error, data };
};

export const useGetVesselPerformanceTrendSpeed = (
  dateRange: DateRange | null,
  vesselImo: string,
  vesselId: string
) => {
  const response = useGetVesselPerformanceTrendSpeedData(
    dateRange,
    vesselImo,
    vesselId
  );

  const loading = response?.loading;
  const error = response?.error;
  const data = VesselTrendChartMapper.deltaSpeedToDomain({
    deltaSpeed: response.data?.vesselPerformanceIndicators?.deltaSpeed,
    vesselEvents: response.data?.vesselEvents,
    dateRange,
  });

  return { loading, error, data };
};

export const useGetVesselPerformanceTrendSfoc = (
  dateRange: DateRange | null,
  vesselImo: string,
  vesselId: string
) => {
  const response = useGetVesselPerformanceTrendSfocData(
    dateRange,
    vesselImo,
    vesselId
  );

  const loading = response?.loading;
  const error = response?.error;
  const data = VesselTrendChartMapper.mesfocToDomain({
    mesfoc: response.data?.vesselPerformanceIndicators?.mesfoc,
    vesselEvents: response.data?.vesselEvents,
    dateRange,
  });

  return { loading, error, data };
};
