import { SxProps } from '@mui/material';
import { theme } from 'styles/theme';

export type DownloadListVessel = {
  id: string;
  imoNumber: string;
  name: string;
};

export const selectStyle: SxProps = {
  minWidth: '200px',
  color: theme.palette.text.primary,
  pt: 1,
  border: 'none',
  '& .MuiSelect-select': {
    pb: '5.5px',
  },
};

export const downloadItemBoxStyle: SxProps = {
  display: 'flex',
  justifyContent: 'inherit',
  alignItems: 'center',
};

export const generateLineItemBoxStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  py: 2,
  gap: 1,
};

export const downloadListStyle: SxProps = {
  display: 'inline-flex',
  flexDirection: 'column',
  minWidth: '38%',
  maxWidth: '100%',
};

export const formControlStyle: SxProps = {
  minWidth: '200px',
  width: '200px',
  height: '46px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '8px',
  pl: 0,
  pb: 1,
  mr: 1.5,
  ml: 0,
  '& .MuiInputLabel-shrink': {
    pt: '12px !important',
    pl: 0,
    fontSize: '14px',
    left: '-10px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiFormLabel-root': {
    letterSpacing: '0.15px',
    pt: 0,
    pl: 0,
    color: theme.palette.text.secondary,
    transformOrigin: 'center',
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '&.Mui-error': {
      color: theme.palette.text.secondary,
    },
  },
};
export const svgIconStyle: SxProps = {
  fontSize: 32,
  p: 0,
  m: 0,
};

export const downloadButtonStyle: SxProps = {
  minWidth: '150px',
  height: '40px',
  color: 'white',
  borderColor: 'white',
  textTransform: 'capitalize',
  mx: 1.5,
};

export const generateButtonStyle: SxProps = {
  textTransform: 'capitalize',
  height: '46px',
  color: 'white',
  borderColor: 'white',
  mx: 1.5,
  '&.Mui-disabled': {
    backgroundColor: '#464D55',
    color: theme.palette.text.disabled,
  },
};

export const formControlStyleLong: SxProps = {
  minWidth: '300px',
  width: '300px',
  height: '46px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '8px',
  pl: 0,
  pb: 1,
  mr: 0,
  ml: 0,
  '& .MuiInputLabel-shrink': {
    pt: '12px !important',
    pl: 0,
    fontSize: '14px',
    left: '-10px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiFormLabel-root': {
    letterSpacing: '0.15px',
    pt: 0,
    pl: 0,
    color: theme.palette.text.secondary,
    transformOrigin: 'center',
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '&.Mui-error': {
      color: theme.palette.text.secondary,
    },
  },
};

export const compare = (
  a: DownloadListVessel,
  b: DownloadListVessel
): number => {
  if (a.name && b.name) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
  }
  return 0;
};
