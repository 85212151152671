import { DateRange } from 'shared/utils/date-utc-helper';
import { useGetVesselExcelReport } from '../repositories/vessel-excel-report.get.repository';

export const useVesselExcelReport = (
  dateRange: DateRange,
  reportType: string | undefined,
  vesselIMO: string | undefined,
  loadReport: boolean | undefined
) => {
  return useGetVesselExcelReport(dateRange, reportType, vesselIMO, loadReport);
};
