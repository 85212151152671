import { gql } from '@apollo/client';

export const GET_STATEMENT_OF_FACT_REPORT_PDF = gql`
  query StatementOfFactReportPdf(
    $imoNumber: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    statementOfFactReportPdf(
      imoNumber: $imoNumber
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      imoNumber
      file {
        contentType
        fileContent
      }
    }
  }
`;
