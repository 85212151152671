import { FC } from 'react';
import { Box } from '@mui/material';
import { ButtonCircleProps } from 'shared/models/button-circle-props.model';

const ButtonCircle: FC<ButtonCircleProps> = ({ fill, border, dashed }) => {
  return (
    <Box
      sx={{
        width: '15px',
        height: '15px',
        top: '2.5px',
        left: '2.5px',
        borderRadius: '50%',
        backgroundColor: fill,
        border: `1px ${dashed ? 'dashed' : 'solid'} ${border}`,
      }}
    />
  );
};

export default ButtonCircle;
