import EsriConfig from '@arcgis/core/config';
import MapView from '@arcgis/core/views/MapView';
import Fullscreen from '@arcgis/core/widgets/Fullscreen';
import Home from '@arcgis/core/widgets/Home';
import Zoom from '@arcgis/core/widgets/Zoom';

import '@arcgis/core/assets/esri/themes/light/main.css';
import './map.component.css';

import { IVesselPosition, VesselLayer } from './utils/vesselLayer';
import { AddLayerList } from './utils/layerList';
import { addBaseMapGallery } from './utils/BaseMapGallery';

import { SearchWidget } from './utils/searchWidget';

import React, { useState } from 'react';
import { ReferenceLayer } from './utils/ReferenceLayer';

import {
  MapViewContext,
  MapViewType,
} from 'shared/models/mapView.context.model';
import { SearchType } from './utils/constants';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
import { updateVesselNamesClusters } from './utils/vesselLabelsCluster';
import { createMapView } from './utils/mapView';

export const MDFMap = ({
  children,
  data,
  displaysearch = true,
  displayhome = true,
}: {
  children?: React.ReactNode;
  data: IVesselPosition[];
  displaysearch?: boolean;
  displayhome?: boolean;
}) => {
  const mapDiv = React.useRef(null);
  const dataRef = React.useRef<IVesselPosition[]>(data);
  const [view, setView] = useState<MapViewType>();

  React.useEffect(() => {
    if (mapDiv.current) {
      EsriConfig.apiKey = process.env['REACT_APP_ESRI_MAP_API_KEY'] ?? '';
      const mapviewOptions = JSON.parse(
        localStorage.getItem('esri-mapview-options') || '{}'
      );

      const view = createMapView(mapDiv.current, mapviewOptions);
      ReferenceLayer.CreateLayer(view, 0);
      SearchWidget.CreateWidget(view, displaysearch, setSearchInfo);

      AddLayerList(view);
      addBaseMapGallery(view); // BaseMaps List

      // Full screen button
      const fullscreen = new Fullscreen({ view: view });
      view.ui.add(fullscreen, 'bottom-right');
      reactiveUtils.watch(
        () => fullscreen.viewModel.state,
        (state) => {
          if (view.navigation)
            view.navigation.mouseWheelZoomEnabled = state === 'active';
        }
      );
      view.on('key-down', function (event) {
        if (!view.navigation || fullscreen.viewModel.state === 'active') return;
        view.navigation.mouseWheelZoomEnabled = event.key === 'Control';
      });

      view.on('key-up', function () {
        if (!view.navigation || fullscreen.viewModel.state === 'active') return;
        view.navigation.mouseWheelZoomEnabled = false;
      });

      // Home widget
      if (displayhome) {
        const homeBtn = new Home({ view: view }); // Home Button
        view.ui.add(homeBtn, 'bottom-right'); // Add the home button to the top left corner of the view
      }

      // Zoom widget
      const zoom = new Zoom({
        view: view,
        layout: 'vertical',
      });
      view.ui.add(zoom, 'bottom-right');

      VesselLayer.CreateLayer(view, dataRef.current);

      view.on('pointer-move', function (evt) {
        const screenPoint = {
          x: evt.x,
          y: evt.y,
        };
        view.hitTest(screenPoint).then(function (response) {
          changeMouseCursor(mapDiv.current, response);
        });
      });

      view.on('click', (event) => {
        view.hitTest(event).then(({ results }) => {
          handleMouseClick({ results, view });
        });
      });

      reactiveUtils.watch(
        () => view.stationary,
        (stationary) => {
          if (stationary) {
            updateVesselNamesClusters(view);
            SearchWidget.refreshCallout(view);
          } else {
            SearchWidget.hideCallout(view);
          }
        }
      );

      setView(view);

      return () => {
        const options = {
          basemap: view.map.basemap.title,
        };
        localStorage.setItem('esri-mapview-options', JSON.stringify(options));
        view && view.destroy();
      };
    }
    return;
  }, [displaysearch, displayhome]);

  function changeMouseCursor(container: any, response: any) {
    if (response.results.length === 0) return;

    const validType = response.results.find(
      (x: any) => x.layer.type === 'geojson' || x.layer.type === 'feature'
    );
    container.style.cursor = validType ? 'pointer' : '';
  }

  function handleMouseClick(response: any) {
    if (response.results.length === 0) return;

    const validLayer = response.results.find(
      (x: any) => x.layer.type === 'geojson' || x.layer.type === 'feature'
    );

    if (!validLayer) return;

    const {
      graphic: {
        attributes: { VesselName, Port_Name },
      },
    } = validLayer;

    const searchWidget: any = response.view.ui.find(
      SearchWidget.SEARCH_WIDGET_ID
    );
    const responseSearchTerm = VesselName || Port_Name;
    const searchTerm: string = searchWidget.searchTerm;
    if (responseSearchTerm === searchTerm) return;

    searchWidget.search(responseSearchTerm);
  }

  React.useEffect(() => {
    dataRef.current = data;
    VesselLayer.Update_Vessels_Position(view as MapView, data);
  }, [data, view]);

  const [searchInfo, setSearchInfo] = useState<{
    searchId: string | undefined;
    searchType: SearchType | undefined;
  }>({ searchId: undefined, searchType: undefined });

  return (
    <div className='viewDiv' ref={mapDiv}>
      {view && (
        <MapViewContext.Provider
          value={{ view: view, setView, searchInfo, setSearchInfo }}
        >
          {children}
        </MapViewContext.Provider>
      )}
    </div>
  );
};
