import { FixedTimeline } from 'assets/fixed-timeline';
import { useParams } from 'react-router-dom';

import { useGetVesselAer } from 'routes/environmental-monitor/services/vessel.service';
import { ChartKeyItem } from 'shared/components/chart-key/chart-key.component';
import {
  xAxisTicksMUI,
  yAxisTickFormatter,
  yAxisTitle,
} from '../../../view-models/aer-chart.viewModel';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { nameof } from 'shared/components/datagrid';
import { LineSeriesType } from '@mui/x-charts-pro';
import {
  getTooltipDataIndex,
  MuiChartContainerWithLegend,
  tooltipContentProps,
} from 'shared/components/mui-chart/container.component';
import { Box, Grid } from '@mui/material';
import { formatNumber } from 'shared/utils/float-utils';

export type AERChartProps = {
  date: Date | undefined;
  deviation: number | null;
  value: number | null;
};

function customTooltip(
  props: tooltipContentProps,
  chartDataset: AERChartProps[]
) {
  const index = getTooltipDataIndex(props);

  // if the dataset or the itemData is not available, return null
  if (!chartDataset || index === undefined) return null;
  const data = chartDataset[index];

  return (
    <Grid container sx={{ width: '250px' }}>
      <Grid item xs={10}>
        AER
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {formatNumber(data?.value, 2)}
      </Grid>

      <Grid item xs={10}>
        Deviation
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {formatNumber(data?.deviation, 2)}
      </Grid>
    </Grid>
  );
}

export function AERChartMui() {
  const { id } = useParams();
  const startDate = new UTCDate().startOfYear;
  const endDate = new UTCDate().endOfDay;
  const dateRange = new DateRange(startDate!, endDate!);
  const { data, error, loading } = useGetVesselAer(id, dateRange);
  const aerData = data?.data;

  const chartDataset: AERChartProps[] = Array.from(aerData ?? [], (p) => {
    return {
      date: new UTCDate(p.date).date,
      value: p.value,
      deviation: p.deviation,
    };
  });
  const chartProps = nameof<AERChartProps>;
  const AerValue: ChartKeyItem = {
    id: 1,
    name: 'AER',
    fill: '#4DE8E3',
  };

  const seriesDate: LineSeriesType[] = [
    {
      type: 'line',
      dataKey: chartProps('value'),
      label: AerValue.name,
      highlightScope: { highlighted: 'item', faded: 'global' },
      color: AerValue.fill,
      connectNulls: true,
      showMark: false,
    },
  ];

  const xAxisFormatter = (date: Date) => {
    return new UTCDate(date).formatDMY();
  };
  const yAxisFormatter = (val: number) => {
    return yAxisTickFormatter(val, data?.required ?? 0);
  };
  const RenderChart = () => {
    return (
      <Box component={'section'} sx={{ pl: 1, mb: 1 }}>
        <MuiChartContainerWithLegend
          margin={{ right: 40, left: 100 }}
          dataset={chartDataset}
          series={seriesDate}
          error={error}
          loading={loading}
          yAxisProps={{
            labelPosition: 40,
          }}
          tooltip={{
            content: (props) => customTooltip(props, chartDataset),
            trigger: 'axis',
          }}
          xAxis={[
            {
              dataKey: chartProps('date'),
              scaleType: 'utc',
              tickLabelStyle: { angle: -35 },
              valueFormatter: xAxisFormatter,
              tickInterval: xAxisTicksMUI(),
              tickNumber: 13,
            },
          ]}
          yAxis={[
            { label: yAxisTitle, min: 0, valueFormatter: yAxisFormatter },
          ]}
        ></MuiChartContainerWithLegend>
      </Box>
    );
  };

  return (
    <CardContainer
      title={vesselPageTitles.aer}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      <RenderChart />
    </CardContainer>
  );
}
