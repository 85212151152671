import { EeoiValues } from '_gql/graphql';
export class FleetEeoiDataMapper {
  static toDomain(data: Array<EeoiValues>) {
    return toDataPoint(data);
  }
}

const toDataPoint = (data: Array<EeoiValues>) => {
  data = data.filter((data) => data.date && data.deviation);
  return (
    data?.map((info: EeoiValues) => ({
      date: info.date.unixTime,
      eeoiDeviation: info.deviation,
      voyageLegsCompleted: info.voyageLegsCompleted ?? 0,
    })) ?? []
  );
};
