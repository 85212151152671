import { v4 as uuidv4 } from 'uuid';
import { setContext } from '@apollo/client/link/context';

export const generateCorrelationId = () => uuidv4();

export const createHeadersLink = (token: string) =>
  setContext(async (_, { headers }) => {
    const correlationId = generateCorrelationId();
    return {
      headers: {
        ...headers,
        'X-Correlation-ID': correlationId,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
