import { Chance } from 'chance';
import { IContext } from '../models/app.context.model';
import { default as useIsAuthenticated } from './useCustomIsAuthenticated';

const chance = new Chance();
export const useAppContext = (): IContext => {
  const isAuthenticated = useIsAuthenticated();

  return {
    isAuthenticated: isAuthenticated,
    user: {
      name: chance.name(),
      tenantId: '',
    },
  };
};
