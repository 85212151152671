import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

/*
 * Custom hook that replaces the useIsAuthenticated hook from @azure/msal-react.
 * It fixes a bug in the MSAL library where useIsAuthenticated always returns false for first page render.
 * PR causing bug: https://github.com/AzureAD/microsoft-authentication-library-for-js/pull/5399
 * Open Bug: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6060
 *
 * That matchAccount parameter was also removed since we don't need it.
 */

export default function useCustomIsAuthenticated(): boolean {
  const { accounts: allAccounts } = useMsal();

  const [hasAuthenticated, setHasAuthenticated] = useState<boolean>(
    () => allAccounts.length > 0
  );

  useEffect(() => {
    setHasAuthenticated(allAccounts.length > 0);
  }, [allAccounts]);

  return hasAuthenticated;
}
