import { SvgIcon, SvgIconProps } from '@mui/material';

export const FixedTimeline = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='24'
      viewBox='0 0 22 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.7 9.8V3.9C17.7 2.8 16.8 1.9 15.7 1.9H14.7V0H12.7V2H5V0H3V2H2C0.9 2 0 2.9 0 4V17.7C0 18.8 0.9 19.7 2 19.7H10V17.7H2V7.8H15.7V9.8H17.7Z'
        fill='#EFEFF0'
        fillOpacity={props.fillOpacity ?? '0.4'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.3 15.8H19.7V14.6C19.7 13 18.4 11.7 16.8 11.7C15.2 11.7 13.9 13 13.9 14.6V15.8H13.3C12.7 15.8 12.1 16.3 12.1 17V22.8C12.1 23.4 12.6 24 13.3 24H20.3C20.9 24 21.5 23.5 21.5 22.8V17C21.4 16.4 20.9 15.8 20.3 15.8ZM16.8 21.1C16.1 21.1 15.6 20.6 15.6 19.9C15.6 19.2 16.1 18.7 16.8 18.7C17.5 18.7 18 19.2 18 19.9C18 20.6 17.4 21.1 16.8 21.1ZM18.6 15.8H15V14.6C15 13.6 15.8 12.8 16.8 12.8C17.8 12.8 18.6 13.6 18.6 14.6V15.8Z'
        fill='#EFEFF0'
        fillOpacity={props.fillOpacity ?? '0.4'}
      />
    </svg>
  </SvgIcon>
);
