import { MenuItem, TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { TextFieldProps } from '@mui/material/TextField';
import { memo, useEffect, useState } from 'react';

export const FormTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: 'white !important',
  },
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor:
      theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.09)' : 'blue',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.09)',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.error.main} !important`,
    },
    'div.MuiChip-root': {
      height: '23px',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const SequenceTextField = (props: TextFieldProps) => {
  return (
    <FormTextField
      variant='filled'
      SelectProps={{
        displayEmpty: true,
      }}
      fullWidth={true}
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
      {...props}
    />
  );
};

export const SequenceNumberField = (props: TextFieldProps) => (
  <FormTextField
    variant='filled'
    fullWidth={true}
    type='number'
    inputProps={{
      step: props?.inputProps?.['step'] ?? 0.1,
      min: props.inputProps?.['min'] ?? 0,
      max: props.inputProps?.['max'] ?? 99999,
    }}
    onKeyDown={(event) => {
      event.stopPropagation();
    }}
    {...props}
  />
);

export type ValidationTextFieldProps = TextFieldProps & {
  validator?: (value: string) => boolean | string;
  formCheck?: (isValid: boolean) => void;
  fieldType?: 'number' | 'string';
};

const isValueEmpty = (value: string | object) => {
  if (typeof value === 'object') {
    if (Object.values(value).find((x) => !!x) === undefined) {
      return true;
    }
  }

  return value === '';
};

export const ValidatedTextField = (props: ValidationTextFieldProps) => {
  const [error, setError] = useState<string | boolean>(false);
  const { validator, formCheck, fieldType, ...textProps } = props;
  const value = textProps.value ?? '';

  useEffect(() => {
    setError(false);
  }, [props.itemID]);

  function onInvalid(e: any) {
    if (props.required)
      if (isValueEmpty(value)) {
        const message =
          props.label !== undefined && typeof props.label === 'string'
            ? props.label.replace(/\(.*?\)/g, '') + ' is required'
            : 'This field is required';
        setError(message);
        if (formCheck) formCheck(false);
        return;
      }

    setError(e.target.validationMessage);
  }

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    const errorMessage: boolean | string = validator ? validator(newValue) : '';

    setError(errorMessage);
    if (formCheck) formCheck(errorMessage === false);

    // call original onChange function
    if (props.onChange) props.onChange(e);
  };

  if (textProps.select && textProps.SelectProps) {
    textProps.SelectProps.onChange = (e) => handleChange(e);
  }

  if (fieldType === 'number')
    return (
      <SequenceNumberField
        {...textProps}
        value={value}
        variant='filled'
        onChange={handleChange}
        fullWidth={textProps.fullWidth ?? true}
        error={!!error}
        helperText={error}
        onInvalid={(e) => onInvalid(e)}
      />
    );

  return (
    <FormTextField
      {...(textProps as TextFieldProps)}
      value={value}
      variant='filled'
      onChange={handleChange}
      fullWidth={textProps.fullWidth ?? true}
      error={!!error}
      helperText={error}
      onInvalid={(e) => onInvalid(e)}
    />
  );
};

export const FormTextFieldMemo = memo(function (props: TextFieldProps) {
  return <FormTextField {...props} />;
});

export enum EmptyOption {
  Undefined = '',
}

/**
 * This function takes an enum as input and returns a list of MenuItems.
 * Each MenuItem represents a key-value pair from the enum.
 *
 * @param {Object} enumtype - The enum to list the keys and values from.
 * @returns {Array} An array of MenuItem components.
 */
export function ListEnum(enumtype: { [s: number | string]: string }) {
  const menuItems = Object.keys(enumtype).map((key) => {
    const enumKey = key as keyof typeof enumtype;
    const value = enumtype[enumKey];
    return (
      <MenuItem key={key} value={value}>
        {value}
      </MenuItem>
    );
  });

  return menuItems;
}
