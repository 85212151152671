import { useCallback, useMemo, useState } from 'react';
import {
  TopNavigationContext,
  TopNavigationType,
} from 'shared/models/topNav.context.model';
import { DownloadListVessel } from '../../../routes/reports/components/generateReport/download-common';
import { DropdownGroupingCategory } from '../vesselGroupingsDropdown/models/vesselGroupings.viewModel';

export const TopNavWrapper = (props: {
  children: JSX.Element;
}): JSX.Element => {
  const setVesselGroup = (vesselGroups: string[]) => {
    setContext((prevContext) => ({
      ...prevContext,
      vesselGroup: vesselGroups,
    }));
  };

  const setVesselGroups = (vesselGroups: DropdownGroupingCategory[]) => {
    setContext((prevContext) => ({
      ...prevContext,
      vesselGroups,
    }));
  };

  const setVesselName = (vesselNames: string[]) => {
    setContext((prevContext) => ({
      ...prevContext,
      vesselName: vesselNames,
    }));
  };
  const setDateRange = (dateRanges: string[]) => {
    setContext((prevContext) => ({
      ...prevContext,
      dateRange: dateRanges,
    }));
  };

  const setVessels = useCallback((vessels: DownloadListVessel[]) => {
    setContext((prevContext) => ({
      ...prevContext,
      vessels: vessels,
    }));
  }, []);

  const [context, setContext] = useState<TopNavigationType>({
    vesselGroup: [],
    vesselGroups: [],
    setVesselGroup,
    setVesselGroups,
    dateRange: [],
    setDateRange,
    vessels: [],
    setVessels,
    vesselName: [],
    setVesselName,
  });
  const topNavContext: TopNavigationType = useMemo(
    () => ({
      vesselGroup: context.vesselGroup,
      vesselGroups: context.vesselGroups,
      setVesselGroup,
      setVesselGroups,
      dateRange: context.dateRange,
      setDateRange,
      vesselName: context.vesselName,
      setVesselName,
      vessels: context.vessels,
      setVessels,
    }),
    [
      context.vesselGroup,
      context.vesselGroups,
      context.dateRange,
      context.vesselName,
      context.vessels,
      setVessels,
    ]
  );

  return (
    <TopNavigationContext.Provider value={topNavContext}>
      {props.children}
    </TopNavigationContext.Provider>
  );
};
