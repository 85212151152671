import { theme } from 'styles/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const componentTheme = createTheme({
  ...theme,
  palette: {
    background: {
      paper: '#2C343D',
      default: 'rgba(239, 239, 240, 0.07)',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#EFEFF0',
    },
    secondary: {
      main: '#B39DDB',
      dark: '#1D262F',
    },
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    subtitle1: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '143%',
      letterSpacing: '0.17px',
      fontFeatureSettings: "'clig' off, 'liga' off",
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(255, 255, 255, 0.70)',
      display: 'inline',
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: '143%',
      letterSpacing: '0.17px',
    },
  },
});

export const MapDrawerLayout = (props: { children: JSX.Element }) => {
  return <ThemeProvider theme={componentTheme}>{props.children}</ThemeProvider>;
};
