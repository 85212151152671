import {
  DataPoint,
  DataToggles,
  Projection,
  ProjectionMetrics,
  ProjectionConditions,
} from '_gql/graphql';
import {
  Cii,
  CiiBoundaries,
  CiiDataToggles,
  ProjectionConditionsDomain,
  ProjectionConditionMetricsDomain,
  CiiDataTogglesChartData,
} from '../models/vessel-cii-rating.model';
import { formatFloatToNumber } from 'shared/utils/float-utils';

export class VesselCiiRatingsDataMapper {
  static toDomain(
    required: number | undefined,
    data: DataPoint[] | undefined,
    boundaries: CiiBoundaries | undefined,
    projection: Projection | undefined,
    dataToggles: DataToggles | undefined
  ): Cii {
    const combinedData = toDataPoint(data);
    const projectionValues = projection?.values ?? undefined;

    if (projectionValues) {
      combinedData.push(
        ...projectionValues.map((dataPoint) => {
          return {
            date: dataPoint.date?.unixTime ?? 0,
            deviation: dataPoint.deviation ?? null,
            value: dataPoint.value ?? 0,
          };
        })
      );
    }

    const mappedDataToggles: CiiDataToggles = {
      speed: dataToggles?.speed ? toDataPoint(dataToggles.speed) : undefined,
      loadingCondition: dataToggles?.loadingCondition
        ? toDataPoint(dataToggles.loadingCondition)
        : undefined,
      fuelOilConsumption: dataToggles?.fuelOilConsumption
        ? {
            mainEngine: toDataPoint(
              dataToggles?.fuelOilConsumption.mainEngine ?? undefined
            ),
            boiler: toDataPoint(
              dataToggles?.fuelOilConsumption.boiler ?? undefined
            ),
            auxEngine: toDataPoint(
              dataToggles?.fuelOilConsumption.auxEngine ?? undefined
            ),
          }
        : undefined,
      operationalProfile: dataToggles?.operationalProfile
        ? toDataPoint(dataToggles.operationalProfile)
        : undefined,
      vesselCondition: dataToggles?.vesselCondition
        ? toDataPoint(dataToggles.vesselCondition)
        : undefined,
      vesselComparison: dataToggles?.vesselComparison
        ? toDataPoint(dataToggles.vesselComparison)
        : undefined,
    };

    return {
      required: required,
      boundaries: boundaries,
      dataPoints: combinedData,
      dataToggles: mappedDataToggles,
      projection: {
        year: projection?.year ?? 0,
        conditions: projectionConditionsToDomain(
          projection?.conditions ?? undefined
        ),
      },
    };
  }

  static toChartData(cii: Cii | undefined): CiiDataTogglesChartData[] {
    const data: CiiDataTogglesChartData[] = [];
    const ciiValues = cii?.dataPoints;
    const ciiDataToggles = cii?.dataToggles;

    let allDates = ciiValues?.map((x) => x.date) ?? [];
    allDates = allDates.concat(ciiDataToggles?.speed?.map((x) => x.date) ?? []);
    allDates = allDates.concat(
      ciiDataToggles?.fuelOilConsumption?.mainEngine?.map((x) => x.date) ?? []
    );
    allDates = allDates.concat(
      ciiDataToggles?.fuelOilConsumption?.boiler?.map((x) => x.date) ?? []
    );
    allDates = allDates.concat(
      ciiDataToggles?.fuelOilConsumption?.auxEngine?.map((x) => x.date) ?? []
    );
    allDates = allDates.concat(
      ciiDataToggles?.loadingCondition?.map((x) => x.date) ?? []
    );

    // Remove duplicated dates
    allDates = allDates.filter((item, idx) => allDates.indexOf(item) === idx);
    allDates = allDates.sort();

    allDates?.forEach((element) => {
      const ciiValue =
        ciiValues?.filter((x) => x.date === element).reverse()[0] ?? null; //last report of the day
      const dataPoint: CiiDataTogglesChartData = {
        date: element,
        ciiRating: ciiValue?.value
          ? formatFloatToNumber(ciiValue?.value)
          : ciiValue?.value,
        ciiDeviation: ciiValue?.deviation || undefined,
        speed: ciiDataToggles?.speed?.find((x) => x.date === element)?.value,
        fuelOilConsumptionmainEngine:
          ciiDataToggles?.fuelOilConsumption?.mainEngine?.find(
            (x) => x.date === element
          )?.value,
        fuelOilConsumptionboiler:
          ciiDataToggles?.fuelOilConsumption?.boiler?.find(
            (x) => x.date === element
          )?.value,
        fuelOilConsumptionauxEngine:
          ciiDataToggles?.fuelOilConsumption?.auxEngine?.find(
            (x) => x.date === element
          )?.value,
        loadingCondition: ciiDataToggles?.loadingCondition?.find(
          (x) => x.date === element
        )?.value,
      };

      data.push(dataPoint);
    });

    return data;
  }
}

const toDataPoint = (data: DataPoint[] | undefined) => {
  return (
    data?.map((rating) => ({
      date: rating.date?.unixTime ?? 0,
      deviation: rating?.deviation ? Number(rating.deviation.toFixed()) : null,
      value: rating?.value ? Number(rating.value.toFixed(2)) : 0,
    })) ?? []
  );
};

export const projectionConditionsToDomain = (
  projectionCondition: ProjectionConditions | undefined
): ProjectionConditionsDomain => {
  return {
    inputMetrics: projectionConditionMetricsToDomain(
      projectionCondition?.inputMetrics ?? undefined
    ),
    yearToDateMetrics: projectionConditionMetricsToDomain(
      projectionCondition?.yearToDateMetrics ?? undefined
    ),
    twelveMonthRollingMetrics: projectionConditionMetricsToDomain(
      projectionCondition?.twelveMonthRollingMetrics ?? undefined
    ),
  };
};

const projectionConditionMetricsToDomain = (
  metrics: ProjectionMetrics | undefined
): ProjectionConditionMetricsDomain => {
  return {
    avgBallastSpeed: metrics?.averageBallastSpeed ?? undefined,
    avgLadenSpeed: metrics?.averageLadenSpeed ?? undefined,
    avgBallastFoc: metrics?.averageBallastFocPerDay ?? undefined,
    avgLadenFoc: metrics?.averageLadenFocPerDay ?? undefined,
    idleTime: metrics?.idleTimePercentage ?? undefined,
    distance: metrics?.distance ?? undefined,
    ballast: metrics?.ballastPercentage ?? undefined,
    laden: metrics?.ladenPercentage ?? undefined,
  };
};
