import { SyncDisabled } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import { getStatusValues } from 'shared/components/status-kpi-card/status-kpi-card.viewModel';
import { TEXT } from 'shared/constants/text';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';

export interface KpiCardErrorProps {
  kpiCards: {
    title?: string;
    showBoxContainerTitle?: boolean;
    boxContainerTitleText?: string;
    showVerticalDivider?: boolean;
  }[];
}

export function KpiCardError(props: KpiCardErrorProps) {
  const cardBackground = theme.background.component;

  const cardStyle: SxProps = {
    borderRadius: '12px',
    backgroundColor: cardBackground,
    minWidth: '245px',
    maxWidth: '400px', // Ensure the cards have a consistent max width
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const { color: statusBarBackground } = getStatusValues(Status.none);

  const statusBarStyle: SxProps = {
    backgroundColor: statusBarBackground,
    height: 8,
    margin: 0,
  };

  const errorCards = props.kpiCards.map((card, index) => {
    const showVerticalDivider = card.showVerticalDivider ?? false;
    const showBoxContainerTitle = card.showBoxContainerTitle ?? false;
    const boxContainerTitleText = card.boxContainerTitleText ?? '';
    return (
      <Grid
        item
        key={card.title + index.toString()}
        xs={12}
        smPlus={6}
        mdPlus={4}
        lg={3}
        data-testid={'kpi-error-component-' + index.toString()}
      >
        <Box
          component={'section'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
        >
          {/* Title above the card */}

          <Typography variant='h6' sx={{ marginBottom: 1, minHeight: '20px' }}>
            {showBoxContainerTitle ? boxContainerTitleText : ''}
          </Typography>
          <Box
            component={'article'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              minHeight: '251px',
            }}
          >
            <Card sx={{ ...cardStyle }}>
              <Divider sx={statusBarStyle} />
              <CardContent
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '234px',
                  justifyContent: 'center',
                  marginTop: '8px',
                  marginRight: '24px',
                  marginLeft: '24px',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <SyncDisabled
                    fontSize={'large'}
                    sx={{
                      color: theme.palette.text.disabled,
                      marginBottom: '12px',
                    }}
                  />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: typography.fontSize[12],
                        textAlign: 'center',
                      }}
                    >
                      {`Unable to load ${card.title} data`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: typography.fontSize[1],
                      }}
                    >
                      {TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            {showVerticalDivider && (
              <Divider
                orientation='vertical'
                flexItem
                sx={{
                  backgroundColor: theme.colors.gradientGray,
                  marginLeft: 2,
                  height: 'auto', // Ensure the divider takes the full height
                  alignSelf: 'stretch', // Stretch to match the height of the card
                }}
              />
            )}
          </Box>
        </Box>
      </Grid>
    );
  });

  return (
    <Grid container columns={12} spacing={2} sx={{ marginTop: 1 }}>
      {errorCards}
    </Grid>
  );
}
