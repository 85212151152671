import { useGetKpiData } from '../repositories/individual-vessel-performance-kpi.repository';
import { IndividualVesselPerformanceKPIMapper } from '../mappers/individual-vessel-performance-kpi.mapper';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetKpis = (dateRange: DateRange | null, vesselImo: string) => {
  const getVesselPerformanceKpisResponse = useGetKpiData(dateRange, vesselImo);

  const isLoading = getVesselPerformanceKpisResponse?.loading;
  const error = getVesselPerformanceKpisResponse?.error;

  if (isLoading) {
    return { loading: true, error: null, data: null };
  }

  if (error) {
    return { loading: false, error: error, data: null };
  }

  if (!getVesselPerformanceKpisResponse?.data?.vesselPerformanceIndicators) {
    return { loading: false, error: null, data: null };
  }

  const kpis = IndividualVesselPerformanceKPIMapper.kpisToDomain(
    getVesselPerformanceKpisResponse?.data?.vesselPerformanceIndicators
  );

  return {
    loading: false,
    error: null,
    data: kpis,
  };
};
