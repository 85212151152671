import {
  ISequence,
  LoadingConditionTypes,
  SequenceTypes,
} from 'routes/environmental-monitor/models/plannedItinerary.model';
import { getSelectedFuelTypes } from '../fuel-types/renders';
import { updateProperty } from '../modal/sequence.config';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export class SequenceValidationResult {
  public valid: boolean;
  public id: number;
  public message: string;
  public failingField: keyof ISequence | undefined;

  private constructor(
    valid?: boolean,
    id?: number,
    message?: string,
    failingField?: keyof ISequence
  ) {
    this.valid = valid ?? true;
    this.id = id ?? -1;
    this.message = message ?? '';
    this.failingField = failingField;
  }

  public static Passed(): SequenceValidationResult {
    return new SequenceValidationResult();
  }

  public static Failed(
    id: number,
    message: string,
    failingField?: keyof ISequence
  ): SequenceValidationResult {
    return new SequenceValidationResult(false, id, message, failingField);
  }
}

export interface ISequenceRule {
  Validate(
    lastSequence: ISequence,
    thisSequence: ISequence | undefined,
    isFinalSequence?: boolean
  ): SequenceValidationResult;
}

const passed = SequenceValidationResult.Passed();

export abstract class LoadingConditionConstant implements ISequenceRule {
  Validate(
    lastSequence: ISequence,
    thisSequence: ISequence | undefined
  ): SequenceValidationResult {
    if (
      !thisSequence ||
      lastSequence.loadingCondition === thisSequence.loadingCondition ||
      thisSequence.sequence === SequenceTypes.InPortDischarge ||
      thisSequence.sequence === SequenceTypes.InPortLoading
    ) {
      return passed;
    }
    return SequenceValidationResult.Failed(
      thisSequence.id,
      `The loading condition can only change with an "${SequenceTypes.InPortLoading}" or "${SequenceTypes.InPortDischarge}" operation.`,
      'loadingCondition'
    );
  }
}

export abstract class CargoConstant implements ISequenceRule {
  Validate(
    lastSequence: ISequence,
    thisSequence: ISequence | undefined
  ): SequenceValidationResult {
    if (
      !thisSequence ||
      lastSequence.cargoQuantity === thisSequence.cargoQuantity ||
      thisSequence.sequence === SequenceTypes.InPortDischarge ||
      thisSequence.sequence === SequenceTypes.InPortLoading
    ) {
      return passed;
    }
    return SequenceValidationResult.Failed(
      thisSequence.id,
      `The cargo amount can only change with an "${SequenceTypes.InPortLoading}" or "${SequenceTypes.InPortDischarge}" operation.`,
      'cargoQuantity'
    );
  }
}

export abstract class FinalSequenceIsInPort implements ISequenceRule {
  Validate(
    _lastSequence: ISequence,
    thisSequence: ISequence | undefined,
    isFinalSequence: boolean
  ): SequenceValidationResult {
    if (!isFinalSequence) {
      return passed;
    }

    const sequenceToCheck = thisSequence ?? _lastSequence;

    if (
      sequenceToCheck.sequence === SequenceTypes.InPortDischarge ||
      sequenceToCheck.sequence === SequenceTypes.InPortLoading
    ) {
      return passed;
    }
    return SequenceValidationResult.Failed(
      sequenceToCheck.id,
      `The final operation must be "${SequenceTypes.InPortLoading}" or "${SequenceTypes.InPortDischarge}".`,
      'sequence'
    );
  }
}

export const getSequenceRules = (): ISequenceRule[] => {
  return [
    LoadingConditionConstant.prototype,
    CargoConstant.prototype,
    FinalSequenceIsInPort.prototype,
  ];
};

export const updateSequenceField = (
  params: GridRenderCellParams<ISequence>,
  field: keyof ISequence,
  value: any
) => {
  const { api, row } = params;

  if (field === 'fuelType') {
    const newFuelTypes = getSelectedFuelTypes(row.fuelType, value);
    const updatedRow = updateProperty(row, field, newFuelTypes);
    api.updateRows([updatedRow]);
    return;
  }
  // Function to copy cargo quantities
  const copyCargoQuantities = (
    targetRow: ISequence | undefined,
    sourceRow: ISequence | undefined
  ) => {
    if (sourceRow?.cargoQuantity !== undefined) {
      if (targetRow) {
        targetRow.cargoQuantity = sourceRow.cargoQuantity;
        targetRow.loadingCondition = LoadingConditionTypes.Laden;
      }
    }
  };

  // Find the last sequence with cargo quantity
  const findLastCargoQuantitySequence = (): ISequence | null => {
    const rowIds = api.getAllRowIds();
    if (!rowIds || !Array.isArray(rowIds)) {
      return null;
    }

    const filteredRows = rowIds
      .map((rowId: any) => api.getRow(rowId))
      .filter(
        (row: any) =>
          (row.sequence === SequenceTypes.InPortDischarge ||
            row.sequence === SequenceTypes.InPortLoading) &&
          row.cargoQuantity !== undefined
      );

    return filteredRows.length > 0 ? filteredRows.pop() : null;
  };

  // We need to reset the field when the sequence field changes, so no leftover values are carry over
  if (field === 'sequence') {
    const emptyRecord: ISequence = {
      id: row.id,
      sequence: value,
    } as ISequence;

    // Clear all other fields when the sequence has changed
    let member: keyof ISequence;
    for (member in params.row) {
      if (member === 'id' || member === 'sequence') continue;
      delete params.row[member as keyof ISequence];
    }

    // Copy cargo quantity if needed
    if (value !== 'InPortDischarge' && value !== 'InPortLoading') {
      const lastCargoQuantitySequence = findLastCargoQuantitySequence();
      if (lastCargoQuantitySequence) {
        copyCargoQuantities(emptyRecord, lastCargoQuantitySequence);
      }
    }

    api.updateRows([emptyRecord]);
    return;
  }
  const updatedRow = updateProperty(row, field, value);
  api.updateRows([updatedRow]);
};
