/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloError } from '@apollo/client';
import { GridRowHeightParams } from '@mui/x-data-grid-pro';
import { DataGridTable } from 'shared/components/datagrid/DataGrid.component';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { constants } from '../individualVesselPerformance.route';
import { formatEventData } from '../utils/format-events-data.utils';
import { IEvent } from './events/models/events.model';
import { getColumnsDefinition } from './vesselPerformanceEventsTable.config';

interface IndividualVesselEventsProps {
  eventsData: IEvent[] | null | undefined;
  error: ApolloError | null;
  loading: boolean;
}

export const VesselPerformanceEventsTable = ({
  eventsData,
  error,
  loading,
}: IndividualVesselEventsProps) => {
  return (
    <CardContainer title={constants.INDV_VESSEL_EVENT_INSIGHT_TITLE}>
      <DataGridTable
        sx={{ mt: 4 }}
        getRowHeight={({ densityFactor }: GridRowHeightParams) => {
          return 65 * densityFactor;
        }}
        name={constants.LOG_KPIS_SECTION_ID}
        rows={formatEventData(eventsData ?? [])}
        columns={getColumnsDefinition()}
        loading={loading}
        error={error}
      ></DataGridTable>
    </CardContainer>
  );
};
