import { VesselPerformanceKPIMapper } from '../mappers/vessel-performance-kpi.mapper';
import {
  useGetVesselPerformanceKpiCO2PenaltyData,
  useGetVesselPerformanceKpiFuelPenaltyData,
  useGetVesselPerformanceKpisData,
} from '../repositories/vessel-performance-kpi.repository';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetVesselPerformanceKpis = (
  dateRange: DateRange | null,
  vesselImos: string[]
) => {
  const getVesselPerformanceKpisResponse = useGetVesselPerformanceKpisData(
    dateRange,
    vesselImos
  );

  const loading = getVesselPerformanceKpisResponse?.loading;
  const error = getVesselPerformanceKpisResponse?.error;
  const data = VesselPerformanceKPIMapper.toDomain(
    getVesselPerformanceKpisResponse?.data?.fleetPerformanceIndicators ??
      undefined
  );

  return {
    kpiLoading: loading,
    kpiError: error,
    kpiData: data,
  };
};

export const useGetVesselPerformanceKpiFuelPenalty = () => {
  return useGetVesselPerformanceKpiFuelPenaltyData();
};

export const useGetVesselPerformanceKpiCO2Penalty = () => {
  return useGetVesselPerformanceKpiCO2PenaltyData();
};
