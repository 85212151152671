import { gql } from '@apollo/client';

export const GET_FUEL_TYPES = gql`
  query FuelTypes {
    fuelTypes {
      name
      abbreviation
      enumValue
      classificationName
    }
  }
`;
