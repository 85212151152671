import { Box } from '@mui/system';
import { typography } from 'styles/typography';
import { formatNumber } from 'shared/utils/float-utils';
import {
  MuiChartContainer,
  getTooltipDataIndex,
  tooltipContentProps,
} from '../mui-chart/container.component';
import { AllSeriesType, ChartsLegend, PieValueType } from '@mui/x-charts-pro';

export type PieChartValueOptions = {
  value: any;
  name: string;
  color?: string;
  unit?: string;
};

function customTooltip(
  props: tooltipContentProps,
  dataset: PieChartValueOptions[],
  noDecimals: number
) {
  const index = getTooltipDataIndex(props);
  if (index === undefined) return null;

  const data = dataset[index];
  const label = data?.name ?? '';
  const unit = data?.unit ?? '';
  const value = formatNumber(data?.value, noDecimals) + unit;

  return (
    <Box>
      <Box component={'span'} fontWeight='bold'>
        {label}:
      </Box>
      <Box component={'span'} sx={{ pl: 2 }}>
        {value}
      </Box>
    </Box>
  );
}

export const PieChartStraightAngleMui = ({
  data,
  noDecimals = 0,
}: {
  data: PieChartValueOptions[];
  noDecimals?: number;
}) => {
  const seriesData: PieValueType[] = data.map((item, index) => {
    return {
      id: index,
      label: item.name,
      value: item.value,
      color: item.color ?? '',
    };
  });

  const series = {
    startAngle: -90,
    endAngle: 90,

    innerRadius: 87,
    outerRadius: 100,
    data: seriesData,
    type: 'pie',
    cy: 70,
  } as AllSeriesType<'pie'>;

  return (
    <MuiChartContainer
      height={170}
      series={[series]}
      tooltip={{ content: (props) => customTooltip(props, data, noDecimals) }}
    >
      <ChartsLegend
        direction='row'
        position={{ vertical: 'bottom', horizontal: 'middle' }}
        padding={0}
        itemMarkHeight={14}
        itemMarkWidth={14}
        labelStyle={{ fontSize: typography.fontSize['1'] }}
      />
    </MuiChartContainer>
  );
};
