import { UTCDate } from 'shared/utils/date-utc-helper';
import {
  ConvertLatToDMS,
  ConvertLongToDMS,
  CalculateTimeDiff,
} from 'shared/utils/map-utils';

interface IWaypoint {
  waypointNumber: number;
  distance: number;
  dateTime: string;
  position: string;
  sailType: string;
  SOG: string;
  course: string;
  swell: string;
  wave: string;
  currents: string;
  wind: string;
  BFScale: string;
}

export class ActiveVoyageInfoForDrawerMapper {
  public static fromDTO(dto: any): any {
    const activeVoyageInfo: any = {};

    activeVoyageInfo.imoNumber = dto.imoNumber ?? '---';
    activeVoyageInfo.vesselName = dto.name ?? '---';

    activeVoyageInfo.status = dto.status ?? '---';
    activeVoyageInfo.location = dto.location ?? '---';
    activeVoyageInfo.position =
      ConvertLatToDMS(dto.currentPosition?.latitude) +
      ' ' +
      ConvertLongToDMS(dto.currentPosition?.longitude);

    activeVoyageInfo.heading = dto.currentPosition?.heading ?? '---';
    activeVoyageInfo.lastUpdatedTime = CalculateTimeDiff(
      dto.currentPosition?.timestamp
    );

    activeVoyageInfo.voyageNumber = dto.activeVoyage?.voyageNumber ?? '---';
    activeVoyageInfo.origin = dto.activeVoyage?.origin ?? '---';
    activeVoyageInfo.distance = dto.activeVoyage?.distance ?? '---';
    activeVoyageInfo.lastDepartureLocation =
      dto.activeVoyage?.lastDepartureLocation ?? '---';
    activeVoyageInfo.nextDestinationLocation =
      dto.activeVoyage?.nextDestinationLocation ?? '---';
    activeVoyageInfo.departureTimeFromLastDepartureLocation =
      dto.activeVoyage &&
      dto.activeVoyage.departureTimeFromLastDepartureLocation
        ? new UTCDate(
            dto.activeVoyage.departureTimeFromLastDepartureLocation
          ).formatDMY()
        : '---';
    activeVoyageInfo.estimatedTimeArrival =
      dto.activeVoyage && dto.activeVoyage.estimatedTimeArrival
        ? new UTCDate(dto.activeVoyage.estimatedTimeArrival).formatDMY()
        : new UTCDate().formatDMY();

    activeVoyageInfo.legInfo = dto.legInfo ?? this.generateLegsInfo();

    const waypointsFromDTO = dto.activeVoyage?.waypoints ?? [];
    // if (waypoints.length > 0) {
    const waypoints = waypointsFromDTO.map((waypoint: any) => {
      return this.fromWaypointDTO(waypoint);
    });
    // }
    activeVoyageInfo.waypoints = waypoints;
    return activeVoyageInfo;
  }

  // This is for generating mock data for the UI
  private static generateLegsInfo() {
    const mockLegsInfo = {
      currentLeg: 8,
      totalLeg: [
        'Tokyo, Japan',
        'Shanghai, China',
        'Hong Kong, China',
        'Singapore',
        'Rotterdam, Netherlands',
        'Hamburg, Germany',
        'Antwerp, Belgium',
        'London, UK',
        'New York, USA',
        'Norfolk, USA',
        'Savannah, USA',
        'Miami, USA',
        'Houston, USA',
        'Los Angeles, USA',
        'Oakland, USA',
        'Tokyo, Japan',
      ],
      legOrigin: 'Leg Origin',
      legDestination: 'Leg Destination',
    };

    return mockLegsInfo;
  }

  private static fromWaypointDTO(waypoint: any): IWaypoint {
    const responseWaypoint: IWaypoint = {
      waypointNumber: waypoint.waypointNumber,
      distance: waypoint.distance ?? 55,
      dateTime: new UTCDate(waypoint.dateTime).formatDMY(),
      position:
        ConvertLatToDMS(waypoint.latitude) +
        ' ' +
        ConvertLongToDMS(waypoint.longitude),
      sailType: waypoint.sailType,
      SOG: waypoint.SOG ?? '836.6 kn',
      course: waypoint.course ?? '871.3°',
      swell: waypoint.swell ?? '3.8 m',
      wave: waypoint.wave ?? '3.9 m, NNW (BF 3)',
      currents: waypoint.currents ?? '0.7 kn, E',
      wind: waypoint.wind ?? '35 kn, NNW (BF 5)',
      BFScale: waypoint.BFScale ?? 'BF 4',
    };

    return responseWaypoint;
  }
}

export class VoyageWaypointMapper {
  public static dto = ({
    imoNumber,
    waypointsOverrides,
  }: {
    imoNumber?: string;
    waypointsOverrides?: [];
  } = {}) => {
    return {
      imoNumber: imoNumber ?? '---',
      waypoints: waypointsOverrides ?? this.generateWaypoints(),
    };
  };

  public static generateWaypoints = () => {
    return [
      {
        arrivalDate: '15 Feb 2022',
        location: "41°24'N 2°10'E",
        distance: 3312,
      },
      {
        arrivalDate: '16 Feb 2022',
        location: "41°24'N 2°10'E",
        distance: 2515,
      },
      {
        arrivalDate: '17 Feb 2022',
        location: "41°24'N 2°10'E",
        distance: 2800,
      },
    ];
  };
}
