import { Link, Tooltip, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { TEXT } from 'shared/constants/text';
import { useGetVessels } from '../../services/vessel.service';
import { useContext } from 'react';
import {
  DataGridTable,
  GridColumn,
  GridColumnDef,
  GridCustomHeaderClass,
  GridRAGColumn,
  nameof,
  objvalue,
} from 'shared/components/datagrid';
import { RAGDisplay } from 'shared/components/status-card/ragIndicator.component';
import { IFleetVessel } from 'routes/environmental-monitor/models/fleet-vessel.model';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { fleetPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';

import { euaLiabilitiesTooltipHeader } from '../tooltip/eua-tooltip.component';
import {
  formatLargeNumber,
  formatNumber,
  isNumeric,
} from 'shared/utils/float-utils';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import {
  euaExcelDisplayValue,
  ghgGetStandardKvpColumn,
} from '../vessel-detail/voyage-comparison/voyage-comparison.component';
import { DateRange } from 'shared/utils/date-utc-helper';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';
import { GridPinnedPosition } from '@mui/x-data-grid-pro';
import { convertKpiStatusToStatus } from 'shared/utils/status-utils';

export type FleetOverviewTableProps = {
  dateRange: DateRange | null;
  disableVirtualization?: boolean;
};

const SMALL_CELL_WIDTH = 135;
const BIG_CELL_WIDTH = 180;

const renderCellRAG = (params: any) => {
  const base = params.colDef as GridColumnDef;
  const noDecimals = base.noDecimals ?? 0;
  const valueField = base.valueField ?? base.field + '.value';
  const statusField = base.statusField ?? base.field + '.status';
  const rawValue = objvalue(params.row, valueField);
  const value = formatNumber(rawValue, noDecimals);
  const status = objvalue(params.row, statusField);
  const realStatus = convertKpiStatusToStatus(status);
  return <RAGDisplay value={value} status={realStatus} />;
};

const GridRagColumnValue = (base: GridColumnDef) =>
  GridRAGColumn({
    ...base,
    minWidth: base.minWidth ?? BIG_CELL_WIDTH,
    renderCell: renderCellRAG,
  });

const GridColumnValue = (base: GridColumnDef) =>
  GridColumn({
    ...base,
    type: 'number',
    noDecimals: base.noDecimals ?? 2,
    minWidth: base.minWidth ?? BIG_CELL_WIDTH,
  });

const GridRagColumnDelta = (base: GridColumnDef) =>
  GridColumn({
    ...base,
    minWidth: SMALL_CELL_WIDTH,
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    noDecimals: base.noDecimals ?? 2,
  });

const GridRagColumnDeviation = (base: GridColumnDef) =>
  GridRAGColumn({
    ...base,
    type: 'number',
    minWidth: base.minWidth ?? SMALL_CELL_WIDTH,
    renderCell: renderCellRAG,
  });

function getColumnsDefinition(
  navigate: any,
  featureFlags: { [key: string]: boolean }
): GridColumnDef[] {
  const columns = nameof<IFleetVessel>; // get the properties from the model

  // Access feature flags from the context
  const ghgMrvStoryFeatureFlagEnabled =
    featureFlags[FEATURE_FLAG.FLEETOVERVIEW_VESSELCOMPARISONGRID_GHG_MRV];
  const ghgMrvFeatureFlagEnabled = featureFlags[FEATURE_FLAG.MRV_PART_2];
  const fleetOverviewGhgIntensityWttColumnVesselEnvironmentalGrid =
    featureFlags[
      FEATURE_FLAG
        .FLEETOVERVIEW_GHG_INTENSITY_WTT_COLUMN_VESSEL_ENVIRONMENTAL_GRID
    ];
  const fleetOverviewGhgIntensityTtwColumnVesselEnvironmentalGrid =
    featureFlags[
      FEATURE_FLAG
        .FLEETOVERVIEW_GHG_INTENSITY_TTW_COLUMN_VESSEL_ENVIRONMENTAL_GRID
    ];
  const fleetOverviewGhgIntensityWtwColumnVesselEnvironmentalGrid =
    featureFlags[
      FEATURE_FLAG
        .FLEETOVERVIEW_GHG_INTENSITY_WTW_COLUMN_VESSEL_ENVIRONMENTAL_GRID
    ];
  const fleetOverviewComplianceBalanceColumnVesselEnvironmentalGrid =
    featureFlags[
      FEATURE_FLAG
        .FLEETOVERVIEW_COMPLIANCE_BALANCE_COLUMN_VESSEL_ENVIRONMENTAL_GRID
    ];
  const fleetOverviewFuelEuPenaltyAgainstFleetColumnVesselEnvironmentalGrid =
    featureFlags[
      FEATURE_FLAG
        .FLEETOVERVIEW_FUEL_EU_PENALTY_AGAINST_FLEET_COLUMN_VESSEL_ENVIRONMENTAL_GRID
    ];
  const fleetOverviewFuelEuPenaltyColumnVesselEnvironmentalGrid =
    featureFlags[
      FEATURE_FLAG
        .FLEETOVERVIEW_FUEL_EU_PENALTY_COLUMN_VESSEL_ENVIRONMENTAL_GRID
    ];

  const handleVesselNameClick = (row: any) => {
    navigate(`${ROUTES.environmentalMonitor.path}/vessels/${row.id}`);
  };

  const commonSection: GridColumnDef[] = [
    {
      field: columns('name'),
      headerName: 'Vessel Name',
      pinPosition: GridPinnedPosition.left,
      flex: 1,
      minWidth: BIG_CELL_WIDTH,
      renderCell: (params: any) => (
        <Link
          className='MuiDataGrid-cellContent'
          onClick={() => handleVesselNameClick(params.row)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: columns('class'),
      headerName: 'Sister Class',
      minWidth: BIG_CELL_WIDTH,
      headerClassName: GridCustomHeaderClass.BorderRight,
    },
  ];

  const CIISection: GridColumnDef[] = [
    GridRagColumnValue({
      field: columns('calculations.cii.value'),
      headerName: 'CII \nRating',
      type: 'string',
      headerAlign: 'center',
      valueField: columns('calculations.cii.value'),
      statusField: columns('calculations.cii.status'),
    }),
    GridRagColumnDelta({
      field: columns('calculations.cii.delta'),
      headerName: 'CII \nΔ',
      minWidth: 100,
      valueField: columns('calculations.cii.delta'),
    }),
    GridRagColumnDeviation({
      field: columns('calculations.ciiPercentage.deviation'),
      headerName: 'CII \n%',
      headerClassName: GridCustomHeaderClass.BorderRight,
      valueField: columns('calculations.ciiPercentage.deviation'),
      statusField: columns('calculations.ciiPercentage.status'),
    }),
  ];

  const EEOISection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('calculations.eeoi.value'),
      headerName: 'EEOI \n(gCO₂/t-NM)',
      valueField: columns('calculations.eeoi.value'),
    }),

    GridRagColumnDelta({
      field: columns('calculations.eeoi.delta'),
      headerName: 'EEOI \nΔ',
      valueField: columns('calculations.eeoi.delta'),
    }),

    GridRagColumnDeviation({
      field: columns('calculations.eeoi.deviation'),
      headerName: 'EEOI \n%',
      headerClassName: GridCustomHeaderClass.BorderRight,
      valueField: columns('calculations.eeoi.deviation'),
      statusField: columns('calculations.eeoi.status'),
    }),
  ];

  const AERSection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('calculations.aer.value'),
      headerName: 'AER \n(gCO₂/DWT-NM)',
      valueField: columns('calculations.aer.value'),
    }),

    GridRagColumnDelta({
      field: columns('calculations.aer.delta'),
      headerName: 'AER \nΔ',
      valueField: columns('calculations.aer.delta'),
    }),
    GridRagColumnDeviation({
      field: columns('calculations.aer.deviation'),
      headerName: 'AER \n%',
      headerClassName: GridCustomHeaderClass.BorderRight,
      valueField: columns('calculations.aer.deviation'),
      statusField: columns('calculations.aer.status'),
    }),
  ];

  const otherSection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('fuelConsumed'),
      headerName: `Fuel \nConsumed (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      headerAlign: 'right',
      align: 'right',
      valueField: columns('fuelConsumed'),
    }),
    GridColumnValue({
      field: columns('co2Emissions'),
      headerName: `CO₂ \nEmissions (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      headerAlign: 'right',
      align: 'right',
      minWidth: SMALL_CELL_WIDTH,

      valueField: columns('co2Emissions'),
    }),
    GridColumnValue({
      field: columns('calculations.cii.required'),
      headerName: 'Required CII',
      headerAlign: 'right',
      align: 'right',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.cii.required'),
    }),
    GridColumnValue({
      field: columns('distance'),
      headerName: `Distance \n(${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`,
      headerAlign: 'right',
      align: 'right',
      valueField: columns('distance'),
    }),
  ];

  const ghgMrvSection: GridColumnDef[] =
    !ghgMrvFeatureFlagEnabled || !ghgMrvStoryFeatureFlagEnabled
      ? [
          GridColumnValue({
            field: columns('calculations.eua.co2'),
            headerName: `EU CO₂\n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_MRV})`,
            headerAlign: 'right',
            align: 'right',
            valueField: columns('calculations.eua.co2'),
          }),
        ]
      : [
          GridColumn({
            field: 'calculations.ghgMrv.totalCo2',
            headerName: `EU CO₂\n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_MRV})`,
            type: 'any',
            align: 'right',
            headerAlign: 'right',
            minWidth: SMALL_CELL_WIDTH,
            valueField: columns('calculations.ghgMrv.totalCo2'),
            valueFormatter: (params) => {
              return euaExcelDisplayValue(params, 2);
            },
          }),
          GridColumn({
            field: 'calculations.ghgMrv.totalN2o',
            headerName: 'EU N₂O \n(t,mrv)',
            type: 'any',
            align: 'right',
            headerAlign: 'right',
            minWidth: SMALL_CELL_WIDTH,
            valueField: columns('calculations.ghgMrv.totalN2o'),
            valueFormatter: (params) => {
              return euaExcelDisplayValue(params, 2);
            },
          }),
          GridColumn({
            field: 'calculations.ghgMrv.totalCh4',
            headerName: 'EU CH₄ \n(t,mrv)',
            type: 'any',
            align: 'right',
            headerAlign: 'right',
            minWidth: SMALL_CELL_WIDTH,
            valueField: columns('calculations.ghgMrv.totalCh4'),
            valueFormatter: (params) => {
              return euaExcelDisplayValue(params, 2);
            },
          }),
          GridColumn({
            field: columns('calculations.ghgMrv.fuelSlippageByYear'),
            headerName: 'EU Fuel Slip \n(t,mrv)',
            type: 'any',
            align: 'right',
            headerAlign: 'right',
            minWidth: SMALL_CELL_WIDTH,
            valueField: columns('calculations.ghgMrv.fuelSlippageByYear'),
            valueFormatter: (params) => ghgGetStandardKvpColumn(params),
          }),
          GridColumn({
            field: columns('calculations.ghgMrv.ghgByYear'),
            headerName: 'GHGmrv \n(tCO₂eq, TtW)',
            type: 'any',
            align: 'center',
            headerAlign: 'center',
            minWidth: SMALL_CELL_WIDTH,
            headerClassName: GridCustomHeaderClass.BorderRight,
            valueField: columns('calculations.ghgMrv.ghgByYear'),
            valueFormatter: (params) => ghgGetStandardKvpColumn(params),
            renderHeader() {
              const styleProperties: SxProps = {
                fontSize: 'inherit',
                textAlign: 'right',
              };
              return (
                <Box className='MuiDataGrid-columnHeaderTitle'>
                  <Typography key={1} sx={styleProperties}>
                    GHGmrv
                  </Typography>
                  <Typography key={2} sx={styleProperties}>
                    (tCO₂eq, TtW)
                  </Typography>
                </Box>
              );
            },
          }),
        ];

  const EUASection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('calculations.eua.delta'),
      headerName: 'EUA Δ',
      minWidth: SMALL_CELL_WIDTH,
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      valueField: columns('calculations.eua.delta'),
      renderHeader() {
        const styleProperties: SxProps = {
          fontSize: 'inherit',
          textAlign: 'right',
        };
        return (
          <Box className='MuiDataGrid-columnHeaderTitle'>
            <Typography key={1} sx={styleProperties}>
              EUA
            </Typography>
            <Typography key={2} sx={styleProperties}>
              Δ
            </Typography>
          </Box>
        );
      },
    }),
    GridColumnValue({
      field: columns('calculations.eua.liability'),
      headerName: 'EUA \nLiability',
      headerAlign: 'center',
      align: 'center',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 0,
      valueField: columns('calculations.eua.liability'),
      renderCell: (params: any) => (
        <Tooltip
          title={formatNumber(params.row.calculations['eua']?.liability, 2)}
        >
          <span className='MuiDataGrid-cellContent pointer'>
            {formatNumber(params.row.calculations['eua']?.liability)}
          </span>
        </Tooltip>
      ),
      renderHeader: euaLiabilitiesTooltipHeader,
      valueFormatter: (params) => euaExcelDisplayValue(params),
    }),
    GridRagColumnValue({
      field: columns('calculations.eua.liabilityPercentage'),
      headerName: 'EUA \nAgainst Planned (%)',
      valueField: columns('calculations.eua.liabilityPercentage'),
      statusField: columns('calculations.eua.status'),
      visibility: (dataRow: any) => {
        if (!dataRow) return false;
        const row = dataRow as IFleetVessel;
        const rawValue = row.calculations.euaAgainstPlanned.value;
        const value = isNumeric(rawValue) ? Number(rawValue) : 0;
        return value > 0;
      },
    }),
    GridRagColumnValue({
      field: columns('calculations.euaAgainstFleet.value'),
      headerName: 'EUA \nAgainst Fleet (%)',
      valueField: columns('calculations.euaAgainstFleet.value'),
      statusField: columns('calculations.euaAgainstFleet.status'),
    }),
    GridColumnValue({
      field: columns('calculations.euaAgainstPlanned.value'),
      headerName: 'Planned \nVessel EUA',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.euaAgainstPlanned.value'),
      noDecimals: 0,
    }),
  ];

  const FuelEUSection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('calculations.fuelEU.wellToTank'),
      headerName: 'GHG Intensity \n(gCO₂eq/MJ, \nWtT)',
      headerClassName: GridCustomHeaderClass.BorderLeft,
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.fuelEU.wellToTank'),
      noDecimals: 2,
      visibility: fleetOverviewGhgIntensityWttColumnVesselEnvironmentalGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumnValue({
      field: columns('calculations.fuelEU.tankToWake'),
      headerName: 'GHG Intensity \n(gCO₂eq/MJ, \nTtW)',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.fuelEU.tankToWake'),
      noDecimals: 2,
      visibility: fleetOverviewGhgIntensityTtwColumnVesselEnvironmentalGrid
        ? 'visible'
        : 'hidden',
    }),
    GridRagColumnValue({
      field: columns('calculations.fuelEU.wellToWake'),
      headerName: 'GHG Intensity \n(gCO₂eq/MJ, \nWtW)',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.fuelEU.wellToWake'),
      statusField: columns('calculations.fuelEU.wellToWakeStatus'),
      noDecimals: 2,
      visibility: fleetOverviewGhgIntensityWtwColumnVesselEnvironmentalGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumnValue({
      field: columns('calculations.fuelEU.complianceBalance'),
      headerName: 'Compliance \nBalance \n(gCO₂eq)',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.fuelEU.complianceBalance'),
      noDecimals: 2,
      visibility: fleetOverviewComplianceBalanceColumnVesselEnvironmentalGrid
        ? 'visible'
        : 'hidden',
    }),
    GridColumnValue({
      field: columns('calculations.fuelEU.fuelPenaltyAgainstFleetPercentage'),
      headerName: 'FuelEU Penalty \nAgainst \nFleet (%)',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns(
        'calculations.fuelEU.fuelPenaltyAgainstFleetPercentage'
      ),
      noDecimals: 0,
      visibility:
        fleetOverviewFuelEuPenaltyAgainstFleetColumnVesselEnvironmentalGrid
          ? 'visible'
          : 'hidden',
    }),
    GridColumnValue({
      field: columns('calculations.fuelEU.fuelPenalty'),
      headerName: 'FuelEU \nPenalty \n(€)',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.fuelEU.fuelPenalty'),
      renderCell: (params: any) => {
        const rawValue = params.row.calculations['fuelEU']?.fuelPenalty;
        const value = formatLargeNumber(rawValue);
        return <span className='MuiDataGrid-cellContent pointer'>{value}</span>;
      },
      noDecimals: 2,
      visibility: fleetOverviewFuelEuPenaltyColumnVesselEnvironmentalGrid
        ? 'visible'
        : 'hidden',
    }),
  ];

  // we could add logic here to include/exclude sections
  let result = [...commonSection];
  result = result.concat(CIISection);
  result = result.concat(EEOISection);
  result = result.concat(AERSection);
  result = result.concat(otherSection);
  result = result.concat(ghgMrvSection);
  result = result.concat(EUASection);
  result = result.concat(FuelEUSection);

  return result;
}

export const FleetOverviewTableGrid: React.FC<FleetOverviewTableProps> = ({
  dateRange,
}) => {
  const navigate = useNavigate();

  const { featureFlags } = useFeatureToggleContext(); // Access feature flags directly from the context

  const columns = getColumnsDefinition(navigate, featureFlags);

  const { imoNumbers } = useContext(NavBarContext);
  const { loading, error, data } = useGetVessels(dateRange, imoNumbers);
  const extractKeysForFiltering = ({
    calculations: {
      aer,
      cii,
      eeoi,
      eua,
      euaAgainstFleet,
      euaAgainstPlanned,
      fuelEU,
    },
  }: IFleetVessel) => ({
    aer,
    cii,
    eeoi,
    eua,
    euaAgainstFleet,
    euaAgainstPlanned,
    fuelEU,
  });
  const filterResult = useFilter<IFleetVessel>(
    data ?? [],
    extractKeysForFiltering
  );
  return (
    <CardContainer title={fleetPageTitles.vesselEnvironmentalKPIsComparison}>
      <RAGFilter
        activeFilters={filterResult.activeFilters}
        handleToggle={filterResult.handleFilter}
      ></RAGFilter>
      <DataGridTable
        name='EM-Fleet Breakdown by Vessels'
        rows={filterResult.filteredData}
        columns={columns}
        autoWidth={false}
        loading={loading}
        error={error}
      ></DataGridTable>
    </CardContainer>
  );
};
