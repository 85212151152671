import Color from '@arcgis/core/Color';
import LineSymbolMarker from '@arcgis/core/symbols/LineSymbolMarker';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';

enum RouteStyle {
  ActiveLaden = 1,
  ActiveBallast = 2,
  ForecastLaden = 10,
  ForecastBallast = 20,
}

const RouteLineSymbol = (type: RouteStyle) => {
  let lineColor = '#EFEFF0';
  let forecast = false;

  switch (type) {
    case RouteStyle.ActiveLaden: {
      lineColor = '#70E8C7';
      break;
    }
    case RouteStyle.ActiveBallast: {
      lineColor = '#8C85CF';
      break;
    }
    case RouteStyle.ForecastLaden: {
      lineColor = '#70E8C7';
      forecast = true;
      break;
    }
    case RouteStyle.ForecastBallast: {
      lineColor = '#8C85CF';
      forecast = true;
      break;
    }
  }

  return new SimpleLineSymbol({
    color: lineColor,
    width: 1,
    style: forecast ? 'dash' : 'solid',
  });
};

// line style used for the callout line from SearchWidget
const calloutLine = new SimpleLineSymbol({
  color: [150, 150, 150],
  width: 1,
  style: 'short-dot',
  marker: new LineSymbolMarker({
    color: new Color([0, 122, 194, 1]),
    placement: 'begin',
    style: 'arrow',
  }),
  miterLimit: 1,
});

export { RouteLineSymbol, calloutLine, RouteStyle };
