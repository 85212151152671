import { gql } from '@apollo/client';

const VesselReportFragment = gql`
  fragment reportData on Report {
    id
    isValid
    reportedDate
    reportType
    dateModified
    emissionDataFailed
    nonEmissionDataFailed
    reportId
    generatedReportId
  }
`;

export const GET_VESSEL_SUMMARY_SCORES = gql`
  ${VesselReportFragment}
  query GetVesselSummaryScores(
    $vesselIMO: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vessels(where: { imoNumber: { eq: $vesselIMO } }) {
      id
      name
      imoNumber
      reports(startDate: $startDate, endDate: $endDate) {
        id
        overallPassScorePercentage
        overallPassScore
        overallFailScore
        status
        vesselReports {
          ...reportData
        }
      }
    }
  }
`;
