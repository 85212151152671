import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import { getBasemap } from './BaseMapGallery';
import { BaseMapType } from './constants';
import TileInfo from '@arcgis/core/layers/support/TileInfo';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Color from '@arcgis/core/Color';

export const createMapView = (
  container: HTMLDivElement,
  mapviewOptions: any
) => {
  const webmap = new Map({
    basemap: getBasemap(mapviewOptions?.basemap || BaseMapType.dark),
  });

  return new MapView({
    container: container, // The id or node representing the DOM element containing the view.
    map: webmap, // An instance of a Map object to display in the view.
    zoom: 3,
    ui: {
      components: ['attribution'], // removes default widgets except for attribution
    },
    constraints: {
      minZoom: 3,
      rotationEnabled: false,
      lods: TileInfo.create({
        spatialReference: new SpatialReference({ wkid: 102100 }),
      }).lods,
    },
    popup: {
      dockEnabled: false,
      dockOptions: {
        buttonEnabled: false,
        breakpoint: false,
        position: 'top-left',
      },
    },
    navigation: {
      mouseWheelZoomEnabled: false,
      browserTouchPanEnabled: false,
    },
    center: [0, 20],
    highlightOptions: {
      color: new Color([255, 255, 255, 1]),
      haloColor: new Color('white'),
      haloOpacity: 0.3,
      fillOpacity: 0.4,
    },
  });
};
