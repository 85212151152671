import { createContext, useEffect, useState } from 'react';
import { fetchPorts } from 'shared/utils/portpolygons-util';

interface ILocationsContext {
  locationsOptions: string[];
}

export const LocationsContext = createContext<ILocationsContext>({
  locationsOptions: [],
});

export const LocationsContextWrapper = (props: {
  children: JSX.Element;
}): JSX.Element => {
  useEffect(() => {
    async function asyncFetchPorts() {
      try {
        const response = await fetchPorts();
        setLocationsOptions(response);
      } catch (err) {
        console.log(err);
      }
    }
    asyncFetchPorts();
  }, []);

  const [locationsOptions, setLocationsOptions] = useState<string[]>([]);

  return (
    <LocationsContext.Provider value={{ locationsOptions }}>
      {props.children}
    </LocationsContext.Provider>
  );
};
