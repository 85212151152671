import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';

export interface ModalCardButtonsProps {
  secondaryButtonText: string;
  primaryButtonText: string;
  onPrimaryButtonEvent(): void;
  onSecondaryButtonEvent(): void;
}

export const ModalCardButtons = (props: ModalCardButtonsProps) => {
  const {
    secondaryButtonText,
    primaryButtonText,
    onPrimaryButtonEvent,
    onSecondaryButtonEvent,
  } = props;

  return (
    <>
      <Box
        sx={{
          mb: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <SecondaryButton
          onClick={onSecondaryButtonEvent}
          variant='outlined'
          sx={{ mr: 1.5 }}
        >
          {secondaryButtonText}
        </SecondaryButton>

        <Button
          onClick={onPrimaryButtonEvent}
          variant='contained'
          sx={{
            fontSize: '16px',
            height: '40px',
            textTransform: 'capitalize',
            py: 1,
          }}
        >
          {primaryButtonText}
        </Button>
      </Box>
    </>
  );
};
