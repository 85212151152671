import { IAlert, IAlertComment } from '../../models/alert.model';
import { isAlertStatusClosed } from '../../services/alert-status';

export const setAlertIsArchived = (alert: IAlert): IAlert => {
  alert.isArchived = true;
  return alert;
};

export const setAlertIsUnarchived = (alert: IAlert): IAlert => {
  alert.isArchived = false;
  return alert;
};

export const setAlertStatus = (alert: IAlert, status: string): IAlert => {
  alert.status = status;
  if (status === 'archived') alert.isSelected = false;
  if (alert.isArchived) alert.isSelected = false;
  return alert;
};

export const setAlertIsRead = (alert: IAlert): IAlert => {
  alert.isRead = true;
  return alert;
};

export const setAlertIsUnread = (alert: IAlert): IAlert => {
  alert.isRead = false;
  return alert;
};

export const setNewAlertComment = (
  alert: IAlert,
  comment: IAlertComment
): IAlert => {
  const comments = alert.comments?.slice() ?? [];
  const newComments = [...comments, comment];
  alert.comments = newComments;
  return alert;
};

export const setEditAlertComment = (
  alert: IAlert,
  comment: IAlertComment
): IAlert => {
  const comments = alert.comments?.slice() ?? [];
  const newComments = comments.map((x) => {
    if (x.id === comment.id) {
      x.text = comment.text;
    }
    return x;
  });
  alert.comments = newComments;
  return alert;
};

export const deleteAlertComment = (
  alert: IAlert,
  comment: IAlertComment
): IAlert => {
  const comments = alert.comments?.slice() ?? [];
  const newComments = comments.filter((c) => c.id !== comment.id);
  alert.comments = newComments;
  return alert;
};

export const setAlertIsSelected = (alert: IAlert): IAlert => {
  alert.isSelected = !alert.isSelected;
  return alert;
};

export const showAlertCtas = (alerts: string[]): boolean => {
  return alerts.length > 0;
};

export const getFilteredAlertIds = (
  alerts: IAlert[],
  filter: string
): string[] => {
  return alerts
    .filter(
      (x) =>
        (x.isArchived && filter === 'archived') ||
        (filter === 'active' && x.status !== 'archived')
    )
    .map((x) => x.alertId);
};

export const isClosed = (id: string, alerts: IAlert[]): boolean => {
  const alert = alerts.find((x) => x.alertId === id);
  return alert ? isAlertStatusClosed(alert.status) : true;
};

export const isSelected = (id: string, alerts: string[]): boolean => {
  return alerts.includes(id);
};
