import { useActiveVoyagePositions } from '../repository/activeVoyagePositions';
import { ServiceResult } from 'shared/models/serviceResult.model';
import {
  IActiveVoyagePositions,
  IGeographicPoint,
} from '../utils/ActiveVoyageLayer';
import { RouteStyle } from '../utils/symbols';

export const useActiveVoyagePositionsService = (
  imoNumber: string
): ServiceResult<IActiveVoyagePositions[]> => {
  try {
    const activeVoyagePositionsResponse = useActiveVoyagePositions(imoNumber);

    const activeVoyagePositionsData =
      activeVoyagePositionsResponse.data?.vessels;

    const activeVoyagePositionsArray: IActiveVoyagePositions[] = [];
    if (
      activeVoyagePositionsData &&
      activeVoyagePositionsData[0]?.activeVoyagePositions
    ) {
      const positions: IGeographicPoint[] = [];
      activeVoyagePositionsData[0].activeVoyagePositions?.positions?.forEach(
        function (position) {
          if (position.latitude && position.longitude) {
            const point: IGeographicPoint = {
              Latitude: position.latitude,
              Longitude: position.longitude,
            };
            positions.push(point);
          }
        }
      );

      const activeVoyagePositions: IActiveVoyagePositions = {
        imoNumber: activeVoyagePositionsData[0].imoNumber ?? '',
        activeVoyagePositions: {
          Type: RouteStyle.ActiveBallast,
          positions: positions,
        },
      };
      activeVoyagePositionsArray.push(activeVoyagePositions);
    }

    return {
      data: activeVoyagePositionsArray,
      loading: Boolean(activeVoyagePositionsResponse?.loading || !imoNumber),
      error: activeVoyagePositionsResponse?.error ?? undefined,
      refetch: activeVoyagePositionsResponse?.refetch,
    };
  } catch (error) {
    console.log(error);

    return {
      loading: false,
      data: undefined,
      error: undefined,
      refetch: undefined,
    };
  }
};
