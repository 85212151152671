import React from 'react';
import { Section } from 'shared/components/section/section.component';
import { VesselDataComparisonTable } from './table/vessel-data-comparison-table';
import { VesselDataFilterProps } from './table/util/vessel-data-overview-filter';
import { Maybe, Report } from '_gql/graphql';
import { ApolloError } from '@apollo/client';
import { IFieldValidation } from 'routes/vessel-data-monitor/models/field-validation.model';
import { constants } from 'routes/vessel-data-monitor/routes/vesselDataSummary/vessel-data-summary.route';
import { ExportableSections } from 'shared/utils/valid-sections';
import { UTCDate } from 'shared/utils/date-utc-helper';
export interface vesselDataOverview {
  id: string;
  isValid: boolean;
  reportedDate: UTCDate;
  reportType: string;
  dateModified: UTCDate;
  emissionDataFailed: boolean;
  nonEmissionDataFailed: boolean;
}

export interface VesselDataOverviewProps {
  vesselDataOverviewFilter: VesselDataFilterProps;
  filteredData: Report[];
  loading: boolean;
  error: ApolloError | undefined;
}
export interface VesselTilesProps {
  title: string;
  tiles: (IFieldValidation | null)[] | undefined;
  rowId: Maybe<string> | undefined;
}

export const VesselDataOverview: React.FC<VesselDataOverviewProps> = ({
  vesselDataOverviewFilter,
  filteredData,
  error,
  loading,
}) => {
  return (
    <Section
      id={ExportableSections['VDM-Vessel Data Quality']}
      exportSection={true}
      preventUnmount={true}
      exportOptions={{
        includePDF: false,
        introductoryRows: { includeVesselName: true },
      }}
      title={constants.VDO_SECTION_TITLE}
      childComponent={
        <VesselDataComparisonTable
          vesselDataOverviewFilter={vesselDataOverviewFilter}
          filteredData={filteredData}
          error={error}
          loading={loading}
        />
      }
    />
  );
};
