const labelExpressionSelected = `var vn = $feature.VesselNames;
var labels = Split(vn, ',');

// Single vessel label
if (Count(labels) == 1) {
    var names = Split(vn, ' ');
    var noNames = Count(names);
    if (noNames > 2) {
        var newNames = Slice(names, 1, noNames);
        newNames[0] = names[0] + ' ' + newNames[0];
        return Concatenate(newNames, TextFormatting.NewLine);
    }
    return Concatenate(names, TextFormatting.NewLine);
}

var result = "";   
for(var k in labels) {
    result = result + labels[k] + TextFormatting.NewLine;    
}

return result + " ";`;

export default labelExpressionSelected;
