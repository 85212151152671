import { useMutation, useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { DateRange } from 'shared/utils/date-utc-helper';
import {
  CREATE_VESSEL_EVENT,
  DELETE_VESSEL_EVENT,
  GET_VESSEL_EVENT_DATA,
  UPDATE_VESSEL_EVENT,
} from '../queries/events.query';

export const useGetEventData = (
  vesselId: string | undefined,
  vesselImo: string | undefined,
  dateRange: DateRange | null
) => {
  return useQuery<Query>(GET_VESSEL_EVENT_DATA, {
    skip:
      !vesselId || !vesselImo || !dateRange || !dateRange?.getDateRangeStruct,
    variables: {
      vesselId,
      vesselImo,
      ...dateRange?.getDateRangeStruct,
    },
  });
};

export const useUpdateEventData = () => {
  return useMutation(UPDATE_VESSEL_EVENT);
};

export const useDeleteEventData = (
  vesselId: string | undefined,
  vesselImo: string | undefined,
  dateRange: DateRange | null
) => {
  return useMutation(DELETE_VESSEL_EVENT, {
    refetchQueries: [
      {
        variables: {
          vesselId,
          vesselImo,
          ...dateRange?.getDateRangeStruct,
        },
        query: GET_VESSEL_EVENT_DATA,
      },
    ],
  });
};

export const useCreateEventData = (
  vesselId: string | undefined,
  vesselImo: string | undefined,
  dateRange: DateRange | null
) => {
  return useMutation(CREATE_VESSEL_EVENT, {
    refetchQueries: [
      {
        variables: {
          vesselId,
          vesselImo,
          ...dateRange?.getDateRangeStruct,
        },
        query: GET_VESSEL_EVENT_DATA,
      },
    ],
  });
};
