import { Box } from '@mui/system';
import { useContext } from 'react';
import { DeleteModal } from 'shared/components/delete-modal/delete-modal.component';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { EventModal } from './events/event-modal.component';
import { IEvent } from './events/models/events.model';
import { useDeleteEvent } from './events/services/events.service';

export const VesselEventRowActionGroup = ({ event }: { event: IEvent }) => {
  const { utcDateRange } = useContext(NavBarContext);
  const { mutation: deleteEvent } = useDeleteEvent(
    event.vesselId,
    event.vesselImo,
    utcDateRange
  );

  const handleDeleteClick = async () => {
    try {
      await deleteEvent({
        variables: { vesselEventId: event.id },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <EventModal editEvent values={event} />
      <DeleteModal deleteHandler={handleDeleteClick} />
    </Box>
  );
};
