import React, { useContext, useState, MouseEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  ExportExcelFile,
  ExportToExcel,
} from 'shared/utils/export/excel/export-excel.utils';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { Button, Typography } from '@mui/material';
import { StyledExportButton } from './styled-export-button/styled-export-button';
import * as ExcelJs from 'exceljs';
import { SectionName } from 'shared/utils/valid-sections';
import { ExportOptions } from '../section/section.component';

export type ExportButtonProps = {
  sectionName: SectionName;
  exportOptions: ExportOptions | undefined;
  introductoryRowsData?: string[][];
};

const getExcelFileName = ({
  props,
  sectionName,
  dateRange,
  vesselGroup,
  vesselName,
}: {
  props: ExportButtonProps;
  sectionName: SectionName;
  dateRange: string[];
  vesselGroup: string[];
  vesselName: string[];
}) => {
  let filename = '';
  if (props.exportOptions?.filenameOptions?.includeVesselName && vesselName) {
    filename += `${vesselName}-`;
  }
  if (props.exportOptions?.filenameOptions?.includeSectionName && sectionName) {
    filename += `${sectionName}-`;
  }
  if (props.exportOptions?.filenameOptions?.includeVesselGroup && vesselGroup) {
    filename += `${vesselGroup}-`;
  }
  if (props.exportOptions?.filenameOptions?.includeDateRange && dateRange) {
    filename += dateRange;
  }

  // make sure filename is not empty
  if (filename.length === 0) filename = 'export';
  // remove trailing hyphen
  if (filename.endsWith('-')) {
    filename = filename.slice(0, -1);
  }
  return `${filename}.xlsx`;
};

export const ExportButton: React.FC<ExportButtonProps> = (
  props: ExportButtonProps
) => {
  const { exportOptions, sectionName } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    cardData: contextCardData,
    filteredData,
    additionalIntroductoryRowsData,
  } = useContext(ExcelExportContext);

  const { dateRange, vesselGroup, vesselName } =
    useContext(TopNavigationContext);

  let introductoryRowsData = props.introductoryRowsData ?? [[]];
  if (additionalIntroductoryRowsData) {
    const rows = additionalIntroductoryRowsData[sectionName];
    if (rows)
      introductoryRowsData = [...(props.introductoryRowsData ?? []), ...rows];
  }

  const cardData = props.exportOptions?.includeCardData ? contextCardData : [];

  const open = Boolean(anchorEl);
  let workbook = new ExcelJs.Workbook();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportExcel = () => {
    if (sectionName) {
      workbook = ExportToExcel({
        sectionName,
        cardData,
        filteredData,
        introductoryRowsData: introductoryRowsData ?? [],
      });

      const filename = getExcelFileName({
        props,
        sectionName,
        dateRange,
        vesselGroup,
        vesselName,
      });
      ExportExcelFile(workbook, filename);
      setAnchorEl(null);
    }
  };
  const handleExportPDF = () => {
    setAnchorEl(null);
  };

  const showPdfExport =
    typeof exportOptions === 'undefined' ||
    (typeof exportOptions !== 'undefined' &&
      typeof exportOptions.includePDF !== 'undefined' &&
      exportOptions.includePDF)
      ? true
      : false;

  return (
    <>
      <Button
        variant='contained'
        disableElevation
        onClick={handleClick}
        endIcon={<LaunchIcon />}
        disableRipple
        sx={{ textTransform: 'none' }}
      >
        Export
      </Button>
      <StyledExportButton anchorEl={anchorEl} open={open} onClose={handleClose}>
        {showPdfExport && (
          <MenuItem onClick={handleExportPDF} disableRipple>
            <PictureAsPdfOutlinedIcon />
            <Typography variant='menuItem'>Export to PDF</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleExportExcel} disableRipple>
          <DescriptionOutlinedIcon />
          <Typography variant='menuItem'>Export to Excel</Typography>
        </MenuItem>
      </StyledExportButton>
    </>
  );
};
