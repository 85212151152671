import { VesselReportTileMapper } from '../mappers/vessel-report-tile.mapper';
import { useGetVesselReportTile } from '../repositories/vessel-report-tile.get.repository';
import { IFieldValidation } from '../models/field-validation.model';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useVesselReportTile = (
  vesselIMO: string | undefined,
  reportId: string | undefined,
  dateRange: DateRange
) => {
  const getVesselReportTilesResponse = useGetVesselReportTile(
    vesselIMO,
    reportId,
    dateRange
  );

  const loading = getVesselReportTilesResponse?.loading;
  const error = getVesselReportTilesResponse?.error;

  const generalTileData =
    getVesselReportTilesResponse?.data?.vessels?.[0]?.reports
      ?.vesselReports?.[0]?.generalTileData;

  const speedPerformanceTileData =
    getVesselReportTilesResponse?.data?.vessels?.[0]?.reports
      ?.vesselReports?.[0]?.speedAndPerformanceTileData;

  const voyageInformationTileData =
    getVesselReportTilesResponse?.data?.vessels?.[0]?.reports
      ?.vesselReports?.[0]?.voyageTileData;

  const seaStateTileData =
    getVesselReportTilesResponse?.data?.vessels?.[0]?.reports
      ?.vesselReports?.[0]?.climateTilesData?.seaState;

  const weatherConditionTileData =
    getVesselReportTilesResponse?.data?.vessels?.[0]?.reports
      ?.vesselReports?.[0]?.climateTilesData?.weatherCondition;

  const fuelConsumptionTileData =
    getVesselReportTilesResponse?.data?.vessels?.[0]?.reports
      ?.vesselReports?.[0]?.consumables;

  const mappedGeneralTileData = generalTileData
    ?.map((item) => VesselReportTileMapper.fromDTO(item))
    .filter(filterUnvalidatedFields);

  const mappedSpeedPerformanceTileData = speedPerformanceTileData
    ?.map((item) => VesselReportTileMapper.fromDTO(item))
    .filter(filterUnvalidatedFields);
  const mappedVoyageInformationTileData = voyageInformationTileData
    ?.map((item) => VesselReportTileMapper.fromDTO(item))
    .filter(filterUnvalidatedFields);
  const mappedSeaStateTileData = seaStateTileData
    ?.map((item) => VesselReportTileMapper.fromDTO(item))
    .filter(filterUnvalidatedFields);
  const mappedWeatherConditionTileData = weatherConditionTileData
    ?.map((item) => VesselReportTileMapper.fromDTO(item))
    .filter(filterUnvalidatedFields);
  const mappedFuelConsumptionTileData = fuelConsumptionTileData
    ?.map((item) => VesselReportTileMapper.fromDTO(item))
    .filter(filterUnvalidatedFields);

  return {
    loading,
    error,
    generalTileData: mappedGeneralTileData,
    speedPerformanceData: mappedSpeedPerformanceTileData,
    voyageInformationData: mappedVoyageInformationTileData,
    seaStateTileData: mappedSeaStateTileData,
    weatherConditionTileData: mappedWeatherConditionTileData,
    fuelConsumptionTileData: mappedFuelConsumptionTileData,
  };
};

const filterUnvalidatedFields = (item: IFieldValidation | null): boolean =>
  item ? !item.notValidatedForReport : false;
