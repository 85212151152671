import { createContext, useContext, ReactNode, useMemo } from 'react';
import { useFeatureFlagsData } from '../../repositories/featureFlag.repository';
import { FEATURE_FLAG } from '../../constants/feature-flag';
import { IFeatureFlagDomain } from '../../models/featureFlag.model';

interface FeatureToggleContextType {
  featureFlags: { [key: string]: boolean };
  loading: boolean;
  error: any;
}

// Create the context
const FeatureToggleContext = createContext<
  FeatureToggleContextType | undefined
>(undefined);

export const FeatureToggleProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // Use the hook to get the feature flags data
  const {
    data: featureFlagsData,
    loading,
    error,
  } = useFeatureFlagsData(Object.values(FEATURE_FLAG)) || {};

  // Memoize the feature flags processing
  const featureFlags = useMemo(() => {
    return (
      featureFlagsData?.featureFlags?.reduce(
        (acc: { [key: string]: boolean }, flag: IFeatureFlagDomain) => {
          acc[flag.name] = flag.isEnabled;
          return acc;
        },
        {} as { [key: string]: boolean }
      ) || {}
    );
  }, [featureFlagsData]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      featureFlags,
      loading: loading ?? false,
      error,
    }),
    [featureFlags, loading, error]
  );

  return (
    <FeatureToggleContext.Provider value={contextValue}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

// Custom hook to access the feature toggle context
export const useFeatureToggleContext = () => {
  const context = useContext(FeatureToggleContext);
  if (context === undefined) {
    throw new Error(
      'useFeatureToggleContext must be used within a FeatureToggleProvider'
    );
  }
  return context;
};
