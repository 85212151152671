import { Box, StandardTextFieldProps, SxProps, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputStyles } from './input-styles';
import { theme } from 'styles/theme';
import { MDFFormHelperText } from './form-helper-text.component';

interface FormTextFieldProps extends StandardTextFieldProps {
  name: string;
  label: string;
}

// TextField wrapper built for Event Modal
export const FormTextField = (props: FormTextFieldProps) => {
  const { control } = useFormContext();
  const { label, name, multiline = false, required = false, sx } = props;

  const getStyles = (error: boolean): SxProps => ({
    ...InputStyles,
    ...{
      border: `1px solid ${error ? theme.palette.error.main : 'transparent'}`,
    },
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <Box sx={{ width: '100%', ...sx }}>
            <TextField
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              required={required}
              sx={getStyles(!!error)}
              variant='standard'
              multiline={multiline}
              fullWidth={true}
              error={!!error}
              label={label}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
            />
            {error && (
              <MDFFormHelperText
                sx={{ mt: '2px' }}
                errorMessage={error.message}
              />
            )}
          </Box>
        );
      }}
    />
  );
};
