import { useContext } from 'react';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { useGetAllVesselGroupings } from 'shared/components/vesselGroupingsDropdown/services/vesselGroupings.get.services';

export const useGlobalVesselGroupParameters = () => {
  const { vesselGroups } = useContext(TopNavigationContext);
  const getVesselGroupings = useGetAllVesselGroupings();

  if (vesselGroups.length === 0) {
    if (!getVesselGroupings?.data) {
      return undefined;
    } else {
      getVesselGroupings?.data.forEach((c) =>
        c.groupings.forEach((g) => (g.isChecked = true))
      );

      return getVesselGroupings?.data;
    }
  }
  return vesselGroups;
};
