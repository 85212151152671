import { useCallback } from 'react';
import * as yup from 'yup';
import { CiiProjectionsFormModel } from '../models/forms/cii-projections.form.model';

export const ciiProjectionsSchema = yup
  .object<CiiProjectionsFormModel>()
  .shape({
    avgBallastSpeed: yup
      .string()
      .matches(
        /^(\d*\.)?\d+$/,
        'Avg. Ballast Speed (kn) must be a positive number'
      )
      .required('Avg. Ballast Speed (kn) is required'),
    avgLadenSpeed: yup
      .string()
      .matches(
        /^(\d*\.)?\d+$/,
        'Avg. Laden Speed (kn) must be a positive number'
      )
      .required('Avg. Laden Speed (kn) is required'),
    avgBallastFoc: yup
      .string()
      .matches(
        /^(\d*\.)?\d+$/,
        'Avg. Ballast FOC (t/day) must be a positive number'
      )
      .required('Avg. Ballast FOC (t/day) is required'),
    avgLadenFoc: yup
      .string()
      .matches(
        /^(\d*\.)?\d+$/,
        'Avg. Laden FOC (t/day) must be a positive number'
      )
      .required('Avg. Laden FOC (t/day) is required'),
    idleTime: yup
      .string()
      .matches(/^(\d*\.)?\d+$/, 'Idle Time (%) must be a positive number')
      .required('Idle Time (%) is required'),
  });

export const useYupValidationResolver = (
  validationSchema: CiiProjectionsSchemaType
) =>
  useCallback(
    (data: CiiProjectionsFormModel) => {
      try {
        const values = validationSchema.validateSync(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

export type CiiProjectionsSchemaType = typeof ciiProjectionsSchema;
