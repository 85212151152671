import { FuelTypeInfo } from '_gql/graphql';
import { IFuelType } from '../models/fuelType.model';
export class FuelTypesMapper {
  static toDomain(data: Array<FuelTypeInfo>) {
    return toFuelType(data);
  }
}

export const toFuelType = (data: Array<FuelTypeInfo>) => {
  return data?.map(
    (fuelType: FuelTypeInfo) =>
      ({
        name: fuelType.name,
        abbreviation: fuelType.abbreviation,
        enumValue: fuelType.enumValue,
        classificationName: fuelType.classificationName,
      } as IFuelType)
  );
};
