import { DateRange } from 'shared/utils/date-utc-helper';
import { useGetReportIssues } from '../repositories/download-report.get.repository';

export const useReportIssues = (
  vesselImo: string | undefined,
  dateRange: DateRange | null | undefined
) => {
  const getReportIssues = useGetReportIssues(vesselImo, dateRange);
  const { loading, error, data } = getReportIssues;

  const criticalReports = data?.vessels?.[0]?.reports;

  return { loading, error, criticalReports };
};
