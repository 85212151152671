import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { DataGridTable } from 'shared/components/datagrid';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';

import { PlannedItinerarySequence } from './modal/sequence.component';
import { Box } from '@mui/material';
import { EnvironmentalKPISummary } from './modal/KPI-Summary.component';

import { useContext, useEffect, useState } from 'react';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import { getColumnsDefinition } from './plannedItinerary.config';
import { SequencesModal } from './modal/modal.component';
import { ServiceResult } from 'shared/models/serviceResult.model';
import {
  IPlannedItinerary,
  IPlannedItineraryResults,
} from '../../models/plannedItinerary.model';
import { useGetVoyageComparisonLastPortData } from 'routes/environmental-monitor/services/vessel.service';
import { useParams } from 'react-router-dom';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { PortData } from 'shared/utils/portpolygons-util';
import { useCalculatePlannedItinerary } from 'routes/environmental-monitor/services/plannedItinerary.service';
import { formatPlannedItineraryData } from './utils/format-plannedItinerary-data.util';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';

class ItineraryDataLocalStorage {
  private readonly VESSEL_PLANNED_ITINERARY_KEY: string;
  constructor(id: string | undefined) {
    this.VESSEL_PLANNED_ITINERARY_KEY = `PLANNED_ITINERARY-${id}`;
  }

  get getValue() {
    return localStorage.getItem(this.VESSEL_PLANNED_ITINERARY_KEY);
  }

  set value(data: IPlannedItineraryResults[]) {
    localStorage.setItem(
      this.VESSEL_PLANNED_ITINERARY_KEY,
      JSON.stringify(data)
    );
  }
}

export const ItineraryComparison = ({
  itineraries,
}: {
  readonly itineraries?: IPlannedItineraryResults[];
}) => {
  const { utcDateRange } = useContext(NavBarContext);
  const { id } = useParams();

  const itineraryLocalStorage = new ItineraryDataLocalStorage(id);
  const getItineraryDataLocalStorage = itineraryLocalStorage.getValue;
  const setItineraryDataLocalStorage = (data: IPlannedItineraryResults[]) =>
    (itineraryLocalStorage.value = data);

  // Access feature flags directly from the context
  const { featureFlags } = useFeatureToggleContext();

  const itineraryData = (
    getItineraryDataLocalStorage
      ? JSON.parse(getItineraryDataLocalStorage)
      : itineraries ?? []
  ) as IPlannedItineraryResults[];

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [itinerary, setItinerary] = useState<IPlannedItinerary>(); // used to display the sequence data in the modal

  // used to display the data in the grid
  const [gridData, setGridData] = useState<IPlannedItineraryResults[]>(
    itineraryData ?? []
  );

  // used to store the results of the calculations based on the sequences
  const [results, setResults] =
    useState<ServiceResult<IPlannedItineraryResults>>();

  // Update the grid data and save the data to local storage
  const updateItineraryResult = (results: IPlannedItineraryResults[]) => {
    setGridData(results);
    setItineraryDataLocalStorage(results);
  };

  // Calculate the KPIs based on the sequences
  const calculations = useCalculatePlannedItinerary(
    id,
    utcDateRange,
    itinerary
  );

  useEffect(() => {
    if (calculations.loading === false) {
      setResults(calculations);
    }
  }, [calculations.loading, itinerary]);

  // Get the last port of call for the vessel
  const { data } = useGetVoyageComparisonLastPortData(id, utcDateRange);
  const lastPortofCall: PortData = {
    Port_Name: data?.portName ?? '',
    UNLOCODE: data?.portCode ?? '',
  };

  const handleClose = () => setModalVisible(false);

  // Open the modal to display the sequences
  // when the user clicks on the vessel name from the grid
  const handleNameClick = (row: IPlannedItineraryResults) => {
    const Itinerary = {
      ...row.plannedItinerary,
      id: row.id,
    } as IPlannedItinerary;
    setResults(undefined);
    setItinerary(Itinerary);
    setModalVisible(true);
  };

  // Add new sequence to the planned itinerary
  // opens the modal to add new sequences
  const addNewSequence = () => {
    const emptyItinerary = {
      lastPortOfCall: lastPortofCall,
    } as IPlannedItinerary;
    setItinerary(emptyItinerary);
    setModalVisible(true);
  };

  // Delete the record from the grid
  const handleDeleteClick = (Itinerary: IPlannedItineraryResults) => {
    const newData = gridData.filter((row) => row.id !== Itinerary.id);
    updateItineraryResult(newData);
  };

  // Calculate the KPIs and record for grid based on the sequences
  const handleCalculate = (itinerary: IPlannedItinerary) => {
    // if there are no sequences, then we want to reset the KPIs
    if (itinerary.sequences.length === 0) {
      setResults(undefined);
      return;
    }
    setItinerary(itinerary);
  };

  // Add new comparison/record to the grid
  // results is the data from the calculations
  // calculations happen when the user clicks on the calculate button
  const handleNewComparison = () => {
    const recordResult = results?.data;
    if (recordResult?.plannedItinerary) {
      const id = gridData.length + 1;
      recordResult.id = id;
      recordResult.plannedItinerary.id = id;
      updateItineraryResult([...gridData, recordResult]);
    }
  };

  // Update the record to the grid and save the data from the modal
  // results is the data from the calculations
  const handleSave = (itinerary: IPlannedItinerary) => {
    const recordIndex = gridData.findIndex((c) => c.id === itinerary.id); // this is used to find the record to update
    const recordResults = results?.data;
    if (recordIndex >= 0 && recordResults) {
      gridData[recordIndex] = recordResults;
      updateItineraryResult([...gridData]);
    }
  };

  const extractKeysForFiltering = ({
    cii,
    eeoiDeviation,
    eua,
  }: IPlannedItineraryResults) => ({ cii, eeoiDeviation, eua });

  const filterResult = useFilter<IPlannedItineraryResults>(
    gridData ?? itineraries,
    extractKeysForFiltering
  );

  return (
    <>
      <CardContainer title=''>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <RAGFilter
            activeFilters={filterResult.activeFilters}
            handleToggle={filterResult.handleFilter}
          ></RAGFilter>
          <SecondaryButton
            variant='outlined'
            sx={{ mr: 5 }}
            onClick={addNewSequence}
          >
            {'+ Add New'}
          </SecondaryButton>
        </Box>

        <DataGridTable
          name={'SequenceComparison'}
          rows={formatPlannedItineraryData(filterResult.filteredData) ?? []}
          columns={getColumnsDefinition(
            featureFlags,
            handleNameClick,
            handleDeleteClick
          )}
        ></DataGridTable>
      </CardContainer>

      <SequencesModal open={modalVisible}>
        {/* The planned itinerary sequences for the vessel */}
        <PlannedItinerarySequence
          onCalculate={handleCalculate}
          onCompare={handleNewComparison}
          onSave={handleSave}
          onClose={handleClose}
          compareData={gridData}
          itineraryData={itinerary}
          isLoading={calculations.loading}
        />
        {/* The KPI summary section */}
        <EnvironmentalKPISummary plannedItineraryResults={results} />
      </SequencesModal>
    </>
  );
};
