import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { ColorBar } from './cii-ratings-color-bar.component';
import { theme } from 'styles/theme';
import { CiiBoundaries } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import { generateBoundaryChartData } from '../../../view-models/cii-ratings-chart.viewModel';

export const RightColorBar = (props: {
  ciiBoundaries: CiiBoundaries | undefined;
  dataMin: number;
  dataMax: number;
  chartMax: number;
  dataTogglesDisplayCount: number;
}) => {
  const ciiBoundaries = props.ciiBoundaries;

  if (!ciiBoundaries) {
    return null;
  }

  const ciiBoundariesChartData = generateBoundaryChartData(
    ciiBoundaries,
    props.dataMax,
    props.chartMax
  );

  return (
    <ResponsiveContainer width='99%' height={415}>
      <BarChart
        data={ciiBoundariesChartData}
        margin={{
          top: 19.5,
          bottom: 60,
          left: 0,
          right: 25 + 60 * props.dataTogglesDisplayCount,
        }}
      >
        <XAxis
          dataKey='positionRight'
          textAnchor='end'
          domain={[0, 1]}
          type='number'
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='maxValue'
          tickLine={false}
          tick={false}
          tickCount={8}
          tickMargin={0}
          type='number'
          domain={[props.dataMin, props.chartMax]}
          axisLine={false}
          dx={-12}
          width={75}
        ></YAxis>
        <Bar
          dataKey='aValue'
          stackId='a'
          fill={theme.colors?.aRating}
          shape={<ColorBar dataKey='aValue' />}
        >
          <LabelList
            fontSize={12}
            dataKey='aKey'
            position='right'
            offset={15}
            fill={theme.palette.background.default}
          ></LabelList>
        </Bar>
        <Bar
          dataKey='bValue'
          stackId='a'
          fill={theme.colors?.bRating}
          shape={<ColorBar dataKey='bValue' />}
        >
          <LabelList
            fontSize={12}
            dataKey='bKey'
            position='right'
            offset={15}
            fill={theme.palette.text.primary}
          ></LabelList>
        </Bar>
        <Bar
          dataKey='cValue'
          stackId='a'
          fill={theme.colors?.cRating}
          shape={<ColorBar dataKey='cValue' />}
        >
          <LabelList
            fontSize={12}
            dataKey='cKey'
            position='right'
            offset={15}
            fill={theme.palette.text.primary}
          ></LabelList>
        </Bar>
        <Bar
          dataKey='dValue'
          stackId='a'
          fill={theme.colors?.dRating}
          shape={<ColorBar dataKey='dValue' />}
        >
          <LabelList
            fontSize={12}
            dataKey='dKey'
            position='right'
            offset={15}
            fill={theme.palette.text.primary}
          ></LabelList>
        </Bar>
        <Bar
          dataKey='eValue'
          stackId='a'
          fill={theme.colors?.eRating}
          shape={<ColorBar dataKey='eValue' />}
        >
          <LabelList
            fontSize={12}
            dataKey='eKey'
            position='right'
            offset={15}
            fill={theme.palette.text.primary}
          ></LabelList>
        </Bar>
        <Bar
          dataKey='topBarValue'
          stackId='a'
          fill={theme.colors.white}
          shape={<ColorBar dataKey='topBarValue' />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
