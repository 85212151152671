import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { ResponsiveChartContainerProps } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { AreaPlot, LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import {
  ChartsAxisHighlight,
  ChartsGrid,
  ChartsXAxis,
  ChartsYAxis,
  ScatterPlot,
  ChartsXAxisProps,
  ScaleName,
  AxisConfig,
  ChartsYAxisProps,
  ChartsItemContentProps,
  ChartsTooltipSlots,
  BarSeriesType,
  LineSeriesType,
  ScatterSeriesType,
  LineHighlightPlot,
  ChartsAxisContentProps,
} from '@mui/x-charts-pro';

import { AxisValueFormatterContext } from '@mui/x-charts/models/axis';
import { MakeOptional } from '_gql/graphql';
import {
  AreaBand,
  AXIS_BANDS_ID,
  BandData,
  DrawBackground,
} from './background/draw-background.component';
import { typography } from 'styles/typography';
import { TriggerOptions } from '@mui/x-charts/ChartsTooltip/utils'; // Add this line to import TriggerOptions
import {
  StyledChartContainer,
  StyledChartsTooltip,
} from './styles/ThemeProperties';
import MuiChartContainerWithNoAxis from './components/pie-chart.component';
import { Typography } from '@mui/material';
import { CustomAnimatedLine } from './components/line-forecast.component';
import { ChartKey, ChartKeyItem } from '../chart-key/chart-key.component';
import { ChartLegend } from 'routes/environmental-monitor/components/vessel-detail/eua/eua-ratings-mui-chart.component';
import { LoadingChartComponent } from './components/loading.component';
import { ApolloError } from '@apollo/client';
import { ErrorComponent } from '../error/error.component';

type AxisXConfig = AxisConfig<ScaleName, any, ChartsXAxisProps>;
type AxisYConfig = AxisConfig<ScaleName, any, ChartsYAxisProps>;
type AxisXConfigNoId = MakeOptional<AxisXConfig, 'id'>;
type AxisYConfigNoId = MakeOptional<AxisYConfig, 'id'>;

export type tooltipContentProps =
  | ChartsItemContentProps<any>
  | ChartsAxisContentProps;
type tooltipContent =
  | string
  | React.ReactNode
  | ((params: tooltipContentProps) => React.ReactNode);

type extraChartContainerProps = {
  background?: {
    bands?: (BandData | AreaBand)[];
  };
  children?: React.ReactNode | React.ReactNode[];
  tooltip?: {
    title?: tooltipContent;
    content?: tooltipContent;
    trigger?: TriggerOptions;
  };
  sectionVisibility?: {
    axisX?: boolean;
    axisY?: boolean;
    grid?: boolean;
    legend?: boolean;
    highlight?: boolean;
    markers?: boolean;
  };
  yAxisProps?: {
    /**
     * The space between the axis label and the chart.
     */
    labelPosition?: number;

    /**
     * The space between the axis tick labels and the chart.
     */
    tickLabelPosition?: number;
  };
};

export type ChartContainer = ResponsiveChartContainerProps &
  extraChartContainerProps;

// get the index of the data
export const getTooltipDataIndex = (
  props: tooltipContentProps
): number | undefined => {
  if ('axisValue' in props) {
    return props.dataIndex ?? undefined;
  }

  return props.itemData.dataIndex ?? undefined;
};

export const getTooltipContentValue = (
  tooltip: tooltipContent,
  props: tooltipContentProps
) => {
  if (typeof tooltip === 'string') {
    return tooltip;
  }

  if (typeof tooltip === 'function') {
    return tooltip(props);
  }
  return null;
};

const CustomAxisTooltipContent = (
  props: ChartsAxisContentProps,
  container?: Readonly<ChartContainer>
) => {
  const tickValue = props.axisValue;
  const axisX = container?.xAxis?.at(0);
  const axisFormatter = axisX?.valueFormatter;

  const title = container?.tooltip?.title;
  const titleValue =
    getTooltipContentValue(title, props) ??
    axisFormatter?.(tickValue, { location: 'tick' }).toString();

  const content = container?.tooltip?.content;
  const contentValue = getTooltipContentValue(content, props);

  return (
    <Paper
      elevation={3}
      sx={{ backgroundColor: 'white', color: 'black', mx: 2 }}
    >
      <Typography
        fontWeight={'bold'}
        component={'header'}
        sx={{ py: 1, px: 3, borderBottom: '1px solid gray' }}
      >
        {titleValue}
      </Typography>
      <Box component={'section'} sx={{ px: 3, py: 1 }}>
        {contentValue}
      </Box>
    </Paper>
  );
};

const CustomItemTooltipContent = (
  props: ChartsItemContentProps<any>, // properties passed to the tooltip
  container?: Readonly<ChartContainer>
) => {
  const { itemData, series } = props;
  const dataset = container?.dataset;
  const axisX = container?.xAxis?.at(0);
  const axisFormatter = axisX?.valueFormatter;

  // if the dataset or the itemData is not available, return null
  if (
    (dataset === undefined || itemData.dataIndex === undefined) &&
    axisX?.data === undefined
  )
    return null;

  const axisData =
    axisX?.data ?? dataset?.map((d) => d[axisX?.dataKey ?? '']) ?? [];
  const tickValue = axisData[itemData.dataIndex];

  if (tickValue === undefined) return null;

  const title = container?.tooltip?.title;
  const titleValue =
    getTooltipContentValue(title, props) ??
    axisFormatter?.(tickValue, { location: 'tick' }).toString();

  const content = container?.tooltip?.content;
  const contentValue =
    getTooltipContentValue(content, props) ?? series.data[itemData.dataIndex];

  return (
    <Paper
      elevation={3}
      sx={{ backgroundColor: 'white', color: 'black', mx: 2 }}
    >
      <Typography
        fontWeight={'bold'}
        component={'header'}
        sx={{ py: 1, px: 3, borderBottom: '1px solid gray' }}
      >
        {titleValue}
      </Typography>
      <Box component={'section'} sx={{ px: 3, py: 1 }}>
        {contentValue}
      </Box>
    </Paper>
  );
};

const getMinMaxNumeric = (value: number | Date | undefined) => {
  if (!value) return undefined;
  return typeof value !== 'number' ? new Date(value).getTime() : value;
};

const getXAxisArray = (props: ChartContainer, offset: number) => {
  const dataset = props.dataset;
  const xAxisArray =
    props.xAxis?.map((x, ix) => {
      const axisData = x.data ?? dataset?.map((d) => d[x?.dataKey ?? '']) ?? [];
      const axisMin = getMinMaxNumeric(x.min) ?? Math.min(...axisData);
      const axisMax = getMinMaxNumeric(x.max) ?? Math.max(...axisData);

      const minX = axisMin + offset;
      const maxX = axisMax - offset;
      const angle = x.tickLabelStyle?.angle ?? 0;

      const maxAxisLabelLength = getAxisLabelsMaxLength(x, axisData);
      const tickLabelsY =
        15 +
        maxAxisLabelLength * 2 * Math.abs(Math.sin((Math.PI * angle) / 180));

      const axis: AxisXConfig = {
        ...x,
        id: x.id ?? `x-axis-${ix}`,
        min: minX,
        max: maxX,
        tickLabelStyle: {
          ...x.tickLabelStyle,
          translate: `0 ${tickLabelsY}px`,
          fontSize: typography.fontSize['1'],
        },
      };
      return axis;
    }) ?? [];

  return xAxisArray;
};

const getYAxisArray = (props: ChartContainer) => {
  const labelXpositionValue = props.yAxisProps?.labelPosition ?? 20;
  const tickLabelPositionValue = props.yAxisProps?.tickLabelPosition ?? 5;

  return (
    props.yAxis?.map((y, ix) => {
      const labelPosition =
        y.position === 'right' ? labelXpositionValue : -labelXpositionValue;
      const tickLabelPosition =
        y.position === 'right'
          ? tickLabelPositionValue
          : -tickLabelPositionValue;
      const tickLabelPositionStr = `${tickLabelPosition}px 0`;

      const axis: AxisConfig<ScaleName, any, ChartsYAxisProps> = {
        ...y,
        id: y.id ?? `y-axis-${ix}`,
        labelStyle: {
          fontSize: typography.fontSize['1'],
          translate: `${labelPosition}px`,
        },
        tickLabelStyle: {
          ...y?.tickLabelStyle,
          translate: tickLabelPositionStr,
          transformBox: 'fill-box',
          transformOrigin: 'center',
        },
      };

      return axis;
    }) ?? []
  );
};

// calculate the max length of the Axis values
const getAxisLabelsMaxLength = (
  axis: AxisXConfigNoId | AxisYConfigNoId | undefined,
  data?: any[]
) => {
  if (!data) return 0;
  const context: AxisValueFormatterContext = { location: 'tick' };
  const tickLabels =
    data?.map((d) => axis?.valueFormatter?.(d, context) ?? d) ?? [];
  const tickLabelsLength = tickLabels.map((d) => d?.length ?? 0);
  const maxLabelLength = Math.max(...tickLabelsLength);
  return maxLabelLength;
};

// get the tooltip behavior
// with the tooltip prop undefined it will show the default Mui Chart Tooltip
const getTooltipSlots = (props: ChartContainer): ChartsTooltipSlots<any> => {
  if (props.tooltip === undefined) return {};

  // check if there is any custom tooltip option
  if (props.tooltip.content === undefined && props.tooltip?.title === undefined)
    return {};

  return {
    itemContent: (x: ChartsItemContentProps<any>) =>
      CustomItemTooltipContent(x, props),
    axisContent: (x: ChartsAxisContentProps) =>
      CustomAxisTooltipContent(x, props),
  };
};

export function MuiChartContainerWithLegend(
  props: Readonly<
    ChartContainer & {
      loading?: boolean;
      error?: ApolloError | undefined;
      additionalLegend?: Array<ChartLegend>;
    }
  >
) {
  const { additionalLegend, ...chartProps } = props;
  const { series } = chartProps;

  // pie and bars charts does not have a external legend
  const chartSeries = series.filter(
    (x) => x.type !== 'pie' && x.type !== 'bar'
  ) as (BarSeriesType | LineSeriesType | ScatterSeriesType)[];

  const keyProps: ChartKeyItem[] = chartSeries
    .filter((x) => x.label !== undefined)
    .map((item, ix) => {
      const label =
        typeof item.label === 'function'
          ? item.label('legend')
          : item.label ?? '';

      const result: ChartKeyItem = {
        id: ix,
        name: label,
        fill: item.color ?? '',
      };
      return result;
    });

  if (additionalLegend && additionalLegend.length > 0) {
    Array.from(additionalLegend, (legend) => {
      keyProps.push({
        id: keyProps.length,
        name: legend.label,
        fill: legend.color,
      });
    });
  }
  keyProps.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Box sx={{ mt: 3, pl: 1 }}>
        <ChartKey items={keyProps} />
      </Box>
      <MuiChartContainer {...chartProps} />
    </>
  );
}

export function MuiChartContainer(
  props: Readonly<
    ChartContainer & { loading?: boolean; error?: ApolloError | undefined }
  >
) {
  const height = props.height ?? 400;
  const { loading, error, ...chartProps } = props;

  if (loading) return <LoadingChartComponent height={height} />;
  if (error) return <ErrorComponent error={error} empty={false} />;

  const firstSeries = chartProps.series?.at(0);
  if (firstSeries?.type === 'pie') {
    return <MuiChartContainerWithNoAxis {...chartProps} />;
  }
  return <MuiChartContainerDefault {...chartProps} />;
}

export function MuiChartContainerDefault(props: Readonly<ChartContainer>) {
  const [offsetX, setOffsetX] = React.useState<number>(0);

  const {
    background,
    children,
    tooltip,
    sectionVisibility,
    yAxisProps,
    ...chartProps
  } = props;

  const dataset = props.dataset;
  const firstxAxis = props.xAxis?.at(0);
  const firstxAxisData =
    firstxAxis?.data ?? dataset?.map((d) => d[firstxAxis?.dataKey ?? '']);

  const firstSeries = props.series?.at(0);
  const firstSeriesData = firstSeries?.data;

  // if there is no data, return null
  if (!(dataset?.length || firstxAxisData?.length || firstSeriesData?.length)) {
    return <ErrorComponent error={undefined} empty={true} />;
  }

  const bands = background?.bands;
  // bands needs to be added to the X axis with the scaleType set to linear
  if (bands && props.xAxis?.findIndex((e) => e.id === AXIS_BANDS_ID) === -1) {
    props.xAxis?.push({
      data: firstxAxisData ?? [],
      id: AXIS_BANDS_ID,
      scaleType: 'linear',
    });
  }

  const xAxisArray = getXAxisArray(props, offsetX);
  const yAxisArray = getYAxisArray(props);

  const firstxAxisId = firstxAxis?.id ?? 'x-axis-0';
  const maxLabelLength = getAxisLabelsMaxLength(firstxAxis, firstxAxisData);

  const angle = firstxAxis?.tickLabelStyle?.angle ?? 0;
  const chartBottomMargin = Math.round(
    60 + maxLabelLength * 4 * Math.abs(Math.sin((Math.PI * angle) / 180))
  );

  const tooltipSlots = getTooltipSlots(props);
  const chartMargins = props.margin;
  const margins = {
    left: chartMargins?.left ?? 80,
    right: chartMargins?.right ?? 30,
    bottom: chartMargins?.bottom ?? chartBottomMargin,
    top: chartMargins?.top ?? 10,
  };

  const showAxisX = sectionVisibility?.axisX !== false;
  const showAxisY = sectionVisibility?.axisY !== false;
  const showGrid = sectionVisibility?.grid !== false;
  const showHighlight = sectionVisibility?.highlight !== false;
  const showMarkers = sectionVisibility?.markers !== false;
  const tooltipTrigger = tooltip?.trigger ?? 'item';

  function updateAxisXOffset(x: number): void {
    setOffsetX(x);
  }

  return (
    <StyledChartContainer
      {...chartProps}
      height={props.height ?? 400}
      margin={margins}
      yAxis={yAxisArray}
      xAxis={xAxisArray}
      data-testid='styled-chart-container'
    >
      {bands && <DrawBackground bands={bands} callback={updateAxisXOffset} />}
      {showGrid && <ChartsGrid horizontal />}
      {showHighlight && <ChartsAxisHighlight x='line' />}
      {children}

      <AreaPlot />
      <BarPlot />
      <LinePlot slots={{ line: CustomAnimatedLine }} />
      <LineHighlightPlot />
      <ScatterPlot />
      {showMarkers && <MarkPlot />}
      {showAxisX && <ChartsXAxis axisId={firstxAxisId} />}
      {showAxisY && (
        <>
          {yAxisArray.map((axis) => (
            <ChartsYAxis key={axis.id} axisId={axis.id} disableTicks />
          ))}
        </>
      )}

      <StyledChartsTooltip trigger={tooltipTrigger} slots={tooltipSlots} />
    </StyledChartContainer>
  );
}
