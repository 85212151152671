import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import {
  formatNumberForDisplay,
  showDashesIfEmpty,
} from 'shared/utils/display-utils';
import { IEvent } from './events/models/events.model';

const toolTipText: SxProps = {
  fontSize: '10px',
  lineHeight: '14px',
};

const TooltipTextComponent = ({ event }: { event: IEvent }) => (
  <Box sx={{ textAlign: 'left', ml: 'auto', mr: 'auto' }}>
    <Box sx={toolTipText}>
      Port & Other Costs ($):{' '}
      {formatNumberForDisplay(event.portAndOtherCosts, 2)}
    </Box>
    <Box sx={toolTipText}>
      Diver Cost ($): {formatNumberForDisplay(event.diverCost, 2)}
    </Box>
    <Box sx={toolTipText}>
      Total OOS Period (Days): {showDashesIfEmpty(event.totalOOSPeriod)}
    </Box>
    <Box sx={toolTipText}>Comments: {showDashesIfEmpty(event.comments)}</Box>
  </Box>
);

export const EventTooltipBoxComponent = ({ event }: { event: IEvent }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Tooltip
        title={<TooltipTextComponent event={event} />}
        placement='right'
        arrow
      >
        <Box sx={{ alignSelf: 'center', pt: 0.5, pr: 1 }}>
          <InfoOutlined color='action' />
        </Box>
      </Tooltip>
      <Box sx={{ pt: 1, wordBreak: 'break-word', whiteSpace: 'normal' }}>
        {event.eventName}
      </Box>
    </Box>
  );
};
