import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Dot,
  DotProps,
  Label,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';
import { ApolloError } from '@apollo/client';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { ChartKey } from 'shared/components/chart-key/chart-key.component';
import {
  TrendEvent,
  TrendLine,
  VesselPerformanceTrend,
} from '../models/vessel-performance-trends.model';
import {
  FormatUnixDate,
  FormatTooltip,
  ChartKeyItems,
  ChartTicks,
} from './vessel-trend-chart.view-model';
import { theme } from 'styles/theme';
import { ChartLayerContainer } from 'shared/components/chart-layer-container/chart-layer-container.component';
import { ColorBarGradientArea } from './color-bar-gradient-area.component';
import { ColorBarSide } from './color-bar-side.component';
import { VpmTrendChartTooltip } from './vessel-trend-chart-tooltip.component';
import { ErrorComponent } from 'shared/components/error/error.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { NavBarContext } from 'shared/models/navBar.context.model';

export interface VesselPerformanceTrendChartProps {
  title: string;
  chartKeyShorten?: string;
  yAxisLabel: string;
  yAxisPadding: number;
  chartData: {
    loading?: boolean;
    error?: ApolloError | undefined;
    data: VesselPerformanceTrend | null;
  };
}

const count = 10;
const xAxisName = 'Date';
const secondaryPurple = theme.vesselPerformance.charts.secondaryPurple;
const secondaryMagenta = theme.vesselPerformance.charts.secondaryMagenta;
const secondaryGray = theme.vesselPerformance.charts.secondaryGray;
const axisColor = theme.vesselPerformance.charts.axis;
const toolTipBackground = theme.vesselPerformance.charts.toolTipBackground;

const wrapperStyle: SxProps = {
  mb: 3,
  py: 5,
  borderRadius: '6px',
  backgroundColor: theme.background.component,
};

const titleStyle: SxProps = {
  mb: 3,
  display: 'flex',
  justifyContent: 'center',
};

const CustomDot: React.FC<DotProps> = ({ cx, cy, fill }) => {
  return <Dot cx={cx} cy={cy} fill={fill} r={2} />;
};

export function VesselPerformanceTrendChart(
  props: Readonly<VesselPerformanceTrendChartProps>
) {
  const { title, yAxisLabel, yAxisPadding, chartData, chartKeyShorten } = props;
  const data = chartData.data;
  const loading = chartData.loading;
  const error = chartData.error;

  const { utcDateRange } = useContext(NavBarContext);
  const startDate = utcDateRange?.startDate;
  const endDate = utcDateRange?.endDate;
  const xAxisChartTicks = ChartTicks(startDate, endDate, count);

  if (loading) {
    return <LoadingComponent margin-bottom={'24px'} height={'484px'} />;
  }

  const hasData = data?.primary && data?.primary.length > 0;
  if (error || !hasData) {
    return (
      <Box sx={wrapperStyle}>
        <Typography variant='h3' sx={titleStyle}>
          {title}
        </Typography>
        <ErrorComponent error={error} empty={!hasData} />
      </Box>
    );
  }

  const Formatter = (value: ValueType, name: NameType, toolTipProps: any) => {
    if (toolTipProps?.dataKey === 'y') {
      const actualValue = toolTipProps.payload.value;
      return FormatTooltip(actualValue, name, xAxisName, title);
    }
    return FormatTooltip(value, name, xAxisName, title);
  };
  const chartIsNegative = chartData.data?.chartIsNegative ?? false;
  return (
    <Box sx={wrapperStyle}>
      <Typography variant='h3' sx={titleStyle}>
        {title}
      </Typography>
      <ChartKey items={ChartKeyItems(chartKeyShorten || title).items} />
      <ChartLayerContainer height={300}>
        <ColorBarGradientArea
          data={data.referenceArea}
          domainY={data?.boundary[0]?.domainY ?? [0, 100]}
        />
        <ColorBarSide
          data={data.boundary}
          height={272}
          side='left'
          negative={chartIsNegative}
        />
        <ColorBarSide
          data={data.boundary}
          height={272}
          side='right'
          negative={chartIsNegative}
        />
        <ResponsiveContainer width='99%' height={300}>
          <ScatterChart margin={{ top: 8, right: 12, bottom: 28, left: 18 }}>
            <CartesianGrid
              vertical={false}
              strokeDasharray='0'
              opacity={0.12}
            />
            <XAxis
              type='number'
              name={xAxisName}
              dataKey='x'
              ticks={xAxisChartTicks}
              tickFormatter={FormatUnixDate}
              dy={6}
              angle={-35}
              textAnchor='end'
              axisLine={false}
              tickLine={true}
              tick={{ fill: axisColor }}
              style={{ ...theme.typography.chart }}
              domain={[
                xAxisChartTicks[0] ?? 'dataMin',
                xAxisChartTicks[count] ?? 'dataMax',
              ]}
            />
            <YAxis
              dataKey='y'
              name={title}
              dx={-16}
              domain={
                data.boundary[0]?.domainY ?? [
                  0,
                  data.boundary[0]?.maxValue ?? 100,
                ]
              }
              axisLine={false}
              tickLine={false}
              tick={{ fill: axisColor }}
              style={{ ...theme.typography.chart }}
            >
              <Label
                dy={yAxisPadding}
                fill={axisColor}
                position={'insideLeft'}
                offset={4}
                angle={-90}
                value={yAxisLabel}
                style={{ ...theme.typography.chart }}
              />
            </YAxis>
            <Tooltip
              content={<VpmTrendChartTooltip />}
              cursor={false}
              contentStyle={{
                fontSize: '12px',
                backgroundColor: toolTipBackground,
                color: theme.background.page,
                borderRadius: '4px',
              }}
              itemStyle={{ color: theme.background.page, padding: 0 }}
              formatter={Formatter}
              labelFormatter={() => {
                return '';
              }}
            />
            {data.events?.map((event: TrendEvent) => {
              const {
                domainY: [y0 = 0, y2 = 0],
              } = data.boundary[0] || { domainY: [0, 0] };
              const y1 = y0 + Math.abs(Math.round(y2 - y0) / 100);
              return (
                <React.Fragment key={event.id}>
                  <ReferenceArea
                    x1={event.x!}
                    x2={event.x2!}
                    y1={y0}
                    y2={y1}
                    fill={theme.palette.text.primary}
                    fillOpacity={1}
                  />
                  <ReferenceArea
                    x1={event.x!}
                    x2={event.x2!}
                    y1={y1}
                    y2={y2}
                    fill={theme.palette.action.active}
                    fillOpacity={0.2}
                  />
                </React.Fragment>
              );
            })}

            {data.trends?.map((trend: TrendLine) => (
              <ReferenceLine
                key={trend.id}
                stroke={theme.palette.action.active}
                strokeWidth={3}
                segment={[trend.start, trend.end]}
              />
            ))}
            <Scatter
              data={data.primary}
              shape={<CustomDot fill={secondaryMagenta} />}
            />
            <Scatter
              data={data.missing}
              shape={<CustomDot fill={secondaryPurple} />}
            />
            <Scatter
              data={data.out}
              shape={<CustomDot fill={secondaryGray} />}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </ChartLayerContainer>
    </Box>
  );
}
