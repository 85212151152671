import { Vessel } from '../fleetDataComparison/table/util/fleet-data-comparison-table.util';

export const fleetSummaryData = (data: Vessel[] | undefined) => {
  let overallPassScore: number | undefined;
  let overallFailScore: number | undefined;
  let overallPassScorePercentage: number | undefined;

  const vessels = data?.filter(
    (v) => v.overallFailScore !== undefined || v.overallPassScore !== undefined
  );

  if (vessels?.length && vessels?.length > 0) {
    overallPassScore =
      vessels?.reduce((vesselSum, currentVessel) => {
        return vesselSum + (currentVessel?.overallPassScore ?? 0);
      }, 0) ?? 0;

    overallFailScore =
      vessels?.reduce((vesselSum, currentVessel) => {
        return vesselSum + (currentVessel?.overallFailScore ?? 0);
      }, 0) ?? 0;

    const calculatePercentage = () => {
      if ((overallPassScore ?? 0) + (overallFailScore ?? 0) === 0) {
        return 0;
      }
      return Math.round(
        ((overallPassScore ?? 0) /
          ((overallPassScore ?? 0) + (overallFailScore ?? 0))) *
          100
      );
    };

    overallPassScorePercentage = calculatePercentage();
  }

  return { overallPassScorePercentage, overallPassScore, overallFailScore };
};
