import { useContext } from 'react';
import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { Section } from 'shared/components/section/section.component';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { FleetPerformanceKPIs } from './components/fleet-performance-kpis.component';
import { VesselPerformanceTable } from './components/vesselPerformanceTable.component';
import { useGetVesselPerformance } from './services/vessel-performance.service';
import { ExportableSections } from 'shared/utils/valid-sections';

export const constants = {
  HEADER_TITLE: 'Vessel Performance Monitor: Fleet Overview',
  SECTION_FLEET_KPI: 'Fleet KPIs',
  SECTION_FLEET_BREAKDOWN: 'Fleet Breakdown by Vessels',
};

export function VesselPerformance() {
  const { imoNumbers, utcDateRange } = useContext(NavBarContext);

  const { data, loading, error } = useGetVesselPerformance(
    utcDateRange,
    imoNumbers
  );

  return (
    <PageWrapper data-testid={'vessel-performance'}>
      <PageHeader title={constants.HEADER_TITLE} />
      <Section
        id={ExportableSections['VPM-Fleet KPIs']}
        title={constants.SECTION_FLEET_KPI}
        exportSection={true}
        exportOptions={{ includePDF: false, includeCardData: true }}
        childComponent={<FleetPerformanceKPIs dateRange={utcDateRange} />}
      />
      <Section
        id='VPM-Fleet Breakdown by Vessels'
        title={constants.SECTION_FLEET_BREAKDOWN}
        exportSection={true}
        exportOptions={{ includePDF: false }}
        preventUnmount={true}
        childComponent={
          <VesselPerformanceTable data={data} loading={loading} error={error} />
        }
      />
    </PageWrapper>
  );
}
