import { gql } from '@apollo/client';

export const GET_VESSEL_IMOS_AND_GROUPINGS = gql`
  query GetVesselImosAndGroupings {
    vessels {
      id
      name
      imoNumber
      vesselGrouping {
        id
        groupingType
        groupingCategoryName
        groupingDescription
        groupingName
        groupingOrder
      }
    }
  }
`;
