import { styled, Theme, CSSObject } from '@mui/material';

import Drawer from '@mui/material/Drawer';

const openedStyle = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  width: theme.collapsibleNavBarOptions.openWidth,
});

const closedStyle = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.collapsibleNavBarOptions.closedWidth,
});

export const CollapsibleDrawer = styled(Drawer)(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiList-root': { p: 0 },
  overflowX: 'hidden',
  ...(open && {
    ...openedStyle(theme),
    '& .MuiDrawer-paper': openedStyle(theme),
  }),
  ...(!open && {
    ...closedStyle(theme),
    '& .MuiDrawer-paper': closedStyle(theme),
  }),
}));
