import { RAGDisplayProps } from 'shared/components/status-card/ragIndicator.component';

export interface IFleetUtilizationDetail {
  count: number;
  countPercentage: number;
  duration: number;
  durationPercentage: number;
}

export interface IFleetUtilization {
  [key: string]: IFleetUtilizationDetail;
}

export enum FleetUtilizationType {
  LADEN = 'laden',
  BALLAST = 'ballast',
  IDLE = 'idle',
  IN_PORT = 'inPort',
  AT_SEA = 'atSea',
  MANEUVERING = 'maneuvering',
}

export interface IFleetUtilizationAndOperationalProfiles {
  id?: string;
  VesselName: string;
  CII?: RAGDisplayProps;
  Laden?: number;
  Ballast?: number;
  AtSea?: number;
  InPort?: number;
  Maneuvering?: number;
  Idle?: number;
  ImoNumber?: number;
}

export type FuelEUGhgIntensityChartProps = {
  date: Date | undefined;
  intensity: number;
  intensityTarget: number;
  complianceBalance: number;
  fuelEUPenalty: number;
  fossilFuel: number;
  bioFuel: number;
  eFuel: number;
};
