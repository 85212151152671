import { TooltipProps } from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';

export const VpmTrendChartTooltip = (
  props: TooltipProps<ValueType, NameType>
) => {
  if (!props.payload?.[0]) {
    // render the default
    return <DefaultTooltipContent {...props} />;
  }

  const detail = props.payload[0].payload.detail;
  const customPayload = [
    ...(props.payload ?? []),
    {
      name: `Expected (${detail.expectedUnit})`,
      value: detail.expected,
    },
    {
      name: 'ME Power (kW)',
      value: detail.mePower,
    },
    {
      name: 'SOG (kn)',
      value: detail.sog,
    },
    {
      name: 'STW (kn)',
      value: detail.stw,
    },
    {
      name: 'Draft (m)',
      value: detail.meanDraft,
    },
    {
      name: 'Beaufort Scale',
      value: detail.beaufortScale,
    },
    {
      name: 'Fuel MGOeq. (t)',
      value: detail.fuelMGOEq,
    },
  ];
  return <DefaultTooltipContent {...props} payload={customPayload} />;
};
