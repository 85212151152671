import { FuelEuGhgIntensity } from '_gql/graphql';
export class FleetFuelGHGIntensityDataMapper {
  static toDomain(data: Array<FuelEuGhgIntensity>) {
    return toDataPoint(data);
  }
}

const toDataPoint = (data: Array<FuelEuGhgIntensity>) => {
  data = data.filter((data) => data.date);
  return (
    data?.map((info: FuelEuGhgIntensity) => ({
      date: info.date,
      isFutureProjection: info.isFutureProjection,
      intensityTarget: info.intensityTarget,
      intensity: info.intensity,
      complianceBalance: info.complianceBalance,
      fuelEUPenalty: info.fuelEUPenalty,
      fossilFuel: info.fuelUsage
        ? info.fuelUsage
            ?.find((c) => c.classification === 'Fossil')
            ?.consumptions?.reduce((a, b) => a + (b.consumptionMt ?? 0), 0) ?? 0
        : 0,
      bioFuel: info.fuelUsage
        ? info.fuelUsage
            ?.find((c) => c.classification === 'Biofuel')
            ?.consumptions?.reduce((a, b) => a + (b.consumptionMt ?? 0), 0) ?? 0
        : 0,
      eFuel: info.fuelUsage
        ? info.fuelUsage
            ?.find((c) => c.classification === 'e-Fuel')
            ?.consumptions?.reduce((a, b) => a + (b.consumptionMt ?? 0), 0) ?? 0
        : 0,
    })) ?? []
  );
};
