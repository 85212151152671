import { KpiData } from 'shared/components/status-kpi-card/status-kpi-card.component';

export enum Status {
  success = 'success',
  warning = 'warning',
  error = 'error',
  primary = 'primary',
  graph = 'graph',
  unknown = 'unknown',
  none = 'none',
}

export interface IVesselKpiGql {
  aer: KpiData;
  ciiRating: KpiData;
  ciiYearToDate: KpiData;
  ciiForecast: KpiData;
  eeoi: KpiData;
  euaAgainstFleet: KpiData;
}

export type VesselKpiGql = IVesselKpiGql;
