import { Box, ToggleButtonGroup } from '@mui/material';
import {
  CiiDataToggles,
  CiiDataTogglesDisplay,
} from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import CiiDataToggleButton from './cii-data-toggles/cii-data-toggle-button.component';
import CiiDataToggleDropdownButton from './cii-data-toggles/cii-data-toggle-dropdown-button.component';
import * as ViewModel from 'routes/environmental-monitor/view-models/cii-explore-data.viewModel';
import { typography } from 'styles/typography';
import { subDataToggleSelected } from 'routes/environmental-monitor/view-models/cii-ratings-chart.viewModel';

function CIIExploreData(props: {
  ciiDataToggles: CiiDataToggles | undefined;
  ciiDataTogglesDisplay: CiiDataTogglesDisplay | undefined;
  onClick: (key: string, value: boolean) => void;
}) {
  const buttonKeys = props.ciiDataToggles
    ? ViewModel.getButtonKeys(props.ciiDataToggles)
    : [];

  const handleToggleClick = (key: string, value: boolean) => {
    props.onClick(key, value);
  };

  return (
    <Box>
      <Box
        sx={{
          paddingTop: `${typography.spacing['4']}px`,
          paddingBottom: `${typography.spacing['2']}px`,
        }}
      >
        Select up to three additional data points.
      </Box>
      <ToggleButtonGroup
        color='primary'
        aria-label='data toggle button group'
        sx={{
          gap: '12px',
          mt: '20px',
          display: 'flex',
          width: '700px',
          flexWrap: 'wrap',
          marginTop: '20px',
        }}
      >
        {buttonKeys.map((key) => {
          const data = props.ciiDataToggles
            ? ViewModel.getDataMap(props.ciiDataToggles || {}, key)
            : [];
          const isDropdown = !Array.isArray(data);
          const colors = ViewModel.getColorsForKey(key);
          const title = ViewModel.formatTitle(key);
          const subOptions = isDropdown ? Object.keys(data) : [];

          return isDropdown ? (
            <CiiDataToggleDropdownButton
              key={key}
              options={subOptions}
              optionSelected={subDataToggleSelected(
                props.ciiDataTogglesDisplay?.[
                  key as keyof CiiDataTogglesDisplay
                ]
              )}
              circleFill={colors['circleFill']}
              selectedColor={colors['selectedColor'] ?? ''}
              title={title}
              name={key}
              isSelected={
                !!subDataToggleSelected(
                  props.ciiDataTogglesDisplay?.[
                    key as keyof CiiDataTogglesDisplay
                  ]
                )
              }
              onClick={handleToggleClick}
            />
          ) : (
            <CiiDataToggleButton
              key={key}
              circleFill={colors['circleFill']}
              selectedColor={colors['selectedColor'] ?? ''}
              title={title}
              name={key}
              isSelected={
                (props.ciiDataTogglesDisplay?.[
                  key as keyof CiiDataTogglesDisplay
                ] as boolean) ?? false
              }
              onClick={handleToggleClick}
            />
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
}

export default CIIExploreData;
