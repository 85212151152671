import LabelClass from '@arcgis/core/layers/support/LabelClass';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';

// Vessel Labels style
export const labelClass = new LabelClass({
  symbol: new TextSymbol({
    color: 'white',
    haloColor: 'black',
    haloSize: 0.5,
    font: {
      size: 11,
      weight: 'bold',
    },
    yoffset: 5,
  }),
  labelPlacement: 'above-center',
  labelExpressionInfo: {
    expression: '$feature.VesselName',
  },
});
