import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { StyledTextField } from 'shared/components/styled-textfield/styledTextField.component';
import { theme } from 'styles/theme';

export interface CIITrendForecastFieldProps {
  label: string;
  name: string;
  disabled: boolean;
  required: boolean;
}

export const CIITrendForecastField = (props: CIITrendForecastFieldProps) => {
  const { watch } = useFormContext();
  const ytdValue = watch(`${props.name}Ytd`);
  const twelveMonthRollingValue = watch(`${props.name}TwelveMonthsRolling`);

  return (
    <Box>
      <StyledTextField
        name={props.name}
        label={props.label}
        disabled={props.disabled}
        sx={{ mr: 1.5 }}
        required={props.required}
      />
      <Box
        sx={{
          mt: 2,
          mb: 0.5,
          display: 'block',
        }}
      >
        <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>
          YTD:
        </Typography>
      </Box>
      <Box>
        <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>
          {ytdValue}
        </Typography>
      </Box>

      <Box sx={{ mt: 2, mb: 0.5, display: 'block' }}>
        <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>
          12M Rolling:
        </Typography>
      </Box>
      <Box>
        <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>
          {twelveMonthRollingValue}
        </Typography>
      </Box>
    </Box>
  );
};
