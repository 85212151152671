import React, { useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Dayjs } from 'dayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { theme } from 'styles/theme';
import { isValid, isValidRange, shortcutsItems } from './dateRange.viewModel';
export interface DateRangeProps {
  initialRange: MuiDateRange<Dayjs>;
  sx?: SxProps;
  label?: string | React.ReactNode;
  handler: (range: MuiDateRange<Dayjs>) => void;
  enableContext?: boolean;
}

export const DateRange = (props: DateRangeProps) => {
  const { sx, label } = props;
  const [value, setValue] = useState<MuiDateRange<Dayjs>>(props.initialRange);

  const handleOnChange = (newValue: MuiDateRange<Dayjs>) => {
    if (
      !isValid(newValue[0]) ||
      !isValid(newValue[1]) ||
      !isValidRange(newValue)
    )
      return;
    setValue(newValue);
    props.handler(newValue);
  };

  return (
    <Box sx={{ p: 0 }}>
      <DateRangePicker
        label={label ?? 'Date Range'}
        format='DD MMM YYYY'
        disableFuture={true}
        value={value}
        timezone='UTC'
        onChange={(newValue) => {
          handleOnChange(newValue);
        }}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          shortcuts: {
            sx: { paddingTop: '12px' },
            items: shortcutsItems,
          },
          layout: {
            sx: { backgroundColor: theme.background.page },
          },
          textField: {
            size: 'small',
            variant: 'standard',
            InputProps: { disableUnderline: true },
          },
        }}
        sx={{
          minWidth: '248px',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '6px',
          pl: 1.5,
          '& .MuiFormLabel-root': {
            paddingTop: '4px',
            paddingLeft: '4px',
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: theme.palette.text.secondary,
            transformOrigin: 'center',
            '&.Mui-focused': {
              color: theme.palette.text.secondary,
            },
            '&.Mui-error': {
              color: theme.palette.text.secondary,
            },
          },
          ...sx,
        }}
      />
    </Box>
  );
};
