import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Modal, ModalProps } from '@mui/material';
import { theme } from 'styles/theme';
import { ModalCardContents } from './modal-card-contents.component';
import { ModalCardButtons } from './modal-card-buttons.component';

export interface ModalCardProps extends Partial<ModalProps> {
  displayModal: boolean;
  title: string;
  actionText: string;
  messageText: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  onClose(): void;
  onPrimaryButtonEvent(): void;
  onSecondaryButtonEvent(): void;
}

export const ModalCard = (props: ModalCardProps) => {
  const {
    displayModal,
    title,
    actionText,
    messageText,
    primaryButtonText,
    secondaryButtonText,
    onClose,
    onPrimaryButtonEvent,
    onSecondaryButtonEvent,
    sx,
  } = props;

  const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '452px',
    bgcolor: theme.background.component,
    borderRadius: '8px',
    py: 3,
    px: 3,
    outline: 'none',
  };

  return (
    <Modal open={displayModal} onClose={onClose}>
      <Box sx={{ ...modalStyle, ...sx }}>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <ModalCardContents
          title={title}
          actionText={actionText}
          messageText={messageText}
        />
        <ModalCardButtons
          secondaryButtonText={secondaryButtonText}
          primaryButtonText={primaryButtonText}
          onPrimaryButtonEvent={onPrimaryButtonEvent}
          onSecondaryButtonEvent={onSecondaryButtonEvent}
        />
      </Box>
    </Modal>
  );
};
