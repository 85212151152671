import { IAlert, IAlertComment } from '../models/alert.model';
import { Alert } from '_gql/graphql';
import { AlertStatus } from '../services/alert-status';
import { UTCDate } from 'shared/utils/date-utc-helper';

export class AlertMapper {
  public static toDTO(alert: IAlert) {
    const dtoComments = alert.comments?.map((comment) => {
      const c: IAlertComment = {
        id: comment.id.length < 32 ? '' : comment.id ?? '', // TODO: update when wired up to apollo client cache
        date: new UTCDate(comment.date).toString(),
        text: comment.text ?? '',
        authorName: comment.authorName ?? '',
        userId: comment.userId ?? '',
      };
      return c;
    });
    return {
      id: alert.alertId ?? '',
      status: this.mapAlertStatusToDTO(alert.status),
      reportedOn: alert.reportedOn?.toString(),
      comments: dtoComments,
      isRead: alert.isRead,
      isArchived: alert.isArchived,
    };
  }

  public static mapAlertStatusToDTO(status: string): string {
    switch (status) {
      case AlertStatus.Closed:
        return AlertStatus.Closed;
      default:
        return AlertStatus.Open;
    }
  }

  public static toDomain(dto: Alert): IAlert {
    const domainComments = dto.comments?.map((comment) => {
      const c: IAlertComment = {
        id: comment.id ?? '',
        date: comment.date?.toString() ?? '',
        text: comment.text ?? '',
        authorName: comment.authorName ?? '',
        userId: comment.userId ?? '',
      };
      return c;
    });
    return {
      alertId: dto.id as string,
      reportedOn:
        typeof dto.reportedOn === 'string'
          ? new UTCDate(dto.reportedOn)
          : dto.reportedOn ?? null,
      reportedOnTimestamp: dto.reportedOn?.startOfDay?.unixTime,
      status: dto.status ?? '',
      vesselName:
        (dto.vesselName as string) ??
        `IMO: ${dto.vesselImo ?? 'ERROR: IMO Missing '}`,
      alertDetails: dto.alertDetails as string,
      // products: dto.reportingSystem as string, // TODO: 27677: revert back to this change when this story is finished and remove the below hardoded line.
      products: 'Vessel Data Monitor' as string,
      description: (dto.description as string) ?? '',
      isRead: dto.isRead as boolean,
      comments: domainComments ?? [],
      isArchived: dto.isArchived as boolean,
      isSelected: dto.isSelected ?? false,
      reportedOnTotal: dto.reportedOnTotal ?? 0,
    };
  }
}
