import Supercluster from 'supercluster';
import { VesselLayer } from './vesselLayer';

import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import labelExpressionSelected from './arcade-templates/label';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import { labelClass } from './symbols';
import { MapViewType } from 'shared/models/mapView.context.model';

const scluster = new Supercluster({
  radius: 60,
  maxZoom: 15,
});

let cluster_zoom = -1;
let savedclusters: any[] = [];

export const createVesselNamesClusters = (view: MapViewType) => {
  cluster_zoom = -1;
  savedclusters = [];
  updateVesselNamesClusters(view);
};

export const updateVesselNamesClusters = (
  view: MapViewType,
  forceUpdate = false
) => {
  if (!view) return;
  if (cluster_zoom === view.zoom && !forceUpdate) return;

  VesselLayer.getAllData(view).then((vesseldata: any) => {
    const data = vesseldata.map((x: any) => {
      return {
        type: 'Feature',
        attributes: {
          vesselname: x.attributes.VesselName,
          imoNumber: x.attributes.VesselImo,
        },
        geometry: {
          coordinates: [x.geometry.longitude, x.geometry.latitude],
          type: 'Point',
        },
      };
    });

    scluster.load(data);
    const clusterResults = scluster.getClusters(
      [-180, -85, 180, 85],
      view.zoom - 1
    ) as any[];

    const results = [...clusterResults];
    const jsonResult = JSON.stringify(
      results.map((x) => x.id || x.attributes.imoNumber)
    );
    const jsonSavedResult = JSON.stringify(
      savedclusters.map((x) => x.id || x.attributes.imoNumber)
    );

    if (jsonResult === jsonSavedResult && !forceUpdate) return;
    savedclusters = [...results];

    const clusters = results.filter((x) => x.id && x.properties.cluster);
    const leaves = results.filter((x) => !x.id);

    leaves.forEach((x, i) => {
      x.leaveId = i + 1000;
    });

    clusters.forEach((x) => {
      const id = Number(x.id);
      const leaves = scluster.getLeaves(id) as any[];
      const vesselnames = leaves.map((x) => x.attributes.vesselname);
      x.vesselnames = vesselnames.join();
    });

    const labelGraphics = results.map(function (x) {
      return new Graphic({
        attributes: {
          objectId: x.id || x.leaveId,
          VesselNames: x.vesselnames || x.attributes?.vesselname,
        },
        geometry: new Point({
          longitude: x.geometry.coordinates[0],
          latitude: x.geometry.coordinates[1],
        }),
      });
    });

    const customLabelClass = labelClass;
    customLabelClass.labelExpressionInfo.expression = labelExpressionSelected;
    customLabelClass.deconflictionStrategy = 'none';

    const VesselGroupsLayer = new FeatureLayer({
      id: VesselLayer.VESSEL_LAYER_LABELS_ID,
      listMode: 'hide',
      source: labelGraphics,
      renderer: new SimpleRenderer({
        symbol: new SimpleMarkerSymbol({ size: 0 }),
      }),

      // Vessel Name
      labelingInfo: [customLabelClass],
      fields: [
        { name: 'objectId', alias: 'objectId', type: 'oid' },
        { name: 'VesselNames', alias: 'VesselNames', type: 'string' },
      ],
    });

    const findVesselGroupsLayer = view.map?.findLayerById(
      VesselLayer.VESSEL_LAYER_LABELS_ID
    ) as FeatureLayer;
    if (findVesselGroupsLayer) {
      view.map.layers.remove(findVesselGroupsLayer);
    }

    view.map.layers.add(VesselGroupsLayer, 9);
    cluster_zoom = view.zoom;
  });
};
