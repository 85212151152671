import { SxProps, styled } from '@mui/material/styles';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { ChartsTooltip, ChartsTooltipProps } from '@mui/x-charts-pro';
import { JSX } from '@emotion/react/jsx-runtime';
import { ChartsSeriesConfig } from '@mui/x-charts/internals';
import { InferProps } from 'prop-types';

const themeProperties: SxProps = {
  '.MuiChartsAxisHighlight-root': {
    stroke: 'gray',
  },
  '& .MuiChartsLegend-root': {
    '& .MuiChartsLegend-mark': {
      rx: '50%', // make legend mark round
    },
  },
  '& .MuiPieArc-root': {
    stroke: 'transparent', // remove pie chart stroke/border
  },
};

export const StyledChartContainer = styled(ResponsiveChartContainer)(
  () => themeProperties
);

export const StyledChartsTooltip = (
  props: JSX.IntrinsicAttributes &
    Pick<
      ChartsTooltipProps<keyof ChartsSeriesConfig>,
      keyof ChartsTooltipProps<keyof ChartsSeriesConfig>
    > &
    Pick<InferProps<any>, string | number | symbol> &
    Pick<ChartsTooltipProps<keyof ChartsSeriesConfig>, never>
) => (
  <ChartsTooltip
    {...props}
    slotProps={{
      popper: {
        sx: {
          '& .MuiChartsTooltip-table': {
            borderRadius: '6px',
            'thead td': {
              borderBottom: '1px solid #999',
              '& .MuiTypography-root': {
                fontWeight: 'bold',
              },
            },
          },
          '& .MuiChartsTooltip-cell, & .MuiTypography-root': {
            color: '#444 !important',
          },
        },
      },
    }}
  />
);
