import React from 'react';
import { Typography, Box, IconButton, SxProps } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  GridColumn,
  GridCustomHeaderClass,
  GridRAGColumn,
  nameof,
} from 'shared/components/datagrid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { theme } from 'styles/theme';
import { Maybe, Report } from '_gql/graphql';
import { UTCDate } from 'shared/utils/date-utc-helper';

type IsAny<T> = unknown extends T
  ? [keyof T] extends [never]
    ? false
    : true
  : false;

type RemoveAny<Obj> = {
  [Prop in keyof Obj]: Obj[Prop] extends Maybe<infer T> & string
    ? IsAny<T> extends T
      ? string
      : Maybe<T>
    : Obj[Prop];
};

export type StatusType = 'None' | 'Passed' | 'Flagged';
export type FlagType = 'None' | 'Emissions' | 'Non-Emissions' | 'Multiple';
export type ReportModel = RemoveAny<SubReportModel> & {
  status: StatusType;
  flagType: FlagType;
  title: string;
  fieldName: string;
  fieldValue: string;
  remarks: string;
};
type SubReportModel = Pick<
  Report,
  | 'id'
  | 'generatedReportId'
  | 'reportedDate'
  | 'reportType'
  | 'isValid'
  | 'dateModified'
>;

//
function CustomDetailPanelToggle(
  props: Readonly<Pick<GridRenderCellParams, 'id' | 'value'>>
) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size='medium'
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize='large'
      />
    </IconButton>
  );
}

const ErrorIcons = ({ row }: { row: any }) => {
  if (row.emissionDataFailed || row.nonEmissionDataFailed) {
    return (
      <Box sx={{ height: '32px' }}>
        <>
          {row.emissionDataFailed && (
            <ErrorIcon color='error' sx={{ width: '32px', height: '32px' }} />
          )}
          {row.nonEmissionDataFailed && (
            <ErrorOutlineIcon
              color='error'
              sx={{ width: '32px', height: '32px' }}
            />
          )}
        </>
      </Box>
    );
  }
  return null;
};

const statusStyle: SxProps = {
  borderColor: theme.background.page,
  p: 0,
  height: '100%',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  width: '10px',
};
const dateStyle: SxProps = { display: 'block', whiteSpace: 'nowrap' };

const getStatusText = (value: boolean) => (value ? 'Passed' : 'Flagged');

const getFlagStatusText = (value: FlagType) => {
  switch (value) {
    case 'Emissions':
      return 'Emissions Regulation Data';
    case 'Non-Emissions':
      return 'Non-Emissions Regulation Data';
    case 'Multiple':
      return 'Emissions & Non-Emissions Regulation Data';
    default:
      return '';
  }
};

const StatusIcon = (params: GridRenderCellParams<ReportModel>) => {
  const row = params.row;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {row.isValid ? (
        <CheckCircleIcon
          sx={{
            color: theme.palette.success.main,
            width: '32px',
            height: '32px',
          }}
        />
      ) : (
        <CancelIcon
          sx={{
            color: theme.palette.error.main,
            width: '32px',
            height: '32px',
          }}
        />
      )}
      <Typography
        sx={{
          textTransform: 'capitalize',
          fontWeight: '300',
          lineHeight: '28px',
          minWidth: '60px',
          textAlign: 'center',
          fontSize: 'small',
        }}
      ></Typography>
    </Box>
  );
};

export function getColumnsDefinition(): GridColDef[] {
  const columns = nameof<ReportModel>;

  return [
    GridColumn({
      field: 'isValidRow',
      align: 'left',
      valueField: 'isValid',
      cellClassName: GridCustomHeaderClass.NoGutters,
      maxWidth: 10,
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ReportModel>) => {
        const color = params.row.isValid
          ? theme.palette.success.main
          : theme.palette.error.main;
        return <Box sx={{ ...statusStyle, backgroundColor: color }}></Box>;
      },
    }),
    GridRAGColumn({
      field: columns('isValid'),
      align: 'left',
      headerName: 'Report Status',
      valueField: columns('isValid'),
      statusField: columns('status'),
      cellClassName: GridCustomHeaderClass.NoGutters,
      maxWidth: 50,
      valueFormatter: (params) => getStatusText(params.value),
      renderCell: StatusIcon,
    }),
    GridRAGColumn({
      field: columns('flagType'),
      headerName: 'Flag Type',
      type: 'date',
      valueField: columns('flagType'),
      statusField: columns('flagType'),
      maxWidth: 230,
      visibility: 'export-only',
      valueFormatter: (params: GridValueFormatterParams<FlagType>) =>
        getFlagStatusText(params?.value),
      renderCell: (params: GridRenderCellParams<ReportModel>) => (
        <Box>{getFlagStatusText(params.row.flagType)}</Box>
      ),
    }),

    GridColumn({
      field: columns('reportedDate'),
      headerName: 'Reported Date',
      type: 'date',
      valueField: columns('reportedDate'),
      maxWidth: 230,
      valueFormatter: (params) => new UTCDate(params?.value).formatDMY(),
      renderCell: (params) => (
        <Typography sx={{ fontSize: 'large', fontWeight: 'bold' }}>
          {new UTCDate(params?.value).formatDMY()}
        </Typography>
      ),
    }),
    GridColumn({
      field: columns('reportType'),
      headerName: 'Reported Type',
      type: 'string',
      valueField: columns('reportType'),
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            gap: '8px',
          }}
        >
          <ErrorIcons row={params.row} />
          <Typography fontSize={'large'} sx={{ alignSelf: 'center' }}>
            {params.value}{' '}
          </Typography>
        </Box>
      ),
    }),
    GridColumn({
      field: columns('dateModified'),
      headerName: 'Last Updated',
      type: 'string',
      valueField: columns('dateModified'),
      valueFormatter: (params) =>
        new UTCDate(params?.value).formatDMYHMZ(false, true, false),
      renderCell: (params) => (
        <Box>
          <Typography sx={dateStyle} fontSize={'medium'}>
            Last Updated Date
          </Typography>
          <Typography variant='body1' sx={dateStyle} fontSize={'medium'}>
            {new UTCDate(params?.value).formatDMYHMZ(false, true, false)}
          </Typography>
        </Box>
      ),
    }),

    GridColumn({
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      maxWidth: 100,
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ReportModel>) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    }),
  ];
}

export function getColumnsDefinitionNoRender(): GridColDef[] {
  const columns = nameof<ReportModel>;
  const columnDefinition = [
    GridRAGColumn({
      field: columns('status'),
      align: 'left',
      headerName: 'Report Status',
      valueField: columns('status'),
      statusField: columns('status'),
      maxWidth: 100,
      valueFormatter: (params: GridValueFormatterParams<StatusType>) =>
        params.value === 'None' ? '' : params.value,
    }),
    GridRAGColumn({
      field: columns('flagType'),
      headerName: 'Flag Type',
      type: 'date',
      valueField: columns('flagType'),
      statusField: columns('flagType'),
      maxWidth: 230,
      valueFormatter: (params: GridValueFormatterParams<FlagType>) =>
        getFlagStatusText(params?.value),
    }),

    GridColumn({
      field: columns('reportedDate'),
      headerName: 'Reported Date',
      type: 'date',
      valueField: columns('reportedDate'),
      maxWidth: 230,
      valueFormatter: (params) => new UTCDate(params?.value).formatDMY(),
    }),
    GridColumn({
      field: columns('reportType'),
      headerName: 'Reported Type',
      type: 'string',
      valueField: columns('reportType'),
      headerAlign: 'left',
      align: 'left',
    }),
    GridColumn({
      field: columns('dateModified'),
      headerName: 'Last Updated',
      type: 'string',
      valueField: columns('dateModified'),
      valueFormatter: (params) =>
        new UTCDate(params?.value).formatDMYHMZ(false, true, false),
    }),
    GridColumn({
      field: columns('title'),
      headerName: 'Section',
      type: 'string',
      valueField: columns('title'),
    }),
    GridColumn({
      field: columns('fieldName'),
      headerName: 'Data Field',
      type: 'string',
      valueField: columns('fieldName'),
    }),
    GridRAGColumn({
      field: columns('fieldValue'),
      headerName: 'Value',
      type: 'string',
      valueField: columns('fieldValue'),
      statusField: columns('flagType'),
    }),
    GridRAGColumn({
      field: columns('remarks'),
      headerName: 'Remarks',
      type: 'string',
      width: 300,
      valueField: columns('remarks'),
      statusField: columns('flagType'),
    }),
  ];

  columnDefinition.forEach((x) => (x.visibility = 'export-only'));
  return columnDefinition;
}
