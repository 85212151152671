import { ApolloError } from '@apollo/client';
import { Query } from '_gql/graphql';
import { useEffect } from 'react';
import { theme } from 'styles/theme';

interface IUseScrollToAnchor {
  loading: boolean;
  error: ApolloError | undefined;
  data: Query | undefined;
  anchor: string;
}
export function useScrollToAnchor(props: IUseScrollToAnchor) {
  const { loading, error, data, anchor } = props;

  useEffect(() => {
    if (!anchor) {
      return;
    }
    const scroll = () => {
      const element = document.body.querySelector(
        `[data-id='${anchor.slice(1)}']`
      );
      if (element) {
        const myButton = element.querySelector('button');
        if (myButton) {
          myButton?.click();
        }
        const mainElement = document.querySelector('main');

        const topPositionValue = element.getBoundingClientRect().top;
        const value = topPositionValue - theme.topNavigationOptions.height - 8;
        setTimeout(() => {
          mainElement?.scrollTo({
            top: value,
            behavior: 'smooth',
          });
        }, 100);
      }
    };

    if (!loading && !error && data) {
      setTimeout(() => {
        scroll();
      }, 100);
    }
  }, [loading, error, data]);
}
