import { useVesselCurrentPositionService } from 'routes/dashboard/services/vesselcurrentposition.get.service';
import { MDFMap } from 'shared/components/esri-map/map.component';
import { ActiveVoyageLayer } from 'shared/components/esri-map/utils/ActiveVoyageLayer';
import { PortsLayer } from 'shared/components/esri-map/utils/PortsLayer';
import { useTransformedVesselData } from 'shared/services/vesselData.service';
import { DrawerInsideMap } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer.component';
import { MapDrawerComponent } from './components/drawer/drawer-component';
export const unlocodes: string[] = [
  'USGLS',
  'GIGIB',
  'USHOU',
  'USMIA',
  'AUADL',
  'AUBNE',
  'AUBWT',
  'AUGEX',
  'AUNTL',
  'AUPLO',
  'AUTSV',
  'CANNO',
  'CNCFD',
  'CNLSN',
  'CNNTG',
  'CNTAG',
  'CNZHE',
  'CNZOS',
  'IDAMA',
  'INBOM',
  'INDAH',
  'INIXY',
  'INKVL',
  'JPMAI',
  'JPNNO',
  'JPOIT',
  'KEMBA',
  'KRTSN',
  'MZBEW',
  'NZBLU',
  'NZGIS',
  'NZMAP',
  'NZNPE',
  'NZNSN',
  'NZTIU',
  'NZTRG',
  'PABLB',
  'PTSET',
  'SGSIN',
  'TZDAR',
  'USCLM',
  'UYMVD',
  'ZADUR',
  'CNSHK',
  'CNYTG',
  'LBBEY',
  'RUIKS',
  'KRKPO',
  'MGDIE',
  'TRGUL',
  'CYFMG',
  'TTCHA',
  'PRPYA',
  'DOCBJ',
  'AEFJR',
  'AUBME',
  'IRABD',
  'AUYBA',
  'WSAPW',
  'TRTZX',
  'SKKNA',
  'MXATM',
  'CACOL',
  'CAKLY',
  'USMQT',
  'USGRB',
  'INBED',
  'INNPT',
  'BDCGP',
  'INIXZ',
  'JPSTD',
  'JPFKY',
  'JPTOB',
  'JPSMD',
  'JPKSN',
  'JPTKS',
  'SEFUD',
  'JPTBT',
  'COCTG',
  'FIINK',
  'GNCKY',
  'DEWVN',
  'KPNAM',
  'CNSYX',
  'DEMAI',
  'RUPKC',
  'NLOOS',
  'RUVVO',
  'BEWLB',
  'KRUSN',
  'FOKVI',
  'GSGRV',
  'ARDME',
  'BRNTR',
  'PEPAI',
  'NOOKF',
  'CLANF',
  'ITCNT',
  'GBLOV',
  'INPAV',
  'VNNGH',
  'CAMRW',
  'ESDNA',
  'USPEF',
  'ITPVE',
  'ITPNZ',
  'MTMLA',
  'ITCRV',
  'CNSDG',
  'HRNVG',
  'INVEN',
  'IDPNG',
  'VNNGT',
  'DKROD',
  'HRVRA',
  'USVDZ',
  'CAKTM',
  'GRSKG',
  'IDSKP',
  'GBDVR',
  'GQLUB',
  'AOLOB',
  'JPNMT',
  'IDPBB',
  'GBFLE',
  'GBHYM',
  'MYMYY',
  'IDTBA',
  'IDSRI',
  'IDPPS',
  'GBINV',
  'GBFLX',
  'CAVAN',
  'SEGRU',
  'HRUMG',
  'USSAB',
  'GBSOW',
  'DEBUZ',
  'GBTAB',
  'GBHRW',
  'USPCV',
  'USGLS',
  'USHUM',
  'USMFX',
  'MRNKC',
  'IDSPH',
  'USLUI',
  'AUCCU',
  'CNWXS',
  'NLDEV',
  'EEMDR',
  'AUABP',
  'UASVP',
  'HTCAP',
  'KRCHF',
  'GRMJT',
  'USRD4',
  'USTKE',
  'FROGG',
  'FRDRZ',
  'USMXH',
  'CNGUT',
  'CNPLI',
  'NOSTU',
  'CNTOJ',
  'CRGLF',
  'EEPRN',
  'CASUM',
  'CASOU',
  'CARIM',
  'IRZBR',
  'IDKBH',
  'PHDVO',
  'ITMSN',
  'PGGUR',
  'AULST',
  'CNDCB',
  'CYKYR',
  'SOBBO',
  'CZCHV',
  'YEBLH',
  'ASPPG',
  'VUVLI',
  'GBHPT',
  'TRAYV',
  'ITAOI',
  'HUDUU',
  'USAKB',
  'GBCYN',
  'GEPTI',
  'IDTKG',
  'GYLIN',
  'RUMOW',
  'USVN3',
  'EGAGN',
  'AEHUL',
  'DKSTD',
  'GBGSA',
  'PHBTG',
  'JPNGO',
  'GBSNS',
  'JPNAH',
  'USCSX',
  'NLNIJ',
  'LYSRT',
  'MAJFL',
  'CLTAL',
  'DEBMK',
  'DEMID',
  'NLLEY',
  'ESMOT',
  'NOKSU',
  'FRSXB',
  'SERAA',
  'NOSNE',
  'GBROY',
  'PEPLO',
  'LYTOB',
  'FRLRT',
  'GBBLU',
  'GBLOW',
  'FRPOV',
  'EGNUW',
  'OMSLL',
  'ARUSH',
  'EERHK',
  'RUZAR',
  'BRPOA',
  'GBMIS',
  'GBBLS',
  'GBBOC',
  'GRSMI',
  'DEFLF',
  'IDSUB',
  'BRPET',
  'PLPLC',
  'BRBEL',
  'ECESM',
  'USESC',
  'USMKE',
  'USKWY',
  'CLPEO',
  'VNDAD',
  'FRRAD',
  'DOHAI',
  'INCOK',
  'RUNNM',
  'RUPES',
  'NOODD',
  'INCCU',
  'NOFFD',
  'NOSVV',
  'ITCHI',
  'SEKHN',
  'INMAL',
  'PHTTO',
  'GBBUS',
  'NGBON',
  'ITPVT',
  'JPKZU',
  'DZORN',
  'USBRO',
  'YERAI',
  'USRAI',
  'GBPAK',
  'DEGHM',
  'DKVEJ',
  'INMDA',
  'HRDBV',
  'BRCDO',
  'ISRHA',
  'CLSVE',
  'COMAM',
  'FRTRE',
  'HTLAB',
  'FINLI',
  'GBKLN',
  'CLPCK',
  'GBTEE',
  'PEGSM',
  'GBIMM',
  'IDBIK',
  'SEOGR',
  'MXMZT',
  'CANSY',
  'GBFAL',
  'ROCAS',
  'ITAUG',
  'ESCED',
  'PFPPT',
  'GBTNM',
  'IDPKU',
  'DKASV',
  'CNNTG',
  'RUPRI',
  'IDBKS',
  'GBNHV',
  'USBUP',
  'USYIG',
  'SEHAD',
  'GBPOO',
  'USMH2',
  'DEBER',
  'TNZRZ',
  'TNGAE',
  'DEECK',
  'GBLDY',
  'IEWAT',
  'GBCNU',
  'BGSLS',
  'NLZWO',
  'USSIB',
  'ARCLC',
  'USNSS',
  'MXPVR',
  'SEIGG',
  'RUTAM',
  'RUBNK',
  'DEBRE',
  'RUMCX',
  'JPTHS',
  'IDKOE',
  'ARVCN',
  'CNZOS',
  'CNWXP',
  'JPTOY',
  'MANDR',
  'FRCIF',
  'USPGL',
  'USLAX',
  'GRASS',
  'TRPEN',
  'ISISA',
  'PAGBA',
  'JPIMB',
  'BZBGK',
  'CNQAW',
  'JPKOM',
  'ROZIC',
  'CAPAC',
  'PHTAC',
  'EGHRG',
  'CNLUS',
  'QARLF',
  'CNPZE',
  'CAPRS',
  'TZMYW',
  'AUPHE',
  'ZAMZY',
  'USXGD',
  'SEWAL',
  'DZGHZ',
  'CNLYA',
  'PGPOM',
  'IDMOW',
  'USANC',
  'AUBBG',
  'NLOMD',
  'SEGRH',
  'FIISN',
  'NONYH',
  'PABLB',
  'GBFLH',
  'CNZZU',
  'DKSTG',
  'PH023',
  'JPHSM',
  'USTIW',
  'USOWB',
  'RURTW',
  'JPNGU',
  'IDTJQ',
  'NCTHI',
  'EESRU',
  'HTPAP',
  'GBWRN',
  'SBHIR',
  'USTGK',
  'JPKMJ',
  'CNZSN',
  'JPOMT',
  'CNXTA',
  'JPSEN',
  'JPKKO',
  'JPTMJ',
  'INTAD',
];

export function VoyageMonitorMap() {
  const service = useVesselCurrentPositionService(900000); // Poll every 15 minutes
  const data = useTransformedVesselData(service);

  return (
    <>
      <MDFMap data={data}>
        <ActiveVoyageLayer></ActiveVoyageLayer>
        <PortsLayer data={unlocodes}></PortsLayer>
        <DrawerInsideMap>
          <MapDrawerComponent></MapDrawerComponent>
        </DrawerInsideMap>
      </MDFMap>
    </>
  );
}
