import {
  useGetFleetKpiCO2Penalty,
  useGetFleetKpiFuelPenalty,
} from '../services/fleet-kpi-chart.service';
import { FleetKPICards } from './fleet-kpi-cards';
import { FleetKpiChart } from './fleet-kpi-chart.component';
import { DateRange } from 'shared/utils/date-utc-helper';
import { useGlobalVesselGroupParameters } from '../services/global-vessel-group-parameter.service';
import { useState } from 'react';
import { useGetAllVesselGroupings } from '../../../shared/components/vesselGroupingsDropdown/services/vesselGroupings.get.services';

export interface FleetPerformanceKPIProps {
  dateRange: DateRange | null;
}

export const FleetPerformanceKPIs = (props: FleetPerformanceKPIProps) => {
  const fleetTitles = {
    fuel: 'Daily Excess Fuel Due to Hull & Propeller Condition',
    co2: 'Daily Excess CO₂ Due to Hull & Propeller Condition',
  };

  const vesselGroups = useGlobalVesselGroupParameters();

  const [subgroupFuelIds, setSubgroupFuelIds] = useState<string[]>([]);
  const [subgroupCO2Ids, setSubgroupCO2Ids] = useState<string[]>([]);

  const { data } = useGetAllVesselGroupings();

  const vesselGroupingSubGroupIds = () => {
    if (data && data.length > 0) {
      const subgroups = data[0]?.groupings ?? [];
      const length = Math.min(subgroups.length, 10);
      const localSubgroupIds = subgroups.map((x) => x.id).slice(0, length);
      return localSubgroupIds ?? undefined;
    }

    return undefined;
  };

  const defaultSubgroupFuelIds =
    subgroupFuelIds.length > 0
      ? subgroupFuelIds
      : vesselGroupingSubGroupIds() || [];

  const defaultSubgroupCO2Ids =
    subgroupCO2Ids.length > 0
      ? subgroupCO2Ids
      : vesselGroupingSubGroupIds() || [];

  const fuelCartData = useGetFleetKpiFuelPenalty(
    props.dateRange,
    vesselGroups,
    defaultSubgroupFuelIds
  );
  const co2ChartData = useGetFleetKpiCO2Penalty(
    props.dateRange,
    vesselGroups,
    defaultSubgroupCO2Ids
  );

  const fuelCallback = (ids: string[]) => {
    setSubgroupFuelIds(ids);
  };

  const co2Callback = (ids: string[]) => {
    setSubgroupCO2Ids(ids);
  };
  return (
    <>
      <FleetKPICards />
      <FleetKpiChart
        title={fleetTitles.fuel}
        chartData={fuelCartData}
        callback={fuelCallback}
        dateRange={props.dateRange}
      />
      <FleetKpiChart
        title={fleetTitles.co2}
        chartData={co2ChartData}
        callback={co2Callback}
        yAxisLabel='Excess CO₂ (t/day)'
        dateRange={props.dateRange}
      />
    </>
  );
};
