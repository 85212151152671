export enum AlertStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export const isAlertStatusOpen = (alertStatus: string | undefined): boolean => {
  return isAlertStatusTrue(
    new RegExp(AlertStatus.Open, 'i'),
    alertStatus ?? ''
  );
};

export const isAlertStatusClosed = (
  alertStatus: string | undefined
): boolean => {
  return isAlertStatusTrue(
    new RegExp(AlertStatus.Closed, 'i'),
    alertStatus ?? ''
  );
};

const isAlertStatusTrue = (compareTo: RegExp, alertStatus: string): boolean => {
  if (compareTo && alertStatus) {
    const match = alertStatus.match(compareTo) ?? [];
    return match.length > 0;
  }
  return false;
};
