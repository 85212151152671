import { useState } from 'react';
import { IconButton, ModalProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalCard } from 'shared/components/modal-card/modal-card.component';
import { TEXT } from 'shared/constants/text';

export interface DeleteModalProps extends Partial<ModalProps> {
  icon?: JSX.Element;
  deleteHandler: () => void;
  cancelHandler?: () => void;
}

export const DeleteModal = (props: DeleteModalProps) => {
  const { icon, deleteHandler, cancelHandler, sx } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePrimaryButtonEvent = () => {
    deleteHandler();
    handleClose();
  };

  const handleSecondaryButtonEvent = () => {
    cancelHandler && cancelHandler();
    handleClose();
  };

  return (
    <>
      <IconButton
        color='default'
        sx={{ ml: 1, alignSelf: 'flex-end' }}
        onClick={handleOpen}
        data-testid='DeleteIconButton'
      >
        {icon ?? <DeleteIcon />}
      </IconButton>
      <ModalCard
        sx={{ ...sx }}
        displayModal={open}
        onClose={handleClose}
        title={`${TEXT.VESSEL_PERFORMANCE.EVENTS_TABLE_DELETE_MODAL.TITLE}`}
        actionText={`${TEXT.VESSEL_PERFORMANCE.EVENTS_TABLE_DELETE_MODAL.ACTION_TEXT}`}
        messageText={`${TEXT.VESSEL_PERFORMANCE.EVENTS_TABLE_DELETE_MODAL.MESSAGE_TEXT}`}
        primaryButtonText={`${TEXT.VESSEL_PERFORMANCE.EVENTS_TABLE_DELETE_MODAL.PRIMARY_BUTTON_TEXT}`}
        secondaryButtonText={`${TEXT.VESSEL_PERFORMANCE.EVENTS_TABLE_DELETE_MODAL.SECONDARY_BUTTON_TEXT}`}
        onPrimaryButtonEvent={handlePrimaryButtonEvent}
        onSecondaryButtonEvent={handleSecondaryButtonEvent}
      ></ModalCard>
    </>
  );
};
