import { formatNumberForDisplay } from 'shared/utils/display-utils';
import { kpiStatusToEnum } from 'shared/utils/status-utils';
import { VesselGrouping, VesselPerformanceIndicators } from '_gql/graphql';
import { VesselPerformanceRow } from '../models/vesselPerformance.viewModel';
import { rows } from '../models/vesselPerformance.viewModel';

const SISTER_CLASS_GROUPING_FIELD = 'By Sister Class';

export const fleetPerformanceTable = (
  data: VesselPerformanceIndicators[] | undefined | null
) => {
  if (!data) return [];

  const fleetData: VesselPerformanceRow[] = data.map((vpi, index) => {
    return {
      ...rows[index % 8]!,
      id: vpi?.vessel?.id ?? '',
      name: vpi?.vessel?.name ?? '',
      vesselImo: vpi?.vessel?.imoNumber ?? '',
      lastCleaning: vpi?.lastCleaning,
      className: getClassFromVesselGroupings(vpi?.vessel?.vesselGrouping || []),
      foulingResistance: {
        value: formatNumberForDisplay(
          vpi?.foulingResistance?.value?.rahFoulingPercent,
          0,
          0
        ),
        status: kpiStatusToEnum(vpi?.foulingResistance?.value?.status),
      },
      dPower: {
        value: formatNumberForDisplay(vpi?.deltaPower?.value?.deltaPower, 0, 0),
        status: kpiStatusToEnum(vpi?.deltaPower?.value?.status),
      },
      dSpeed: {
        value: formatNumberForDisplay(vpi?.deltaSpeed?.value?.deltaSpeed, 0, 0),
        status: kpiStatusToEnum(vpi?.deltaSpeed?.value?.status),
      },
      dMainEngineSFOC: {
        value: formatNumberForDisplay(
          vpi?.mesfoc?.value?.sfocDeviationPercent,
          0,
          0
        ),
        status: kpiStatusToEnum(vpi?.mesfoc?.value?.status),
      },
      dPowerDailyFuelPenalty: {
        value: formatNumberForDisplay(
          vpi?.deltaPowerFuelPenalty?.value?.deltaPowerDailyFuelPenalty
        ),
        status: kpiStatusToEnum(vpi?.deltaPowerFuelPenalty?.value?.dailyStatus),
      },
      dPowerTotalFuelPenalty: {
        value: formatNumberForDisplay(
          vpi?.deltaPowerFuelPenalty?.value?.deltaPowerDailyFuelPenaltySum
        ),
        status: kpiStatusToEnum(vpi?.deltaPowerFuelPenalty?.value?.totalStatus),
      },
      dPowerTotalDailyCO2Penalty: {
        value: formatNumberForDisplay(
          vpi?.deltaPowerDailyCO2Penalty?.value?.deltaPowerDailyCO2Penalty
        ),
        status: kpiStatusToEnum(
          vpi?.deltaPowerDailyCO2Penalty?.value?.dailyStatus
        ),
      },
      dPowerTotalCO2Penalty: {
        value: formatNumberForDisplay(
          vpi?.deltaPowerDailyCO2Penalty?.value?.deltaPowerDailyCO2PenaltySum
        ),
        status: kpiStatusToEnum(
          vpi?.deltaPowerDailyCO2Penalty?.value?.totalStatus
        ),
      },
    };
  });
  return fleetData;
};

const getClassFromVesselGroupings = (groupings: VesselGrouping[]): string => {
  if (!groupings || groupings.length === 0) return '';
  const className = groupings.find(
    (grouping) =>
      grouping.groupingOrder === 1 &&
      grouping.groupingCategoryName === SISTER_CLASS_GROUPING_FIELD
  )?.groupingName;
  return className ? className : '';
};
