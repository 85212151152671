import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { LineItemGenerator } from './generateReport/line-item-generator';
import { ReactComponent as ExcelIcon } from 'assets/excel-icon.svg';
import { ReactComponent as PdfIcon } from 'assets/pdf-icon.svg';
import { DownloadLineItemList } from './generateReport/download-line-item-list';
import { ReportModalWarning } from './generateReport/report-modal-warning';
import { useAvailableReportsAndStatements } from '../services/available-reports-and-statements';
import {
  ExcelDownloadContext,
  ExcelDownloadContextProvider,
} from './generateReport/excel-download-context';
import {
  PdfDownloadContext,
  PdfDownloadContextProvider,
} from './generateReport/pdf-download-context';

export const Report = () => {
  const { availableReports, availableStatements } =
    useAvailableReportsAndStatements();

  return (
    <PageWrapper data-testid={'reports'}>
      <PageHeader title='Manage Reports' />
      <ExcelDownloadContextProvider reportType='IMO DCS/MRV'>
        <LineItemGenerator
          itemTypes={availableReports}
          title='Generate Report'
          inputTypeName='Report Type'
          generateButtonText='Generate Report'
          submitButtonText='Direct Submission to ABS ERP'
          context={ExcelDownloadContext}
        >
          <DownloadLineItemList
            downloadButtonText='Download Report'
            icon={<ExcelIcon />}
            context={ExcelDownloadContext}
          />
        </LineItemGenerator>
        <ReportModalWarning />
      </ExcelDownloadContextProvider>
      <PdfDownloadContextProvider>
        <LineItemGenerator
          itemTypes={availableStatements}
          title='Generate Statement'
          inputTypeName='Statement Type'
          generateButtonText='Generate Statement'
          context={PdfDownloadContext}
        >
          <DownloadLineItemList
            downloadButtonText='Download Statement'
            icon={<PdfIcon />}
            context={PdfDownloadContext}
          />
        </LineItemGenerator>
      </PdfDownloadContextProvider>
    </PageWrapper>
  );
};
