import { useMutation, useQuery } from '@apollo/client';
import { Mutation, Query } from '../../../_gql/graphql';
import { GET_FLEET_VESSELS } from '../queries/fleet.query';
import {
  GET_CII_CALCULATION_BY_VESSEL_ID,
  UPDATED_CII_EOY_PROJECTIONS,
} from '../queries/vessel-cii.query';
import {
  GET_AER_CHART_DATA_BY_VESSEL_ID,
  GET_VESSEL_DETAIL,
  GET_VESSEL_KPI_DATA,
  GET_VESSEL_VOYAGE_COMPARISON,
  GET_VESSEL_VOYAGE_COMPARISON_LAST_PORT,
} from '../queries/vessels.query';
import { DateRange } from 'shared/utils/date-utc-helper';

export function useGetVesselAerData(
  id: string | undefined,
  dateRange: DateRange | null
) {
  return useQuery<Query>(GET_AER_CHART_DATA_BY_VESSEL_ID, {
    skip: !id || !dateRange?.dateRangeAsString,
    variables: {
      ...dateRange?.dateRangeAsString,
      id,
    },
  });
}

export function useGetFleetVessels(
  dateRange: DateRange | null,
  vesselImos: string[] = []
) {
  return useQuery<Query>(GET_FLEET_VESSELS, {
    skip: !dateRange?.dateRangeAsString,
    variables: {
      ...dateRange?.dateRangeAsString,
      vesselImos,
    },
  });
}

export function useGetVesselData(id: string | undefined) {
  return useQuery<Query>(GET_VESSEL_DETAIL, {
    skip: !id,
    variables: {
      id,
    },
  });
}

export function useGetVesselCiiData(
  id: string | undefined,
  dateRange: DateRange | null
) {
  return useQuery<Query>(GET_CII_CALCULATION_BY_VESSEL_ID, {
    skip: !id || !dateRange?.dateRangeAsString,
    variables: {
      id,
      ...dateRange?.dateRangeAsString,
    },
  });
}

export function useGetVesselKPIData(
  id: string | undefined,
  dateRange: DateRange | null
) {
  return useQuery<Query>(GET_VESSEL_KPI_DATA, {
    skip: !id || !dateRange?.dateRangeAsString,
    variables: {
      id,
      ...dateRange?.dateRangeAsString,
    },
  });
}
export function useGetVoyageComparisonData(
  id: string | undefined,
  dateRange: DateRange | null
) {
  return useQuery<Query>(GET_VESSEL_VOYAGE_COMPARISON, {
    skip: !id || !dateRange?.dateRangeAsString,
    variables: {
      ...dateRange?.dateRangeAsString,
      id,
    },
  });
}

export function useUpdatedCiiEoyProjectionsData() {
  const [mutation, { error, loading, data }] = useMutation<Mutation>(
    UPDATED_CII_EOY_PROJECTIONS
  );

  return {
    mutation,
    error,
    loading,
    data,
  };
}

export function useGetVoyageComparisonLastPort(
  id: string | undefined,
  dateRange: DateRange | null
) {
  return useQuery<Query>(GET_VESSEL_VOYAGE_COMPARISON_LAST_PORT, {
    skip: !id || !dateRange?.dateRangeAsString,
    variables: {
      ...dateRange?.dateRangeAsString,
      id,
    },
  });
}
