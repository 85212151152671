import Collection from '@arcgis/core/core/Collection';
import Geometry from '@arcgis/core/geometry/Geometry';
import Point from '@arcgis/core/geometry/Point';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Search from '@arcgis/core/widgets/Search';
import LayerSearchSource from '@arcgis/core/widgets/Search/LayerSearchSource';

import { ACTIVE_VOYAGE_LAYER_ID } from './ActiveVoyageLayer';
import { SearchType } from './constants';
import { searchCallout } from './searchCallout';
import { VesselLayer } from './vesselLayer';
import { getNormalizedScreenPosition } from './calculations/geometryAnalysis';
import { MapViewType } from 'shared/models/mapView.context.model';

let shape: Geometry;
function getSearchId(searchType: SearchType, goToParams: any) {
  const {
    target: {
      target: {
        attributes: { VesselImo, UNLOCODE },
      },
    },
  } = goToParams;

  if (searchType === SearchType.vessel) return VesselImo;

  return UNLOCODE;
}

async function searchWidgetFunction(
  view: MapViewType,
  goToParams: any,
  setSearchInfo: Function
) {
  if (!view) return;
  const {
    target: {
      target: {
        attributes: { geometry },
        layer: { id: layerId },
      },
    },
  } = goToParams;
  const mapPoint = new Point({
    y: geometry.Latitude,
    x: geometry.Longitude,
  });

  const screenPosition = getNormalizedScreenPosition(view, mapPoint);
  const target = view.toMap(screenPosition);

  let targetZoom = 4;
  const originZoom = 2;
  const currentZoom = view.zoom;
  const options = {
    duration: 1250,
  };

  resetSearchWidget(view);
  const searchType =
    layerId === VesselLayer.VESSEL_LAYER_ID
      ? SearchType.vessel
      : SearchType.port;

  const searchId = getSearchId(searchType, goToParams);

  setSearchInfo({
    searchType: searchType,
    searchId: searchId,
  });

  const targetOnScreen = view.extent.intersects(target);
  if (searchType === SearchType.port) {
    const portArea = geometryEngine.geodesicArea(
      goToParams.target.target.geometry,
      'square-kilometers'
    );
    targetZoom = portArea > 1000 ? 6 : 8;
  }

  if (!targetOnScreen && currentZoom >= originZoom) {
    await view.goTo({ zoom: originZoom }, options);
  }

  await view.goTo({ target: target }, options);

  if (view.zoom !== targetZoom) await view.goTo({ zoom: targetZoom }, options);

  shape =
    searchType === SearchType.vessel
      ? target
      : goToParams.target.target.geometry;
  return view;
}

function resetSearchWidget(view: MapViewType) {
  searchCallout.resetCallout(view);
  const activeVoyageLayer = view.map?.findLayerById(
    ACTIVE_VOYAGE_LAYER_ID
  ) as GraphicsLayer;
  if (activeVoyageLayer) {
    activeVoyageLayer.visible = false;
    activeVoyageLayer.listMode = 'hide';
  }
}

export class SearchWidget {
  static SEARCH_WIDGET_ID = 'vesselSearchWidget';
  static view: MapViewType;
  static lastSearchId: string | null = null;

  static SourceExist = (view: MapViewType, layerId: string) => {
    const searchWidget: any = view.ui.find(SearchWidget.SEARCH_WIDGET_ID);
    const sourcesCollection =
      searchWidget.sources as Collection<LayerSearchSource>;
    const datasources = sourcesCollection.toArray();
    return datasources.find((x) => x.layer.id === layerId);
  };

  static search = (
    searchTerm: string,
    toggleView = false,
    searchId: string | null = null
  ) => {
    const searchWidgetExist: any = SearchWidget.view.ui.find(
      SearchWidget.SEARCH_WIDGET_ID
    );
    if (!searchWidgetExist) return;

    // If we want to toggle the search widget and the last search ID is the same as the current search ID
    if (toggleView && SearchWidget.lastSearchId === searchId) {
      SearchWidget.clearSearchAndResetWidget();
    } else {
      searchWidgetExist.search(searchTerm);
      SearchWidget.updateLastSearchId(searchId);
    }
  };

  static updateLastSearchId = (searchId: string | null) => {
    SearchWidget.lastSearchId = searchId; // Update the last searched ID
  };

  static clearSearchAndResetWidget = () => {
    const searchWidgetExist: any = SearchWidget.view.ui.find(
      SearchWidget.SEARCH_WIDGET_ID
    );

    if (!searchWidgetExist) return;

    // Programmatically clear the search widget
    searchWidgetExist.clear();

    // Reset the widget search info
    resetSearchWidget(SearchWidget.view);
    SearchWidget.updateLastSearchId(null);
  };

  static refreshCallout = (view: MapViewType) => {
    searchCallout.updateCallout(view, shape);
  };

  static hideCallout = (view: MapViewType) => {
    searchCallout.hideCallout(view);
  };

  static CreateWidget = (
    view: MapViewType,
    visible: boolean,
    setSearchInfo: Function
  ) => {
    const searchWidgetExist: any = view.ui.find(SearchWidget.SEARCH_WIDGET_ID);
    if (searchWidgetExist) return;

    SearchWidget.view = view;
    const searchWidget = new Search({
      id: SearchWidget.SEARCH_WIDGET_ID,
      view: view,
      includeDefaultSources: false,
      allPlaceholder: 'Search vessels or ports',
      visible: visible,
      locationEnabled: false,
      goToOverride: (view: MapViewType, goToParams: Object) =>
        searchWidgetFunction(view, goToParams, setSearchInfo),
    });

    // Add the search widget to the top right corner of the view
    view.ui.add(searchWidget, 'top-right');

    // callout layer
    if (visible) {
      searchCallout.createCallout(view);
    }

    // search clear
    searchWidget.on('search-clear', () => {
      resetSearchWidget(view);
      setSearchInfo({ searchId: null });
    });

    return searchWidget;
  };
}
