import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { TableFilter } from './util/vessel-data-overview-filter';

import {
  VesselDataOverviewProps,
  VesselTilesProps,
} from '../vessel-data-overview';
import { DataGridTable } from 'shared/components/datagrid';

import {
  GridRowId,
  GridRowParams,
  GridRowSpacingParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { VesselDetails } from './util/vessel-details';
import { ExportableSections } from 'shared/utils/valid-sections';

import {
  FlagType,
  ReportModel,
  getColumnsDefinition,
  getColumnsDefinitionNoRender,
} from './vessel-data-comparison-table.config';
import {
  VesselDetailsTileProps,
  VesselTileMergedData,
} from 'routes/vessel-data-monitor/mappers/export-excel-data.mapper';
import { Report } from '_gql/graphql';
import { NO_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { useLocation } from 'react-router-dom';

const MergedVesselsAndSections = (vesselTiles: VesselTilesProps[]) => {
  return vesselTiles?.map((section) => {
    const updatedTiles = section?.tiles?.map((tile) => ({
      title: section.title,
      rowId: section.rowId,
      ...tile,
    }));

    return {
      tiles: updatedTiles,
    };
  });
};

type VesselDetailsTileExtendedProps = VesselDetailsTileProps & {
  title: string;
};
const validateTileRow = (
  tile: VesselDetailsTileExtendedProps,
  updatedItem: ReportModel
) => {
  let flagType: FlagType = 'None';

  if (tile.isEmission) flagType = 'Emissions';
  else if (tile.validation?.reason) flagType = 'Non-Emissions';

  const mergedTile: ReportModel = {
    ...updatedItem,
    ...{
      title: tile.title,
      fieldName: tile.fieldName ?? '',
      fieldValue: tile.fieldValue?.toString() ?? '',
      remarks: tile.validation?.reason ?? '',
      flagType: flagType,
    },
  };

  if (mergedTile.flagType === 'None') mergedTile.status = 'Passed';

  return mergedTile;
};

const MappedData = (
  data: ReportModel[],
  mergedData: VesselTileMergedData[],
  modifiedData: ReportModel[]
) => {
  data?.forEach((item) => {
    const updatedItem = { ...item };

    let hasMatch = false;
    if (mergedData) {
      mergedData.forEach((section) => {
        section.tiles?.forEach((tile) => {
          if (tile.rowId === updatedItem.id) {
            const mergedTile = validateTileRow(
              tile as VesselDetailsTileExtendedProps,
              updatedItem
            );
            modifiedData.push(mergedTile);
            hasMatch = true;
          }
        });

        if (hasMatch) {
          return;
        }
      });
    }
    if (!hasMatch) {
      const mergedTile: ReportModel = {
        ...updatedItem,
        fieldName: '',
        fieldValue: NO_VALUE_INDICATOR,
        title: '',
      };
      modifiedData.push(mergedTile);
    }
  });
};

const dictionary: { [key: string]: VesselTilesProps[] } = {};

const validateFlagType = (x: Report): FlagType => {
  if (x.emissionDataFailed && x.nonEmissionDataFailed) return 'Multiple';
  if (x.emissionDataFailed) return 'Emissions';
  if (x.nonEmissionDataFailed) return 'Non-Emissions';
  return 'None';
};

const translateData = (reportData: Report[]) =>
  reportData.map((x): ReportModel => {
    return {
      ...x,
      id: x.generatedReportId ?? '',
      status: x.isValid ? 'Passed' : 'Flagged',
      isValid: x.isValid,
      flagType: validateFlagType(x),
      title: '',
      fieldName: '',
      fieldValue: '',
      remarks: '',
    };
  });

const getCombinedData = (rowIds: GridRowId[], filteredData: Report[]) => {
  let result: VesselTilesProps[] = [];
  rowIds.forEach((rowId) => {
    const data = dictionary[rowId];
    if (data) {
      result = result.concat(data);
    }
  });

  const translatedData = translateData(filteredData);
  const updatedData = result ? MergedVesselsAndSections(result) : [];
  const mergedData: ReportModel[] = [];
  MappedData(translatedData, updatedData, mergedData);
  mergedData.forEach((x, ix) => (x.id = ix.toString())); // add unique ids
  return mergedData;
};

export const VesselDataComparisonTable: React.FC<VesselDataOverviewProps> = ({
  vesselDataOverviewFilter,
  filteredData,
  error,
  loading,
}) => {
  const apiRef = useGridApiRef();
  const [gridData, setGridData] = useState<ReportModel[]>([]);
  const translatedData = translateData(filteredData);
  const location = useLocation();

  const locationHash = location.hash.split('?')[0]?.slice(1) ?? '';
  const [selectedRowId, setSelectedRowId] = useState<string>(locationHash);

  // scroll to the row = locationHash
  useEffect(() => {
    if (translatedData?.length > 0) {
      const ix = translatedData.findIndex(
        (x) => x.generatedReportId === selectedRowId
      );

      if (ix !== -1 && apiRef?.current.scrollToIndexes)
        apiRef.current.scrollToIndexes({ rowIndex: ix, colIndex: 0 });
    }
  }, [apiRef, apiRef?.current?.state?.visibleRowsLookup]);

  useEffect(() => {
    setGridData(translatedData);
  }, [filteredData]);

  const [tilesData, setTilesData] = useState<VesselTilesProps[]>();

  useEffect(() => {
    if (tilesData) {
      setSelectedRowId('');
      const rowId = tilesData.find((x) => x.rowId)?.rowId;
      if (rowId) {
        dictionary[rowId] = tilesData;
      }

      const expandedRowIds = apiRef.current.getExpandedDetailPanels();
      const mergedData = getCombinedData(expandedRowIds, filteredData);
      setGridData(mergedData);
    }
  }, [vesselDataOverviewFilter, tilesData]);

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => (
      <VesselDetails
        rowId={row.id}
        reportId={row.generatedReportId ?? ''}
        setTilesData={setTilesData}
        tilesData={tilesData}
      />
    ),
    []
  );

  const onExpandedRowIdsChange = React.useCallback(
    (ids: GridRowId[]) => {
      const mergedData = getCombinedData(ids, filteredData);
      setGridData(mergedData);
    },
    [filteredData]
  );

  const tableContent = (
    <>
      <DataGridTable
        name={ExportableSections['VDM-Vessel Data Quality']}
        rows={gridData}
        getRowId={(row) => 'export-' + row.id}
        columns={getColumnsDefinitionNoRender()}
        visible={false}
      />

      <DataGridTable
        apiRef={apiRef}
        height={'90vh'}
        rowHeight={74}
        rows={translatedData}
        columns={getColumnsDefinition()}
        getRowClassName={() => 'rounded'}
        getDetailPanelContent={getDetailPanelContent}
        onDetailPanelExpandedRowIdsChange={onExpandedRowIdsChange}
        getDetailPanelHeight={() => 'auto'}
        getRowSpacing={getRowSpacing}
        loading={loading}
        error={error}
        columnHeaderHeight={0}
      />
    </>
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <Typography variant='h4'>Filter by:</Typography>

        <TableFilter {...vesselDataOverviewFilter} />
      </Box>
      {tableContent}
    </Box>
  );
};
