import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { GET_KPI_DATA } from '../queries/individual-vessel-performance-kpi.query';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetKpiData = (
  dateRange: DateRange | null,
  vesselImo: string
) => {
  return useQuery<Query>(GET_KPI_DATA, {
    skip: !dateRange?.getDateRangeStruct || !vesselImo,
    variables: {
      ...dateRange?.getDateRangeStruct,
      vesselImo,
    },
  });
};
