import { KpiChartItem, FleetPenaltyKpi } from '../models/fleet-kpi-chart.model';
import {
  VesselGroupingIndicators,
  VesselGroupingPenaltyIndicators,
} from '_gql/graphql';
import { UTCDate } from 'shared/utils/date-utc-helper';

export class FleetKpiChartMapper {
  public static toDomain = (
    dto: VesselGroupingIndicators[] | undefined,
    penaltyType: 'fuel' | 'co2',
    startDate: UTCDate | null,
    endDate: UTCDate | null
  ): KpiChartItem[] => {
    if (!dto) {
      return [];
    }

    const fleetPenaltyKpis: FleetPenaltyKpi[] = [];

    dto.forEach((subgroup) => {
      const indicators: VesselGroupingPenaltyIndicators[] =
        subgroup.vesselGroupingPenaltyIndicators;

      indicators?.forEach((indicator) => {
        const timestamp = indicator.timestamp ?? new UTCDate();
        const penalty =
          penaltyType === 'fuel'
            ? indicator.averageFuelPenalty
            : indicator.averageCO2Penalty;

        const fleetPenaltyKpi: FleetPenaltyKpi = {
          timestamp,
          groupId: subgroup.groupId,
          groupName: subgroup.groupName,
          penalty: Number(penalty?.toFixed(2)),
          type: penaltyType,
        };
        fleetPenaltyKpis.push(fleetPenaltyKpi);
      });
    });

    const chartItems: KpiChartItem[] = [];
    fleetPenaltyKpis.forEach((kpi) => {
      const date = kpi.timestamp.formatDMY();
      const item: KpiChartItem = {
        date,
        [kpi.groupName]: kpi.penalty,
      };

      const index = chartItems.findIndex((x) => x['date'] === date);
      if (index === -1) {
        chartItems.push(item);
      } else {
        chartItems[index] = {
          ...chartItems[index],
          [kpi.groupName]: kpi.penalty,
        };
      }
    });

    const sorted = chartItems.sort((a, b) => {
      return (
        new UTCDate(a['date'])?.unixTime! - new UTCDate(b['date'])?.unixTime!
      );
    });

    const appended = appendDateRangeItems(sorted, startDate, endDate);
    return appended;
  };
}

const appendDateRangeItems = (
  items: KpiChartItem[],
  startDate: UTCDate | null,
  endDate: UTCDate | null
) => {
  if (!startDate || !endDate) {
    return items;
  }

  const defaultItem: KpiChartItem = {
    date: new UTCDate().formatDMY(),
  };
  const first = items[0] ?? defaultItem;
  const last = items[items.length - 1] ?? defaultItem;
  const firstItemTimestamp = new UTCDate(first['date']).unixTime;
  const lastItemTimestamp = new UTCDate(last['date']).unixTime;
  const startDateTimestamp = startDate.unixTime;
  const endDateTimestamp = endDate.unixTime;

  if (firstItemTimestamp !== startDateTimestamp) {
    items.unshift({
      date: startDate.formatDMY(),
    });
  }

  if (lastItemTimestamp !== endDateTimestamp) {
    items.push({
      date: endDate.formatDMY(),
    });
  }

  return items;
};
