import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { theme } from 'styles/theme';
import { CiiBoundaries } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import { generateReferenceAreaData } from 'routes/environmental-monitor/view-models/cii-ratings-chart.viewModel';

export const ColorBarGradientArea = (props: {
  ciiBoundaries?: CiiBoundaries | undefined;
  dataMin: number;
  dataMax: number;
  chartMax: number;
  dataTogglesDisplayCount: number;
}) => {
  const ciiBoundaries = props.ciiBoundaries;

  if (!ciiBoundaries) {
    return null;
  }

  const ciiBoundariesChartData = generateReferenceAreaData(
    ciiBoundaries,
    props.dataMax
  );

  const colors = [
    theme.colors?.aRating,
    theme.colors?.bRating,
    theme.colors?.cRating,
    theme.colors?.dRating,
    theme.colors?.eRating,
  ];
  return (
    <ResponsiveContainer width='99%' height={415}>
      <AreaChart
        data={ciiBoundariesChartData}
        margin={{
          top: 20,
          bottom: 60,
          right: 25 + 60 * props.dataTogglesDisplayCount,
          left: 50,
        }}
      >
        <XAxis
          dataKey={'position'}
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='maxValue'
          tickLine={false}
          tick={false}
          tickCount={8}
          tickMargin={0}
          type='number'
          domain={[props.dataMin, props.chartMax]}
          axisLine={false}
          dx={-12}
          width={75}
        />
        {colors.map((fill, index) => {
          return (
            <svg key={index}>
              <defs>
                return (
                <linearGradient
                  key={fill}
                  id={'grad-' + fill}
                  x1='0%'
                  y1='0%'
                  x2='100%'
                  y2='0%'
                >
                  <stop
                    offset='0%'
                    style={{ stopColor: fill, stopOpacity: 0.5 }}
                  />
                  <stop
                    offset='33%'
                    style={{
                      stopColor: theme.background.page,
                      stopOpacity: 0,
                    }}
                  />
                  <stop
                    offset='66%'
                    style={{
                      stopColor: theme.background.page,
                      stopOpacity: 0,
                    }}
                  />
                  <stop
                    offset='100%'
                    style={{ stopColor: fill, stopOpacity: 0.5 }}
                  />
                </linearGradient>
                );
              </defs>
            </svg>
          );
        })}

        <Area
          type='monotone'
          dataKey='aValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[0] + ')'}
        />
        <Area
          type='monotone'
          dataKey='bValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[1] + ')'}
        />
        <Area
          type='monotone'
          dataKey='cValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[2] + ')'}
        />
        <Area
          type='monotone'
          dataKey='dValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[3] + ')'}
        />
        <Area
          type='monotone'
          dataKey='eValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[4] + ')'}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
