import { DateRange } from 'shared/utils/date-utc-helper';
import { AlertMapper } from '../mappers/alert.mapper';
import {
  useGetAlertData,
  useUpdateAlertData,
  useGetAlertCountData,
  useGetAlertChartData,
  useUpdateAlertsData,
} from '../repositories/alerts.repository';
import { GridSortDirection } from '@mui/x-data-grid-pro';

export const useUpdateAlert = () => {
  return useUpdateAlertData();
};

export const useUpdateAlerts = () => {
  return useUpdateAlertsData();
};

export const useGetAlertCharts = (
  vesselImoNumbers: string[] | undefined,
  dateRange: DateRange
) => {
  const response = useGetAlertChartData(vesselImoNumbers, dateRange);
  const loading = response?.loading;
  const error = response?.error;
  const data = {
    total: response?.data?.fleetAlerts?.total ?? 0,
    alerts: response?.data?.fleetAlerts?.alerts
      // remove falsey items
      ?.filter((alert) => alert)
      .map((alert) => AlertMapper.toDomain(alert)),
  };

  return { loading, error, data };
};

export const useGetAlerts = ({
  vesselImoNumbers,
  dateRange,
  pageSize,
  pageNumber,
  orderBy,
  sortDirection,
  activeAlertsOnly,
  containsQueryTerm,
}: {
  vesselImoNumbers: string[] | undefined;
  dateRange: DateRange;
  pageSize?: number;
  pageNumber?: number;
  orderBy?: string;
  sortDirection?: GridSortDirection;
  activeAlertsOnly?: boolean;
  containsQueryTerm?: string;
}) => {
  const response = useGetAlertData(
    vesselImoNumbers,
    dateRange,
    pageSize,
    pageNumber,
    orderBy,
    sortDirection,
    activeAlertsOnly,
    containsQueryTerm
  );
  const loading = response?.loading;
  const error = response?.error;
  const data = {
    total: response?.data?.fleetAlerts?.total ?? 0,
    alerts: response?.data?.fleetAlerts?.alerts
      // remove falsey items
      ?.filter((alert) => alert)
      .map((alert) => AlertMapper.toDomain(alert)),
  };

  return { loading, error, data };
};

export const useGetAlertCount = (params: {
  dateRange: DateRange;
  vesselImoNumbers?: string[] | undefined;
  alertStatuses?: string[] | undefined;
  isArchived?: boolean | undefined;
  isRead?: boolean | undefined;
}) => {
  const { dateRange, vesselImoNumbers, alertStatuses, isArchived, isRead } =
    params;
  const response = useGetAlertCountData({
    dateRange,
    vesselImoNumbers,
    alertStatuses,
    isArchived,
    isRead,
  });
  const loading = response?.loading;
  const error = response?.error;
  const data = response?.data?.fleetAlerts?.total;
  return {
    loading,
    error,
    data,
    refetch: response.refetch,
    networkStatus: response.networkStatus,
  };
};
