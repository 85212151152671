import { fleetPerformanceTable } from '../mappers/vessel-performance.mapper';
import { useGetVesselPerformanceData } from '../repositories/vessel-performance.respository';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetVesselPerformance = (
  dateRange: DateRange | null,
  vesselImos: string[]
) => {
  const getVesselPerformanceResponse = useGetVesselPerformanceData(
    dateRange,
    vesselImos
  );

  const loading = getVesselPerformanceResponse?.loading;
  const error = getVesselPerformanceResponse?.error;
  const data = fleetPerformanceTable(
    getVesselPerformanceResponse?.data?.fleetPerformanceIndicators
      ?.vesselPerformanceStatistics
  );

  return { loading, error, data };
};
