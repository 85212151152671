import { useQuery } from '@apollo/client';
import { GET_REPORT_ISSUES } from '../queries/download-report.query';
import { Query } from '_gql/graphql';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetReportIssues = (
  vesselImo: string | undefined,
  dateRange: DateRange | null | undefined
) => {
  const dateRangeAsString = dateRange?.dateRangeAsString;
  const query = useQuery<Query>(GET_REPORT_ISSUES, {
    skip: !(vesselImo && dateRangeAsString),
    variables: {
      vesselImo,
      ...dateRangeAsString,
    },
  });
  return query;
};
