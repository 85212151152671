import { Box } from '@mui/material';
import { ChartLayer } from './chart.layer.component';

export const ChartLayerContainer = (props: {
  height: number | string;
  children: JSX.Element[];
}): JSX.Element => {
  return (
    <Box sx={{ position: 'relative', width: '100%', height: props.height }}>
      {props.children.map((child, index) => {
        return (
          <ChartLayer key={index} zIndex={index}>
            {child}
          </ChartLayer>
        );
      })}
    </Box>
  );
};
