import { ReactElement } from 'react';
import { PermissionModule } from 'shared/models/user.model';
import { useGetFeatureFlag } from 'shared/services/featureFlag.service';

export interface RestrictedModuleProps {
  requiredModule: PermissionModule | null;
  childComponent: ReactElement;
}

interface RestrictedModuleWithModuleProps extends RestrictedModuleProps {
  requiredModule: PermissionModule;
}

const RestrictedModuleWithModule = (props: RestrictedModuleWithModuleProps) => {
  const { data } = useGetFeatureFlag(props.requiredModule);
  if (data?.isEnabled) return <>{props.childComponent}</>;
  return <></>;
};

export const RestrictedModule = (props: RestrictedModuleProps) => {
  if (!props.requiredModule) return <>{props.childComponent}</>;
  return (
    <RestrictedModuleWithModule
      requiredModule={props.requiredModule}
      childComponent={props.childComponent}
    />
  );
};
