import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { SxProps } from '@mui/system';
import React from 'react';

export interface TooltipProps {
  tooltipText: React.ReactNode;
  CSSProps?: SxProps;
}

export const MDFTooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  return (
    <Tooltip title={props.tooltipText} placement='top' arrow>
      <IconButton
        disableRipple
        data-testid='icon-button'
        sx={{ ...props.CSSProps, paddingY: 0 }}
      >
        <InfoOutlinedIcon sx={{ fontSize: '16px' }} color='action' />
      </IconButton>
    </Tooltip>
  );
};
