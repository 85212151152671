import { gql } from '@apollo/client';

export const GET_FLEET_KPIS_FOR_DASHBOARD_TABLE = gql`
  query GetFleetKpisForDashboardTable(
    $startDate: DateTime!
    $endDate: DateTime!
    $vesselImos: [String!]
  ) {
    fleet(
      vesselImos: $vesselImos
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      id
      dashboardVessels {
        id
        vesselId
        name
        imoNumber
        sisterClass
        kpis {
          cii {
            status
            ciiRating
          }
          eeoi {
            deviation
            status
          }
          euaAgainstPlanned {
            value
            status
            vesselEuaPlannedValue
          }
        }
        performanceIndicators {
          mesfoc {
            value {
              sfocDeviationPercent
              status
            }
          }
          deltaPower {
            value {
              deltaPower
              status
            }
          }
          deltaSpeed {
            value {
              deltaSpeed
              status
            }
          }
          foulingResistance {
            value {
              rahFoulingPercent
              status
            }
          }
        }
        reports {
          overallPassScorePercentage
          overallFailScore
          status
        }
      }
    }
  }
`;
