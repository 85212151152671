import { convertToEventTypeEnum } from 'shared/utils/convert-event-type-enum.utils';
import { formatNumber } from 'shared/utils/float-utils';
import { IEvent } from '../components/events/models/events.model';
import { showDashesIfEmpty } from 'shared/utils/display-utils';

export const formatEventData = (events: IEvent[]) => {
  return events.map((event) => {
    event.daysToRecover = Math.round(Number(event.daysToRecover)).toString();
    event.eventType = convertToEventTypeEnum(event.eventType)?.toString();
    event.dailyFuelSavingsMT = formatNumber(event.dailyFuelSavingsMT, 2);
    event.Remarks = `Port & Other Costs ($): ${formatNumber(
      event.portAndOtherCosts,
      2
    )}\nDiver Cost ($): ${formatNumber(
      event.diverCost,
      2
    )}\nTotal OOS Period (Days): ${showDashesIfEmpty(
      event.totalOOSPeriod
    )}\nComments: ${showDashesIfEmpty(event.comments)}`;

    return event;
  });
};
