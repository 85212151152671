import { styled } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

interface ButtonPropsExtended extends ButtonProps {
  open?: boolean;
}
export const SideNavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ButtonPropsExtended>(({ theme, open }) => ({
  color: theme.palette.text.secondary,
  flexDirection: 'column',
  background: theme.background.page,
  fontWeight: 300,
  fontSize: '10px',
  width: '64px',
  height: '56px',
  borderRadius: '8px 0 0 8px',
  textTransform: 'none',
  minWidth: '56px',
  padding: '8px 12px',
  ...(open && {
    width: '64px',
    padding: '8px 12px',
  }),
  ...(!open && {
    width: '56px',
    padding: '8px',
  }),
  '& .MuiButton-startIcon': {
    margin: '0',
    '& .MuiSvgIcon-root': {
      fontSize: '24px',
    },
  },
}));
