import { createContext } from 'react';
import MapView from '@arcgis/core/views/MapView';
import { SearchType } from 'shared/components/esri-map/utils/constants';
import SceneView from '@arcgis/core/views/SceneView';

export type MapViewType = MapView | SceneView;

export interface MapViewContextType {
  view: MapViewType | null;
  setView: Function;
  searchInfo: {
    searchId: string | undefined;
    searchType: SearchType | undefined;
  };
  setSearchInfo: Function;
}

export const defaultContext: MapViewContextType = {
  view: null,
  setView: () => {},
  searchInfo: {
    searchId: undefined,
    searchType: undefined,
  },
  setSearchInfo: () => {},
};

export const MapViewContext = createContext<MapViewContextType>(defaultContext);
