import { UTCDate } from './date-utc-helper';

export function ConvertDDToDMS(D: number, lng = true) {
  const dir = D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N';
  const deg = 0 | (D < 0 ? (D = -D) : D);
  const min = 0 | (((D += 1e-9) % 1) * 60);
  const sec = (0 | (((D * 60) % 1) * 6000)) / 100;

  return `${deg}° ${min} ${sec}${dir}`;
}

export function ConvertLatToDMS(lat: number) {
  return ConvertDDToDMS(lat, false);
}

export function ConvertLongToDMS(long: number) {
  return ConvertDDToDMS(long, true);
}

export function CalculateTimeDiff(startDate: string, endDate?: string) {
  const timeDiff =
    (endDate ? new UTCDate(endDate) : new UTCDate()).unixTime! -
    new UTCDate(startDate).unixTime!;

  const minuteInSeconds = 60;
  const hourInSeconds = minuteInSeconds * 60;
  const dayInSeconds = hourInSeconds * 24;

  const components: [string, number][] = [
    ['Day', Math.floor(timeDiff / dayInSeconds)],
    ['Hour', Math.floor((timeDiff % dayInSeconds) / hourInSeconds)],
    [
      'Minute',
      Math.round(((timeDiff % dayInSeconds) % hourInSeconds) / minuteInSeconds),
    ],
  ];

  const result = components
    .filter(([_, value]) => value > 0)
    .map(([unit, value]) => `${value} ${unit}${value !== 1 ? 's' : ''}`)
    .join(' ');

  return result;
}
