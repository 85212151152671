export type Maybe<T> = T | null;

export const exportPDF = (
  base64Data: Maybe<string> | undefined,
  filename: Maybe<string> | undefined
) => {
  if (!base64Data || !filename) {
    console.error('base64Data or filename is undefined.');
    return;
  }
  const fileBlob = new Blob([base64ToUint8Array(base64Data)], {
    type: 'application/pdf',
  });
  const objectURL = URL.createObjectURL(fileBlob);
  const exportLinkElement = document.createElement('a');
  exportLinkElement.href = objectURL;
  exportLinkElement.download = `${filename}.pdf`;
  document.body.appendChild(exportLinkElement);
  exportLinkElement.click();
  URL.revokeObjectURL(objectURL);
  exportLinkElement.remove();
};
const base64ToUint8Array = (string: string) => {
  const raw = atob(string);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));
  for (let i = 0; i < rawLength; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};
