import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { GET_USER } from '../queries/user.query';

export function useGetUserData() {
  try {
    return useQuery<Query>(GET_USER);
  } catch (error) {
    console.log(error);
    return null;
  }
}
