import ArchivedIcon from 'assets/status-archived-icon.svg';
import EmptyStateIcon from 'assets/status-empty-state-icon.svg';
import ErrorIcon from 'assets/status-error-icon.svg';
import GraphIcon from 'assets/status-graph-icon.svg';
import SuccessIcon from 'assets/status-success-icon.svg';
import WarningIcon from 'assets/status-warning-icon.svg';
import { Status } from 'shared/models/status.type';
import { theme } from 'styles/theme';
import { footerType } from './status-kpi-card.component';

export interface StatusKpiCardValues {
  color: string;
  icon?: string;
}

// TODO: remove or refactor this function
// looks like it's was created to handle a specific case, it more like a patch
export const getStatus = (
  footerType: footerType,
  status: Status | undefined
): Status => {
  if (footerType === 'default-none') {
    return 'graph';
  }

  return status ?? 'success';
};

export const getStatusValues = (status: Status): StatusKpiCardValues => {
  switch (status) {
    case 'success':
      return { color: theme.palette.success.main, icon: SuccessIcon };
    case 'warning':
      return { color: theme.palette.warning.main, icon: WarningIcon };
    case 'error':
      return { color: theme.palette.error.main, icon: ErrorIcon };
    case 'primary':
      return { color: theme.colors.offWhite, icon: ArchivedIcon };
    case 'graph':
      return { color: theme.palette.primary.main, icon: GraphIcon };
    default:
      return { color: theme.palette.divider, icon: EmptyStateIcon };
  }
};
