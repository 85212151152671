import { Box, Tab, Tabs, ThemeProvider } from '@mui/material';
import { ProjectionInput } from '_gql/graphql';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useCiiProjectionsForm } from 'routes/environmental-monitor/forms/cii-projections.form';
import {
  CiiDataToggles,
  CiiDataTogglesDisplay,
  ProjectionConditionsDomain,
} from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import TabPanel from 'shared/components/tab-panel/tab-panel.component';
import { theme } from 'styles/theme';
import CIIExploreData from './cii-explore-data.component';
import CIITrendForecast from './cii-trend-forecast.component';

function tabProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface ITabsSectionProps {
  ciiDataToggles: CiiDataToggles | undefined;
  ciiDataTogglesDisplay: CiiDataTogglesDisplay | undefined;
  projectionConditions: ProjectionConditionsDomain | undefined;
  onUpdate: (input: ProjectionInput) => void;
  onToggleClick: (key: string, value: boolean) => void;
  isSubmitting: boolean;
  updatedConditions: ProjectionConditionsDomain | undefined;
}

function TabsSection(props: ITabsSectionProps) {
  const [value, setValue] = useState(0);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleToggleClick = (key: string, value: boolean) => {
    props.onToggleClick(key, value);
  };

  const projectionForm = useCiiProjectionsForm(props.projectionConditions);

  useEffect(() => {
    if (props.updatedConditions) {
      projectionForm.onUpdate(props.updatedConditions);
    }
  }, [props.updatedConditions]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='Explore Data' {...tabProps(0)} />
          <Tab label='Forecast CII' {...tabProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <CIIExploreData
            ciiDataToggles={props.ciiDataToggles}
            ciiDataTogglesDisplay={props.ciiDataTogglesDisplay}
            onClick={handleToggleClick}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CIITrendForecast
            conditions={props.projectionConditions}
            form={projectionForm.form}
            onUpdate={props.onUpdate}
            isSubmitting={props.isSubmitting}
          />
        </TabPanel>
      </Box>
    </ThemeProvider>
  );
}

export default TabsSection;
