import { useQuery } from '@apollo/client';
import { Query } from 'express-serve-static-core';
import {
  GET_FLEET_EEOI_CHART_DATA,
  GET_FLEET_KPIS,
  GET_FLEET_CII_MONTHLY_RATINGS,
  GET_FLEET_UTILIZATION,
  GET_FLEET_FUELEU_GHG_INTENSITY_CHART_DATA,
} from '../queries/fleet.query';
import { DateRange } from 'shared/utils/date-utc-helper';

export function useGetFleetKpiData(
  dateRange: DateRange | null,
  vesselImos: string[] = []
) {
  return useQuery<Query>(GET_FLEET_KPIS, {
    skip: !dateRange?.dateRangeAsString,
    variables: {
      ...dateRange?.dateRangeAsString,
      vesselImos: vesselImos,
    },
  });
}

export const useGetFleetEeoiChartData = (
  dateRange: DateRange | null,
  vesselImos: string[] = []
) => {
  return useQuery<Query>(GET_FLEET_EEOI_CHART_DATA, {
    variables: {
      ...dateRange?.dateRangeAsString,
      vesselImos: vesselImos,
    },
  });
};

export function useGetFleetCiiMonthlyRatings(
  dateRange: DateRange | null,
  vesselImos: string[] = []
) {
  return useQuery<Query>(GET_FLEET_CII_MONTHLY_RATINGS, {
    skip: !dateRange?.dateRangeAsString,
    variables: {
      ...dateRange?.dateRangeAsString,
      vesselImos: vesselImos,
    },
  });
}

export const useGetFleetUtilizationData = (
  dateRange: DateRange | null,
  vesselImos?: string[]
) => {
  return useQuery<Query>(GET_FLEET_UTILIZATION, {
    skip: !dateRange?.dateRangeAsString,
    variables: {
      ...dateRange?.dateRangeAsString,
      vesselImos: vesselImos,
    },
  });
};

export const useGetFleetFuelEuGhgIntensityChartData = (
  dateRange: DateRange | null,
  vesselImos: string[] = []
) => {
  return useQuery<Query>(GET_FLEET_FUELEU_GHG_INTENSITY_CHART_DATA, {
    skip: !dateRange?.dateRangeAsString,
    variables: {
      ...dateRange?.dateRangeAsString,
      vesselImos: vesselImos,
    },
  });
};