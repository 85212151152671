import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { VesselDetailsGroup } from '../../vessel-detail-group/vessel-details-group';
import { useVesselReportTile } from 'routes/vessel-data-monitor/services/vessel-report-tile.get.service';
import { useParams } from 'react-router-dom';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { VesselTilesProps } from '../../vessel-data-overview';
import { Maybe } from '_gql/graphql';
import { DateRange } from 'shared/utils/date-utc-helper';

export type DetailsProps = {
  reportId: string;
  setTilesData: (data: VesselTilesProps[]) => void;
  tilesData: VesselTilesProps[] | undefined;
  rowId: Maybe<string> | undefined;
};

export const VesselDetails: React.FC<DetailsProps> = ({
  reportId,
  setTilesData,
  tilesData,
  rowId,
}) => {
  const { utcDateRange } = useContext(NavBarContext);
  const { id: vesselIMO } = useParams();

  const {
    generalTileData,
    speedPerformanceData,
    seaStateTileData,
    voyageInformationData,
    weatherConditionTileData,
    fuelConsumptionTileData,
    loading,
    error,
  } = useVesselReportTile(vesselIMO, reportId, utcDateRange ?? new DateRange());
  useEffect(() => {
    if (!loading) {
      // Check if the rowId already exists in tilesData
      const existingIndex = (tilesData || []).findIndex(
        (tile) => tile.rowId === rowId
      );

      if (existingIndex === -1) {
        // If rowId doesn't exist, add the new data
        const vesselTiles: VesselTilesProps[] = [
          {
            title: 'General',
            tiles: generalTileData,
            rowId: rowId,
          },
          {
            title: 'Speed and Performance',
            tiles: speedPerformanceData,
            rowId: rowId,
          },
          {
            title: 'Voyage Information',
            tiles: voyageInformationData,
            rowId: rowId,
          },
          {
            title: 'Sea State',
            tiles: seaStateTileData,
            rowId: rowId,
          },
          {
            title: 'Weather Condition',
            tiles: weatherConditionTileData,
            rowId: rowId,
          },
          {
            title: 'Fuel Consumption',
            tiles: fuelConsumptionTileData,
            rowId: rowId,
          },
        ];

        setTilesData([...(tilesData || []), ...vesselTiles]);
      }
    }
  }, [loading]);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          columnGap: '24px',
          marginTop: '12px',
          alignItems: 'baseline',
        }}
      >
        <VesselDetailsGroup
          title={'General'}
          tiles={generalTileData}
          error={error}
          loading={loading}
        />
        <VesselDetailsGroup
          title={'Voyage Information'}
          tiles={voyageInformationData}
          error={error}
          loading={loading}
        />
        <VesselDetailsGroup
          title={'Speed and Performance'}
          tiles={speedPerformanceData}
          error={error}
          loading={loading}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          columnGap: '24px',
          alignItems: 'baseline',
          marginTop: '12px',
        }}
      >
        <VesselDetailsGroup
          title={'Weather Condition'}
          tiles={weatherConditionTileData}
          error={error}
          loading={loading}
        />
        <VesselDetailsGroup
          title={'Sea State'}
          tiles={seaStateTileData}
          error={error}
          loading={loading}
        />
        <VesselDetailsGroup
          title={'Fuel Consumption'}
          tiles={fuelConsumptionTileData}
          error={error}
          loading={loading}
        />
      </Box>
    </>
  );
};
