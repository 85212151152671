import { Box } from '@mui/material';

export const ChartLayer = (props: {
  zIndex: number;
  children: JSX.Element;
}) => {
  return (
    <Box
      data-testid={'chart-layer-' + props.zIndex.toString()}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: props.zIndex,
        px: 1,
      }}
    >
      {props.children}
    </Box>
  );
};
