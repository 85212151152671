import { Box, CssBaseline } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { InteractionStatus } from '@azure/msal-browser';
import { AppContext } from 'shared/models/app.context.model';
import { theme } from 'styles/theme';
import logo from '../../logo.png';
import { SignInButton } from './components/SignInButton';
import { loginRequest } from 'auth/config';
import { useMsal } from '@azure/msal-react';

export function Login() {
  const isAuthenticated = useContext(AppContext).isAuthenticated;
  const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      navigate(ROUTES.dashboard.path);
    } else {
      if (inProgress !== InteractionStatus.Logout) handleLogin();
    }
  }, [inProgress, isAuthenticated]);

  const handleLogin = () => {
    if (inProgress !== InteractionStatus.None || accounts.length > 0) {
      return;
    }

    const loginPromise = instance.loginRedirect(loginRequest);
    loginPromise
      .then(() => {
        console.info('msal: loginRedirect success');
      })
      .catch((e: any) => {
        console.error('msal: loginRedirect failure', e);
      });
  };

  return (
    <>
      <CssBaseline />
      <Box
        data-testid={'login-route'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.background.page,
          color: theme.palette.secondary.contrastText,
        }}
      >
        <Box component='img' alt='ABS Logo' src={logo} />
        <SignInButton />
      </Box>
    </>
  );
}
