import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ContextWrapper } from './shared/components/contexts/contextWrapper.component';
import { reportWebVitals } from './reportWebVitals';
import { router } from './router/router';
import { ThemeProvider } from '@mui/material/styles';
import { MsalProvider } from '@azure/msal-react';
import { theme } from './styles/theme';
import { msalInstance } from 'auth';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { NavBarWrapper } from 'shared/components/contexts/navBarWrapper.component';
import { ApolloWrapper } from 'auth/components/apollo-wrapper/apollo-wrapper.component';
import { TopNavWrapper } from 'shared/components/contexts/topNavWrapper.component';
import { ExportExcelWrapper } from 'shared/components/contexts/exportExcelWrapper.component';
import { FeatureToggleProvider } from 'shared/components/contexts/featureToggleProvider.component';
const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

(async function () {
  window.loadAptrinsic(process.env['REACT_APP_APTRINSIC_API_KEY'] ?? '');

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <ApolloWrapper>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <NavBarWrapper>
                <ContextWrapper>
                  <TopNavWrapper>
                    <ExportExcelWrapper>
                      <FeatureToggleProvider>
                        <RouterProvider router={router} />
                      </FeatureToggleProvider>
                    </ExportExcelWrapper>
                  </TopNavWrapper>
                </ContextWrapper>
              </NavBarWrapper>
            </LocalizationProvider>
          </ThemeProvider>
        </ApolloWrapper>
      </MsalProvider>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
