import Graphic from '@arcgis/core/Graphic';
import Geometry from '@arcgis/core/geometry/Geometry';
import Point from '@arcgis/core/geometry/Point';
import Polyline from '@arcgis/core/geometry/Polyline';
import * as projection from '@arcgis/core/geometry/projection';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { SearchWidget } from './searchWidget';
import {
  getNormalizedScreenPosition,
  sr4326,
} from './calculations/geometryAnalysis';
import { calloutLine } from './symbols';
import { MapViewType } from 'shared/models/mapView.context.model';

let layerVisible = false;

// project geometry to readable lat & long coordinates
function getLatLongExtent(geometry: Geometry) {
  // same spatial reference
  if (geometry.spatialReference.wkid === sr4326.wkid) return geometry.extent;

  const newGeometry = projection.project(geometry, sr4326) as Geometry;
  return newGeometry?.extent;
}

// return Lat Long from geometry:
// from polygon: return extent (square boundaries)
// from point: return the point location
function getTargetLatLongPosition(shape: Geometry) {
  if (!shape.extent) return shape.clone() as Point;

  const LatLongExtent = getLatLongExtent(shape);
  return new Point({ x: LatLongExtent.xmax, y: LatLongExtent.ymax });
}

export class searchCallout {
  static SEARCH_CALLOUT_ID = 'vesselSearchCallout';

  static createCallout(view: MapViewType) {
    if (view.type !== '2d') return;
    const calloutLayer = new GraphicsLayer({
      id: searchCallout.SEARCH_CALLOUT_ID,
      title: 'Callout',
      listMode: 'hide',
    });
    view.map.layers.add(calloutLayer, 2);
  }

  static hideCallout(view: MapViewType, removeGraphis = false) {
    const findCalloutLayer = view.map?.findLayerById(
      searchCallout.SEARCH_CALLOUT_ID
    ) as GraphicsLayer;
    if (findCalloutLayer) {
      layerVisible = findCalloutLayer.visible;
      findCalloutLayer.visible = false;
      findCalloutLayer.listMode = 'hide';
      if (removeGraphis) findCalloutLayer.removeAll();
    }
  }

  static resetCallout(view: MapViewType) {
    searchCallout.hideCallout(view, true);
  }

  // update the callout position
  static updateCallout(view: MapViewType, shape: Geometry) {
    if (!shape && !layerVisible) return;

    let sx = 30;
    let sy = 0;
    let polylineGraphic: Graphic | null = null;

    const searchWidget: any = view.ui.find(SearchWidget.SEARCH_WIDGET_ID);
    if (!searchWidget.searchTerm) return; // no search term defined

    const searchWidgetRect = searchWidget.container.getBoundingClientRect();
    const viewRect = view.container.getBoundingClientRect();
    let searchWidgetX = searchWidgetRect.x - viewRect.x - sx;
    let searchWidgetY =
      searchWidgetRect.y - viewRect.y + searchWidgetRect.height / 2;

    if (searchWidgetRect.x - viewRect.x > view.width) {
      // search widget out of map bounds
      return;
    }

    const findCalloutLayer = view.map?.findLayerById(
      searchCallout.SEARCH_CALLOUT_ID
    ) as GraphicsLayer;

    if (!findCalloutLayer) return;

    const targetLatLong = getTargetLatLongPosition(shape);
    const targetScreenXY = getNormalizedScreenPosition(view, targetLatLong);
    const targetMapPosition = view.toMap({
      x: targetScreenXY.x + 8,
      y: targetScreenXY.y - 8,
    });

    if (searchWidgetX < targetScreenXY.x) {
      sx = 0;
      sy = 30;
      searchWidgetX =
        searchWidgetRect.x - viewRect.x + searchWidgetRect.width / 2;
      searchWidgetY =
        searchWidgetRect.y - viewRect.y + searchWidgetRect.height + sy;
    }

    const targetToSearch = view.toMap({ x: searchWidgetX, y: searchWidgetY });
    const lineToSearch = view.toMap({
      x: searchWidgetX + sx,
      y: searchWidgetY - sy,
    });

    if (
      targetMapPosition.longitude > targetToSearch.longitude &&
      Math.sign(targetMapPosition.longitude) !==
        Math.sign(targetToSearch.longitude)
    ) {
      targetMapPosition.longitude -= 360;
    }

    // Create a polyline
    const polyline = new Polyline({
      paths: [
        [
          [targetMapPosition.longitude, targetMapPosition.latitude], // target position
          [targetToSearch.longitude, targetToSearch.latitude], // close to search position
          [lineToSearch.longitude, lineToSearch.latitude], // search widget position
        ],
      ],
    });

    polylineGraphic = new Graphic({
      geometry: polyline,
      symbol: calloutLine,
    });

    if (findCalloutLayer) {
      findCalloutLayer.visible =
        layerVisible || findCalloutLayer.graphics.length === 0;
      findCalloutLayer.removeAll();
      if (polylineGraphic) findCalloutLayer.add(polylineGraphic);
      findCalloutLayer.listMode = 'show';
    }
  }
}
