import dayjs from 'dayjs';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';

export interface IEvent {
  id?: string;
  vesselId?: string;
  vesselImo?: string;
  eventName?: string;
  eventType?: string;
  startDate?: string;
  endDate?: string;
  dateRange: DateRange;
  location?: string;
  company?: string;
  portAndOtherCosts?: string;
  paybackPeriod?: string;
  dailyFuelSavingsDollars?: string;
  dailyFuelSavingsMT?: string;
  achievedImprovement?: string;
  diverCost?: string;
  bunkerPrice?: string;
  totalOOSPeriod?: string;
  comments?: string;
  Remarks?: string;
  daysToRecover?: string;
  totalEventCost?: string;
}

export type EventModalValues = IEvent;

export const initialValues: EventModalValues = {
  id: '',
  vesselId: '',
  eventName: '',
  eventType: '',
  startDate: '',
  dateRange: new DateRange(
    new UTCDate(dayjs().subtract(7, 'days')),
    new UTCDate(),
    true
  ),
  endDate: '',
  location: '',
  company: '',
  paybackPeriod: '',
  dailyFuelSavingsDollars: '',
  dailyFuelSavingsMT: '',
  achievedImprovement: '',
  portAndOtherCosts: '',
  bunkerPrice: '',
  diverCost: '',
  totalOOSPeriod: '',
  comments: '',
  daysToRecover: '',
  totalEventCost: '',
};
