import React, { useContext } from 'react';
import { Button, Modal, Typography, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import SvgIcon from '@mui/material/SvgIcon';
import CloseIcon from '@mui/icons-material/Close';
import { ReportIssueItem } from './report-issue-item';
import { DataValidationsFoundMessage } from './data-validations-found-message/data-validations-found-message.component';
import { NoIssuesFoundMessage } from './no-issues-found-message/no-issues-found-message.component';
import Skeleton from '@mui/material/Skeleton';
import { ErrorComponent } from 'shared/components/error/error.component';
import { ReactComponent as AlertWarningIcon } from 'assets/alert-warning-icon.svg';
import { ReactComponent as ReportsSuccessIcon } from 'assets/reports-success-icon.svg';
import { ExcelDownloadContext } from './excel-download-context';

export const ReportModalWarning = () => {
  const theme = useTheme();

  const {
    handleClose,
    showDownloadModal,
    handleProceedToDownload,
    imoNumber,
    loadingReportIssues,
    reportIssuesError,
    criticalReports,
  } = useContext(ExcelDownloadContext);

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '588px',
    bgcolor: theme.background.component,
    borderRadius: '8px',
    padding: '24px 24px 0px',
    maxHeight: '75vh',
    display: 'flex',
    flexDirection: 'column',
  };

  const scrollableTable = {
    overflowY: 'scroll',
    minHeight: '52px',
    '&::-webkit-scrollbar': { width: '4px' },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255,255,255,0.08)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '4px',
    },
  };

  if (reportIssuesError) {
    return <ErrorComponent error={reportIssuesError} />;
  }

  if (loadingReportIssues) {
    return (
      <Modal open={showDownloadModal} onClose={handleClose}>
        <Box sx={style}>
          <Skeleton height={300} animation='pulse' />
        </Box>
      </Modal>
    );
  }

  const lengthOfCriticalReports = criticalReports?.vesselReports?.length ?? 0;

  return (
    <Modal open={showDownloadModal} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '12px',
          }}
        >
          <Typography variant='h6'>Download Report</Typography>
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: '12px 0px 24px' }}>
          <Box
            sx={{
              borderColor:
                lengthOfCriticalReports > 0
                  ? theme.palette.error.main
                  : theme.palette.success.main,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderRadius: '4px',
              display: 'flex',
              px: '16px',
            }}
          >
            <Box sx={{ padding: '8px 12px 8px 0' }}>
              {lengthOfCriticalReports > 0 && (
                <SvgIcon sx={{ color: theme.palette.error.main }}>
                  <AlertWarningIcon />
                </SvgIcon>
              )}
              {lengthOfCriticalReports === 0 && (
                <SvgIcon sx={{ color: theme.palette.success.main }}>
                  <ReportsSuccessIcon />
                </SvgIcon>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                py: '8px',
              }}
            >
              {lengthOfCriticalReports > 0 && <DataValidationsFoundMessage />}
              {lengthOfCriticalReports === 0 && <NoIssuesFoundMessage />}
            </Box>
          </Box>
        </Box>
        {lengthOfCriticalReports > 0 && (
          <Box sx={scrollableTable}>
            {criticalReports?.vesselReports.map((item: any) => {
              return (
                <ReportIssueItem
                  key={item.id}
                  vesselId={imoNumber}
                  issues={item.criticalIssuesTotal ?? 0}
                  reportedDate={item.reportedDate ?? undefined}
                  generatedReportId={item.generatedReportId ?? ''}
                  reportType={item.reportType ?? ''}
                />
              );
            })}
          </Box>
        )}
        <Box
          sx={{
            padding: '24px 0px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
          }}
        >
          <SecondaryButton
            variant='outlined'
            onClick={handleClose}
            sx={{ px: '16px', borderRadius: '8px' }}
          >
            Cancel
          </SecondaryButton>

          <Button
            variant='contained'
            sx={{
              fontSize: '16px',
              borderRadius: '8px',
              textTransform: 'capitalize',
              px: '16px',
              letterSpacing: '0.016px',
            }}
            onClick={handleProceedToDownload}
          >
            Proceed to Download
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
