import { MDFMap } from 'shared/components/esri-map/map.component';
import { DrawerInsideMap } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer.component';
import { PortsLayer } from 'shared/components/esri-map/utils/PortsLayer';
import { unlocodes } from 'routes/voyage-monitor/voyage-monitor-map';
import { ActiveVoyageLayer } from 'shared/components/esri-map/utils/ActiveVoyageLayer';
import { MapDrawerComponent } from '../../components/drawer/drawer-component';
import { IVesselPosition } from 'shared/components/esri-map/utils/vesselLayer';

export interface VesselVoyageMapProps {
  VesselPosition: IVesselPosition[];
}

export function VesselVoyageMap(props: VesselVoyageMapProps) {
  return (
    <MDFMap displaysearch={true} data={props.VesselPosition}>
      <PortsLayer data={unlocodes}></PortsLayer>
      <DrawerInsideMap>
        <MapDrawerComponent></MapDrawerComponent>
      </DrawerInsideMap>
      <ActiveVoyageLayer></ActiveVoyageLayer>
    </MDFMap>
  );
}
