import { Box, Toolbar } from '@mui/material';
import { ProjectionInput } from '_gql/graphql';
import { FixedTimeline } from 'assets/fixed-timeline';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { VesselCiiRatingsDataMapper } from 'routes/environmental-monitor/mappers/vessel-cii-ratings.mapper';
import { CiiDataTogglesDisplay } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import {
  useGetVesselCii,
  useUpdatedCiiEoyProjections,
} from 'routes/environmental-monitor/services/vessel.service';
import {
  dateReferenceKeyProps,
  getChartRangeValues,
  getDomain,
  keyProps,
  loadingConditionKeyProps,
  subDataToggleSelected,
} from 'routes/environmental-monitor/view-models/cii-ratings-chart.viewModel';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import {
  getXAxisTicks,
  getYAxisTicks,
} from 'routes/environmental-monitor/view-models/utils/graph-utils';
import { ChartKey } from 'shared/components/chart-key/chart-key.component';
import { ChartLayerContainer } from 'shared/components/chart-layer-container/chart-layer-container.component';
import { CircularLoading } from 'shared/components/circular-loading/circular-loading.component';
import { ErrorComponent } from 'shared/components/error/error.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import TabsSection from './cii-chart-tabs.component';
import { ColorBarGradientArea } from './cii-ratings-color-bar-gradient-area.component';
import { LeftColorBar } from './cii-ratings-left-color-bar.component';
import { LineCharts } from './cii-ratings-line-charts.component';
import { RightColorBar } from './cii-ratings-right-color-bar.component';
import { TopLeftColorBar } from './cii-ratings-top-left-color-bar.component';
import { UTCDate, DateRange } from 'shared/utils/date-utc-helper';

export function CIIRatingsChart() {
  const { id } = useParams();
  const startDate = new UTCDate().startOfYear!;
  const endDate = new UTCDate().endOfDay!;
  const dateRange = new DateRange(startDate, endDate);
  const ciiResponse = useGetVesselCii(id, dateRange);
  const updatedCiiProjections = useUpdatedCiiEoyProjections();

  const isLoading = ciiResponse.loading;
  const ciiValues = ciiResponse.data?.dataPoints;
  const ciiBoundaries = ciiResponse.data?.boundaries;
  const ciiDataToggles = ciiResponse.data?.dataToggles;
  const projectionConditions = ciiResponse.data?.projection?.conditions;
  const updatedConditions = updatedCiiProjections.data;
  const requiredCii = ciiResponse.data?.required;

  const chartData = VesselCiiRatingsDataMapper.toChartData(ciiResponse.data);
  const ciiDataTogglesDisplay: CiiDataTogglesDisplay = {
    speed: false,
    loadingCondition: false,
    fuelOilConsumption: {
      mainEngine: false,
      boiler: false,
      auxEngine: false,
    },
    operationalProfile: false,
    vesselCondition: false,
    vesselComparison: false,
  };

  const [displayDataToggles, setDisplayDataToggles] = useState(
    ciiDataTogglesDisplay
  );

  const dataValues = ciiValues?.map((v) => v.value) ?? [];
  const chartRangeValues = getChartRangeValues(dataValues, ciiBoundaries);
  const binaryDataItems = ['loadingCondition'];

  const keysToDisplay = Object.keys(displayDataToggles).filter(
    (key) =>
      (typeof displayDataToggles[key as keyof CiiDataTogglesDisplay] ==
        'boolean' &&
        displayDataToggles[key as keyof CiiDataTogglesDisplay]) ||
      !!subDataToggleSelected(
        displayDataToggles[key as keyof CiiDataTogglesDisplay]
      )
  );
  const chartKeysToDisplay = keyProps.items.filter(
    (item) => item.key === 'ciiRating' || keysToDisplay.includes(item.key ?? '')
  );

  const shouldRenderChart = !!ciiBoundaries;

  const handleUpdate = (input: ProjectionInput) => {
    updatedCiiProjections.mutation({
      variables: {
        input,
      },
    });
  };

  const handleDataToggleChange = (key: string, value: boolean) => {
    if (!key.includes('.')) {
      setDisplayDataToggles({
        ...displayDataToggles,
        [key]: value,
      });
    } else {
      const parentKey: string = key.split('.')[0] ?? '';
      const childKey: string = key.split('.')[1] ?? '';

      setDisplayDataToggles({
        ...displayDataToggles,
        [parentKey]: {
          [childKey]: value,
        },
      });
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingComponent height='415px' />;
    }

    if (ciiResponse.error) {
      return <ErrorComponent error={ciiResponse.error} />;
    }

    if (!shouldRenderChart) {
      return <ErrorComponent error={null} />;
    }

    return (
      <>
        <Toolbar disableGutters sx={{ paddingLeft: 1.2, mt: 2 }}>
          <ChartKey items={chartKeysToDisplay} />
          {keysToDisplay.includes('loadingCondition') && (
            <ChartKey {...loadingConditionKeyProps} />
          )}
        </Toolbar>

        {new UTCDate().isBeginningOfYear() && (
          <Toolbar disableGutters sx={{ paddingLeft: 1.2, marginTop: -2 }}>
            <ChartKey {...dateReferenceKeyProps} />
          </Toolbar>
        )}

        {renderChart(updatedCiiProjections.loading)}

        <Box
          sx={{
            ml: 4,
            mr: 4,
            mb: 5,
          }}
        >
          <TabsSection
            projectionConditions={projectionConditions}
            ciiDataToggles={ciiDataToggles}
            ciiDataTogglesDisplay={displayDataToggles}
            onUpdate={handleUpdate}
            isSubmitting={updatedCiiProjections.loading}
            onToggleClick={handleDataToggleChange}
            updatedConditions={updatedConditions}
          ></TabsSection>
        </Box>
      </>
    );
  };

  const renderChart = (updating: boolean) => {
    if (updating) {
      return <CircularLoading height='415px' backgroundColor='transparent' />;
    }

    const dataTogglesDisplayCount = keysToDisplay.filter(
      (k) => !binaryDataItems.includes(k)
    ).length;
    const currentDate = new UTCDate();
    const currentTime = currentDate.unixTime!;
    const domain = getDomain();
    const xAxisTicks = getXAxisTicks();
    const yAxisTicks = getYAxisTicks(
      chartRangeValues.dataMin,
      chartRangeValues.dataMax
    );

    return (
      <ChartLayerContainer height={415}>
        <ColorBarGradientArea
          ciiBoundaries={ciiBoundaries}
          dataMin={chartRangeValues.dataMin}
          dataMax={chartRangeValues.dataMax}
          chartMax={chartRangeValues.chartMax}
          dataTogglesDisplayCount={dataTogglesDisplayCount}
        />

        {ciiBoundaries ? (
          <LeftColorBar
            ciiBoundaries={ciiBoundaries}
            dataMin={chartRangeValues.dataMin}
            dataMax={chartRangeValues.dataMax}
            chartMax={chartRangeValues.chartMax}
          />
        ) : (
          <TopLeftColorBar
            dataMin={chartRangeValues.dataMin}
            dataMax={chartRangeValues.dataMax}
            chartMax={chartRangeValues.chartMax}
          />
        )}
        <RightColorBar
          ciiBoundaries={ciiBoundaries}
          dataMin={chartRangeValues.dataMin}
          dataMax={chartRangeValues.dataMax}
          chartMax={chartRangeValues.chartMax}
          dataTogglesDisplayCount={dataTogglesDisplayCount}
        />

        <LineCharts
          chartData={chartData}
          chartRangeValues={chartRangeValues}
          displayDataToggles={displayDataToggles}
          requiredCii={requiredCii}
          ciiBoundaries={ciiBoundaries}
          currentTime={currentTime}
          chartKeysToDisplay={chartKeysToDisplay}
          keysToDisplay={keysToDisplay}
          xAxisTicks={xAxisTicks}
          yAxisTicks={yAxisTicks}
          domain={domain}
          binaryDataItems={binaryDataItems}
        />
      </ChartLayerContainer>
    );
  };

  return (
    <CardContainer
      title={vesselPageTitles.ciiRatings}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      {renderContent()}
    </CardContainer>
  );
}
