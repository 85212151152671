import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import RotationVariable from '@arcgis/core/renderers/visualVariables/RotationVariable';
import ColorVariable from '@arcgis/core/renderers/visualVariables/ColorVariable';
import { vesselIcon } from './symbols';
import { SearchWidget } from './searchWidget';
import { SearchType } from './constants';
import {
  createVesselNamesClusters,
  updateVesselNamesClusters,
} from './vesselLabelsCluster';
import { MapViewType } from 'shared/models/mapView.context.model';

export interface IVesselPosition {
  imoType: string;
  imoNumber: string;
  VesselName: string;
  Latitude: number;
  Longitude: number;
  Heading: number;
  ColorCode?: number;
}

function getGraphicsFromVesselData(data: IVesselPosition[]) {
  const graphics = data.map(function (item, ix) {
    return new Graphic({
      attributes: {
        ObjectId: ix,
        VesselImo: item.imoNumber,
        VesselName: item.VesselName,
        heading: item.Heading,
        colorcode: item.ColorCode ?? 0,
        geometry: {
          Longitude: item.Longitude,
          Latitude: item.Latitude,
        },
        type: SearchType.vessel,
        imoType: item.imoType,
      },
      geometry: new Point({
        y: item.Latitude,
        x: item.Longitude,
      }),
      symbol: vesselIcon,
    });
  });

  return graphics;
}

export class VesselLayer {
  static VESSEL_LAYER_ID = 'VesselLayerId';
  static VESSEL_LAYER_LABELS_ID = 'VesselLayerLabelsId';
  static view: MapViewType;

  static get layer() {
    if (!VesselLayer.view) return;
    const vesselLayer = VesselLayer.view.map?.findLayerById(
      VesselLayer.VESSEL_LAYER_ID
    ) as FeatureLayer;
    return vesselLayer;
  }

  static async getAllData(view: MapViewType | null) {
    if (!view) return;

    const vesselLayer = VesselLayer.layer;
    if (!vesselLayer) return [];

    const response = await vesselLayer.queryFeatures();
    return response?.features;
  }

  static async getVesselData(view: MapViewType | null, VesselImo: string) {
    if (!view) return;

    const vesselLayer = VesselLayer.layer;
    if (!vesselLayer) return;

    const query = vesselLayer.createQuery();
    query.where = `VesselImo = '${VesselImo}'`;
    query.outFields = ['*'];

    const response = await vesselLayer.queryFeatures(query);
    return response?.features.at(0);
  }

  static CreateLayer(view: MapViewType, data: IVesselPosition[]) {
    if (!data || !data.length) return;
    VesselLayer.view = view;

    const graphics = getGraphicsFromVesselData(data);

    const rotationRenderer = new SimpleRenderer({
      symbol: vesselIcon,
      visualVariables: [
        new RotationVariable({ field: 'Heading', rotationType: 'geographic' }),
        new ColorVariable({
          field: 'colorcode',
          stops: [
            { value: 0, color: '#707070' },
            { value: 1, color: '#509E2F' },
            { value: 2, color: '#FFAD0A' },
            { value: 3, color: '#DF444E' },
          ],
        }),
      ],
    });

    const vesselLayer = new FeatureLayer({
      id: VesselLayer.VESSEL_LAYER_ID,
      title: 'Vessels',
      source: graphics,
      renderer: rotationRenderer,
      popupEnabled: true,
      //labelingInfo: [labelClass],
      objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
      fields: [
        { name: 'ObjectId', alias: 'ObjectId', type: 'oid' },
        { name: 'VesselImo', alias: 'VesselImo', type: 'string' },
        { name: 'VesselName', alias: 'VesselName', type: 'string' },
        { name: 'Heading', alias: 'Heading', type: 'integer' },
        { name: 'colorcode', alias: 'colorcode', type: 'integer' },
        { name: 'geometry', alias: 'geometry', type: 'geometry' },
        { name: 'type', alias: 'type', type: 'string' },
        { name: 'imoType', alias: 'imoType', type: 'string' },
      ],
      outFields: ['VesselName', 'VesselImo'],
    });

    const findVesselLayer = VesselLayer.layer;
    if (findVesselLayer) {
      view.map.layers.remove(findVesselLayer);
    }
    view.map.layers.add(vesselLayer, 4);

    const searchWidget: any = view.ui.find(SearchWidget.SEARCH_WIDGET_ID);
    if (searchWidget) {
      if (!SearchWidget.SourceExist(view, VesselLayer.VESSEL_LAYER_ID)) {
        searchWidget.sources.add({
          layer: vesselLayer,
          searchFields: ['VesselName'],
          displayField: 'VesselName',
          exactMatch: false,
          outFields: [
            'VesselName',
            'heading',
            'geometry',
            'VesselImo',
            'type',
            'imoType',
          ],
          name: 'Vessels',
        });
      }
    }

    view.whenLayerView(vesselLayer).then(() => {
      // data has only one vessel
      if (data.length === 1) {
        searchWidget.search(data[0]?.VesselName);
      }
      createVesselNamesClusters(view);
    });
  }

  static async Update_Vessels_Position(
    view: MapViewType,
    data: IVesselPosition[]
  ) {
    if (!view) return;

    const vesselLayer = VesselLayer.layer;
    if (vesselLayer) {
      const dataImo = data.map((x) => `'${x.imoNumber}'`).join(',');
      vesselLayer.definitionExpression = `VesselImo in (${dataImo})`;

      const featureSet = await vesselLayer.queryFeatures();

      const currentGraphics = featureSet.features;
      const currentImoNumbers = currentGraphics.map(
        (x) => x.attributes.VesselImo
      );

      // update existing vessels
      currentGraphics.forEach((graphic) => {
        const VesselImo = graphic.attributes.VesselImo;
        const newLocation = data.find((x) => x.imoNumber === VesselImo);

        graphic.attributes.heading = newLocation?.Heading;
        graphic.attributes.geometry = {
          Longitude: newLocation?.Longitude,
          Latitude: newLocation?.Latitude,
        };

        graphic.geometry = new Point({
          y: newLocation?.Latitude || 0,
          x: newLocation?.Longitude || 0,
        });
      });

      // add new vessels
      const newVessels = data.filter(
        (x) => !currentImoNumbers.includes(x.imoNumber)
      );
      const newFeatures = getGraphicsFromVesselData(newVessels);

      const updateEdits = {
        addFeatures: newFeatures,
        updateFeatures: [...currentGraphics],
      };
      await vesselLayer.applyEdits(updateEdits);
      updateVesselNamesClusters(view, true);
    } else {
      this.CreateLayer(view, data);
    }
  }
}
