import { SxProps, Theme } from '@mui/system';
import { LineSeriesType } from '@mui/x-charts-pro';
import { AnimatedLine, AnimatedLineProps } from '@mui/x-charts/LineChart';
import { useChartId, useDrawingArea, useXScale } from '@mui/x-charts/hooks';

import { Fragment, useContext, useMemo } from 'react';
import { SeriesContext } from '@mui/x-charts/context/SeriesProvider/index.js';
import { FormattedSeries } from '@mui/x-charts/context/SeriesProvider/Series.types'; // Add this import
//import { useCartesianContext } from '@mui/x-charts/context/CartesianProvider';
import { isBandScale } from '@mui/x-charts/internals/isBandScale';
import { UTCDate } from 'shared/utils/date-utc-helper';

type forecastLineStyles = {
  limit?: number;
  sxBefore?: SxProps<Theme>;
  sxAfter?: SxProps<Theme>;
};
export type ForecastLineSeriesType = LineSeriesType & {
  forecast?: forecastLineStyles;
};

function LineSeries(series: FormattedSeries) {
  return useMemo(() => series.line, [series.line]);
}

export function CustomAnimatedLine(props: Readonly<AnimatedLineProps>) {
  const { top, bottom, height, left, width } = useDrawingArea();
  const { isInitialized, data } = useContext(SeriesContext);

  const scale = useXScale();
  const chartId = useChartId();

  if (!isInitialized) return null; // this is always true, but jest is not recognizing it

  const lineSeries = LineSeries(data);
  const ownerId = props.ownerState.id; // the series id coming from the rendering chart

  const series = lineSeries?.series[ownerId] as ForecastLineSeriesType;
  const forecastOptions = series?.forecast;
  const limit = forecastOptions?.limit;
  const sxAfter = forecastOptions?.sxAfter ?? { strokeDasharray: '10 5' };
  const sxBefore = forecastOptions?.sxBefore ?? {};

  if (limit === undefined) {
    return <AnimatedLine {...props} />;
  }

  let limitPosition = scale(limit); // Convert value to x coordinate.
  if (isBandScale(scale)) {
    if (scale.domain()[0] instanceof Date) {
      const bandIx = scale
        .domain()
        .findIndex((x) => limit < new Date(x).getTime());
      if (bandIx === -1) {
        return <AnimatedLine {...props} />;
      }

      const range = scale.range();
      const step = scale.step();

      const currentDate = new UTCDate(new Date(limit));
      const daysInMonth = currentDate.daysInMonth ?? 1;
      const daysToPxScale = step / daysInMonth;
      const daysToPx = daysToPxScale * currentDate.dateDayjs?.date()!;
      limitPosition = bandIx * step - step / 2 + range[0] + daysToPx;
    }
  }

  if (limitPosition === undefined) {
    return <AnimatedLine {...props} />;
  }

  const clipIdleft = `${chartId}-${props.ownerState.id}-line-limit-${limit}-1`;
  const clipIdRight = `${chartId}-${props.ownerState.id}-line-limit-${limit}-2`;
  return (
    <Fragment>
      {/* Clip to show the line before the limit */}
      <clipPath id={clipIdleft}>
        <rect
          x={left}
          y={0}
          width={Math.max(limitPosition - left, 0)}
          height={top + height + bottom}
        />
      </clipPath>
      {/* Clip to show the line after the limit */}
      <clipPath id={clipIdRight} data-testid='forecastLineId'>
        <rect
          x={limitPosition}
          y={0}
          width={Math.max(left + width - limitPosition, 0)}
          height={top + height + bottom}
        />
      </clipPath>
      <g clipPath={`url(#${clipIdleft})`}>
        <AnimatedLine {...props} sx={sxBefore} />
      </g>
      <g clipPath={`url(#${clipIdRight})`}>
        <AnimatedLine {...props} sx={sxAfter} />
      </g>
    </Fragment>
  );
}
