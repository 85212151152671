import { MapDrawerLayout } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer-layout';
import { MapDrawerContentLayout } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer-content-layout';
import { useDrawerHeaderTitle } from 'shared/components/esri-map/services/useDataFetchingForDrawer';
import { SearchType } from 'shared/components/esri-map/utils/constants';
import { MapDrawerVesselComponent } from './vessel/drawer-vessel-component';
import { MapDrawerPortComponent } from './port/drawer-port-component';

export const MapDrawerComponent = () => {
  // Fethching Title for Drawer from the Map
  const { data, searchType } = useDrawerHeaderTitle();
  return (
    <MapDrawerLayout>
      {data ? (
        searchType === SearchType.vessel ? (
          <>
            <MapDrawerContentLayout
              title1={data.title1}
              title2={data.title2 + (data.title3 ? ` - ${data.title3}` : '')}
            >
              <MapDrawerVesselComponent></MapDrawerVesselComponent>
            </MapDrawerContentLayout>
          </>
        ) : (
          <MapDrawerContentLayout title1={data.title1} title2='Port'>
            <MapDrawerPortComponent></MapDrawerPortComponent>
          </MapDrawerContentLayout>
        )
      ) : (
        <></>
      )}
    </MapDrawerLayout>
  );
};
