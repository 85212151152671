import { IconButton, Link } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridColDef } from '@mui/x-data-grid-pro';
import { SearchWidget } from 'shared/components/esri-map/utils/searchWidget';
import {
  DataGridTable,
  GridRAGColumnLink,
  nameof,
} from 'shared/components/datagrid/DataGrid.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { IFleetKPITableData } from '../models/fleet-kpis.model';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { useGetUser } from 'shared/services/user.service';
import { userHasPermission } from 'shared/utils/permissions-utils';
import { PermissionModule } from 'shared/models/user.model';
import { constants } from 'routes/dashboard/dashboard.route';
import { ApolloError } from '@apollo/client';
import { isNumeric } from 'shared/utils/float-utils';

function searchIcon(vesselName: string, searchId: string) {
  SearchWidget.search(vesselName, true, searchId);
}

function getColumnsDefinition(navigate: any): GridColDef[] {
  const columns = nameof<IFleetKPITableData>; // get the properties from the model
  const goToEMVesselPage = (row: any) => {
    navigate(`${ROUTES.environmentalMonitor.path}/vessels/${row.id}`);
  };

  const goToVPMVesselPage = (row: any) => {
    navigate(`${ROUTES.vesselPerformance.path}/${row.id}/${row.vesselImo}`);
  };

  const goToVDMVesselPage = (row: any) => {
    navigate(ROUTES.vesselDataSummary.path.replace(':id', `${row.vesselImo}`));
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useGetUser();
  const userPermissions = data?.permissions;

  const defaultColumns: any = [
    {
      field: columns('VesselName'),
      headerName: 'Vessel Name',
      flex: 1,
      renderCell: (params: any) => (
        <>
          {params.value}
          <IconButton onClick={() => searchIcon(params.value, params.id)}>
            <VisibilityIcon fontSize='small' />
          </IconButton>
        </>
      ),
    },
    {
      field: columns('SisterClass'),
      headerName: 'Sister Class',
    },
  ];

  const columnsForEM = [
    GridRAGColumnLink(
      {
        field: columns('CII'),
        type: 'string',
        headerName: 'CII \nRating',
      },
      goToEMVesselPage
    ),
    GridRAGColumnLink(
      { field: columns('EEOI'), headerName: 'EEOI (%)' },
      goToEMVesselPage
    ),
    GridRAGColumnLink(
      {
        field: columns('EUA'),
        headerName: 'EUA \nAgainst Planned \n(%)',
        visibility: (dataRow: any) => {
          if (!dataRow) return false;
          const row = dataRow as IFleetKPITableData;
          const rawValue = row.vesselEuaPlannedValue;
          const value = isNumeric(rawValue) ? Number(rawValue) : 0;
          return value > 0;
        },
      },
      goToEMVesselPage
    ),
  ];

  const columnsForVPM = [
    GridRAGColumnLink(
      {
        field: 'FoulingResistance',
        headerName: 'Fouling \nResistance \n(%)',
      },
      goToVPMVesselPage
    ),
    GridRAGColumnLink(
      {
        field: columns('DeltaPower'),
        headerName: 'Excess \nPower \n(%)',
      },
      goToVPMVesselPage
    ),
    GridRAGColumnLink(
      { field: 'DeltaSpeed', headerName: 'Speed \nLoss \n(%)' },
      goToVPMVesselPage
    ),
    GridRAGColumnLink(
      {
        field: columns('MainEngine'),
        headerName: 'Main \nEngine \nSFOC (%)',
      },
      goToVPMVesselPage
    ),
  ];

  const columnsForVDM = [
    GridRAGColumnLink(
      {
        field: columns('PassedScore'),
        headerName: 'Overall Passed \nScore \n(%)',
      },
      goToVDMVesselPage
    ),
    {
      field: columns('FlaggedReports'),
      headerName: 'Flagged',
      type: 'number',
      renderCell: (params: any) => (
        <Link onClick={() => goToVDMVesselPage(params.row)}>
          {params.value}
        </Link>
      ),
    },
  ];

  if (
    userHasPermission([PermissionModule.EnvironmentalMonitor], userPermissions)
  )
    defaultColumns.push(...columnsForEM);
  if (
    userHasPermission(
      [PermissionModule.VesselPerformanceMonitor],
      userPermissions
    )
  )
    defaultColumns.push(...columnsForVPM);
  if (userHasPermission([PermissionModule.VesselDataMonitor], userPermissions))
    defaultColumns.push(...columnsForVDM);

  return defaultColumns;
}

export const ActiveVesselsGridTable = ({
  data,
  loading,
  error,
}: {
  data: IFleetKPITableData[];
  loading: boolean;
  error: ApolloError | undefined;
}) => {
  const navigate = useNavigate();
  const columns = getColumnsDefinition(navigate);

  const extractKeysForFiltering = ({
    CII,
    EEOI,
    EUA,
    FoulingResistance,
    DeltaPower,
    DeltaSpeed,
    MainEngine,
    PassedScore,
  }: IFleetKPITableData) => {
    const originData: Record<string, unknown> = {
      CII,
      EEOI,
      EUA,
      FoulingResistance,
      DeltaPower,
      DeltaSpeed,
      MainEngine,
      PassedScore,
    };

    return Object.keys(originData).reduce((data, key) => {
      if (columns.find((column) => column.field === key)) {
        return { ...data, [key]: originData[key] };
      }

      return data;
    }, {});
  };

  const filterResult = useFilter<IFleetKPITableData>(
    data ?? [],
    extractKeysForFiltering
  );

  return (
    <>
      <RAGFilter
        activeFilters={filterResult.activeFilters}
        handleToggle={filterResult.handleFilter}
      ></RAGFilter>
      <DataGridTable
        name={constants.SECTION_ACTIVE_VESSELS_TABLE_ID}
        rows={filterResult.filteredData}
        columns={columns}
        loading={loading}
        error={error}
        initialState={{
          sorting: { sortModel: [{ field: 'VesselName', sort: 'asc' }] },
        }}
      ></DataGridTable>
    </>
  );
};
