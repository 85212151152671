import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box, Container, CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { SideNavigation } from 'shared/components/navigation/sideNavigation.component';
import { TopNavigation } from 'shared/components/navigation/topNavigation.component';
import { AppContext } from 'shared/models/app.context.model';
import { theme } from 'styles/theme';

LicenseInfo.setLicenseKey(process.env['REACT_APP_MUI_X_LICENSE_KEY'] ?? '');

interface RootProps {
  childComponent: ReactElement;
}

export function Root(props: RootProps): EmotionJSX.Element {
  const isAuthenticated = useContext(AppContext).isAuthenticated;
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(ROUTES.login.path);
    }
  }, [isAuthenticated, navigate]);

  // On route change, scroll to top of page
  useEffect(() => {
    document.querySelector('main')?.scrollTo(0, 0);
  });

  if (isAuthenticated) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            backgroundColor: theme.background.page,
          }}
        >
          <CssBaseline />
          <SideNavigation open={open} setOpen={setOpen} />
        </Box>
        <Box
          sx={{
            flexGrow: '1',
            overflow: 'hidden',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TopNavigation open={open} />
          <Box
            component='main'
            sx={{
              height: '100vh',
              flexGrow: '1',
              overflow: 'auto',
            }}
          >
            <Container maxWidth='xl'>{props.childComponent}</Container>
          </Box>
        </Box>
      </Box>
    );
  }

  return <LoadingComponent height='100%' />;
}
