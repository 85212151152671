import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { EmergencyHomeIcon } from 'assets/emergency-home-icon';

interface WarningMessageProps {
  sx: SxProps<Theme>;
  title: string;
  details: string;
}

export const WarningMessage = (props: WarningMessageProps) => {
  const { title, details, sx } = props;
  const theme = useTheme();

  return (
    <Box sx={sx} data-testid={'warning-box'}>
      <Box
        sx={{
          border: `1px solid ${theme.palette.error.main}`,
          borderRadius: '4px',
          px: 1.5,
          py: 1,
          color: theme.validatedModal.errorTextLight,
          fontSize: '12px',
          display: 'flex',
        }}
      >
        <Box sx={{ mr: 1.5 }}>
          <EmergencyHomeIcon sx={{ color: theme.palette.error.main }} />
        </Box>
        <Box>
          <Typography sx={{ mb: 0.5 }}>{title}</Typography>
          <Typography sx={{ fontSize: '12px' }}>{details}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
