import { ApolloError } from '@apollo/client';
import { Grid } from '@mui/material';
import { Report } from '_gql/graphql';
import { useContext, useEffect } from 'react';
import { Section } from 'shared/components/section/section.component';
import {
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';

import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { constants } from 'routes/vessel-data-monitor/routes/vesselDataSummary/vessel-data-summary.route';
import { StatusCardError } from 'shared/components/status-card/status-card-error.component';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { TEXT } from 'shared/constants/text';
import { ExportableSections } from 'shared/utils/valid-sections';

export interface VesselDataOverviewSummaryProps {
  overallPassedScoreCardProps: StatusKpiCardProps;
  passedCardProps: StatusKpiCardProps;
  failedCardProps: StatusKpiCardProps;
  loading: boolean;
  error: ApolloError | undefined;
  filteredData?: Report[];
}

export const VesselDataSummary: React.FC<VesselDataOverviewSummaryProps> = ({
  error,
  loading,
  overallPassedScoreCardProps,
  passedCardProps,
  failedCardProps,
}) => {
  const cardData = {
    overallPassedScoreCardProps,
    passedCardProps,
    failedCardProps,
  };

  const { setCardData } = useContext(ExcelExportContext);
  const childComponent = () => {
    if (loading) {
      return <KpiCardSkeleton kpiCards={[{}, {}, {}]} />;
    }

    let gridRows: EmotionJSX.Element;
    if (error) {
      gridRows = (
        <>
          <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
            <StatusCardError
              message={`Unable to load ${overallPassedScoreCardProps.title}`}
              subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
            />
          </Grid>
          <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
            <StatusCardError
              message={`Unable to load ${passedCardProps.title}`}
              subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
            />
          </Grid>
          <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
            <StatusCardError
              message={`Unable to load ${failedCardProps.title}`}
              subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
            />
          </Grid>
        </>
      );
    } else {
      gridRows = (
        <>
          <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
            <StatusKpiCard {...overallPassedScoreCardProps} />
          </Grid>
          <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
            <StatusKpiCard {...passedCardProps} />
          </Grid>
          <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
            <StatusKpiCard {...failedCardProps} />
          </Grid>
        </>
      );
    }

    return (
      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        {gridRows}
      </Grid>
    );
  };

  useEffect(() => {
    if (!loading) setCardData(cardData);
  }, [loading]);

  return (
    <Section
      id={ExportableSections['VDM-Vessel Data Quality Summary']}
      exportSection={true}
      exportOptions={{
        includePDF: false,
        introductoryRows: { includeVesselName: true },
        includeCardData: true,
      }}
      title={constants.VDS_SECION_TITLE}
      childComponent={childComponent()}
    />
  );
};
