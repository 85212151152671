import ActionToggle from '@arcgis/core/support/actions/ActionToggle';
import Expand from '@arcgis/core/widgets/Expand';
import LayerList from '@arcgis/core/widgets/LayerList';
import { VesselLayer } from './vesselLayer';
import { ReferenceLayer } from './ReferenceLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { vesselIconSize } from './symbols';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import { MapViewType } from 'shared/models/mapView.context.model';

const DISPLAY_LABELS = 'display-labels';
const DISPLAY_ICONS = 'display-markers';

function defineActions(event: any) {
  // The event object contains an item property.
  // is is a ListItem referencing the associated layer
  // and other properties. You can control the visibility of the
  // item, its title, and actions using this object.

  const item = event.item;

  if (item.layer.id === VesselLayer.VESSEL_LAYER_ID) {
    item.actionsSections = [
      [
        new ActionToggle({
          title: 'Vessel Names',
          className: 'esri-icon-visible',
          id: DISPLAY_LABELS,
          value: true,
        }),
        new ActionToggle({
          title: 'Vessel Icons',
          className: 'vessel-layer-items',
          id: DISPLAY_ICONS,
          value: true,
        }),
      ],
    ];
  }
}

function actions(view: MapViewType, event: any) {
  // Capture the action id.
  const id = event.action.id;

  switch (id) {
    case DISPLAY_LABELS: {
      const vesselLayer = view.map.findLayerById(
        VesselLayer.VESSEL_LAYER_LABELS_ID
      ) as FeatureLayer;
      vesselLayer.labelsVisible = event.action.value;
      break;
    }

    case DISPLAY_ICONS: {
      const vesselLayer = view.map.findLayerById(
        VesselLayer.VESSEL_LAYER_ID
      ) as FeatureLayer;
      const renderer: any = vesselLayer.renderer;
      renderer.symbol.size = event.action.value ? vesselIconSize : 0;
      break;
    }
  }
}

export const AddLayerList = (view: MapViewType) => {
  const layerList = new LayerList({
    label: 'Layer List',
    container: document.createElement('div'),
    view: view,
    listItemCreatedFunction: defineActions,
  });

  layerList.on('trigger-action', (event) => actions(view, event));

  const layerListExpand = new Expand({
    expandTooltip: 'Expand LayerList', // optional, defaults to 'Expand' for English locale
    view: view,
    content: layerList,
    group: 'top-right-group',
  });

  view.ui.add(layerListExpand, 'top-right');

  reactiveUtils.watch(
    // getValue function
    () => view.map?.findLayerById(ReferenceLayer.REFERENCE_LAYER_ID)?.visible,
    // callback
    (visible = true) => {
      const refLayer = view.map?.basemap.referenceLayers.getItemAt(0);
      if (refLayer) {
        refLayer.visible = visible;
      }
    }
  );

  reactiveUtils.watch(
    () => view.map?.findLayerById(VesselLayer.VESSEL_LAYER_ID)?.visible,
    (visible) => {
      const findVesselGroupsLayer = view.map?.findLayerById(
        VesselLayer.VESSEL_LAYER_LABELS_ID
      ) as FeatureLayer;
      if (findVesselGroupsLayer) {
        findVesselGroupsLayer.visible = visible;
      }
    }
  );
};
