import { useState, useMemo, useContext } from 'react';
import { FleetDataSummary } from './components/fleetDataSummary/fleet-data-summary';
import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import {
  IVessel,
  scoreThresholdToLabel,
} from './components/fleetDataComparison/table/util/fleet-data-comparison-table.util';

import { fleetSummaryData } from './components/fleetDataSummary/fleet-data-summary.viewModel';
import { useFleetSummaryScores } from './services/fleet-summary.get.service';
import {
  failedScoreText,
  overallScoreText,
  passedScoreText,
} from './common/common';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { FleetSummaryMapper } from './mappers/vessel-fleet-summary.mapper';
import { StatusKpiCardProps } from 'shared/components/status-kpi-card/status-kpi-card.component';
import { FleetDataComparisonTable } from './components/fleetDataComparison/table/fleet-data-comparison-table';
import { Section } from 'shared/components/section/section.component';
import { Maybe, Vessel } from '_gql/graphql';
import { TEXT } from 'shared/constants/text';
import { ExportableSections } from 'shared/utils/valid-sections';
import { DateRange } from 'shared/utils/date-utc-helper';

export const constants = {
  VDM_PAGE_TITLE: 'Vessel Data Monitor: Fleet Overview',
  FDS_SECTION_TITLE: 'Fleet Data Quality Summary',
  FDC_SECTION_TITLE: 'Fleet Breakdown by Vessels',
  FC_TABLE_TITLE: 'Vessel Data Quality KPIs Comparison',
};

function getVesselsFromImoList(
  vessels: Maybe<Vessel[]> | undefined,
  vesselImos: string[]
) {
  return vessels?.filter((x) => vesselImos.includes(x.imoNumber)) ?? [];
}

export function VesselDataMonitor() {
  const [filter] = useState<string[]>([]);

  // TODO: find what's going on with imoNumbers from NavBarContext
  const { utcDateRange } = useContext(NavBarContext);
  const { imoNumbers } = useContext(NavBarContext);

  const { loading, error, data } = useFleetSummaryScores(
    utcDateRange ?? new DateRange()
  );
  const vessels: Vessel[] = getVesselsFromImoList(data?.vessels, imoNumbers);

  const rows = useMemo(
    () =>
      (vessels.map((item) => FleetSummaryMapper.fromDTO(item)) as IVessel[]) ??
      [],
    [vessels]
  );

  const vesselGroupFilteredData = [...rows];
  const filteredData = vesselGroupFilteredData?.filter((row) => {
    if (
      filter.includes(scoreThresholdToLabel(row.kpiStatus)) ||
      filter.length === 0
    ) {
      return true;
    }
    return false;
  });

  const { overallPassScorePercentage, overallPassScore, overallFailScore } =
    fleetSummaryData(vesselGroupFilteredData);

  const threshold = {
    success: data?.fleetThresholds?.thresholds?.[0] ?? 60, // >60% = low/green,
    warning: data?.fleetThresholds?.thresholds?.[1] ?? 31, // 31-60% = med/amber
    error: data?.fleetThresholds?.thresholds?.[2] ?? 0, // 0-30% = high/red
  };

  const overallPassedScoreCardColor =
    overallPassScorePercentage == null
      ? 'unknown'
      : overallPassScorePercentage >= threshold.success
      ? 'success'
      : overallPassScorePercentage !== null &&
        overallPassScorePercentage >= threshold.warning
      ? 'warning'
      : 'error';

  const overallPassedScoreCardProps: StatusKpiCardProps = {
    kpiData: {
      status: overallPassedScoreCardColor,
      primaryValue: `${
        overallPassScorePercentage === undefined
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : overallPassScorePercentage
      }`,
      unit: '%',
    },
    title: 'Overall Passed Score',
    showTooltip: true,
    tooltipText: overallScoreText,
  };
  const passedCardProps: StatusKpiCardProps = {
    kpiData: {
      status: `${overallPassScore === undefined ? 'unknown' : 'success'}`,
      primaryValue: `${overallPassScore ?? '---'}`,
    },
    title: 'Passed',
    showTooltip: true,
    tooltipText: passedScoreText,
  };
  const failedCardProps: StatusKpiCardProps = {
    kpiData: {
      status: `${overallFailScore === undefined ? 'unknown' : 'error'}`,
      primaryValue: `${overallFailScore ?? '---'}`,
    },
    title: 'Flagged',
    showTooltip: true,
    tooltipText: failedScoreText,
  };
  return (
    <PageWrapper data-testid={'vessel-data-monitor'}>
      <PageHeader title={constants.VDM_PAGE_TITLE} />
      <FleetDataSummary
        filteredData={filteredData}
        failedCardProps={failedCardProps}
        passedCardProps={passedCardProps}
        overallPassedScoreCardProps={overallPassedScoreCardProps}
        error={error}
        loading={loading}
      />
      <Section
        data-testid={'fleet-data-comparison'}
        id={ExportableSections['VDM-Fleet Breakdown by Vessels']}
        title={constants.FDC_SECTION_TITLE}
        exportSection={true}
        exportOptions={{ includePDF: false }}
        preventUnmount={true}
        childComponent={
          <FleetDataComparisonTable
            error={error}
            loading={loading}
            filteredData={filteredData}
          />
        }
      />
    </PageWrapper>
  );
}
