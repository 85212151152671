import { useMemo, useState } from 'react';
import {
  ExcelExportContext,
  ExcelExportContextType,
  GridProperties,
} from 'shared/models/excel-export.context.model';

export const ExportExcelWrapper = (props: {
  children: JSX.Element;
}): JSX.Element => {
  const setCardData = (cardData: any[], section = '') => {
    if (section === '') {
      setContext((prevContext) => ({
        ...prevContext,
        cardData,
      }));
      return;
    }

    setContext((prevState: any) => {
      const newState = { ...prevState };
      newState.cardData[section] = {
        kpis: cardData,
        sectionId: section,
      };
      return newState;
    });
  };

  const setFilteredData = (filteredData: GridProperties) => {
    setContext((prevState) => {
      const newState = { ...prevState };
      if (!newState.filteredData) {
        newState.filteredData = {};
      }
      newState.filteredData[filteredData.name] = {
        apiRef: filteredData.apiRef,
        sectionId: filteredData.sectionId,
      };
      return newState;
    });
  };

  const setTilesData = (tilesData: any[]) => {
    setContext((prevContext) => ({
      ...prevContext,
      tilesData,
    }));
  };

  const setAdditionalIntroductoryRowsData = (
    sectionId: string,
    additionalIntroductoryRowsData: string[][]
  ) => {
    setContext((prevState) => {
      const newState = { ...prevState };
      if (!newState.additionalIntroductoryRowsData) {
        newState.additionalIntroductoryRowsData = {};
      }
      newState.additionalIntroductoryRowsData[sectionId] =
        additionalIntroductoryRowsData;
      return newState;
    });
  };

  const [context, setContext] = useState<ExcelExportContextType>({
    cardData: [],
    filteredData: {},
    tilesData: [],
    additionalIntroductoryRowsData: {},
    setCardData,
    setFilteredData,
    setTilesData,
    setAdditionalIntroductoryRowsData,
  });
  const exportExcelContext: ExcelExportContextType = useMemo(
    () => ({
      cardData: context.cardData,
      filteredData: context.filteredData ?? {},
      tilesData: context.tilesData,
      additionalIntroductoryRowsData:
        context.additionalIntroductoryRowsData ?? {},
      setCardData,
      setFilteredData,
      setTilesData,
      setAdditionalIntroductoryRowsData,
    }),
    [
      context.cardData,
      context.tilesData,
      context.filteredData,
      context.additionalIntroductoryRowsData,
    ]
  );

  return (
    <ExcelExportContext.Provider value={exportExcelContext}>
      {props.children}
    </ExcelExportContext.Provider>
  );
};
