import { SyncDisabled } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from 'styles/theme';

export const ErrorPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        overflow: 'hidden',
        flexDirection: 'column',
      }}
      data-testid='error-component'
    >
      <SyncDisabled
        fontSize={'large'}
        sx={{ color: theme.palette.text.disabled, marginBottom: '12px' }}
      />
      <Box>
        <Typography sx={{ fontSize: '20px' }}>Unable to load page</Typography>
      </Box>
    </Box>
  );
};
