import { gql } from '@apollo/client';

export const GET_FLEET_SUMMARY_SCORES = gql`
  query GetFleetSummaryScores($startDate: DateTime!, $endDate: DateTime!) {
    fleetThresholds {
      thresholds
    }
    vessels {
      id
      name
      imoNumber
      vesselGrouping {
        id
        groupingType
        groupingCategoryName
        groupingDescription
        groupingName
        groupingOrder
      }
      reports(startDate: $startDate, endDate: $endDate) {
        overallPassScorePercentage
        overallPassScore
        overallFailScore
        status
      }
    }
  }
`;
