import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { MapViewType } from 'shared/models/mapView.context.model';

export class ReferenceLayer {
  static REFERENCE_LAYER_ID = 'ReferenceLayerId';
  static REFERENCE_LAYER_TITLE = 'Reference';

  static CreateLayer(view: MapViewType, layerIndex: number) {
    const _emptyLayer = new GraphicsLayer();
    _emptyLayer.title = ReferenceLayer.REFERENCE_LAYER_TITLE;
    _emptyLayer.id = ReferenceLayer.REFERENCE_LAYER_ID;

    view.map.layers.add(_emptyLayer, layerIndex);
  }
}
