import { useQuery } from '@apollo/client';
import { CALCULATE_PLANNED_ITINERARY } from '../queries/plannedItinerary.query';
import { IPlannedItinerary } from '../models/plannedItinerary.model';
import { DateRange } from 'shared/utils/date-utc-helper';
import { PlannedItineraryInputMapper } from '../mappers/plannedItinerary.mapper';
import { Query } from '_gql/graphql';

export function useCalculatePlannedItineraryData(
  vesselId: string | undefined,
  dateRange: DateRange | null,
  plannedItineraryInput: IPlannedItinerary | undefined
) {
  const plannedItinerary = PlannedItineraryInputMapper.toDomain(
    plannedItineraryInput
  );
  return useQuery<Query>(CALCULATE_PLANNED_ITINERARY, {
    skip: !vesselId || !dateRange?.dateRangeAsString || !plannedItinerary,
    variables: {
      id: vesselId,
      ...dateRange?.dateRangeAsString,
      plannedItinerary,
    },
  });
}
