import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Breadcrumb } from '../breadcrumb/BreadCrumb.component';
import { theme } from 'styles/theme';
import { useGetDataLatestDate } from 'shared/services/dataLatestUpdatedDate.service';

const componentTheme = createTheme({
  ...theme,
  palette: {
    background: {
      paper: '#1D262F',
      default: 'rgba(239, 239, 240, 0.07)',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#EFEFF0',
    },
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '116%',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: '40px',
      letterSpacing: '-0.5px',
    },
    subtitle1: {
      fontSize: '12px',
      fontWeight: 300,
      lineHeight: '143%',
      letterSpacing: '.17px',
    },
  },
});

export interface PageHeaderProps {
  title: string;
  heading?: string;
}

export const PageHeader = (props: PageHeaderProps) => {
  const { data } = useGetDataLatestDate();
  const dateString = data?.formatDMYHMZ(true) ?? '';

  return (
    <ThemeProvider theme={componentTheme}>
      <Box
        sx={{
          mb: 0.5,
          backgroundColor: 'background.paper',
          width: '100%',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box>
            <Typography
              variant='subtitle1'
              sx={{ mt: 0, color: 'text.primary', alignSelf: 'center' }}
            >
              <Breadcrumb vesselName={props.title} />
            </Typography>
            <Typography
              variant='h1'
              sx={{ alignSelf: 'center', color: 'text.primary' }}
            >
              {props.title} {props.heading}
            </Typography>
          </Box>
          <Typography variant='subtitle1' sx={{ mt: 1, color: 'text.primary' }}>
            Data Last Updated: {dateString}
          </Typography>
        </Toolbar>
      </Box>
    </ThemeProvider>
  );
};
