import { useQuery } from '@apollo/client';
import { GET_VESSEL_IMOS_AND_GROUPINGS } from '../queries/vesselGroupings.query';
import { Query } from '_gql/graphql';

export function useGetAllVesselGroupingData() {
  try {
    return useQuery<Query>(GET_VESSEL_IMOS_AND_GROUPINGS);
  } catch (error) {
    console.log(error);
  }
  return null;
}
