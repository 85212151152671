import { Grid } from '@mui/material';

const PageWrapper = ({ children }: any) => {
  return (
    <Grid container data-testid='page-wrapper' sx={{ pt: 3 }}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default PageWrapper;
