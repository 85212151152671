import { EventType } from 'routes/vessel-performance/individual-vessel-performance/components/events/models/constants';

export const getEventTypeKeyByValue = (
  value: string | undefined
): string | undefined => {
  if (!value) return undefined;
  for (const key in EventType) {
    if (EventType[key as keyof typeof EventType] === value) {
      return key;
    }
  }
  return undefined;
};
