import {
  Box,
  Card,
  CardContent,
  Divider,
  SxProps,
  Typography,
} from '@mui/material';
import { theme } from 'styles/theme';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';

export const StatusCardError = (props: {
  message: string;
  subtext?: string;
}) => {
  const { message, subtext } = props;

  const dividerStyle: SxProps = {
    backgroundColor: theme.colors.infoStatesFocusedVisible,
    height: 10,
    m: 0,
    border: 'none',
  };
  return (
    <Card
      sx={{
        borderRadius: '12px',
        backgroundColor: theme.background.component,
        height: '192px',
      }}
    >
      <Divider sx={dividerStyle} />
      <CardContent
        sx={{
          p: 0.6,
          m: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box sx={{ textAlign: 'center' }}>
            <SyncDisabledIcon
              sx={{
                width: '40px',
                height: '40px',
                color: theme.colors.infoStatesFocusedVisible,
                mb: 1.5,
              }}
            />
            <Typography variant='h6'>{message}</Typography>
            {subtext && (
              <Typography
                variant='body1'
                sx={{ color: theme.palette.text.secondary }}
              >
                {subtext}
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
