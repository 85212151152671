import { useContext } from 'react';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { useGetFleetKpisForDashboardTable } from '../services/fleet-kpis-table.get.service';
import { ActiveVesselsGridTable } from './active-vessels-grid.component';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';

export function ActiveVesselsGrid() {
  const { utcDateRange } = useContext(NavBarContext);
  const { imoNumbers } = useContext(NavBarContext);
  const { data, loading, error } = useGetFleetKpisForDashboardTable(
    utcDateRange,
    imoNumbers
  );

  return (
    <CardContainer title='Vessel KPIs Comparison'>
      <ActiveVesselsGridTable
        data={data || []}
        loading={loading}
        error={error}
      />
    </CardContainer>
  );
}
