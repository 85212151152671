import { IUserPermission, PermissionModule } from 'shared/models/user.model';

export const userHasPermission = (
  requiredPermissions: PermissionModule[],
  userPermissions: IUserPermission[] | null | undefined
) => {
  if (requiredPermissions.length === 0) {
    return true;
  }

  if (userPermissions === null || userPermissions === undefined) {
    return false;
  }

  return userPermissions.some((permission) =>
    requiredPermissions.includes(permission.module)
  );
};
