import { ServiceResult } from 'shared/models/serviceResult.model';
import { useGetFleetKPIDashboardTableData } from '../repositories/fleet-kpis-table.get.repository';
import { FleetKpiDashboardTableMapper } from '../mappers/fleet-kpi-dashboard-table.mapper';
import { IFleetKPITableData } from '../models/fleet-kpis.model';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetFleetKpisForDashboardTable = (
  dateRange: DateRange | null,
  vesselImos: string[] = []
): ServiceResult<IFleetKPITableData[]> => {
  const response = useGetFleetKPIDashboardTableData(dateRange, vesselImos);
  const vessels = response?.data?.fleet?.dashboardVessels;
  const loading = response?.loading;
  const error = response?.error;
  const data = vessels?.map((vessel) =>
    FleetKpiDashboardTableMapper.fromDTO(vessel)
  );

  return {
    data,
    loading,
    error,
  };
};
