enum Reports {
  IMO_DCS_MRV = 'IMO DCS/MRV',
}
enum Statements {
  ABS_Wavesight_EU_ETS_Statement = 'ABS Wavesight EU ETS Statement',
}

const useAvailableReportsAndStatements = () => {
  const availableReports = [Reports.IMO_DCS_MRV];
  const availableStatements = [Statements.ABS_Wavesight_EU_ETS_Statement];

  return {
    availableReports,
    availableStatements,
  };
};

export { Reports, Statements, useAvailableReportsAndStatements };
