import Box from '@mui/material/Box';
import { Link, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

import { VesselPerformanceRow } from '../models/vesselPerformance.viewModel';
import { constants } from './vesselPerformanceTable.config';
import { ApolloError } from '@apollo/client';
import { useFilter } from '../../../shared/components/rag-filter/filter.hook';
import { RAGFilter } from '../../../shared/components/rag-filter/grid-filter.component';
import {
  DataGridTable,
  GridColumnDef,
  GridRAGColumn,
  nameof,
} from 'shared/components/datagrid/DataGrid.component';
import { ROUTES } from 'router/router';
import { useNavigate } from 'react-router-dom';
import { UTCDate } from 'shared/utils/date-utc-helper';

export interface VesselPerformanceTableProps {
  data: VesselPerformanceRow[] | null;
  loading: boolean;
  error: ApolloError | null | undefined;
}

const SMALL_CELL_WIDTH = 135;
const BIG_CELL_WIDTH = 180;

function getColumnsDefinition(navigate: any): GridColumnDef[] {
  const columns = nameof<VesselPerformanceRow>; // get the properties from the model

  const handleVesselNameClick = (row: any) => {
    navigate(`${ROUTES.vesselPerformance.path}/${row.id}/${row.vesselImo}`);
  };

  const commonSection: GridColumnDef[] = [
    {
      field: columns('name'),
      headerName: 'Vessel Name',
      flex: 1,
      minWidth: BIG_CELL_WIDTH,
      renderCell: (params: any) => (
        <Link
          onClick={() => handleVesselNameClick(params.row)}
          className='MuiDataGrid-cellContent'
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: columns('className'),
      headerName: 'Sister Class',
      minWidth: BIG_CELL_WIDTH,
    },
    {
      field: columns('lastCleaning'),
      headerName: 'Last Cleaning',
      minWidth: SMALL_CELL_WIDTH,
      type: 'date',
      valueFormatter: (params) => UTCDate.safeFormatDMY(params?.value),
    },
    GridRAGColumn({
      field: columns('foulingResistance'),
      headerName: 'Fouling \nResistance (%)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 0,
    }),
    GridRAGColumn({
      field: columns('dPower'),
      headerName: 'Excess \nPower (%)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 0,
    }),
    GridRAGColumn({
      field: columns('dSpeed'),
      headerName: 'Speed \nLoss (%)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 0,
    }),
    GridRAGColumn({
      field: columns('dMainEngineSFOC'),
      headerName: 'Main Engine \nSFOC (%)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 0,
      type: 'number',
    }),
    GridRAGColumn({
      field: columns('dPowerDailyFuelPenalty'),
      headerName: 'Excess Fuel \n(Av. Daily) \n(t/day)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridRAGColumn({
      field: columns('dPowerTotalFuelPenalty'),
      headerName: 'Excess Fuel \n(Total)\n(t)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridRAGColumn({
      field: columns('dPowerTotalDailyCO2Penalty'),
      headerName: 'Excess CO₂\n(Av. Daily)\n(t/day)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),

    GridRAGColumn({
      field: columns('dPowerTotalCO2Penalty'),
      headerName: 'Excess CO₂\n(Total)\n(t)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
  ];

  // we could add logic here to include/exclude sections
  const result = [...commonSection];

  return result;
}

export const VesselPerformanceTable = (props: VesselPerformanceTableProps) => {
  const { data, error, loading } = props;
  const theme = useTheme();
  const extractKeysForFiltering = ({
    foulingResistance,
    dPower,
    dSpeed,
    dMainEngineSFOC,
    dPowerDailyFuelPenalty,
    dPowerTotalFuelPenalty,
    dPowerTotalDailyCO2Penalty,
    dPowerTotalCO2Penalty,
  }: VesselPerformanceRow) => ({
    foulingResistance,
    dPower,
    dSpeed,
    dMainEngineSFOC,
    dPowerDailyFuelPenalty,
    dPowerTotalFuelPenalty,
    dPowerTotalDailyCO2Penalty,
    dPowerTotalCO2Penalty,
  });

  const navigate = useNavigate();
  const filterResult = useFilter<VesselPerformanceRow>(
    data ?? [],
    extractKeysForFiltering
  );
  const columns = getColumnsDefinition(navigate);

  const table = (
    <>
      <RAGFilter
        activeFilters={filterResult.activeFilters}
        handleToggle={filterResult.handleFilter}
      ></RAGFilter>
      <DataGridTable
        name='VPM-Fleet Breakdown by Vessels'
        rows={filterResult.filteredData}
        columns={columns}
        error={error}
        loading={loading}
        initialState={{
          sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
        }}
      ></DataGridTable>
    </>
  );

  return (
    <Box
      sx={{
        p: 0,
        mt: 3,
        mb: 1,
        backgroundColor: theme.background.component,
        borderRadius: '6px',
      }}
    >
      <Typography
        variant='h3'
        paragraph
        sx={{
          mb: 0,
          pt: 4,
          display: 'flex',
          justifyContent: 'center',
          color: theme.palette.text.primary,
        }}
      >
        {constants.TABLE_TITLE}
      </Typography>
      {table}
    </Box>
  );
};
