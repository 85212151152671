import { useCallback } from 'react';
import * as yup from 'yup';
import { EventModalValues } from './models/events.model';

export const eventValidationSchema = yup.object<EventModalValues>().shape({
  eventName: yup
    .string()
    .matches(/^[A-Za-z0-9 ]*$/, 'Cannot use special characters')
    .max(50, 'Event Name must not exceed 50 characters')
    .required('Event Name is required'),
  eventType: yup.string().required('Event Type selection is required'),
  location: yup.string().required('Location selection is required'),
  company: yup.string().matches(/^[ \w]*$/, 'Cannot use special characters'),
  portAndOtherCosts: yup
    .string()
    .test(
      'maxDigitsAfterDecimal',
      'Decimal must be less than 3 digits',
      (number) => /^\d+(\.\d{1,2})?$/.test(number ?? '')
    )
    .matches(/^[\d.]*$/, 'Port & Other Costs must be a number')
    .required('Port & Other Costs is required'),
  diverCost: yup
    .string()
    .test(
      'maxDigitsAfterDecimal',
      'Decimal must be less than 3 digits',
      (number) => /^\d+(\.\d{1,2})?$/.test(number ?? '')
    )
    .matches(/^[\d.]*$/, 'Diver Cost must be a number')
    .required('Diver Cost is required'),
  bunkerPrice: yup
    .string()
    .test(
      'maxDigitsAfterDecimal',
      'Decimal must be less than 3 digits',
      (number) => /^\d+(\.\d{1,2})?$/.test(number ?? '')
    )
    .matches(/^[\d.]*$/, 'Bunker Price must be a number')
    .required('Bunker Price is required'),
  totalOOSPeriod: yup
    .string()
    .test(
      'maxDigitsAfterDecimal',
      'Total OOS Period must be at most a 2 digit number with a single decimal point.',
      (number) =>
        number ? /^((\d{0,2}?)|(\d{0,2}?\.\d{0,1}?))$/.test(number) : true
    )
    .test(
      'notRequiredIsNumber',
      'Total OOS Period must be a number',
      (number) => (number ? /^[\d.]*$/.test(number) : true)
    ),
  comments: yup.string().max(400, 'Comments must not exceed 400 characters'),
});

export const useYupValidationResolver = (validationSchema: EventFormSchema) =>
  useCallback(
    async (data: EventModalValues) => {
      try {
        const values = validationSchema.validateSync(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

export type EventFormSchema = typeof eventValidationSchema;
