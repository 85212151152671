import { Skeleton, Grid, Divider, Card, CardContent } from '@mui/material';
import { theme } from 'styles/theme';
import Box from '@mui/system/Box';
import { SxProps } from '@mui/material';
import { getStatusValues } from 'shared/components/status-kpi-card/status-kpi-card.viewModel';
import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';

export interface KpiCardSkeletonProps {
  kpiCards: { sectionVisibility?: { footer?: boolean } }[];
}

export function KpiCardSkeleton(props: KpiCardSkeletonProps) {
  const dividerStyle: SxProps = {
    backgroundColor: theme.colors.divider,
    mt: 2,
    mb: 2,
    ml: 0.5,
    mr: 0.5,
  };
  const cardBackground = theme.background.component;

  const cardStyle: SxProps = {
    borderRadius: '12px',
    backgroundColor: cardBackground,
    minWidth: '245px',
  };

  const boxStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    justifyContent: 'space-between',
    mr: 0.5,
    ml: 0.5,
  };

  const { color: statusBarBackground } = getStatusValues(Status.none);

  const statusBarStyle: SxProps = {
    backgroundColor: statusBarBackground,
    height: 8,
    margin: 0,
  };

  const skeletonCards = props.kpiCards.map((card, index) => (
    <Grid
      item
      key={index}
      xs={12}
      smPlus={6}
      mdPlus={4}
      lg={3}
      data-testid={'kpi-loading-skeleton-' + index.toString()}
    >
      <Card sx={cardStyle}>
        <Divider sx={statusBarStyle} />
        <CardContent sx={{ m: '24px' }}>
          <Box sx={boxStyle}>
            <Skeleton variant='rounded' width='30%' />
          </Box>
          <Box sx={boxStyle}>
            <Skeleton variant='rounded' width='55%' height={30} />
            <Skeleton variant='circular' width={40} height={40} />
          </Box>
          {card.sectionVisibility?.footer && (
            <>
              <Divider sx={dividerStyle} />
              <Box sx={boxStyle}>
                <Skeleton variant='rounded' width='30%' />
                <Skeleton variant='rounded' width='40%' />
              </Box>
              <Box sx={boxStyle}>
                <Skeleton variant='rounded' width='30%' />
                <Skeleton variant='rounded' width='40%' />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  ));

  return (
    <Grid container columns={12} spacing={2} sx={{ marginTop: 1 }}>
      {skeletonCards}
    </Grid>
  );
}
