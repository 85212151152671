import { DataPoint } from '_gql/graphql';
import { AerCalculation } from '../models/calculations.model';

export class AerMapper {
  public static fromDTO(aerData: DataPoint[]): AerCalculation[] {
    return aerData.map((item) => ({
      date: item.date?.unixTime ?? null,
      deviation: item?.deviation ? Number(item.deviation.toFixed(0)) : null,
      value: item?.value ? Number(item.value.toFixed(2)) : null,
    }));
  }

  public static toDomain(
    aerRequired: number | undefined,
    aerData: AerCalculation[] | []
  ) {
    return {
      required: aerRequired ?? 0,
      data: aerData,
    };
  }
}
