import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  VesselPerformanceTrendChart,
  VesselPerformanceTrendChartProps,
} from '../components/vessel-trend-chart.component';
import {
  useGetVesselPerformanceTrendFouling,
  useGetVesselPerformanceTrendPower,
  useGetVesselPerformanceTrendSfoc,
  useGetVesselPerformanceTrendSpeed,
} from '../services/vessel-trend-chart.service';
import { constants } from '../individualVesselPerformance.route';
import { DateRange } from 'shared/utils/date-utc-helper';

export interface PerformanceTrendProps {
  dateRange: DateRange | null;
  vesselImo: string;
  vesselId: string;
}

export const PerformanceTrends = (props: PerformanceTrendProps) => {
  const theme = useTheme();
  const { dateRange, vesselImo, vesselId } = props;

  const foulingProps: VesselPerformanceTrendChartProps = {
    title: constants.FOULING_RES_CHART_TITLE,
    chartKeyShorten: 'Fouling Resistance',
    yAxisLabel: constants.ADDITIONAL_RESISTANCE_LABEL,
    yAxisPadding: 62,
    chartData: useGetVesselPerformanceTrendFouling(
      dateRange,
      vesselImo,
      vesselId
    ),
  };

  const powerProps: VesselPerformanceTrendChartProps = {
    title: constants.DELTA_POWER_CHART_TITLE,
    chartKeyShorten: 'Excess Power',
    yAxisLabel: constants.DELTA_POWER_LABEL,
    yAxisPadding: 36,
    chartData: useGetVesselPerformanceTrendPower(
      dateRange,
      vesselImo,
      vesselId
    ),
  };

  const speedProps: VesselPerformanceTrendChartProps = {
    title: constants.DELTA_SPEED_CHART_TITLE,
    chartKeyShorten: 'Speed Loss',
    yAxisLabel: constants.DELTA_SPEED_LABEL,
    yAxisPadding: 42,
    chartData: useGetVesselPerformanceTrendSpeed(
      dateRange,
      vesselImo,
      vesselId
    ),
  };

  const sfocProps: VesselPerformanceTrendChartProps = {
    title: constants.DELTA_ENGINE_SFOC_TITLE,
    chartKeyShorten: '∆ Main Engine SFOC',
    yAxisLabel: constants.DELTA_ENGINE_SFOC_LABEL,
    yAxisPadding: 86,
    chartData: useGetVesselPerformanceTrendSfoc(dateRange, vesselImo, vesselId),
  };

  return (
    <Box sx={{ mt: 4, backgroundColor: theme.background.page }}>
      <VesselPerformanceTrendChart {...foulingProps} />
      <VesselPerformanceTrendChart {...powerProps} />
      <VesselPerformanceTrendChart {...speedProps} />
      <VesselPerformanceTrendChart {...sfocProps} />
    </Box>
  );
};
