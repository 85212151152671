import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ColorBar } from './cii-ratings-color-bar.component';
import { theme } from 'styles/theme';

export const TopLeftColorBar = (props: {
  dataMin: number;
  dataMax: number;
  chartMax: number;
}) => {
  return (
    <ResponsiveContainer width='99%' height={415}>
      <BarChart
        data={[
          {
            positionLeft: 0,
            maxValue: props.dataMax,
            aValue: props.dataMax,
            topBarValue: props.chartMax - props.dataMax,
          },
        ]}
        margin={{ top: 20, bottom: 60, right: 25 }}
      >
        <XAxis
          dataKey={'positionLeft'}
          textAnchor='end'
          domain={[0, 1]}
          type='number'
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='maxValue'
          tickLine={false}
          tick={false}
          tickMargin={10}
          type='number'
          domain={[props.dataMin, props.chartMax]}
          axisLine={false}
          dx={-12}
          width={75}
        />

        <Bar
          dataKey='aValue'
          stackId='a'
          opacity={0}
          fill={'rgba(0, 0, 0, 0)'}
          shape={<ColorBar dataKey='aValue' />}
        ></Bar>
        <Bar
          dataKey='topBarValue'
          stackId='a'
          fill={theme.colors.blueGray}
          opacity={0.5}
          shape={<ColorBar dataKey='topBarValue' />}
        ></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
