import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { GET_STATEMENT_OF_FACT_REPORT_PDF } from '../queries/statement-of-fact-report-pdf.query';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetStatementOfFactReportPdfData = (
  dateRange: DateRange,
  imoNumber: string | null
) => {
  const dateRangeAsString = dateRange.dateRangeAsString;
  return useQuery<Query>(GET_STATEMENT_OF_FACT_REPORT_PDF, {
    skip: !dateRangeAsString || !imoNumber,
    errorPolicy: 'all', // Shows data even if there is an error
    variables: {
      ...dateRangeAsString,
      imoNumber,
    },
  });
};
