import { ApolloError } from '@apollo/client';
import { Box, List, Skeleton, Typography, useTheme } from '@mui/material';
import React from 'react';
import { IFieldValidation } from 'routes/vessel-data-monitor/models/field-validation.model';
import { VesselDetailsTile } from '../vessel-detail-tile/vessel-details-tile';

interface VesselDetailsGroupProps {
  loading: boolean;
  error: ApolloError | undefined;
  title: string;
  tiles: (IFieldValidation | null)[] | undefined;
}

export const VesselDetailsGroup: React.FC<VesselDetailsGroupProps> = ({
  loading,
  error,
  title,
  tiles,
}) => {
  const theme = useTheme();

  const cardBackground = theme.background.component;
  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    p: 3,
    backgroundColor: cardBackground,
    width: '33%',
    height: 'auto',
  };

  if (loading) {
    return (
      <Skeleton variant='rounded' width='33%' height={470} animation='wave' />
    );
  }

  if (error) {
    return (
      <Box sx={boxStyle}>
        <p>Error Occurred</p>
        <p>{error.message}</p>
      </Box>
    );
  }
  return (
    <Box sx={boxStyle}>
      <Typography variant={'h2'} sx={{ pb: 4 }}>
        {title}
      </Typography>
      <List>
        {tiles &&
          tiles.map((tile, index) => (
            <VesselDetailsTile key={index} {...tile} />
          ))}
      </List>
    </Box>
  );
};
