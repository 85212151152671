import { gql } from '@apollo/client';

export const CII_PROJECTION_FIELDS = gql`
  fragment CiiProjectionProperties on Projection {
    year
    values {
      date
      deviation
      value
    }
    conditions {
      inputMetrics {
        averageBallastSpeed
        averageLadenSpeed
        averageBallastFocPerDay
        averageLadenFocPerDay
        idleTimePercentage
        ballastPercentage
        ladenPercentage
        distance
      }
      twelveMonthRollingMetrics {
        averageBallastSpeed
        averageLadenSpeed
        averageBallastFocPerDay
        averageLadenFocPerDay
        idleTimePercentage
        ballastPercentage
        ladenPercentage
        distance
      }
      yearToDateMetrics {
        averageBallastSpeed
        averageLadenSpeed
        averageBallastFocPerDay
        averageLadenFocPerDay
        idleTimePercentage
        ballastPercentage
        ladenPercentage
        distance
      }
    }
  }
`;

export const CII_DATA_TOGGLES_FIELDS = gql`
  fragment DataTogglesProperties on DataToggles {
    speed {
      date
      value
    }
    loadingCondition {
      date
      value
    }
    fuelOilConsumption {
      mainEngine {
        date
        value
      }
      boiler {
        date
        value
      }
      auxEngine {
        date
        value
      }
    }
    operationalProfile {
      date
      value
    }
    vesselCondition {
      date
      value
    }
    vesselComparison {
      date
      value
    }
  }
`;

export const GET_CII_CALCULATION_BY_VESSEL_ID = gql`
  ${CII_PROJECTION_FIELDS}
  ${CII_DATA_TOGGLES_FIELDS}
  query GetVesselCIICalculations(
    $id: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vessel(id: $id) {
      id
      calculations(startDate: $startDate, endDate: $endDate) {
        id
        cii {
          data {
            date
            deviation
            value
          }
          projections {
            ...CiiProjectionProperties
          }
          boundaries {
            superior
            lower
            upper
            inferior
          }
          dataToggles {
            ...DataTogglesProperties
          }
          required
        }
      }
    }
  }
`;

export const UPDATED_CII_EOY_PROJECTIONS = gql`
  ${CII_PROJECTION_FIELDS}
  mutation UpdatedCiiProjections($input: ProjectionInput!) {
    updatedCiiProjections(input: $input) {
      id
      cii {
        projections {
          ...CiiProjectionProperties
        }
      }
    }
  }
`;
