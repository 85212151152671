import Box from '@mui/material/Box';
import {
  BarChart,
  useXScale,
  useDrawingArea,
  useYScale,
} from '@mui/x-charts-pro';

import { styled } from '@mui/material';
import { LoadingComponent } from 'shared/components/loading/loading.component';

const ratios = [0.2, 0.8, 0.6, 0.5];
const LoadingReact = styled('rect')({
  opacity: 0.2,
  fill: 'lightgray',
});

const LoadingText = styled('text')(({ theme }) => ({
  stroke: 'none',
  fill: theme.palette.text.primary,
  shapeRendering: 'crispEdges',
  textAnchor: 'middle',
  dominantBaseline: 'middle',
}));

function LoadingOverlay() {
  const xScale = useXScale<'band'>();
  const yScale = useYScale();
  const { left, width, height } = useDrawingArea();

  const bandWidth = xScale.bandwidth();

  const [bottom, top] = yScale.range();

  return (
    <g>
      {xScale.domain().map((item, index) => {
        const ratio = ratios[index % ratios.length]!;
        const barHeight = ratio * (bottom! - top!);

        return (
          <LoadingReact
            key={index}
            x={xScale(item)}
            width={bandWidth}
            y={bottom! - barHeight}
            height={height}
          />
        );
      })}
      <LoadingText x={left + width / 2} y={top! + height / 2}>
        Loading data ...
      </LoadingText>
    </g>
  );
}

export function LoadingChartComponent(props: Readonly<{ height: number }>) {
  const height = props.height ?? 400;
  const SkeletonHeight = height - 50;

  return (
    <Box
      sx={{ width: '100%', height: height, pl: 1, pr: 2, position: 'relative' }}
    >
      <BarChart
        loading
        xAxis={[
          {
            scaleType: 'band',
            data: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
          },
        ]}
        slots={{ loadingOverlay: LoadingOverlay }}
        series={[]}
        margin={{ top: 10, right: 25, left: 25, bottom: 0 }}
        height={SkeletonHeight}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          top: 0,
          height: SkeletonHeight,
          pr: 3,
        }}
      >
        <LoadingComponent
          data-testid='loading-component'
          height={`${SkeletonHeight}px`}
        />
      </Box>
    </Box>
  );
}
