import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  getAlertActivityData,
  TooltipFormatter,
} from '../../viewModels/alert-activity.viewmodel';
import {
  ChartKey,
  ChartKeyProps,
} from 'shared/components/chart-key/chart-key.component';
import { useGetAlertCharts } from '../../services/alerts.service';
import { theme } from 'styles/theme';
import { IAlert } from '../../models/alert.model';
import { ErrorComponent } from 'shared/components/error/error.component';
import { DateRange } from 'shared/utils/date-utc-helper';

export const AlertActivity = (props: {
  vesselImoNumbers: string[] | undefined;
  dateRange: DateRange | null;
}) => {
  const { vesselImoNumbers, dateRange } = props;
  const alertDataResponse = useGetAlertCharts(
    vesselImoNumbers,
    dateRange ?? new DateRange()
  );

  if (alertDataResponse.loading) {
    return (
      <Skeleton
        sx={{ mt: 3, height: '560px' }}
        variant='rounded'
        animation='pulse'
      ></Skeleton>
    );
  }

  let responseData: IAlert[] = [];
  if (
    alertDataResponse &&
    alertDataResponse.data &&
    alertDataResponse.data.alerts
  ) {
    responseData = alertDataResponse.data.alerts;
  }

  const hasData = responseData.length > 0;
  if (alertDataResponse.error || !hasData) {
    return <ErrorComponent error={alertDataResponse.error} empty={!hasData} />;
  }

  const start = dateRange?.startDate!;
  const end = dateRange?.endDate!;
  const alertActivityData = getAlertActivityData(responseData, start, end);

  const strokeColor = theme.alerts.activityChartStroke;
  const stopColor = theme.alerts.activityChartStopColor;
  const keyProps: ChartKeyProps = {
    items: [{ id: 1, name: 'Alerts', fill: stopColor }],
  };

  return (
    <Box
      component='div'
      sx={{
        mt: 3,
        p: 2,
        backgroundColor: theme.background.component,
        borderRadius: '8px',
      }}
    >
      <Typography
        variant='h2'
        paragraph
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Number of Alerts
      </Typography>
      <ChartKey sx={{ ml: 8 }} items={keyProps.items} />
      <ResponsiveContainer width='98%' height={400}>
        <AreaChart
          data={alertActivityData}
          margin={{ top: 20, right: 20, left: 0, bottom: 60 }}
        >
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor={stopColor} stopOpacity={0.8} />
              <stop offset='95%' stopColor={stopColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey='friendlyDate'
            dy={6}
            angle={-35}
            textAnchor='end'
            axisLine={false}
            tickLine={true}
            tick={{ fill: theme.colors.offWhite }}
            style={{ ...theme.typography.chart }}
          />
          <YAxis
            dataKey='alerts'
            allowDecimals={false}
            axisLine={false}
            dx={-12}
            tickLine={false}
            tick={{ fill: theme.colors.offWhite }}
            style={{ ...theme.typography.chart }}
          />
          <CartesianGrid vertical={false} strokeDasharray='0' opacity={0.12} />
          <Tooltip
            contentStyle={{
              backgroundColor: theme.colors.offWhite,
              color: theme.background.page,
              borderRadius: '4px',
            }}
            itemStyle={{ color: theme.background.page }}
            formatter={TooltipFormatter}
          />
          <Area
            type='linear'
            dataKey='alerts'
            stroke={strokeColor}
            strokeWidth={3}
            fillOpacity={1}
            fill='url(#colorUv)'
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
