import { Box, Grid, SxProps, Typography } from '@mui/material';
import { typography } from 'styles/typography';
import { theme } from 'styles/theme';

import {
  PieChartValueOptions,
  PieChartStraightAngleMui,
} from 'shared/components/piechart-straightangle/piechart';
import { useState, useEffect } from 'react';
import {
  FleetUtilizationType,
  IFleetUtilization,
} from 'routes/environmental-monitor/models/fleet.model';

function getColumnsWidth(column: string) {
  const elements = document.getElementsByClassName(
    column.toLowerCase().replace(' ', '-')
  );

  const arr = Array.from(elements);
  const width = Math.max(
    arr.reduce((sum, x) => sum + x.clientWidth, 0),
    300
  );

  return width;
}

const PieChartWithTitle = ({
  title,
  data,
}: {
  title: string;
  data: PieChartValueOptions[];
}) => {
  const cssprops: SxProps = {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginRight: 0,
  };
  const [size, setSize] = useState<number>(400);

  function setDimensions() {
    requestAnimationFrame(() => {
      const width = getColumnsWidth(title);
      setSize(width);
    });
  }

  let timeoutId: any; // holder for timeout id

  useEffect(() => {
    setDimensions();

    function handleResize() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(setDimensions, 100);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Box key={title} width={size} sx={{ ...cssprops }}>
      <PieChartStraightAngleMui data={data}></PieChartStraightAngleMui>
      <Typography
        paragraph
        sx={{
          m: 3,
          fontSize: typography.fontSize['3'],
          display: 'flex',
          justifyContent: 'center',
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const CHART_COLORS = {
  laden: '#8C85CF',
  ballast: '#D9D9D9',
  atSea: '#319DB4',
  inPort: '#247486',
  maneuvering: '#9AB8C1',
  default: '#FFFFFF',
};

export type FleetUtilizationChartsProps = {
  data: IFleetUtilization | undefined;
};

export const FleetUtilizationCharts: React.FC<FleetUtilizationChartsProps> = ({
  data,
}) => {
  const dataFleetUtilization: PieChartValueOptions[] = [
    {
      name: 'Laden',
      value: data ? data[FleetUtilizationType.LADEN]?.durationPercentage : 0,
      color: CHART_COLORS.laden,
      unit: '%',
    },
    {
      name: 'Ballast',
      value: data ? data[FleetUtilizationType.BALLAST]?.durationPercentage : 0,
      color: CHART_COLORS.ballast,
      unit: '%',
    },
  ];

  const dataOperationalProfile: PieChartValueOptions[] = [
    {
      name: 'At Sea',
      value: data ? data[FleetUtilizationType.AT_SEA]?.durationPercentage : 0,
      color: CHART_COLORS.atSea,
      unit: '%',
    },
    {
      name: 'In Port',
      value: data ? data[FleetUtilizationType.IN_PORT]?.durationPercentage : 0,
      color: CHART_COLORS.inPort,
      unit: '%',
    },
    {
      name: 'Maneuvering',
      value: data
        ? data[FleetUtilizationType.MANEUVERING]?.durationPercentage
        : 0,
      color: CHART_COLORS.maneuvering,
      unit: '%',
    },
    {
      name: 'Idle',
      value: data ? data[FleetUtilizationType.IDLE]?.durationPercentage : 0,
      color: CHART_COLORS.default,
      unit: '%',
    },
  ];
  return (
    <Grid container justifyContent='center' spacing={0}>
      <Grid key={1} item xs={6}>
        <PieChartWithTitle
          title='Fleet Utilization'
          data={dataFleetUtilization}
        ></PieChartWithTitle>
      </Grid>
      <Grid key={2} item xs={6}>
        <PieChartWithTitle
          title='Operational Profile'
          data={dataOperationalProfile}
        ></PieChartWithTitle>
      </Grid>
    </Grid>
  );
};
