import { Vessel, VesselGrouping } from '_gql/graphql';
import { DropdownGroupingCategory } from '../models/vesselGroupings.viewModel';
import { DownloadListVessel } from 'routes/reports/components/generateReport/download-common';

const groupingTypes = {
  custom: 'Custom',
  organization: 'Organization',
  industry: 'Industry',
};

function initializeBucket(grouping: VesselGrouping) {
  return {
    groupingDisplayName: grouping?.groupingCategoryName || '',
    id: grouping?.id!,
    groupingOrder: grouping?.groupingOrder!,
    isChecked: false,
    isDisabled: false,
    groupings: [],
  };
}

function setBucket(
  dropdown: DropdownGroupingCategory[],
  grouping: VesselGrouping
) {
  // if Grouping doesn't exist
  if (
    !dropdown.find(
      (x) => x.groupingDisplayName === grouping?.groupingCategoryName
    )
  ) {
    dropdown.push(initializeBucket(grouping));
  }
}

function processBucket(
  dropdown: DropdownGroupingCategory[],
  grouping: VesselGrouping,
  vessel: Vessel
) {
  const customGroupingCategoryIndex = dropdown.findIndex(
    (x) => x.groupingDisplayName === grouping?.groupingCategoryName
  );
  // check if subGrouping exists. If not, add
  if (customGroupingCategoryIndex >= 0) {
    const groupingIndex = dropdown[
      customGroupingCategoryIndex
    ]?.groupings.findIndex((y) => y.groupingName === grouping.groupingName);
    if (groupingIndex !== undefined && groupingIndex >= 0) {
      const subcategory =
        dropdown[customGroupingCategoryIndex]?.groupings[groupingIndex];
      if (
        !subcategory?.imoNumbers.includes(vessel.imoNumber!) &&
        subcategory?.vessels
      ) {
        subcategory?.imoNumbers.push(vessel.imoNumber!);
        subcategory?.vessels.push(vesselToDownloadVessel(vessel));
      }
    } else {
      dropdown[customGroupingCategoryIndex]?.groupings.push({
        groupingName: grouping?.groupingName!,
        id: grouping?.id!,
        isChecked: false,
        isDisabled: false,
        imoNumbers: [vessel.imoNumber!],
        vessels: [vesselToDownloadVessel(vessel)],
      });
    }
  }
}

// Method that removes all the un-used fields in the Report Generate Page
const vesselToDownloadVessel = (v: Vessel): DownloadListVessel => {
  return {
    id: v.id as string,
    imoNumber: v.imoNumber as string,
    name: v.name as string,
  };
};

function sortBucket(dropdown: DropdownGroupingCategory[]) {
  dropdown.sort(
    // sort bucket by groupingOrder
    (a, b) =>
      a?.groupingOrder &&
      b?.groupingOrder &&
      a.groupingOrder! - b.groupingOrder!
  );

  dropdown.forEach((grouping) => {
    // sort bucket alphabetically by groupingName
    grouping.groupings.sort((a, b) => {
      if (a.groupingName < b.groupingName) {
        return -1;
      }
      if (a.groupingName > b.groupingName) {
        return 1;
      }
      return 0;
    });
  });
}

export const processVesselGroupingsForDropdown = (
  vessels?: Vessel[] | null
): DropdownGroupingCategory[] => {
  if (!vessels || vessels.length === 0) {
    return [];
  }
  const customVesselGroupingsBucket: DropdownGroupingCategory[] = [];
  const orgsVesselGroupingsBucket: DropdownGroupingCategory[] = [];
  const industryVesselGroupingsBucket: DropdownGroupingCategory[] = [];
  vessels.forEach((vessel) => {
    vessel.vesselGrouping?.forEach((grouping) => {
      // Split groupings in 3 buckets, "Custom", "Organization", "Industry"
      switch (grouping.groupingType) {
        case groupingTypes.custom:
          // if Grouping doesn't exist in customVesselGroupingsBucket
          setBucket(customVesselGroupingsBucket, grouping);
          break;
        case groupingTypes.organization:
          // if Grouping doesn't exist in orgsVesselGroupingsBucket
          setBucket(orgsVesselGroupingsBucket, grouping);
          break;
        case groupingTypes.industry:
          // if Grouping doesn't exist in industryVesselGroupingsBucket
          setBucket(industryVesselGroupingsBucket, grouping);
          break;
      }

      processBucket(customVesselGroupingsBucket, grouping, vessel);
      processBucket(orgsVesselGroupingsBucket, grouping, vessel);
      processBucket(industryVesselGroupingsBucket, grouping, vessel);
    });
  });

  // sort the 3 buckets individually
  sortBucket(customVesselGroupingsBucket);
  sortBucket(orgsVesselGroupingsBucket);
  sortBucket(industryVesselGroupingsBucket);

  // concate the 3 sorted buckets into final result and return the result
  return customVesselGroupingsBucket.concat(
    orgsVesselGroupingsBucket,
    industryVesselGroupingsBucket
  );
};

export class VesselGroupingsDrodownMapper {
  public static fromDTO(
    dto: Vessel[] | undefined
  ): DropdownGroupingCategory[] | null {
    return dto === undefined || dto === null || dto.length === 0
      ? null
      : processVesselGroupingsForDropdown(dto);
  }
}
