import { useEffect, useState, useContext } from 'react';
import { IVesselPosition } from 'shared/components/esri-map/utils/vesselLayer';
import { NavBarContext } from 'shared/models/navBar.context.model';

export function useTransformedVesselData(
  service: any,
  imoNumber: string | undefined = undefined
) {
  const [data, setData] = useState<IVesselPosition[]>([]);
  const { imoNumbers } = useContext(NavBarContext);

  useEffect(() => {
    if (!service.loading) {
      let data = service.data?.vessels;
      let transformedData: IVesselPosition[] = [];

      if (imoNumber) {
        if (Array.isArray(data)) {
          data = data
            .filter((vessel) => vessel.imoNumber === imoNumber)
            .slice(0, 1);
        }
      }

      if (Array.isArray(data)) {
        transformedData = data
          .filter(
            (x) =>
              x.currentPosition &&
              x.currentPosition.latitude &&
              x.currentPosition.longitude &&
              (imoNumbers.length === 0 || imoNumbers.includes(x.imoNumber))
          )
          .map((vessel) => ({
            imoNumber: vessel.imoNumber ?? '',
            VesselName: vessel.name ?? '',
            Latitude: vessel.currentPosition?.latitude ?? 0,
            Longitude: vessel.currentPosition?.longitude ?? 0,
            Heading: vessel.currentPosition?.heading ?? 0,
            imoType: vessel.imoType,
            ColorCode: 2,
          }));
        setData([...transformedData]);
      }
      if (service.error) {
        console.log('An error occurred:', service.error);
      }
    }
  }, [service.loading, service.data, service.error, imoNumber, imoNumbers]);

  return data;
}

export interface IVesselInfo {
  vesselName: string;
  imoNumber: string;
  lat: string;
  lon: string;
  heading: string;
  position: string;
  distance: string;
  status: string;
  location: string;
  lastPositionUpdated: string;
  voyageNumber: string;
  origin: string;
  originStatus: string;
  destination: string;
  destinationStatus: string;
  currentLeg: number;
  totalLeg: string[];
  legOrigin: string;
  legDestination: string;
  ATD: string;
  ETA: string;
  waypoints: object[];
}

const dummyVesselInfo: IVesselInfo = {
  vesselName: 'Apollo Voyager',
  imoNumber: '1578338',
  lat: 'Latitude',
  lon: 'Longitude',
  position: '41°24\'12.2"N 2°10\'26.5"E',
  heading: '086',
  distance: '1,428',
  status: 'Laden',
  location: 'Sea',
  lastPositionUpdated: 'Last Position Updated Date',
  voyageNumber: 'Voyage Number',
  origin: 'Pasir Panjang, Singapore',
  originStatus: 'Ballast',
  destination: 'Mumbai, Indi',
  destinationStatus: 'Laden',
  currentLeg: 8,
  totalLeg: [
    'Tokyo, Japan',
    'Shanghai, China',
    'Hong Kong, China',
    'Singapore',
    'Rotterdam, Netherlands',
    'Hamburg, Germany',
    'Antwerp, Belgium',
    'London, UK',
    'New York, USA',
    'Norfolk, USA',
    'Savannah, USA',
    'Miami, USA',
    'Houston, USA',
    'Los Angeles, USA',
    'Oakland, USA',
    'Tokyo, Japan',
  ],
  legOrigin: 'Leg Origin',
  legDestination: 'Leg Destination',
  ATD: '13 Feb 2022 12:23 CT',
  ETA: '15 Feb 2022 23:40 CT',
  waypoints: [
    {
      arrivalDate: '15 Feb 2022',
      location: "41°24'N 2°10'E",
      distance: 3312,
    },
    {
      arrivalDate: '16 Feb 2022',
      location: "41°24'N 2°10'E",
      distance: 2515,
    },
    {
      arrivalDate: '17 Feb 2022',
      location: "41°24'N 2°10'E",
      distance: 2800,
    },
  ],
};

// mock function for graphQL
export const useGetVesselInfo = (vesselId: string) => {
  console.log('GraphQL should be called with vessel id ' + vesselId);

  return {
    data: dummyVesselInfo,
    loading: false,
    error: false,
  };
};
