import { AerCalculation } from '../models/calculations.model';
import { ChartKeyProps } from 'shared/components/chart-key/chart-key.component';
import { TEXT } from 'shared/constants/text';
import { theme } from 'styles/theme';
import { UTCDate } from 'shared/utils/date-utc-helper';

export const yAxisTickFormatter = (val: number, requiredVal: number) => {
  if (requiredVal === 0) return `${val}`;

  const deviation = ((val - (requiredVal ?? 0)) / (requiredVal ?? 1)) * 100;
  return `${val} (${deviation.toFixed(0)}%)`;
};

export const yAxisTitle = `${TEXT.FORMULAS.AER} (Deviation)`;

export const xAxisTickFormatter = (date: number) => {
  if (!date) {
    return '';
  }
  const dateObject = new UTCDate(date).formatDMY();
  return dateObject;
};

export const xAxisTicksMUI = (): Date[] => {
  const currentYear = new UTCDate().year!;
  const xAxisTicks = [];
  for (let i = 0; i < 12; i++) {
    xAxisTicks.push(UTCDate.create(currentYear, i, 1).date!);
    if (i === 11) xAxisTicks.push(UTCDate.create(currentYear, i, 31).date!);
  }
  return xAxisTicks;
};

export const xAxisTicks = (): number[] => {
  const currentYear = new UTCDate().year!;
  return [
    UTCDate.create(currentYear, 0, 1).unixTime!,
    UTCDate.create(currentYear, 1, 1).unixTime!,
    UTCDate.create(currentYear, 2, 1).unixTime!,
    UTCDate.create(currentYear, 3, 1).unixTime!,
    UTCDate.create(currentYear, 4, 1).unixTime!,
    UTCDate.create(currentYear, 5, 1).unixTime!,
    UTCDate.create(currentYear, 6, 1).unixTime!,
    UTCDate.create(currentYear, 7, 1).unixTime!,
    UTCDate.create(currentYear, 8, 1).unixTime!,
    UTCDate.create(currentYear, 9, 1).unixTime!,
    UTCDate.create(currentYear, 10, 1).unixTime!,
    UTCDate.create(currentYear, 11, 1).unixTime!,
    UTCDate.create(currentYear, 11, 31).unixTime!,
  ];
};

export const getChartRangeValues = (
  data: AerCalculation[] | undefined
): { dataMin: number; dataMax: number } => {
  const dataValues =
    data
      ?.filter((item) => item !== null && item.value !== null)
      .map((v) => v!.value!) ?? [];
  const maxAer = Math.max(...dataValues) || 0;
  const adjustment = 1;

  const dataMin = 0;
  const dataMax = maxAer + adjustment;

  return {
    dataMin: dataMin,
    dataMax: dataMax,
  };
};

export const keyProps: ChartKeyProps = {
  items: [{ id: 1, name: 'AER', fill: theme.colors.cyan }],
};
