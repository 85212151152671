import { gql } from '@apollo/client';

export const GET_ALERTS = gql`
  query GetFleetAlerts(
    $vesselImoNumbers: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $pageSize: Int
    $pageNumber: Int
    $orderBy: String
    $sortDirection: String
    $alertStatuses: [String!]
    $isArchived: Boolean
    $containsQueryTerm: String!
  ) {
    fleetAlerts(
      fleetParameters: {
        vesselImoNumbers: $vesselImoNumbers
        startDate: $startDate
        endDate: $endDate
        containsQueryTerm: $containsQueryTerm
      }
      paginationParameters: { pageSize: $pageSize, pageNumber: $pageNumber }
      sortingParameters: { orderBy: $orderBy, sortDirection: $sortDirection }
      alertParameters: {
        alertStatuses: $alertStatuses
        isArchived: $isArchived
      }
    ) {
      total
      alerts {
        id
        vesselImo
        vesselName
        reportedOn
        reportingSystem
        alertDetails
        status
        description
        alertType
        alertSource
        isRead
        isArchived
        isSelected @client
        comments {
          id
          date
          text
          authorName
          userId
        }
      }
    }
  }
`;

export const GET_ALERT_CHART_DATA = gql`
  query GetAlertsChartData(
    $vesselImoNumbers: [String!]
    $startDate: DateTime
    $endDate: DateTime
  ) {
    fleetAlerts(
      fleetParameters: {
        vesselImoNumbers: $vesselImoNumbers
        startDate: $startDate
        endDate: $endDate
      }
      alertParameters: { totalsOnly: true, useAlertStartDateSetting: true }
    ) {
      alerts {
        reportedOn
        reportedOnTotal
      }
    }
  }
`;

export const GET_ALERT_COUNT = gql`
  query GetAlertCount(
    $vesselImoNumbers: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $alertStatuses: [String!]
    $isArchived: Boolean
    $isRead: Boolean
    $containsQueryTerm: String
  ) {
    fleetAlerts(
      fleetParameters: {
        vesselImoNumbers: $vesselImoNumbers
        startDate: $startDate
        endDate: $endDate
        containsQueryTerm: $containsQueryTerm
      }
      paginationParameters: { pageSize: 0, pageNumber: 1 }
      alertParameters: {
        alertStatuses: $alertStatuses
        isArchived: $isArchived
        isRead: $isRead
      }
    ) {
      total
    }
  }
`;

export const UPDATE_ALERT = gql`
  mutation UpdateAlert($alert: AlertInput!) {
    updateAlert(alert: $alert) {
      id
      status
      reportedOn
      isRead
      isArchived
      comments {
        id
        date
        text
      }
    }
  }
`;

export const UPDATE_ALERTS = gql`
  mutation UpdateAlerts($alerts: [AlertInput!]!) {
    updateAlerts(alerts: $alerts) {
      id
      status
      reportedOn
      isRead
      isArchived
      comments {
        id
        date
        text
      }
    }
  }
`;
