import { ProjectionInput } from '_gql/graphql';
import {
  convertStringToNumber,
  formatFloatToNumber,
} from 'shared/utils/float-utils';
import { CiiProjectionsFormModel } from '../models/forms/cii-projections.form.model';
import { ProjectionConditionsDomain } from '../models/vessel-cii-rating.model';

export class CiiProjectionsFormMapper {
  public static toModel(
    projectionConditions: ProjectionConditionsDomain | undefined
  ): CiiProjectionsFormModel {
    return {
      avgBallastSpeed:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgBallastSpeed
        ) ?? 0,
      avgBallastSpeedYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.avgBallastSpeed
        ) ?? 0,
      avgBallastSpeedTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgBallastSpeed
        ) ?? 0,
      avgLadenSpeed:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgLadenSpeed
        ) ?? 0,
      avgLadenSpeedYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.avgLadenSpeed
        ) ?? 0,
      avgLadenSpeedTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgLadenSpeed
        ) ?? 0,
      avgBallastFoc:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgBallastFoc
        ) ?? 0,
      avgBallastFocYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.avgBallastFoc
        ) ?? 0,
      avgBallastFocTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgBallastFoc
        ) ?? 0,
      avgLadenFoc:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgLadenFoc
        ) ?? 0,
      avgLadenFocYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.avgLadenFoc
        ) ?? 0,
      avgLadenFocTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgLadenFoc
        ) ?? 0,
      idleTime:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.idleTime
        ) ?? 0,
      idleTimeYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.idleTime
        ) ?? 0,
      idleTimeTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.idleTime
        ) ?? 0,
      distance:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.distance
        ) ?? 0,
      distanceYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.distance
        ) ?? 0,
      distanceTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.distance
        ) ?? 0,
      ballast:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.ballast
        ) ?? 0,
      ballastYtd:
        formatFloatToNumber(projectionConditions?.yearToDateMetrics?.ballast) ??
        0,
      ballastTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.ballast
        ) ?? 0,
      laden:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.laden
        ) ?? 0,
      ladenYtd:
        formatFloatToNumber(projectionConditions?.yearToDateMetrics?.laden) ??
        0,
      ladenTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.laden
        ) ?? 0,
    };
  }

  public static fromUpdate(
    projectionConditions: ProjectionConditionsDomain | undefined
  ): CiiProjectionsFormModel {
    return {
      avgBallastSpeed:
        formatFloatToNumber(
          projectionConditions?.inputMetrics?.avgBallastSpeed
        ) ?? 0,
      avgBallastSpeedYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.avgBallastSpeed
        ) ?? 0,
      avgBallastSpeedTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgBallastSpeed
        ) ?? 0,
      avgLadenSpeed:
        formatFloatToNumber(
          projectionConditions?.inputMetrics?.avgLadenSpeed
        ) ?? 0,
      avgLadenSpeedYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.avgLadenSpeed
        ) ?? 0,
      avgLadenSpeedTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgLadenSpeed
        ) ?? 0,
      avgBallastFoc:
        formatFloatToNumber(
          projectionConditions?.inputMetrics?.avgBallastFoc
        ) ?? 0,
      avgBallastFocYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.avgBallastFoc
        ) ?? 0,
      avgBallastFocTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgBallastFoc
        ) ?? 0,
      avgLadenFoc:
        formatFloatToNumber(projectionConditions?.inputMetrics?.avgLadenFoc) ??
        0,
      avgLadenFocYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.avgLadenFoc
        ) ?? 0,
      avgLadenFocTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.avgLadenFoc
        ) ?? 0,
      idleTime:
        formatFloatToNumber(projectionConditions?.inputMetrics?.idleTime) ?? 0,
      idleTimeYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.idleTime
        ) ?? 0,
      idleTimeTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.idleTime
        ) ?? 0,
      distance:
        formatFloatToNumber(projectionConditions?.inputMetrics?.distance) ?? 0,
      distanceYtd:
        formatFloatToNumber(
          projectionConditions?.yearToDateMetrics?.distance
        ) ?? 0,
      distanceTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.distance
        ) ?? 0,
      ballast:
        formatFloatToNumber(projectionConditions?.inputMetrics?.ballast) ?? 0,
      ballastYtd:
        formatFloatToNumber(projectionConditions?.yearToDateMetrics?.ballast) ??
        0,
      ballastTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.ballast
        ) ?? 0,
      laden:
        formatFloatToNumber(projectionConditions?.inputMetrics?.laden) ?? 0,
      ladenYtd:
        formatFloatToNumber(projectionConditions?.yearToDateMetrics?.laden) ??
        0,
      ladenTwelveMonthsRolling:
        formatFloatToNumber(
          projectionConditions?.twelveMonthRollingMetrics?.laden
        ) ?? 0,
    };
  }

  public static toInput(
    vesselId: string,
    data: CiiProjectionsFormModel
  ): ProjectionInput {
    return {
      vesselId,
      averageBallastSpeed: convertStringToNumber(data.avgBallastSpeed),
      averageLadenSpeed: convertStringToNumber(data.avgLadenSpeed),
      averageBallastFocPerDay: convertStringToNumber(data.avgBallastFoc),
      averageLadenFocPerDay: convertStringToNumber(data.avgLadenFoc),
      idleTimePercentage: convertStringToNumber(data.idleTime),
      distance: convertStringToNumber(data.distance),
      ballastPercentage: convertStringToNumber(data.ballast),
      ladenPercentage: convertStringToNumber(data.laden),
    };
  }
}
