import { SxProps } from '@mui/material';
import { theme } from 'styles/theme';

export const InputStyles: SxProps = {
  color: theme.palette.text.primary,
  backgroundColor: theme.validatedModal.inputBackground,
  borderRadius: '6px',
  border: 'none',
  height: '48px',
  width: '100%',
  '& .MuiInputLabel-shrink': {
    fontSize: '14px',
    left: '12px',
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
  },
  '& .MuiInput-input': {
    paddingLeft: '12px',
    paddingRight: '12px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: '53px',
  },
  '& .MuiFormLabel-root': {
    paddingTop: '4px',
    fontSize: '14px',
    color: theme.palette.text.secondary,
    transformOrigin: 'center left',
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.error.light,
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
  '& .MuiFormLabel-root.Mui-disabled .MuiFormLabel-asterisk': {
    color: theme.palette.text.disabled,
  },
};

export const SelectStyles: SxProps = {
  color: theme.palette.text.primary,
  backgroundColor: theme.validatedModal.inputBackground,
  borderRadius: '6px',
  border: 'none',
  height: '48px',
  width: '100%',
  '& .MuiInputLabel-shrink': {
    fontSize: '14px',
    color: theme.palette.text.primary,
  },
  '& .MuiFormLabel-root': {
    paddingTop: '12px',
    fontSize: '14px',
    color: theme.palette.text.secondary,
    transformOrigin: 'center left',
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.error.light,
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    pb: 1,
    pt: 3,
  },
  '& .MuiInputBase-root': {
    height: '48px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: '48px',
    top: '0px',
  },
};

export const ErrorMessageIconStyles: SxProps = {
  color: theme.palette.error.main,
  width: '12px',
  mr: '3px',
  position: 'relative',
  top: '-1px',
};
