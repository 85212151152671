import { createContext } from 'react';
import { DownloadListVessel } from 'routes/reports/components/generateReport/download-common';
import { DateRange } from 'shared/utils/date-utc-helper';

export interface INavBarContext {
  imoNumbers: string[];
  setImoNumbers: Function;
  vessels: DownloadListVessel[];
  setVessels: Function;
  utcDateRange: DateRange | null;
  setUTCDateRange: Function;
  setDateRange: Function;
  alertCount: number;
  setAlertCount: Function;
}
const navBarContext: INavBarContext = {
  imoNumbers: [],
  vessels: [],
  setImoNumbers: () => {},
  setVessels: () => {},
  utcDateRange: null,
  setUTCDateRange: () => {},
  setDateRange: () => {},
  alertCount: 0,
  setAlertCount: () => {},
};

export const NavBarContext = createContext<INavBarContext>(navBarContext);
