import { gql } from '@apollo/client';

export const GET_VOYAGE_INFO_FOR_DRAWER_DASHBOARD = gql`
  query Get_ActiveVoyageInfoForDrawer(
    $imoNumber: String!
    $alertStatuses: [String!]
    $isArchived: Boolean
    $isRead: Boolean
  ) {
    vessels(where: { imoNumber: { eq: $imoNumber } }) {
      id
      name
      imoNumber
      currentPosition {
        id
        latitude
        longitude
        heading
        timestamp
      }
      activeVoyage {
        voyageNumber
        location
        status
      }
      alerts(
        alertParameters: {
          alertStatuses: $alertStatuses
          isArchived: $isArchived
          isRead: $isRead
        }
      ) {
        total
      }
    }
  }
`;
