import {
  CiiDataPoint,
  CiiDataToggles,
  FuelOilConsumptionDataToggles,
} from 'routes/environmental-monitor/models/vessel-cii-rating.model';

const opacities = {
  strong: '1',
  medium: '0.35',
  light: '0.3',
};

const baseColors = {
  speed: 'rgba(255, 128, 171, {opacity})',
  loadingCondition: 'rgba(239,239,240, {opacity})',
  fuelOilConsumption: 'rgba(185, 246, 202, {opacity})',
  operationalProfile: 'rgba(49, 157, 180, {opacity})',
  vesselCondition: 'rgba(179, 136, 255, {opacity})',
  vesselComparison: 'rgba(255, 213, 79, {opacity})',
};

export const customColors = {
  speed: {
    circleFill: baseColors['speed'].replace('{opacity}', opacities.strong),
    selectedColor: baseColors['speed'].replace('{opacity}', opacities.medium),
  },
  loadingCondition: {
    circleFill: {
      border: '#ffffff',
      inner: baseColors['loadingCondition'].replace(
        '{opacity}',
        opacities.light
      ),
    },
    selectedColor: baseColors['loadingCondition'].replace(
      '{opacity}',
      opacities.light
    ),
  },
  fuelOilConsumption: {
    circleFill: baseColors['fuelOilConsumption'].replace(
      '{opacity}',
      opacities.strong
    ),
    selectedColor: baseColors['fuelOilConsumption'].replace(
      '{opacity}',
      opacities.medium
    ),
  },
  operationalProfile: {
    circleFill: {
      border: baseColors['operationalProfile'].replace(
        '{opacity}',
        opacities.strong
      ),
      inner: baseColors['operationalProfile'].replace(
        '{opacity}',
        opacities.strong
      ),
      borderStyle: 'dashed',
    },
    selectedColor: baseColors['operationalProfile'].replace(
      '{opacity}',
      opacities.light
    ),
  },
  vesselCondition: {
    circleFill: baseColors['vesselCondition'].replace(
      '{opacity}',
      opacities.medium
    ),
    selectedColor: baseColors['vesselCondition'].replace(
      '{opacity}',
      opacities.light
    ),
  },
  vesselComparison: {
    circleFill: baseColors['vesselComparison'].replace(
      '{opacity}',
      opacities.strong
    ),
    selectedColor: baseColors['vesselComparison'].replace(
      '{opacity}',
      opacities.light
    ),
  },
};

export const getSelectionsLeft = (): string => {
  // Your logic here
  return '_____';
};

export const getButtonKeys = (data: CiiDataToggles): string[] => {
  return Object.keys(data)
    .map((key) => {
      const values = data[key as keyof typeof data];
      if (Array.isArray(values) && values?.length) return key;

      // Subtypes of data toggles (i.e. fuelOilConsumption)
      if (typeof values === 'object' && values !== null) {
        const subKeys = Object.keys(values);
        if (subKeys.length) return key;
      }

      return '';
    })
    .filter((key) => key !== '');
};

export const getDataMap = (
  data: CiiDataToggles,
  key: string
): CiiDataPoint[] | FuelOilConsumptionDataToggles =>
  data[key as keyof typeof data] || [];

export const getColorsForKey = (key: string) =>
  customColors[key as keyof typeof customColors];

export const formatTitle = (str: string, additinalMark = ''): string => {
  return (
    str
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()) + additinalMark
  );
};
