import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Popper,
  TextFieldProps,
  autocompleteClasses,
} from '@mui/material';
import {
  EMPTY_PORT_DATA,
  PortData,
  fetchPortData,
} from 'shared/utils/portpolygons-util';
import { theme } from 'styles/theme';
import { styled } from '@mui/material/styles';
import { FormTextField } from 'shared/components/inputs/input-fields';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.background.componentLight,
    boxSizing: 'border-box',
    padding: 0,
    borderRadius: '6px',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.scrollbar.scrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.scrollbar.scrollbarThumb,
      borderRadius: '4px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '.MuiAutocomplete-option': {
      paddingLeft: '12px',
      '&[aria-selected="true"]': {
        background: theme.palette.action.selected,
        '&.Mui-focused': {
          background: theme.palette.action.focus,
        },
      },
    },
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

type PortSelectionInputProps = TextFieldProps & {
  onMenuChange: (value: PortData) => void;
};
export const PortSelectionInput: React.FC<PortSelectionInputProps> = ({
  onMenuChange,
  ...props
}) => {
  const [ports, setPorts] = useState<PortData[]>([]);

  useEffect(() => {
    async function asyncFetchPorts() {
      try {
        const ports = await fetchPortData();
        setPorts([EMPTY_PORT_DATA, ...ports]);
      } catch (err) {
        console.log(err);
      }
    }
    asyncFetchPorts();
  }, []);

  const [autoCompleteError, setAutoCompleteError] = useState<string | boolean>(
    false
  );

  function onInvalid() {
    if (props.required)
      if (!props.value || (props.value as PortData).UNLOCODE === '') {
        const message =
          props.label !== undefined && typeof props.label === 'string'
            ? props.label.replace(/\(.*?\)/g, '') + ' is required'
            : 'This field is required';
        setAutoCompleteError(message);
        return;
      }
  }

  const inputName = 'port-selection' + `${props.name ?? props.label}`;

  return (
    <Autocomplete
      disablePortal
      options={ports}
      sx={props.sx ?? {}}
      PopperComponent={StyledPopper}
      getOptionLabel={(option) => option?.Port_Name}
      isOptionEqualToValue={(option, value) => {
        return option?.Port_Name === value.Port_Name;
      }}
      value={props.value as PortData}
      renderInput={(params) => (
        <FormTextField
          {...(params as TextFieldProps)}
          {...(props as TextFieldProps)}
          name={inputName}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          variant='filled'
          onInvalid={() => onInvalid()}
          error={!!autoCompleteError}
          helperText={autoCompleteError}
        />
      )}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onChange={(_, newValue: PortData | null) => {
        if (newValue === null || newValue.UNLOCODE === '') return;
        setAutoCompleteError(false);
        return onMenuChange(newValue);
      }}
    />
  );
};
