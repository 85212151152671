import { useGetStatementOfFactReportPdfData } from '../repositories/statement-of-fact-report-pdf.repository';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useStatementOfFactReportPdf = (
  dateRange: DateRange,
  imoNumber: string | null
) => {
  const response = useGetStatementOfFactReportPdfData(dateRange, imoNumber);
  const data = response?.data;
  const loading = response?.loading;
  const error = response?.error;

  return {
    data,
    loading,
    error,
  };
};
