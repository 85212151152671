import { ServiceResult } from 'shared/models/serviceResult.model';
import { IFuelType } from '../models/fuelType.model';
import { useGetFuelTypeData } from '../repositories/fuelTypes.repository';
import { FuelTypesMapper } from '../mappers/fuel-type.mapper';

export const useGetFuelTypes = (): ServiceResult<IFuelType[]> => {
  try {
    const response = useGetFuelTypeData();
    const loading = response?.loading;
    const error = response?.error;
    let data: IFuelType[] = [];
    if (response?.data?.fuelTypes && response?.data?.fuelTypes.length > 0) {
      data = FuelTypesMapper.toDomain(response?.data?.fuelTypes);
    }

    return {
      data: data,
      loading,
      error,
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};
