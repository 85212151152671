import { xAxisTickFormatter } from '../../../view-models/aer-chart.viewModel';
import { Tooltip as CustomTooltip } from '../aer/tooltip.component';
import {
  getChartRangeValues,
  subDataToggleSelected,
  yAxisTickFormatter,
  yAxisTitle,
} from 'routes/environmental-monitor/view-models/cii-ratings-chart.viewModel';
import {
  CiiBoundaries,
  CiiDataTogglesChartData,
  CiiDataTogglesDisplay,
} from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import { ChartKeyItem } from 'shared/components/chart-key/chart-key.component';
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import { getYAxisTicks } from 'routes/environmental-monitor/view-models/utils/graph-utils';
import { TopBarDates } from '../../shared/topbar-dates.component';

export interface LineChartsProps {
  chartData: CiiDataTogglesChartData[];
  chartRangeValues: {
    dataMin: number;
    dataMax: number;
    chartMax: number;
  };
  displayDataToggles: any;
  requiredCii: number | undefined;
  ciiBoundaries: CiiBoundaries | undefined;
  currentTime: number;
  chartKeysToDisplay: ChartKeyItem[];
  keysToDisplay: string[];
  xAxisTicks: number[];
  yAxisTicks: number[];
  domain: { min: number; max: number };
  binaryDataItems: string[];
}

export const LineCharts = (props: LineChartsProps) => {
  return (
    <ResponsiveContainer width='99%' height={415}>
      <ComposedChart
        data={props.chartData}
        margin={{
          top: 20,
          bottom: 60,
          left: 0,
          right: 25,
        }}
      >
        <XAxis
          dataKey='date'
          angle={-35}
          tick={{ fill: theme.colors?.offWhite }}
          type='number'
          ticks={props.xAxisTicks}
          textAnchor='end'
          tickFormatter={xAxisTickFormatter}
          fontSize={12}
          domain={[props.domain.min, props.domain.max]}
          padding={{ left: 8, right: 0 }}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey={'ciiRating'}
          tickLine={false}
          tick={{ fill: theme.colors?.offWhite }}
          tickCount={props.yAxisTicks.length}
          ticks={props.yAxisTicks}
          tickFormatter={(tick) =>
            yAxisTickFormatter(tick, 'ciiRating', props.requiredCii)
          }
          interval={0}
          tickMargin={0}
          type='number'
          domain={[
            props.chartRangeValues.dataMin,
            props.chartRangeValues.chartMax,
          ]}
          axisLine={false}
          width={120}
        >
          <Label
            dy={60}
            fontSize={12}
            fill={theme.palette.text.primary}
            position={'insideLeft'}
            offset={25}
            angle={-90}
            value={yAxisTitle}
          />
        </YAxis>

        <CartesianGrid
          color={theme.colors.white}
          vertical={false}
          strokeDasharray='0'
          opacity={0.14}
        />

        <Tooltip content={<CustomTooltip chartType='CII' />} cursor={false} />

        <Line
          activeDot={true}
          dataKey={'ciiRating'}
          dot={false}
          stroke={'#4DE8E3'}
          connectNulls
          strokeWidth={3}
          type='linear'
        />

        <>
          {props.keysToDisplay.map((name, i) => {
            const subKeyToDisplay =
              (Array.isArray(
                props.displayDataToggles?.[name as keyof CiiDataTogglesDisplay]
              )
                ? ''
                : subDataToggleSelected(
                    props.displayDataToggles?.[
                      name as keyof CiiDataTogglesDisplay
                    ]
                  )) ?? '';

            const dataValuesDataToggle =
              props.chartData
                ?.filter(
                  (v) =>
                    !!v[
                      (name + subKeyToDisplay) as keyof CiiDataTogglesChartData
                    ]
                )
                .map(
                  (v) =>
                    v[
                      (name + subKeyToDisplay) as keyof CiiDataTogglesChartData
                    ] as number
                ) ?? [];
            const chartRangeValuesDataToggle = getChartRangeValues(
              dataValuesDataToggle,
              props.ciiBoundaries
            );

            const yAxisTicksDataToggle = getYAxisTicks(
              chartRangeValuesDataToggle.dataMin,
              chartRangeValuesDataToggle.dataMax
            );

            const lineColor =
              props.chartKeysToDisplay.find((k) => k.key === name)?.fill ?? '';
            return (
              <>
                {!props.binaryDataItems.includes(name) && (
                  <>
                    <YAxis
                      yAxisId={name + subKeyToDisplay}
                      fontSize={12}
                      dataKey={name + subKeyToDisplay}
                      tickLine={false}
                      tick={{ fill: lineColor }}
                      tickCount={yAxisTicksDataToggle.length}
                      ticks={yAxisTicksDataToggle}
                      tickFormatter={(tick) =>
                        yAxisTickFormatter(tick, name, props.requiredCii)
                      }
                      interval={0}
                      tickMargin={0}
                      type='number'
                      domain={[
                        chartRangeValuesDataToggle.dataMin,
                        chartRangeValuesDataToggle.chartMax * 1.025,
                      ]}
                      axisLine={false}
                      orientation={'right'}
                      dx={i === 0 ? 32 : 20}
                    ></YAxis>
                    <Line
                      yAxisId={name + subKeyToDisplay}
                      key={name + subKeyToDisplay}
                      activeDot={false}
                      dataKey={name + subKeyToDisplay}
                      dot={false}
                      connectNulls
                      stroke={lineColor}
                      strokeWidth={3}
                      type='linear'
                    />
                  </>
                )}
              </>
            );
          })}
        </>

        {props.binaryDataItems.some((bdt) =>
          props.keysToDisplay.includes(bdt)
        ) && (
          <>
            <defs>
              <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                <stop
                  offset='5%'
                  stopColor={theme.colors?.offWhite}
                  stopOpacity={0.8}
                />
                <stop
                  offset='95%'
                  stopColor={theme.colors?.offWhite}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            {props.chartData
              .sort((x1, x2) => x1.date - x2.date)
              .map((v) => {
                if (v.loadingCondition === 1) {
                  const index = props.chartData.findIndex(
                    (d) => d.date === v.date
                  );
                  if (index === props.chartData.length - 2) {
                    return;
                  }
                  return (
                    <>
                      <ReferenceArea
                        x1={v.date}
                        x2={props.chartData[index + 1]?.date ?? v.date}
                        y1={0}
                        y2={props.chartRangeValues.dataMax}
                        fill={'url(#colorUv)'}
                      />
                      <ReferenceArea
                        x1={v.date}
                        x2={props.chartData[index + 1]?.date ?? v.date}
                        y1={0}
                        y2={0.14}
                        fill={theme.colors?.white}
                      />
                    </>
                  );
                }
                return <></>;
              })}
          </>
        )}

        {TopBarDates({
          props: {
            currentTime: props.currentTime,
            min: props.domain.min,
            max: props.domain.max,
            dataMax: props.chartRangeValues.dataMax,
            chartMax: props.chartRangeValues.chartMax,
          },
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
