import { Grid } from '@mui/material';
import { useContext, useEffect } from 'react';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { StatusCardError } from 'shared/components/status-card/status-card-error.component';
import { StatusKpiCard } from 'shared/components/status-kpi-card/status-kpi-card.component';
import { TEXT } from 'shared/constants/text';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { NavBarContext } from 'shared/models/navBar.context.model';
import {
  deltaPowerTotalCo2PenaltyTitle,
  deltaPowerTotalFuelPenaltyTitle,
  getKpiCardCo2Props,
  getKpiCardFuelProps,
} from '../models/vesselPerformance.viewModel';
import { useGetVesselPerformanceKpis } from '../services/vessel-performance-kpi.service';

export const FleetKPICards = () => {
  const { imoNumbers, utcDateRange } = useContext(NavBarContext);
  const { setCardData } = useContext(ExcelExportContext);

  const { kpiData, kpiError, kpiLoading } = useGetVesselPerformanceKpis(
    utcDateRange,
    imoNumbers
  );

  let kpiCardFuel;
  let kpiCardCo2;

  useEffect(() => {
    if (!kpiLoading && kpiData) {
      const realKpiData = {
        deltaPowerTotalFuelPenalty: getKpiCardFuelProps(
          kpiData.deltaPowerTotalFuelPenalty
        ),
        deltaPowerTotalCo2Penalty: getKpiCardCo2Props(
          kpiData.deltaPowerTotalCo2Penalty
        ),
      };
      setCardData(realKpiData);
    }
  }, [kpiLoading]);

  if (kpiLoading) {
    return <KpiCardSkeleton kpiCards={[{}, {}]} />;
  }

  if (kpiError) {
    kpiCardFuel = (
      <StatusCardError
        message={`Unable to load ${deltaPowerTotalFuelPenaltyTitle}`}
        subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
      />
    );
    kpiCardCo2 = (
      <StatusCardError
        message={`Unable to load ${deltaPowerTotalCo2PenaltyTitle}`}
        subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
      />
    );
  } else if (
    kpiData &&
    kpiData?.deltaPowerTotalFuelPenalty &&
    kpiData?.deltaPowerTotalCo2Penalty
  ) {
    kpiCardFuel = (
      <StatusKpiCard
        {...getKpiCardFuelProps(kpiData.deltaPowerTotalFuelPenalty)}
      />
    );
    kpiCardCo2 = (
      <StatusKpiCard
        {...getKpiCardCo2Props(kpiData.deltaPowerTotalCo2Penalty)}
      />
    );
  }

  return (
    <Grid container spacing={2} sx={{ marginTop: 0 }}>
      <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
        {kpiCardFuel}
      </Grid>
      <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
        {kpiCardCo2}
      </Grid>
    </Grid>
  );
};
