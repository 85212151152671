import { styled } from '@mui/material';
import { useDrawingArea, useXScale } from '@mui/x-charts-pro';
import { Fragment } from 'react';
import { Label, ReferenceArea } from 'recharts';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { theme } from 'styles/theme';
import { ScaleBand } from 'd3-scale';
import { typography } from 'styles/typography';

export function TopBarDates({
  props,
}: {
  props: {
    currentTime: number;
    min: number;
    max: number;
    dataMax: number;
    chartMax: number;
    leftLabel?: string;
  };
}) {
  return (
    <>
      <ReferenceArea
        ifOverflow='extendDomain'
        x1={props.min}
        x2={props.currentTime}
        y1={props.dataMax}
        y2={props.chartMax}
        stroke={theme.colors?.blueGray}
        strokeOpacity={0.6}
        fill={theme.colors?.blueGray}
        fillOpacity={1}
      >
        {!new UTCDate().isBeginningOfYear() && (
          <Label
            value={props.leftLabel ?? 'Year to Date'}
            position='center'
            fill={theme.palette.text.primary}
            fontSize={14}
          />
        )}
      </ReferenceArea>
      <ReferenceArea
        ifOverflow='visible'
        x1={props.currentTime}
        x2={props.max}
        y1={props.dataMax}
        y2={props.chartMax}
        stroke={theme.colors.white}
        strokeOpacity={1}
        fill={theme.colors.white}
        fillOpacity={1}
      >
        {!new UTCDate().isEndOfYear() && (
          <Label
            value='End of Year Projections'
            position='center'
            fill='black'
            fontSize={14}
          />
        )}
      </ReferenceArea>
    </>
  );
}

const StyledText = styled('text')(() => ({
  shapeRendering: 'crispEdges',
  dominantBaseline: 'middle',
  textAnchor: 'middle',
  fontSize: typography.fontSize[3],
}));

// This component is used to draw the top bar dates in the chart (Mui version)
export function TopBarDatesMui() {
  const { left, top, width } = useDrawingArea();

  const verticalPosition = top - 65;
  const xAxisScale = useXScale(0) as ScaleBand<number>;
  const domain = xAxisScale.domain();
  const step = xAxisScale.step();

  const currentDate = new UTCDate();
  const currentUnixTime = currentDate.unixTime!;

  // find the nearest x value from domain
  const nearestValue =
    [...domain].reverse().find((x) => x <= currentUnixTime) ?? 0;
  const nearestIndex = domain.indexOf(nearestValue);
  const daysInMonth = currentDate.daysInMonth ?? 1;
  const daysToPxScale = step / daysInMonth;
  const daysToPx = daysToPxScale * currentDate.dateDayjs?.date()!;

  const monthPosX = step * nearestIndex;
  const x = monthPosX + daysToPx; // x position of the current date in the chart

  const showYearToDate = new UTCDate().isBeginningOfYear() === false;
  const showEndOfYear = new UTCDate().isEndOfYear() === false;
  return (
    <Fragment>
      <rect
        key={'blue'}
        x={left}
        y={verticalPosition}
        width={width}
        height={38}
        style={{ fill: theme.colors?.blueGray, stroke: theme.colors?.blueGray }}
        data-testid='blue-rect' // Add test id for the gradient background
      />
      {showYearToDate && (
        <StyledText x={left + x / 2} y={verticalPosition + 20} fill='white'>
          Year to Date
        </StyledText>
      )}
      <StyledText x={left + x / 2} y={verticalPosition + 20} fill='white'>
        Year to Date
      </StyledText>
      <rect
        key={'white'}
        x={left + x}
        y={verticalPosition}
        width={width - x}
        height={38}
        style={{ fill: theme.colors?.white, stroke: theme.colors?.white }}
        data-testid='white-rect' // Add test id for the gradient background
      />
      {showEndOfYear && (
        <StyledText
          x={left + x + (width - x) / 2}
          y={verticalPosition + 20}
          fill='black'
        >
          End of Year Projections
        </StyledText>
      )}
    </Fragment>
  );
}
