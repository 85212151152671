import { useQuery } from '@apollo/client';
import { GET_FLEET_SUMMARY_SCORES } from '../queries/fleet-summary.query';
import { Query } from '_gql/graphql';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetFleetSummaryScores = (dateRange: DateRange) => {
  const dateRangeAsString = dateRange.dateRangeAsString;
  const query = useQuery<Query>(GET_FLEET_SUMMARY_SCORES, {
    skip: !dateRangeAsString,
    variables: {
      ...dateRangeAsString,
    },
  });
  return query;
};
