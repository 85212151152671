import {
  Autocomplete,
  Box,
  AutocompleteProps,
  SxProps,
  TextField,
  Popper,
  autocompleteClasses,
  MenuItem,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AutocompleteStyles } from './input-styles';
import { theme } from 'styles/theme';
import { MDFFormHelperText } from './form-helper-text.component';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import React from 'react';
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';

interface FormAutocompleteProps
  extends Partial<
    AutocompleteProps<
      string,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >
  > {
  name: string;
  label: string;
  required: boolean;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    pl: '4px',
  };

  return (
    <MenuItem
      data-testid='location'
      {...dataSet[0]}
      style={inlineStyle}
      value={dataSet[1]}
    >
      {dataSet[1]}
    </MenuItem>
  );
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.background.componentLight,
    boxSizing: 'border-box',
    padding: 0,
    borderRadius: '6px',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.scrollbar.scrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.scrollbar.scrollbarThumb,
      borderRadius: '4px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '.MuiAutocomplete-option': {
      paddingLeft: '12px',
      '&[aria-selected="true"]': {
        background: theme.palette.action.selected,
        '&.Mui-focused': {
          background: theme.palette.action.focus,
        },
      },
    },
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach((item: React.ReactElement) => {
    itemData.push(item);
  });

  const itemCount = itemData.length;
  const gridRef = useResetCache(itemCount);
  const size = 36;

  const getChildSize = () => {
    return size;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * size;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={() => size}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

// Autocomplete field built for Event Modal
export const FormAutocomplete = (props: FormAutocompleteProps) => {
  const { control } = useFormContext();
  const { name, label, sx, options, required } = props;

  const getStyles = (error: boolean): SxProps => ({
    ...AutocompleteStyles,
    ...{
      border: `1px solid ${error ? theme.palette.error.main : 'transparent'}`,
    },
  });

  // adds the empty option due to empty default value
  const newOptions = ['', ...(options ? options : [])];

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <Box sx={{ width: '100%', ...sx }}>
            <Autocomplete
              id={`form-autocomplete-${name}`}
              data-testid={`form-autocomplete-${name}`}
              sx={getStyles(!!error)}
              onChange={(evt) => {
                const target = evt.target as HTMLElement;
                const newEvent = {
                  ...evt,
                  ...{
                    target: {
                      ...target,
                      value: target.textContent,
                    },
                  },
                };
                return onChange(newEvent);
              }}
              onBlur={onBlur}
              disableListWrap
              popupIcon={<ExpandMore />}
              value={value}
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              options={newOptions ?? []}
              renderInput={(params) => (
                <TextField
                  id={params.id}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  inputProps={{ ...params.inputProps }}
                  InputLabelProps={{
                    htmlFor: params.id,
                    id: params.id,
                    shrink: true,
                  }}
                  fullWidth={params.fullWidth}
                  variant='standard'
                  label={label}
                  required={required}
                />
              )}
              renderOption={(props, option, state) =>
                [props, option, state.index] as React.ReactNode
              }
            />
            {error && <MDFFormHelperText errorMessage={error.message} />}
          </Box>
        );
      }}
    />
  );
};
