import { VesselEvent } from '_gql/graphql';
import {
  TrendItem,
  TrendLine,
  TrendPoint,
} from '../../routes/vessel-performance/individual-vessel-performance/models/vessel-performance-trends.model';
import { createTrend } from 'trendline';
import { UTCDate } from './date-utc-helper';

export interface LinearRegressionModel {
  slope: number;
  intercept: number;
}

export const sum = (array: number[]): number => {
  return array.reduce((a, b) => a + b, 0);
};

export const getTrendLineData = (
  trendItems: TrendItem[],
  vesselEvents: VesselEvent[],
  startDate: UTCDate | undefined,
  endDate: UTCDate | undefined,
  domainY: number[] | undefined
): TrendLine[] => {
  let trendLines: TrendLine[] = [];
  const dateList: Array<number | undefined> = [];

  const filteredTrendItems = trendItems
    .filter((item) => !item.isOutlier && !item.isMissingData)
    .sort((a, b) => a.x - b.x);

  if (filteredTrendItems.length <= 0) {
    return [];
  }

  const gdrStartDateTick = startDate?.unixTime;
  const gdrEndDateTick = endDate?.unixTime;

  if (vesselEvents.length <= 0) {
    dateList.push(gdrStartDateTick);
    dateList.push(gdrEndDateTick);
  } else {
    vesselEvents.forEach((event: any) => {
      let eventDatesAdded = false;
      const eventStartDateTick = event.x; // Get event start date
      const eventEndDateTick = event.x2; // Get event end date

      // If event spans over both start and end date, there should be
      // no data points and the chart will just be the event stretch over // it
      if (
        gdrStartDateTick !== undefined &&
        gdrEndDateTick !== undefined &&
        eventStartDateTick >= gdrStartDateTick &&
        gdrStartDateTick <= eventEndDateTick &&
        eventStartDateTick <= gdrEndDateTick &&
        gdrEndDateTick <= eventEndDateTick
      ) {
        return; // No trend line
      }

      // Does the event overlap the start date?
      // If so update our start date tick
      if (
        gdrStartDateTick !== undefined &&
        gdrEndDateTick !== undefined &&
        eventStartDateTick <= gdrStartDateTick &&
        gdrStartDateTick <= eventEndDateTick
      ) {
        dateList.push(eventEndDateTick);
        eventDatesAdded = true;
      }

      // Does the event overlap the end date?
      // If so update our start date tick
      if (
        gdrEndDateTick !== undefined &&
        eventStartDateTick <= gdrEndDateTick &&
        gdrEndDateTick <= eventEndDateTick
      ) {
        dateList.push(eventStartDateTick);
        eventDatesAdded = true;
      }

      if (!eventDatesAdded) {
        if (dateList.length <= 1) {
          dateList.push(gdrStartDateTick);
        }
        dateList.push(eventStartDateTick);
        dateList.push(eventEndDateTick);
      }
    });
  }
  dateList.push(gdrEndDateTick);
  if (dateList.length < 2) {
    return [];
  }

  // sort dateList ascending
  dateList.filter((item) => item !== undefined).sort((a, b) => a! - b!);

  let flipFlag = false;

  dateList.forEach((date, index, arr) => {
    if (!flipFlag) {
      const trendLineItem: TrendLine = {
        id: date?.toString() ?? '',
        start: {
          x: date ?? 0,
          y: 0, // initializing some random value, it will be updated in below section
        },
        end: {
          x: arr[index + 1] ?? 0,
          y: 0, // initializing some random value, it will be updated in below section
        },
      };

      trendLines.push(trendLineItem);
    }
    flipFlag = !flipFlag;
  });

  const minY = Math.min(...(domainY ?? [0]));
  const maxY = Math.max(...(domainY ?? [100]));

  trendLines.forEach(function (currentTrendLineItem) {
    const trendLineDataSet = filteredTrendItems.filter(
      (ds) =>
        currentTrendLineItem.start.x <= ds.x &&
        ds.x <= currentTrendLineItem.end.x
    );
    if (trendLineDataSet.length < 2) {
      return;
    }

    const newTrendLineDataSet = trendLineDataSet.map((currentItem) => {
      return {
        x: currentItem.x,
        y: currentItem.y,
      };
    });

    const linearTrendObj = createTrend(newTrendLineDataSet, 'x', 'y');
    currentTrendLineItem.start.y = linearTrendObj.calcY(
      currentTrendLineItem.start.x
    );
    clampY(
      currentTrendLineItem.start,
      minY,
      maxY,
      linearTrendObj.slope,
      linearTrendObj.yStart
    );
    currentTrendLineItem.end.y = linearTrendObj.calcY(
      currentTrendLineItem.end.x
    );
    clampY(
      currentTrendLineItem.end,
      minY,
      maxY,
      linearTrendObj.slope,
      linearTrendObj.yStart
    );
    currentTrendLineItem.id = currentTrendLineItem.start.x.toFixed();
  });

  trendLines = trendLines.filter(
    (a) =>
      !(a.start.y === minY && a.end.y === minY) &&
      !(a.start.y === maxY && a.end.y === maxY)
  );

  return trendLines;
};

const clampY = (
  point: TrendPoint,
  minY: number,
  maxY: number,
  slope: number,
  yStart: number
) => {
  if (point.y < minY) {
    point.y = minY;
    point.x = calcX(slope, yStart, point.y);
  }
  if (point.y > maxY) {
    point.y = maxY;
    point.x = calcX(slope, yStart, point.y);
  }
};

const calcX = (slope: number, yStart: number, y: number): number => {
  if (slope === 0) {
    return 0;
  }
  return (y - yStart) / slope;
};
