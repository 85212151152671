import { useContext, useEffect, useState } from 'react';

import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { MapViewContext } from 'shared/models/mapView.context.model';

import Polyline from '@arcgis/core/geometry/Polyline';
import * as geodesicUtils from '@arcgis/core/geometry/support/geodesicUtils';
import Graphic from '@arcgis/core/Graphic';
import { RouteLineSymbol, RouteStyle } from './symbols';
import { SearchType } from './constants';
import { useActiveVoyagePositionsService } from '../services/activeVoyagePositions.get.service';
import { VesselLayer } from './vesselLayer';
export interface IGeographicPoint {
  Longitude: number;
  Latitude: number;
}

export interface IActiveVoyagePositions {
  imoNumber: string;
  activeVoyagePositions: {
    Type: RouteStyle;
    positions: IGeographicPoint[];
  };
}

async function AddRoutesToMap(
  graphicsLayer: GraphicsLayer,
  pathdata: IActiveVoyagePositions[]
) {
  graphicsLayer.removeAll(); // Reset Active Route graphics layer
  pathdata.forEach((route) => {
    AddRouteToMap(graphicsLayer, route);
  });
  graphicsLayer.listMode = pathdata.length > 0 ? 'show' : 'hide';
  graphicsLayer.visible = true;
}

async function AddRouteToMap(
  graphicsLayer: GraphicsLayer,
  pathdata: IActiveVoyagePositions
) {
  const path = pathdata.activeVoyagePositions.positions.map((x) => {
    return [x.Longitude, x.Latitude];
  });

  const polylineSymbol = new Polyline({ paths: [path] });

  // get a densified line, and adjust the projection for the map
  // see {@link https://developers.arcgis.com/javascript/latest/api-reference/esri-geometry-support-geodesicUtils.html#geodesicDensify}
  const polyline = geodesicUtils.geodesicDensify(polylineSymbol, 100000);
  const polylineGraphic = new Graphic({
    geometry: polyline,
    attributes: {
      imoNumber: pathdata.imoNumber,
    },
    symbol: RouteLineSymbol(pathdata.activeVoyagePositions.Type),
  });

  graphicsLayer.add(polylineGraphic);
}

export const ACTIVE_VOYAGE_LAYER_ID = 'activeVoyageLayerId';

export const ActiveVoyageLayer = ({
  data,
}: {
  data?: IActiveVoyagePositions[];
}) => {
  const { searchInfo, view } = useContext(MapViewContext);
  const [graphicsLayer, setGraphicsLayer] = useState<
    GraphicsLayer | undefined
  >();

  useEffect(() => {
    const _graphicsLayer = new GraphicsLayer({
      id: ACTIVE_VOYAGE_LAYER_ID,
      title: 'Active Voyage',
      listMode: 'hide',
    });
    setGraphicsLayer(_graphicsLayer);

    return () => {
      console.log('Removing layer');
    };
  }, []);

  useEffect(() => {
    if (!view || !graphicsLayer) return;

    view.map.layers.add(graphicsLayer, 3);
    const vesselLayer = view.map.findLayerById(VesselLayer.VESSEL_LAYER_ID);
    view.map.layers.reorder(vesselLayer, 4);
  }, [view, graphicsLayer]);

  // called when the "data" has changed
  useEffect(() => {
    if (!view || !graphicsLayer || !data) return;

    AddRoutesToMap(graphicsLayer, data);
  }, [view, graphicsLayer, data]);

  const getActiveVoyagePositions = useActiveVoyagePositionsService(
    searchInfo.searchId ?? ''
  );
  const refetch = getActiveVoyagePositions.refetch;

  useEffect(() => {
    if (!graphicsLayer || !searchInfo.searchId) return;

    if (searchInfo.searchType === SearchType.vessel) {
      const voyagePositionsResponse = getActiveVoyagePositions;
      if (voyagePositionsResponse && voyagePositionsResponse.data) {
        AddRoutesToMap(graphicsLayer, voyagePositionsResponse.data);
      }
    }
  }, [graphicsLayer, searchInfo, getActiveVoyagePositions]);

  useEffect(() => {
    refetch({ imoNumber: searchInfo.searchId ?? '' });
  }, [refetch, searchInfo]);

  return <></>;
};
