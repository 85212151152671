import { Fragment } from 'react';
import { Box, SxProps } from '@mui/system';
import { Typography } from '@mui/material';
import { MDFTooltip } from 'shared/components/tooltip/tooltip.component';

type EuaTooltip = {
  percentage: string;
  yearToDate: string;
  targetYear: string;
};

const euaLiabilitiesTooltipData: EuaTooltip[] = [
  {
    percentage: '40%',
    yearToDate: '2024',
    targetYear: '2025',
  },
  {
    percentage: '70%',
    yearToDate: '2025',
    targetYear: '2026',
  },
  {
    percentage: '100%',
    yearToDate: '2026',
    targetYear: '2027',
  },
];

const euaLiabilitiesTooltip = euaLiabilitiesTooltipData.map(
  ({ percentage, yearToDate, targetYear }, index) => (
    <Fragment key={index}>
      <Box>
        {percentage} {yearToDate} EUA CO₂ in {targetYear}
      </Box>
    </Fragment>
  )
);

export function euaLiabilitiesTooltipHeader() {
  const styleProperties: SxProps = {
    fontSize: 'inherit',
    textAlign: 'right',
  };
  return (
    <>
      <Box className='MuiDataGrid-columnHeaderTitle'>
        <Typography key={1} sx={styleProperties}>
          EUA
        </Typography>
        <Typography key={2} sx={styleProperties}>
          Liability
          <MDFTooltip
            CSSProps={{ paddingRight: 0, paddingLeft: '2px' }}
            tooltipText={euaLiabilitiesTooltip}
          ></MDFTooltip>
        </Typography>
      </Box>
    </>
  );
}
