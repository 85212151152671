// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { formatNumber } from './float-utils';
export const dashes = '---';

/**
 * @deprecated This method is deprecated and will be removed in future versions.
 use {@link formatNumber} instead
 */
export const formatNumberForDisplay = (
  value: number | string | undefined | null,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  returnDashes = true // if you don't want dashes, pass false
): string => {
  if (value === undefined || value === null) return returnDashes ? dashes : '';

  const num = typeof value === 'string' ? parseFloat(value) : value;
  const formatted = new Intl.NumberFormat('en-US', {
    useGrouping: true,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(num);

  if (formatted === 'NaN') return returnDashes ? dashes : '';

  return formatted;
};

export const showDashesIfEmpty = (value: string | undefined | null): string => {
  if (value === '' || value === undefined || value === null) {
    return '---';
  } else {
    return value;
  }
};

// this is similar to Object.groupBy
// but it requires Node 21 or higher for JEST to run
export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
) =>
  array.reduce((acc, value, index, array) => {
    const key = predicate(value, index, array);
    acc[key] ||= [];
    acc[key].push(value);
    return acc;
  }, {} as { [key: string]: T[] });
