import { Box, Button, FormControl, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useContext, useEffect } from 'react';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FormSelect } from 'routes/vessel-performance/individual-vessel-performance/components/events/form-inputs/form-select.component';
import { FormTextField } from 'routes/vessel-performance/individual-vessel-performance/components/events/form-inputs/form-text-field.component';
import { LocationsContext } from 'shared/components/contexts/locationsWrapper.component';
import { DateRange } from 'shared/components/date-range/dateRange.component';
import { RequiredAsterisk } from 'shared/components/required-asterisk/requiredAsterisk.component';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import { NavBarContext } from 'shared/models/navBar.context.model';
import {
  eventValidationSchema,
  useYupValidationResolver,
} from './events.validation';
import { FormAutocomplete } from './form-inputs/form-autocomplete.component';
import { EventsMapper } from './mappers/events.mapper';
import { EventType } from './models/constants';
import { EventModalValues, initialValues } from './models/events.model';
import { useCreateEvent, useUpdateEvent } from './services/events.service';

interface EventModalFormProps {
  editEvent?: boolean; // Boolean used to indicate whether this Modal is meant to be an Add or Edit
  values?: EventModalValues | undefined;
  loadingCallback: (isLoading: boolean) => void;
  closeCallback: () => void;
  errorCallback: (hasErrors: boolean) => void; // used to let parent modal know to render the Save Error warning
}

export const EventModalForm = (props: EventModalFormProps) => {
  const { closeCallback, errorCallback, loadingCallback, editEvent } = props;
  const theme = useTheme();
  const { id, vesselImo } = useParams();
  const { locationsOptions } = useContext(LocationsContext);

  const { utcDateRange } = useContext(NavBarContext);
  const { mutation: createEvent } = useCreateEvent(id, vesselImo, utcDateRange);
  const { mutation: updateEvent } = useUpdateEvent();

  const resolver = useYupValidationResolver(eventValidationSchema);

  const additionalDataFields = {
    vesselId: id ?? '',
    vesselImo: vesselImo ?? '',
  };

  const form = useForm<EventModalValues>({
    resolver,
    mode: 'onBlur',
    defaultValues: (props.values && {
      ...props.values,
      ...additionalDataFields,
    }) || {
      ...initialValues,
      ...additionalDataFields,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit: SubmitHandler<EventModalValues> = (eventData) => {
    handleSave(eventData);
  };

  const onSubmitError: SubmitErrorHandler<EventModalValues> = () => {
    errorCallback(true);
  };

  const errorCount = Object.keys(errors).length;
  useEffect(() => {
    if (errorCount === 0) {
      errorCallback(false);
    }
  }, [errorCallback, errorCount]);

  const handleSave = async (eventData: EventModalValues) => {
    const dtoEvent = EventsMapper.toDTO(eventData);
    loadingCallback(true);
    if (editEvent) {
      // do UPDATE operation
      try {
        await updateEvent({
          variables: { event: EventsMapper.formatUpdate(dtoEvent) },
        });
        closeCallback();
      } catch (error) {
        console.log(error);
      }
    } else {
      // do CREATE operation
      try {
        await createEvent({
          variables: { event: EventsMapper.formatCreate(dtoEvent) },
        });
        closeCallback();
      } catch (error) {
        console.log(error);
      }
    }
    loadingCallback(false);
  };

  return (
    <Box>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
          <FormControl sx={{ width: '100%' }}>
            <Box sx={{ py: 1.5, display: 'flex', width: '100%' }}>
              <FormTextField
                label='Event Name'
                name='eventName'
                required
                sx={{ mr: 1.5 }}
              />
              <FormSelect label='Event Type' name='eventType' required>
                <MenuItem data-testid='eventType' value={EventType.Drydock}>
                  {EventType.Drydock}
                </MenuItem>
                <MenuItem
                  data-testid='eventType'
                  value={EventType.HullCleaning}
                >
                  {EventType.HullCleaning}
                </MenuItem>
                <MenuItem
                  data-testid='eventType'
                  value={EventType.PropellerPolishing}
                >
                  {EventType.PropellerPolishing}
                </MenuItem>
                <MenuItem
                  data-testid='eventType'
                  value={EventType.HullCleaningPropellerPolishing}
                >
                  {EventType.HullCleaningPropellerPolishing}
                </MenuItem>
                <MenuItem
                  data-testid='eventType'
                  value={EventType.HullRecoating}
                >
                  {EventType.HullRecoating}
                </MenuItem>
                <MenuItem
                  data-testid='eventType'
                  value={EventType.DrydockHullRecoating}
                >
                  {EventType.DrydockHullRecoating}
                </MenuItem>
              </FormSelect>
            </Box>
            <Box sx={{ py: 1.5 }}>
              <Controller
                name='dateRange'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DateRange
                    enableContext={false}
                    handler={(output) => {
                      onChange(output);
                    }}
                    initialRange={
                      [
                        value?.startDate?.dateDayjs,
                        value?.endDate?.dateDayjs,
                      ] as MuiDateRange<Dayjs>
                    }
                    sx={{
                      width: '100%',
                      backgroundColor: theme.validatedModal.inputBackground,
                    }}
                    label={
                      <Box component='span'>
                        Date Range
                        <RequiredAsterisk padLeft />
                      </Box>
                    }
                  />
                )}
              />
            </Box>
            <Box sx={{ py: 1.5, display: 'flex', width: '100%' }}>
              <FormAutocomplete
                label='Location'
                name='location'
                required
                sx={{ mr: 1.5 }}
                options={locationsOptions}
              />
              <FormTextField label='Company' name='company' />
            </Box>
            <Box sx={{ py: 1.5, display: 'flex', width: '100%' }}>
              <FormTextField
                required
                label='Port & Other Costs ($)'
                name='portAndOtherCosts'
                sx={{ mr: 1.5 }}
              />
              <FormTextField
                required
                label='Diver Cost ($)'
                name='diverCost'
                sx={{ mr: 1.5 }}
              />
              <FormTextField
                required
                label='Bunker Price (MGO $/t)'
                name='bunkerPrice'
                sx={{ mr: 1.5 }}
              />
              <FormTextField
                label='Total OOS Period (Days)'
                name='totalOOSPeriod'
              />
            </Box>
            <Box sx={{ py: 1.5, display: 'flex', width: '100%' }}>
              <FormTextField label='Add Comments' name='comments' multiline />
            </Box>

            <Box sx={{ py: 1.5, display: 'flex', justifyContent: 'flex-end' }}>
              <SecondaryButton
                variant='outlined'
                sx={{
                  width: '82px',
                  mr: 1.5,
                  py: 1,
                }}
                onClick={closeCallback}
              >
                Cancel
              </SecondaryButton>
              <Button
                onClick={handleSubmit(onSubmit, onSubmitError)}
                variant='contained'
                sx={{
                  fontSize: '16px',
                  py: 1,
                  width: '67px',
                  height: '40px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                }}
              >
                Save
              </Button>
            </Box>
          </FormControl>
        </form>
      </FormProvider>
    </Box>
  );
};
