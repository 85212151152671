import { Typography, Chip, IconButton } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import { Box } from '@mui/system';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { IAlert } from 'routes/alerts/models/alert.model';
import {
  nameof,
  GridColumn,
  GridCustomHeaderClass,
  GridRAGColumn,
} from 'shared/components/datagrid';
import { UTCDate } from 'shared/utils/date-utc-helper';
import {
  StatusStyle,
  ChipLabel,
  ChipStyle,
  CellTextStyle,
} from './alert-styles';
import { isValidElement } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function getColumnsDefinition(): GridColDef[] {
  const columns = nameof<IAlert>;

  const columnDefinition = [
    GridColumn({
      field: 'selectRow',
      align: 'left',
      valueField: 'isValid',
      renderHeader: () => '',
      cellClassName: GridCustomHeaderClass.NoGutters,
      headerClassName: GridCustomHeaderClass.NoGutters,
      maxWidth: 10,
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<IAlert>) => {
        return (
          <Box
            sx={{
              ...StatusStyle(params.row.isRead, false),
              height: '100%',
            }}
          ></Box>
        );
      },
    }),
    GridColumn({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      maxWidth: 60,
      visibility: 'screen-only',
    }),
    GridColumn({
      field: columns('comments'),
      maxWidth: 40,
      visibility: 'screen-only',
      sortable: false,
      renderHeader: () => '',
      renderCell: (params: GridRenderCellParams<IAlert>) => {
        const hasComments = params.row.comments.length > 0;
        return (
          hasComments && <CommentIcon data-testid='CommentIcon'></CommentIcon>
        );
      },
    }),
    /*
    GridColumn({
      field: columns('alertId'),
      type: 'string',
      valueField: columns('alertId'),
    }), */
    GridRAGColumn({
      field: columns('status'),
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      maxWidth: 100,
      valueField: columns('status'),
      statusField: columns('status'),
      headerClassName: GridCustomHeaderClass.NoGutters,
      cellClassName: GridCustomHeaderClass.NoGutters,
      renderCell: (params: GridRenderCellParams<IAlert>) => {
        const { status, isArchived } = params.row;
        return (
          <Chip
            label={ChipLabel(status, isArchived)}
            sx={ChipStyle(status, isArchived)}
          />
        );
      },
    }),
    GridColumn({
      field: columns('vesselName'),
      headerName: 'Vessel Name',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      valueField: columns('vesselName'),
      maxWidth: 140,
      renderCell: (params: GridRenderCellParams<IAlert>) => {
        return (
          <Typography
            sx={{
              ...CellTextStyle(params.row.isRead),
              p: 0,
              textWrap: 'wrap',
            }}
          >
            {params.row.vesselName}
          </Typography>
        );
      },
    }),
    GridColumn({
      field: columns('products'),
      headerName: 'Module',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      maxWidth: 150,
      valueField: columns('products'),
      renderCell: (params: GridRenderCellParams<IAlert>) => {
        return (
          <Typography
            sx={{
              ...CellTextStyle(params.row.isRead),
              p: 0,
              textWrap: 'wrap',
            }}
          >
            {params.row.products}
          </Typography>
        );
      },
    }),
    GridColumn({
      field: columns('reportedOn'),
      headerName: 'Alert Date',
      headerAlign: 'left',
      align: 'left',
      type: 'date',
      maxWidth: 200,
      renderCell: (params: GridRenderCellParams<IAlert>) => {
        return (
          <Box sx={{ py: 2 }}>
            <Typography
              sx={{
                textWrap: 'wrap',
              }}
            >
              {new UTCDate(params.row.reportedOn?.date).formatDMYHMZ(
                true,
                false,
                true
              )}
            </Typography>
          </Box>
        );
      },
      valueGetter: (params: GridValueGetterParams<IAlert>) =>
        params.row.reportedOn?.date,
      valueFormatter: (params: GridValueFormatterParams<Date>) =>
        new UTCDate(params.value).formatDMYHMZ(true, false, true),
    }),
    GridColumn({
      field: columns('alertDetails'),
      headerName: 'Description',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      valueField: columns('alertDetails'),
      renderCell: (params: GridRenderCellParams<IAlert>) => {
        return (
          <Box sx={{ py: 2 }}>
            <Typography
              sx={{
                textWrap: 'wrap',
                fontSize: 'small',
              }}
            >
              {params.row.alertDetails}
            </Typography>
          </Box>
        );
      },
    }),
    GridColumn({
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      maxWidth: 100,
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<IAlert>) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    }),
  ];
  return columnDefinition;
}

function CustomDetailPanelToggle(
  props: Readonly<Pick<GridRenderCellParams, 'id' | 'value'>>
) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton
      size='medium'
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize='large'
      />
    </IconButton>
  );
}
