import { ApolloError } from '@apollo/client';
import { ManageSearch, SyncDisabled } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { TEXT } from 'shared/constants/text';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';

interface ErrorComponentProps {
  error?: ApolloError | null | undefined;
  empty?: boolean;
  heightOverride?: string;
}

export const ErrorComponent: FC<ErrorComponentProps> = ({
  error,
  empty,
  heightOverride,
}) => {
  const primaryMessage =
    empty && !error
      ? TEXT.ERROR_MESSAGES.NO_DATA
      : TEXT.ERROR_MESSAGES.LOADING_DATA;
  const secondaryMessage =
    empty && !error
      ? TEXT.ERROR_MESSAGES.NO_DATA_ACTION
      : TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION;

  const manageSearchIcon = (
    <ManageSearch
      fontSize={'large'}
      sx={{ color: theme.palette.text.disabled, marginBottom: '12px' }}
    />
  );

  const syncDisabledIcon = (
    <SyncDisabled
      fontSize={'large'}
      sx={{ color: theme.palette.text.disabled, marginBottom: '12px' }}
    />
  );

  const iconToDisplay = empty && !error ? manageSearchIcon : syncDisabledIcon;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: heightOverride ?? '50vh',
        overflow: 'hidden',
        flexDirection: 'column',
      }}
      data-testid='error-component'
    >
      {iconToDisplay}
      <Box>
        <Typography sx={{ fontSize: typography.fontSize[6] }}>
          {primaryMessage}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontSize: typography.fontSize[5],
          }}
        >
          {secondaryMessage}
        </Typography>
      </Box>
    </Box>
  );
};
