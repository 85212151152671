import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Point from '@arcgis/core/geometry/Point';

// well know GPS coordinate system with regular lat & long
// see {@link https://spatialreference.org/ref/epsg/wgs-84/}
const sr4326 = new SpatialReference({ wkid: 4326 });

const getNormalizeLong = (longitude: number) =>
  (((longitude % 360) + 540) % 360) - 180;

function getMapViewWidth(view: MapView | SceneView) {
  const startLong = view.toScreen(
    new Point({ x: -180, y: 0, spatialReference: sr4326 })
  );
  const endLong = view.toScreen(
    new Point({ x: 180, y: 0, spatialReference: sr4326 })
  );
  return Math.round(endLong.x - startLong.x);
}

// get the position on screen for the X coordinate from the latitude.
// this calculation is necessary to cover the panning beyond the initial map extent.
const getNormalizedScreenPosition = (
  view: MapView | SceneView,
  mapPoint: Point
) => {
  const pointOnScreen = view.toScreen(mapPoint);
  if (view.type === '3d') return pointOnScreen;

  const mapWidth = getMapViewWidth(view);
  let screenX = pointOnScreen.x % mapWidth;
  if (screenX < 0) screenX = mapWidth + screenX;

  pointOnScreen.x = screenX;
  return pointOnScreen;
};

export {
  sr4326,
  getNormalizeLong,
  getMapViewWidth,
  getNormalizedScreenPosition,
};
