import { Box } from '@mui/material';
import { ProjectionInput } from '_gql/graphql';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CiiProjectionsFormMapper } from 'routes/environmental-monitor/mappers/cii-projections.form.mapper';
import { CiiProjectionsFormModel } from 'routes/environmental-monitor/models/forms/cii-projections.form.model';
import { ProjectionConditionsDomain } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import { SecondaryButtonSizeable } from 'shared/components/secondary-button/secondary-button-sizeable.component';
import { TEXT } from 'shared/constants/text';
import { typography } from 'styles/typography';
import { CIITrendForecastField } from './cii-trend-forecast-field.component';

export interface CIITrendForecastProps {
  form: UseFormReturn<CiiProjectionsFormModel>;
  onUpdate: (input: ProjectionInput) => void;
  isSubmitting: boolean;
  conditions: ProjectionConditionsDomain | undefined;
}

export const CIITrendForecast = (props: CIITrendForecastProps) => {
  const { form } = props;
  const { id } = useParams();
  const vesselId = id ?? '';
  const disabled = !form.formState.isDirty || props.isSubmitting;

  const handleUpdate = (input: ProjectionInput) => {
    props.onUpdate(input);
  };

  const onSubmit = async (data: CiiProjectionsFormModel) => {
    const input = CiiProjectionsFormMapper.toInput(vesselId, data);
    handleUpdate(input);
  };

  const onReset = async () => {
    const formValues = CiiProjectionsFormMapper.toModel(props.conditions);
    form.reset(formValues);
    const input = CiiProjectionsFormMapper.toInput(vesselId, formValues);
    handleUpdate(input);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form?.handleSubmit(onSubmit)}>
        <Box sx={{ paddingY: `${typography.spacing['4']}px` }}>
          <Box sx={{ float: 'left', mt: 0.5 }}>
            Enter new operational condition values below to forecast the EOY CII
            performance in the chart.
          </Box>
          <Box sx={{ float: 'right' }}>
            <SecondaryButtonSizeable
              variant='outlined'
              size='small'
              sx={{ mr: 1.5 }}
              disabled={disabled}
              type='submit'
            >
              Calculate
            </SecondaryButtonSizeable>
            <SecondaryButtonSizeable
              variant='outlined'
              size='small'
              onClick={() => onReset()}
              disabled={disabled}
            >
              Reset Values
            </SecondaryButtonSizeable>
          </Box>

          <Box sx={{ clear: 'both' }} />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            columnGap: `${typography.spacing['2']}px`,
            alignItems: 'baseline',
          }}
        >
          <Box>
            <CIITrendForecastField
              label={`Avg. Ballast Speed (${TEXT.UNIT_MEASUREMENT.KNOTS})`}
              name='avgBallastSpeed'
              disabled={false}
              required={true}
            />
          </Box>
          <Box>
            <CIITrendForecastField
              label={`Avg. Laden Speed (${TEXT.UNIT_MEASUREMENT.KNOTS})`}
              name='avgLadenSpeed'
              disabled={false}
              required={true}
            />
          </Box>
          <Box>
            <CIITrendForecastField
              label={`Avg. Ballast FOC (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY})`}
              name='avgBallastFoc'
              disabled={false}
              required={true}
            />
          </Box>
          <Box>
            <CIITrendForecastField
              label={`Avg. Laden FOC (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY})`}
              name='avgLadenFoc'
              disabled={false}
              required={true}
            />
          </Box>
          <Box>
            <CIITrendForecastField
              label={`Idle Time (${TEXT.UNIT_MEASUREMENT.PERCENTAGE})`}
              name='idleTime'
              disabled={false}
              required={true}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            columnGap: `${typography.spacing['2']}px`,
            marginTop: `${typography.spacing['6']}px`,
            alignItems: 'baseline',
          }}
        >
          <Box>
            <CIITrendForecastField
              label={`Distance (${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`}
              name='distance'
              disabled={true}
              required={true}
            />
          </Box>
          <Box>
            <CIITrendForecastField
              label={`Ballast (${TEXT.UNIT_MEASUREMENT.PERCENTAGE})`}
              name='ballast'
              disabled={true}
              required={true}
            />
          </Box>
          <Box>
            <CIITrendForecastField
              label={`Laden (${TEXT.UNIT_MEASUREMENT.PERCENTAGE})`}
              name='laden'
              disabled={true}
              required={true}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default CIITrendForecast;
