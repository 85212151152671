import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const getIcon = (indicator: boolean, isEmission: boolean) => {
  switch (indicator) {
    case true:
      return (
        <CheckCircleIcon
          color='success'
          sx={{ width: '32px', height: '32px' }}
        />
      );
    case false:
      if (isEmission) {
        return (
          <ErrorIcon color='error' sx={{ width: '32px', height: '32px' }} />
        );
      } else {
        return (
          <ErrorOutlineIcon
            color='error'
            sx={{ width: '32px', height: '32px' }}
          />
        );
      }
    default:
      return (
        <ErrorOutlineIcon
          color='disabled'
          sx={{ width: '32px', height: '32px' }}
        />
      );
  }
};
