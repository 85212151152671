export interface CiiProjectionsFormModel {
  avgBallastSpeed: number;
  avgBallastSpeedYtd: number;
  avgBallastSpeedTwelveMonthsRolling: number;
  avgLadenSpeed: number;
  avgLadenSpeedYtd: number;
  avgLadenSpeedTwelveMonthsRolling: number;
  avgBallastFoc: number;
  avgBallastFocYtd: number;
  avgBallastFocTwelveMonthsRolling: number;
  avgLadenFoc: number;
  avgLadenFocYtd: number;
  avgLadenFocTwelveMonthsRolling: number;
  idleTime: number;
  idleTimeYtd: number;
  idleTimeTwelveMonthsRolling: number;
  distance: number;
  distanceYtd: number;
  distanceTwelveMonthsRolling: number;
  ballast: number;
  ballastYtd: number;
  ballastTwelveMonthsRolling: number;
  laden: number;
  ladenYtd: number;
  ladenTwelveMonthsRolling: number;
}

export const defaultValues: CiiProjectionsFormModel = {
  avgBallastSpeed: 0,
  avgBallastSpeedYtd: 0,
  avgBallastSpeedTwelveMonthsRolling: 0,
  avgLadenSpeed: 0,
  avgLadenSpeedYtd: 0,
  avgLadenSpeedTwelveMonthsRolling: 0,
  avgBallastFoc: 0,
  avgBallastFocYtd: 0,
  avgBallastFocTwelveMonthsRolling: 0,
  avgLadenFoc: 0,
  avgLadenFocYtd: 0,
  avgLadenFocTwelveMonthsRolling: 0,
  idleTime: 0,
  idleTimeYtd: 0,
  idleTimeTwelveMonthsRolling: 0,
  distance: 0,
  distanceYtd: 0,
  distanceTwelveMonthsRolling: 0,
  ballast: 0,
  ballastYtd: 0,
  ballastTwelveMonthsRolling: 0,
  laden: 0,
  ladenYtd: 0,
  ladenTwelveMonthsRolling: 0,
};
