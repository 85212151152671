import { useEffect, useState, useContext } from 'react';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/system';
import { Box, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { theme } from 'styles/theme';

import { MapViewContext } from 'shared/models/mapView.context.model';

export const DrawerInsideMap = ({ children }: any) => {
  const [open, setOpen] = useState(false);
  const { searchInfo } = useContext(MapViewContext);

  useEffect(() => {
    if (!searchInfo.searchId) {
      setOpen(false);
      return;
    }
    setOpen(true);
  }, [searchInfo]);

  const handleDrawer = (flag: boolean) => () => {
    setOpen(flag);
  };

  const drawerToggleBtnStyle = {
    width: '32px',
    height: '40px',
    display: 'flex',
    backgroundColor: '#3887BD',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  };

  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <StyledDrawer open={open}>
        <Box sx={{ mt: 0, overflow: 'hidden', height: '100%' }}>
          <Box component='div' sx={{ height: '80%' }}>
            <Box
              sx={{
                ...drawerToggleBtnStyle,
                borderRadius: '8px 0px 0px 8px',
                position: 'absolute',
                right: 0,
                top: 36,
              }}
            >
              <IconButton
                onClick={handleDrawer(false)}
                sx={{
                  p: '4px',
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Box>
            {children}
          </Box>
        </Box>
      </StyledDrawer>
      {!!searchInfo.searchId && (
        <Box
          sx={{
            ...drawerToggleBtnStyle,
            borderRadius: '0px 8px 8px 0px',
            position: 'absolute',
            right: -32,
            top: 36,
          }}
        >
          <IconButton
            onClick={handleDrawer(true)}
            sx={{
              p: '4px',
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const StyledDrawer = styled((props: DrawerProps) => (
  <Drawer variant='persistent' {...props} />
))(() => {
  return {
    '&>.MuiPaper-root': {
      width: 360,
      flexShrink: 0,
      position: 'absolute',
      height: 'calc(100% - 20px)',
      borderRadius: 8,
      background: theme.background.component,
      boxShadow: '2px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    '& .MuiPaper-root.MuiPaper-elevation': {
      border: 'none',
    },
  };
});
