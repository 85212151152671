import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { DownloadListVessel } from './download-common';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { exportExcel } from 'shared/utils/export-report-excel.utils';
import { useVesselExcelReport } from 'routes/vessel-data-monitor/services/vessel-excel-report.get.service';
import { useReportIssues } from 'routes/reports/services/download-report.get.service';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { ApolloError } from '@apollo/client';
import { IDownloadContextBase } from 'shared/models/reportDownload.contextBase.model';
import { Maybe, VesselReportCalculation } from '_gql/graphql';

export interface IExcelDownloadContext extends IDownloadContextBase {
  handleProceedToDownload: () => void;
  loadingReportIssues: boolean;
  reportIssuesError: ApolloError | undefined;
  criticalReports: Maybe<VesselReportCalculation> | undefined;
}

const ExcelDownloadContext = createContext<IExcelDownloadContext>({
  handleGenerateLineItems: () => {},
  selectedVessels: [],
  showDownloadModal: false,
  imoNumber: '',
  vesselName: '',
  handleDownload: () => {},
  handleClose: () => {},
  handleProceedToDownload: () => {},
  loadingReportIssues: false,
  reportIssuesError: undefined,
  criticalReports: null,
});

type ExcelDownloadContextProviderProps = {
  children: ReactNode;
  reportType: string;
};

export const ExcelDownloadContextProvider = ({
  children,
  reportType,
}: ExcelDownloadContextProviderProps) => {
  const [selectedVessels, setSelectedVessels] = useState<DownloadListVessel[]>(
    []
  );
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [imoNumber, setImoNumber] = useState<string>('');
  const [vesselName, setVesselName] = useState<string>('');
  const [loadReport, setLoadReport] = useState<boolean>(false);

  const { utcDateRange } = useContext(NavBarContext);

  const {
    criticalReports,
    loading: loadingReportIssues,
    error: reportIssuesError,
  } = useReportIssues(imoNumber, utcDateRange);

  const { data, loading } = useVesselExcelReport(
    new DateRange(
      new UTCDate(utcDateRange?.startDate?.date),
      new UTCDate(utcDateRange?.endDate?.date)
    ),
    reportType,
    imoNumber,
    loadReport
  );

  useEffect(() => {
    if (loading === false && data?.vesselReportExcel && loadReport) {
      data.vesselReportExcel.forEach((report) => {
        if (report?.encodedExcelFile) {
          exportExcel(report.encodedExcelFile, report.filename);
        }
      });
      setLoadReport(false);
    }
  }, [data, loading, loadReport]);

  const handleGenerateLineItems = (vessels: DownloadListVessel[]) => {
    setSelectedVessels(vessels);
  };

  const handleDownload = (key: string, vesselName: string) => {
    setShowDownloadModal(true);
    setImoNumber(key);
    setVesselName(vesselName);
  };

  const handleClose = () => {
    setShowDownloadModal(false);
  };

  const handleProceedToDownload = () => {
    setLoadReport(true);
    handleClose();
  };

  const contextValue: IExcelDownloadContext = {
    handleGenerateLineItems,
    selectedVessels,
    showDownloadModal,
    imoNumber,
    vesselName,
    handleDownload,
    handleClose,
    handleProceedToDownload,
    loadingReportIssues,
    reportIssuesError,
    criticalReports,
  };

  return (
    <ExcelDownloadContext.Provider value={contextValue}>
      {children}
    </ExcelDownloadContext.Provider>
  );
};

export { ExcelDownloadContext };
