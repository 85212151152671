import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import {
  GridColumn,
  GridColumnDef,
  GridCustomHeaderClass,
  nameof,
} from 'shared/components/datagrid';
import { IEvent } from './events/models/events.model';
import { VesselEventRowActionGroup } from './vessel-event-row-action-group.component';
import { EventTooltipBoxComponent } from './vessel-event-tooltip-box-component';
import { DateRange } from 'shared/utils/date-utc-helper';

type IVoyagePerformanceEventsTable = IEvent;

const BIG_CELL_WIDTH = 155;
const SMALL_CELL_WIDTH = 135;

export const getColumnsDefinition = (): GridColumnDef[] => {
  const columns = nameof<IVoyagePerformanceEventsTable>;

  return [
    GridColumn({
      field: columns('eventName'),
      headerName: 'Event Name',
      type: 'string',
      headerAlign: 'left',
      minWidth: BIG_CELL_WIDTH,
      align: 'left',
      valueField: columns('eventName'),
      renderCell: (value: GridRenderCellParams<IEvent, void>) => (
        <EventTooltipBoxComponent event={value.row} />
      ),
    }),
    GridColumn({
      field: columns('eventType'),
      headerName: 'Event Type',
      valueField: columns('eventType'),
      type: 'text',
      minWidth: BIG_CELL_WIDTH,
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'whiteSpaceAndBreakWords',
    }),
    GridColumn({
      field: columns('dateRange'),
      headerName: 'Date Range',
      type: 'string',
      valueGetter: (value: GridRenderCellParams<IEvent, void>) => {
        return value.row.dateRange.formatDMY();
      },
      valueFormatter: (params: GridValueFormatterParams<any>) => {
        if (!params?.value) return '';
        if (typeof params?.value === 'string') {
          return params?.value.toString().replace('- ', '-\n');
        }
        return (params.value as DateRange).formatDMY();
      },
      headerAlign: 'left',
      align: 'left',
      minWidth: BIG_CELL_WIDTH,
      cellClassName: GridCustomHeaderClass.PreLine,
    }),
    GridColumn({
      field: columns('location'),
      headerName: 'Location',
      valueField: columns('location'),
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      minWidth: BIG_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('company'),
      headerName: 'Company',
      valueField: columns('company'),
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      minWidth: BIG_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('totalEventCost'),
      type: 'number',
      noDecimals: 2,
      valueField: columns('totalEventCost'),
      headerName: 'Total Cleaning \nCost ($)',
      headerAlign: 'right',
      align: 'right',
      minWidth: SMALL_CELL_WIDTH,
      headerClassName: 'whiteSpaceAndBreakWords',
    }),
    GridColumn({
      field: columns('daysToRecover'),
      headerName: 'Payback Period \n(Days)',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueField: columns('daysToRecover'),
      minWidth: SMALL_CELL_WIDTH,
      headerClassName: 'whiteSpaceAndBreakWords',
    }),
    GridColumn({
      field: columns('dailyFuelSavingsDollars'),
      headerName: 'Daily Fuel \nSavings ($)',
      type: 'number',
      noDecimals: 2,
      headerAlign: 'right',
      align: 'right',
      valueField: columns('dailyFuelSavingsDollars'),
      minWidth: SMALL_CELL_WIDTH,
      headerClassName: 'whiteSpaceAndBreakWords',
    }),
    GridColumn({
      field: columns('dailyFuelSavingsMT'),
      headerName: 'Daily Fuel \nSavings (t/day)',
      type: 'number',
      noDecimals: 2,
      headerAlign: 'right',
      align: 'right',
      valueField: columns('dailyFuelSavingsMT'),
      minWidth: SMALL_CELL_WIDTH,
      headerClassName: 'whiteSpaceAndBreakWords',
    }),
    GridColumn({
      field: columns('achievedImprovement'),
      headerName: 'Achieved \nImprovement (%)',
      type: 'number',
      headerAlign: 'right',
      align: 'right',
      valueField: columns('achievedImprovement'),
      minWidth: BIG_CELL_WIDTH,
      headerClassName: 'whiteSpaceAndBreakWords',
    }),
    GridColumn({
      field: columns('Remarks'),
      headerName: 'Remarks',
      type: 'string',
      headerAlign: 'right',
      align: 'right',
      valueField: columns('Remarks'),
      minWidth: BIG_CELL_WIDTH,
      visibility: 'export-only',
      cellClassName: GridCustomHeaderClass.PreLine,
    }),
    GridColumn({
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      valueField: 'actions',
      type: 'string',
      align: 'center',
      minWidth: SMALL_CELL_WIDTH,
      visibility: 'screen-only',
      renderCell: (value: GridRenderCellParams<IEvent, void>) => (
        <VesselEventRowActionGroup event={value.row} />
      ),
    }),
  ];
};
