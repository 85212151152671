import { ServiceResult } from 'shared/models/serviceResult.model';
import { FeatureFlagMapper } from 'shared/mappers/featureFlag.mapper';
import {
  useFeatureFlagData,
  useFeatureFlagsData,
} from '../repositories/featureFlag.repository';
import { FeatureFlagDomain } from 'shared/models/featureFlag.model';

export const useGetFeatureFlag = (
  flagName: string
): ServiceResult<FeatureFlagDomain> => {
  const response = useFeatureFlagData(flagName);

  const featureFlag = response?.data?.featureFlag;
  const loading = response?.loading ?? false;
  const error = response?.error;

  return {
    data: FeatureFlagMapper.toDomain(featureFlag),
    loading,
    error,
  };
};

export const useGetFeatureFlags = (
  flagNames: string[]
): ServiceResult<FeatureFlagDomain[]> => {
  const response = useFeatureFlagsData(flagNames);

  const featureFlags = Array.isArray(response?.data?.featureFlags)
    ? response.data?.featureFlags
    : [response?.data?.featureFlags];
  const loading = response?.loading ?? false;
  const error = response?.error;
  console.log('featureFlags' + featureFlags);
  return {
    data: featureFlags.map((flag) => FeatureFlagMapper.toDomain(flag)),
    loading,
    error,
  };
};
