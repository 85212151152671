import Typography from '@mui/material/Typography';

export interface ModalCardContentsProps {
  title: string;
  actionText: string;
  messageText: string;
}

export const ModalCardContents = (props: ModalCardContentsProps) => {
  const { title, actionText, messageText } = props;

  return (
    <>
      <Typography variant='h6' sx={{ mb: 3 }}>
        {title}
      </Typography>
      <Typography variant='body1'>{actionText}</Typography>
      <Typography variant='body1' sx={{ mb: 3 }}>
        {messageText}
      </Typography>
    </>
  );
};
