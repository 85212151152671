import { Box, Toolbar } from '@mui/material';
import { useContext, useState } from 'react';
import { useGetFleetCII } from 'routes/environmental-monitor/services/fleet.service';
import {
  dateReferenceKeyProps,
  fleetCIIHistoricalType,
} from 'routes/environmental-monitor/view-models/cii-ratings-chart.viewModel';
import { ChartKey } from 'shared/components/chart-key/chart-key.component';
import { CIIHistoricalDataSelection } from './cii-ratings-historical-data-selection.component';
import { fleetPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { FixedTimeline } from 'assets/fixed-timeline';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { MuiChartContainerWithLegend } from 'shared/components/mui-chart/container.component';
import { AxisConfig, BarSeriesType, ChartsXAxisProps } from '@mui/x-charts-pro';
import { theme } from 'styles/theme';
import { FleetCiiDataPoint } from 'routes/environmental-monitor/models/vessel-cii-rating.model';

import { formatNumber } from 'shared/utils/float-utils';
import { TopBarDatesMui } from '../shared/topbar-dates.component';
import { nameof } from 'shared/components/datagrid';

const colorsDictionary = {
  E: theme.colors?.eRating,
  D: theme.colors?.dRating,
  C: theme.colors?.cRating,
  B: theme.colors?.bRating,
  A: theme.colors?.aRating,
};
const getChartSeries = (rate: 'A' | 'B' | 'C' | 'D' | 'E'): BarSeriesType => {
  return {
    type: 'bar',
    label: (location) => (location === 'tooltip' ? rate + ':' : rate),
    dataKey: rate,
    stack: 'stackbar',
    color: colorsDictionary[rate],
    valueFormatter: (value: number | null) => {
      return formatNumber(value ?? 0) + ' Vessels';
    },
  };
};

const getAllChartSeries = (): BarSeriesType[] => {
  return [
    getChartSeries('E'),
    getChartSeries('D'),
    getChartSeries('C'),
    getChartSeries('B'),
    getChartSeries('A'),
  ];
};

export const CIIRatingsChart = ({
  dateRange,
}: {
  dateRange: DateRange | null;
}) => {
  const { imoNumbers } = useContext(NavBarContext);
  const ciiResponse = useGetFleetCII(dateRange, imoNumbers);
  const isLoading = ciiResponse.loading;

  const [historicalType, setHistoricalType] = useState(
    fleetCIIHistoricalType.YTD
  );

  const handleChangeHistoricalData = (
    selectedHistoricalType: fleetCIIHistoricalType
  ) => {
    setHistoricalType(selectedHistoricalType);
  };

  const renderContent = () => {
    const ciiValues = ciiResponse.data ? ciiResponse.data[historicalType] : [];

    // remove 'actual' property from FleetCiiDataPoint
    // Mui charts does not support the 'actual' property type boolean
    type modelDataPoint = Omit<FleetCiiDataPoint, 'actual'>;
    const fleetCIIYAxisTitle = 'Total Vessels';
    const chartDataset: modelDataPoint[] = ciiValues ?? [];
    const chartSeries: BarSeriesType[] = getAllChartSeries();
    const calculatedGap = chartDataset.length > 12 ? 0.8 : 0.9; // calculate the gap between the bars

    return (
      <Box component={'section'} sx={{ pl: 1, mb: 1, position: 'relative' }}>
        <Toolbar
          disableGutters
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            justifyContent: 'right',
            alignItems: 'baseline',
            position: 'absolute',
            right: 0,
            left: 0,
            top: -8,
          }}
        >
          <Box>
            <CIIHistoricalDataSelection
              handleHistoricaDataChange={handleChangeHistoricalData}
            ></CIIHistoricalDataSelection>
          </Box>
        </Toolbar>

        {new UTCDate().isBeginningOfYear() && (
          <Toolbar disableGutters sx={{ paddingLeft: 5, marginTop: -2 }}>
            <ChartKey {...dateReferenceKeyProps} />
          </Toolbar>
        )}

        <MuiChartContainerWithLegend
          height={450}
          loading={isLoading}
          error={ciiResponse.error}
          dataset={chartDataset}
          series={chartSeries}
          tooltip={{ trigger: 'axis' }}
          yAxis={[{ label: fleetCIIYAxisTitle }]}
          margin={{ top: 65 }}
          xAxis={[
            {
              id: 'date-axis',
              dataKey: nameof<modelDataPoint>('date'),
              tickNumber: chartDataset.length,
              categoryGapRatio: calculatedGap,
              scaleType: 'band',
              tickLabelStyle: { angle: -35 },
              tickPlacement: 'middle',
              valueFormatter: dateFormatter,
            } as AxisConfig<'band', any, ChartsXAxisProps>,
          ]}
        >
          <TopBarDatesMui />
        </MuiChartContainerWithLegend>
      </Box>
    );
  };

  return (
    <CardContainer
      title={fleetPageTitles.ciiRatings}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      {renderContent()}
    </CardContainer>
  );
};

const dateFormatter = (date: Date) => {
  return new UTCDate(date).formatMY();
};
