import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box, ThemeProvider, Typography, useTheme } from '@mui/material';

export const CardContainer = (props: {
  title?: string;
  icon?: EmotionJSX.Element;
  children?: React.ReactNode;
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box
        component='div'
        sx={{
          mr: 0,
          pt: 5,
          pr: 0,
          pb: 1,
          mt: 3,
          backgroundColor: theme.background.component,
          borderRadius: '8px',
        }}
      >
        <Typography
          variant='h3'
          paragraph
          sx={{
            mb: 0,
            display: 'flex',
            justifyContent: 'center',
            color: theme.palette.text.primary,
          }}
        >
          {props.title}
          {props.icon}
        </Typography>

        {props.children}
      </Box>
    </ThemeProvider>
  );
};
