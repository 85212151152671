import React, {
  useState,
  SyntheticEvent,
  useCallback,
  memo,
  useEffect,
} from 'react';
import Box from '@mui/material/Box';
///import Checkbox from '@mui/material/Checkbox';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { theme } from 'styles/theme';
import {
  useGetAlerts,
  useUpdateAlert,
  useUpdateAlerts,
} from '../../services/alerts.service';

import { IAlert, IAlertComment, AlertAction } from '../../models/alert.model';
import {
  setAlertStatus,
  setAlertIsRead,
  setNewAlertComment,
  setAlertIsSelected,
  setAlertIsUnread,
  setEditAlertComment,
  deleteAlertComment,
  setAlertIsUnarchived,
  setAlertIsArchived,
} from './alert-table.viewModel';
import { AlertMapper } from '../../mappers/alert.mapper';

import { AlertTableWarning } from './alert-table-warning.component';
import { AlertStatus } from '../../services/alert-status';
import { DateRange } from 'shared/utils/date-utc-helper';
import { DataGridTable, nameof } from 'shared/components/datagrid';
import { constants } from 'routes/alerts/alerts.route';
import {
  GridFilterModel,
  GridRowParams,
  GridRowSpacingParams,
  GridSortItem,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { AlertDetail } from './alert-detail.component';
import { getColumnsDefinition } from './alert-table.component.config';
import { AlertToolBar } from './alert-table.toolbar';

export const AlertTable = (props: {
  vesselImoNumbers: string[] | undefined;
  dateRange: DateRange;
  filter: string;
  setFilter: (filter: string) => void;
  refreshSummaryCallBack: () => void;
}) => {
  const { vesselImoNumbers, dateRange, filter, setFilter } = props;

  const [mutateFunction, { error }] = useUpdateAlert();
  const [mutateUpdateAlertsFunction] = useUpdateAlerts();
  const [tabValue, setTabValue] = useState(0);
  const [selectedAlerts, setSelectedAlerts] = useState<string[]>([]);
  const [filterQuery, setFilterQuery] = useState('');

  const apiRef = useGridApiRef();
  const columns = nameof<IAlert>;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const defaultSortModel: GridSortItem = {
    field: columns('reportedOn'),
    sort: 'desc',
  };
  const [queryOptions, setQueryOptions] =
    useState<GridSortItem>(defaultSortModel);

  const alertDataResponse = useGetAlerts({
    vesselImoNumbers,
    dateRange,
    pageSize: paginationModel.pageSize,
    pageNumber: paginationModel.page + 1,
    activeAlertsOnly: filter === 'active',
    sortDirection: queryOptions?.sort ?? defaultSortModel.sort,
    orderBy: queryOptions?.field ?? defaultSortModel.field,
    containsQueryTerm: filterQuery,
  });

  const alertData: IAlert[] = alertDataResponse?.data.alerts ?? [];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    const newSortModel = sortModel[0] ?? defaultSortModel;
    setQueryOptions(newSortModel);
  }, []);

  const [dataRef, setDataRef] = useState<IAlert[]>([]);

  useEffect(() => {
    if (alertDataResponse.loading === false) {
      const gridData = alertData.map((a) => {
        const result = { ...a };
        if (a.isArchived) result.status = 'archived';
        return result;
      });

      setDataRef(gridData);
    }
  }, [alertDataResponse.loading]);

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams) => (
      <AlertDetail
        key={`alert-detail-${row.alertId}`}
        status={row.status}
        isArchived={row.isArchived}
        comments={row.comments || []}
        handler={(action, data) => handleDetailEvent(row.alertId, action, data)}
      />
    ),
    []
  );

  const handleDetailEvent = (
    alertId: string,
    action: AlertAction,
    data?: IAlertComment
  ) => {
    // Update for updating summary numbers real time
    const promise = handleRowEvent(alertId, action, data);
    promise?.then(() => props.refreshSummaryCallBack());
  };

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    const column = apiRef.current.getColumn('reportedOn');
    if (column) apiRef.current.sortColumn(column, null);

    setFilter(newValue === 0 ? 'active' : 'archived');
    handleDeselectCheckbox();
  };

  const updateAlert = (
    id: string,
    func: (alert: IAlert, data: any) => IAlert,
    arg: string | IAlertComment
  ) => {
    const gridData =
      dataRef.length > 0
        ? dataRef
        : (apiRef.current.getSortedRows() as IAlert[]);
    const alertIndex = gridData.findIndex((x) => x.alertId === id);
    const alert = gridData[alertIndex];

    if (alert) {
      const domainAlert = func.apply(alert, [alert, arg]);
      if (arg && arg !== 'select') {
        const dtoAlert = AlertMapper.toDTO(domainAlert);
        const promise = mutateFunction({ variables: { alert: dtoAlert } });

        // we don't need to update the grid data, we just need to remove it from display
        if (arg === 'archived' || arg === 'unarchived') {
          const index = gridData.findIndex((obj) => obj.alertId === id);
          if (index > -1) {
            gridData.splice(index, 1);
          }
        }
        setDataRef(gridData);
        return promise;
      }
    }

    return null;
  };

  const handleRowEvent = (
    id: string,
    action: AlertAction,
    data?: IAlertComment
  ) => {
    switch (action) {
      case AlertAction.Select:
        return updateAlert(id, setAlertIsSelected, 'select');
      case AlertAction.Read:
        return updateAlert(id, setAlertIsRead, 'read');

      case AlertAction.Unread:
        return updateAlert(id, setAlertIsUnread, 'unread');

      case AlertAction.Close:
        return updateAlert(id, setAlertStatus, AlertStatus.Closed);

      case AlertAction.Archive:
        return updateAlert(id, setAlertIsArchived, 'archived');

      case AlertAction.Reopen:
        return updateAlert(id, setAlertStatus, AlertStatus.Open);

      case AlertAction.Unarchive:
        return updateAlert(id, setAlertIsUnarchived, 'unarchived');

      case AlertAction.NewComment:
        return updateAlert(id, setNewAlertComment, data as IAlertComment);

      case AlertAction.EditComment:
        return updateAlert(id, setEditAlertComment, data as IAlertComment);

      case AlertAction.DeleteComment:
        return updateAlert(id, deleteAlertComment, data as IAlertComment);
    }
    return null;
  };

  const handleDeselectCheckbox = () =>
    apiRef.current.selectRows(apiRef.current.getAllRowIds(), false);

  const onGridFilterChange = React.useCallback(
    (filterModel: GridFilterModel) => {
      if (
        filterModel?.quickFilterValues &&
        filterModel?.quickFilterValues?.length > 0
      ) {
        setFilterQuery(filterModel.quickFilterValues.join(' '));
      } else {
        setFilterQuery('');
      }
    },
    []
  );

  const handleOnRowSelectionModelChange = () => {
    const { getSelectedRows } = apiRef.current;
    const selectedRows = Array.from(getSelectedRows().values()) as IAlert[];

    setSelectedAlerts(
      selectedRows.length > 0
        ? selectedRows.map((row) => row.alertId)
        : ([] as string[])
    );

    selectedRows.forEach((alert: IAlert) => {
      handleRowEvent(alert.alertId, AlertAction.Select);
    });
  };

  if (error) {
    console.log('error = ', error);
    return <div>`Submission error! ${error.message}`</div>;
  }

  return (
    <Box sx={{ backgroundColor: theme.background.page }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        textColor='inherit'
        sx={{ mt: 4, mb: 2 }}
      >
        <Tab label='Active' />
        <Tab label='Archived' />
      </Tabs>

      <AlertToolBar
        gridData={dataRef}
        selectedAlerts={selectedAlerts}
        filter={filter}
        mutateFunction={mutateUpdateAlertsFunction}
        refreshSummaryCallBack={props.refreshSummaryCallBack}
      />

      <AlertTableWarningMemo
        dateRange={dateRange}
        vesselImoNumbers={vesselImoNumbers}
      />

      <DataGridTable
        apiRef={apiRef}
        name={constants.ALERT_TABLE_NAME}
        columns={getColumnsDefinition()}
        rows={alertDataResponse.loading ? [] : dataRef}
        pagination={true}
        getRowId={(row) => row.alertId}
        hideFooter={false}
        getRowSpacing={getRowSpacing}
        getRowClassName={() => 'rounded'}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        height={'90vh'}
        rowHeight={120}
        checkboxSelection
        error={error}
        loading={alertDataResponse.loading}
        sortingMode={'server'}
        onSortModelChange={handleSortModelChange}
        initialState={{
          sorting: {
            sortModel: [defaultSortModel],
          },
        }}
        rowCount={alertDataResponse.data.total ?? 0}
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        checkboxSelectionVisibleOnly
        onRowSelectionModelChange={handleOnRowSelectionModelChange}
        filterMode='server'
        onFilterModelChange={onGridFilterChange}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
};

const AlertTableWarningMemo = memo(function (props: {
  vesselImoNumbers: string[] | undefined;
  dateRange: DateRange;
}) {
  return (
    <AlertTableWarning
      dateRange={props.dateRange}
      vesselImoNumbers={props.vesselImoNumbers}
    />
  );
});
