import { UTCDate } from 'shared/utils/date-utc-helper';
import { AerCalculation } from '../models/calculations.model';

export const generatePlaceholderData = (): AerCalculation[] => {
  const placeholderData: AerCalculation[] = [];

  const currentYear = new UTCDate().year!;

  for (let month = 0; month < 12; month++) {
    const daysInMonth = new UTCDate().dateDayjs
      ?.set('month', month)
      .daysInMonth()!;
    for (let day = 1; day <= daysInMonth; day++) {
      const date = UTCDate.create(currentYear, month, day);
      placeholderData.push({
        date: date.unixTime!,
        deviation: null,
        value: null,
      });
    }
  }

  return placeholderData;
};
