import Box from '@mui/material/Box';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import CancelIcon from '@mui/icons-material/Cancel';
import { AlertAction } from '../../models/alert.model';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import UndoIcon from 'assets/undo-icon.svg';
import {
  isAlertStatusClosed,
  isAlertStatusOpen,
} from '../../services/alert-status';

export interface AlertDetailButtonProps {
  status: string;
  isArchived: boolean;
  handler: (action: AlertAction) => void;
}

export const AlertDetailButtons = (props: AlertDetailButtonProps) => {
  const { status, isArchived, handler } = props;

  const handleClick = (event: AlertAction) => () => {
    handler(event);
  };

  const generateAlertDetailButtons = (status: string) => {
    if (isAlertStatusOpen(status)) {
      return (
        <SecondaryButton
          sx={{ mr: 1.5 }}
          variant='outlined'
          startIcon={<CancelIcon />}
          onClick={handleClick(AlertAction.Close)}
        >
          Close
        </SecondaryButton>
      );
    } else if (isAlertStatusClosed(status)) {
      return (
        <SecondaryButton
          sx={{ mr: 1.5 }}
          variant='outlined'
          startIcon={<img src={UndoIcon} alt={'reopen'} />}
          onClick={handleClick(AlertAction.Reopen)}
        >
          Reopen
        </SecondaryButton>
      );
    }
    return '';
  };

  if (isArchived) {
    return (
      <Box sx={{ m: 3, display: 'flex', flexWrap: 'nowrap' }}>
        <SecondaryButton
          variant='outlined'
          startIcon={<UnarchiveIcon />}
          onClick={handleClick(AlertAction.Unarchive)}
        >
          Unarchive
        </SecondaryButton>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ m: 3, display: 'flex', flexWrap: 'nowrap' }}>
        {generateAlertDetailButtons(status)}
        <SecondaryButton
          variant='outlined'
          startIcon={<ArchiveIcon />}
          onClick={handleClick(AlertAction.Archive)}
        >
          Archive
        </SecondaryButton>
      </Box>
    </>
  );
};
