import { gql } from '@apollo/client';

export const GET_KPI_DATA = gql`
  query getKpiData($vesselImo: String!, $dateRange: DateRangeInput) {
    vesselPerformanceIndicators(vesselImo: $vesselImo, dateRange: $dateRange) {
      # Delta Power Total CO2 Penalty
      deltaPowerDailyCO2Penalty {
        value {
          deltaPowerDailyCO2PenaltySum
          deltaPowerDailyCO2Penalty
          totalStatus
          dailyStatus
        }
      }
      deltaPowerFuelPenalty {
        value {
          deltaPowerDailyFuelPenaltySum
          deltaPowerDailyFuelPenalty
          totalStatus
          dailyStatus
        }
      }
      mesfoc {
        value {
          sfocDeviationPercent
          status
        }
      }
      deltaSpeed {
        value {
          deltaSpeed
          status
        }
      }
      deltaPower {
        value {
          deltaPower
          status
        }
      }
      foulingResistance {
        value {
          rahFoulingPercent
          status
        }
      }
    }
  }
`;
