import { CiiBoundaries } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import { ChartKeyProps } from 'shared/components/chart-key/chart-key.component';
import { TEXT } from 'shared/constants/text';
import { theme } from 'styles/theme';
import { customColors } from './cii-explore-data.viewModel';
import { ChartKeyItem } from 'shared/components/chart-key/chart-key.component';
import { UTCDate } from 'shared/utils/date-utc-helper';
export const fleetCIIYAxisTitle = 'Total Vessels';
export const yAxisTitle = `${TEXT.FORMULAS.CII} (Deviation)`;
export enum fleetCIIHistoricalType {
  YTD = 'ytd',
  LAST_YEAR_AND_YTD = 'lastYAndYtd',
}
export enum fleetCIIHistoricalTypeLabel {
  YTD = 'Year to Date',
  LAST_YEAR_AND_YTD = 'Year to Date & Last 12 Months',
}

const minX = 0;
const maxX = 1;
const currentYear = new UTCDate().year!;

export const getDomain = (): { min: number; max: number } => {
  return {
    min: UTCDate.create(currentYear, 0, 1, 0, 0, 0).unixTime!,
    max: UTCDate.create(currentYear, 11, 31, 0, 0, 0).unixTime!,
  };
};

export const getChartRangeValues = (
  dataValues: number[],
  ciiBoundaries?: CiiBoundaries
): { dataMin: number; dataMax: number; chartMax: number } => {
  if (!ciiBoundaries) {
    return {
      dataMin: 0,
      dataMax: 8,
      chartMax: (8 / 275) * 305,
    };
  }

  const maxCii = Math.max(...dataValues) || 0;
  const adjustment =
    ciiBoundaries?.upper && ciiBoundaries?.lower
      ? ciiBoundaries.upper - ciiBoundaries.lower
      : 2;
  const maxE = ciiBoundaries?.inferior
    ? ciiBoundaries.inferior + adjustment
    : 15;

  // Bottom of line chart
  const dataMin = 0;
  // Top of line chart
  const dataMax = maxCii < maxE ? maxE : maxCii + adjustment;

  const pixelsToUnits = dataMax / 275;

  // Top of chart (above reference areas)
  const chartMax = 305 * pixelsToUnits;

  // Uncomment to verify reference area height is 30 pixels
  // const unitsToPixels = 275 / dataMax;
  // const refAreaHeightInUnits = chartMax - dataMax;
  // console.log('should be 30: ', refAreaHeightInUnits * pixelsPerUnits);

  return {
    dataMin: dataMin < 0 ? 0 : dataMin,
    dataMax: dataMax,
    chartMax: chartMax,
  };
};

export const generateBoundaryChartData = (
  ciiBoundaries: CiiBoundaries,
  maxY: number,
  chartMax: number
) => {
  const pixelsToUnits = maxY / 275;
  const approximateAdjustmentInPixels = 0.8812746855161966;
  const adjustmentInUnits = approximateAdjustmentInPixels * pixelsToUnits;
  const ciiBoundaryChartData = [
    {
      positionLeft: minX,
      positionRight: maxX,
      maxValue: maxY,
      aKey: 'A',
      aValue: ciiBoundaries.superior,
      bKey: 'B',
      bValue: ciiBoundaries.lower - ciiBoundaries.superior,
      cKey: 'C',
      cValue: ciiBoundaries.upper - ciiBoundaries.lower,
      dKey: 'D',
      dValue: ciiBoundaries.inferior - ciiBoundaries.upper,
      eKey: 'E',
      eValue: maxY - ciiBoundaries.inferior - adjustmentInUnits,
      topBarValue: chartMax - maxY + adjustmentInUnits,
    },
  ];

  return ciiBoundaryChartData;
};

export const generateReferenceAreaData = (
  ciiBoundaries: CiiBoundaries,
  maxY: number
) => {
  return [
    {
      position: 'left',
      aValue: ciiBoundaries.superior,
      bValue: ciiBoundaries.lower - ciiBoundaries.superior,
      cValue: ciiBoundaries.upper - ciiBoundaries.lower,
      dValue: ciiBoundaries.inferior - ciiBoundaries.upper,
      eValue: maxY - ciiBoundaries.inferior,
    },
    {
      position: 'right',
      aValue: ciiBoundaries.superior,
      bValue: ciiBoundaries.lower - ciiBoundaries.superior,
      cValue: ciiBoundaries.upper - ciiBoundaries.lower,
      dValue: ciiBoundaries.inferior - ciiBoundaries.upper,
      eValue: maxY - ciiBoundaries.inferior,
    },
  ];
};

export const keyProps: ChartKeyProps = {
  items: [
    { id: 1, key: 'ciiRating', name: 'CII Rating', fill: theme.colors.cyan },
    { id: 2, key: 'speed', name: 'Speed', fill: customColors.speed.circleFill },
    {
      id: 3,
      key: 'fuelOilConsumption',
      name: 'Fuel Oil Consumption',
      fill: customColors.fuelOilConsumption.circleFill,
    },
  ],
};

export const loadingConditionKeyProps: ChartKeyProps = {
  title: 'Loading Condition Key',
  items: [
    {
      id: 1,
      key: 'laden',
      name: 'Laden',
      fill: theme.background.component,
      border: `solid ${theme.colors.white}`,
      borderWidth: '1px',
    },
    {
      id: 2,
      key: 'ballast',
      name: 'Ballast',
      fill: 'transparent',
      border: `solid ${theme.colors.offWhite}`,
      backgroundImage: theme.colors.gradientGray,
      borderWidth: '0 0 1px 0',
    },
  ],
  icon: 'square',
};

export const dateReferenceKeyProps: ChartKeyProps = {
  title: 'Chart Time Ranges',
  items: [
    {
      id: 1,
      key: 'currentYear',
      name: 'Year to Date',
      fill: theme.colors?.blueGray,
    },
    {
      id: 2,
      key: 'endOfYearProjections',
      name: 'End of Year Projections',
      fill: theme.colors.white,
    },
  ],
  icon: 'square',
};

export const yAxisTickFormatter = (
  tick: number,
  name: string,
  requiredCii: number | undefined
) => {
  switch (name) {
    case 'ciiRating': {
      const deviation =
        ((tick - (requiredCii ?? 0)) / (requiredCii ?? 1)) * 100;
      return `${tick} (${deviation.toFixed(0)}%)`;
    }
    case 'speed':
      return tick + ' ' + TEXT.UNIT_MEASUREMENT.KNOTS;
    case 'fuelOilConsumption':
      return tick + ' ' + TEXT.UNIT_MEASUREMENT.METRIC_TONNES;
    default:
      return tick.toString();
  }
};

export const fleetCIIKeyProps: ChartKeyItem[] = [
  { id: 1, key: 'A', name: 'A', fill: theme.colors?.aRating },
  { id: 2, key: 'B', name: 'B', fill: theme.colors?.bRating },
  { id: 3, key: 'C', name: 'C', fill: theme.colors?.cRating },
  { id: 4, key: 'D', name: 'D', fill: theme.colors?.dRating },
  { id: 5, key: 'E', name: 'E', fill: theme.colors?.eRating },
];

export const getFleetCIIChartXAxisTicks = (
  historicalType: string
): number[] => {
  const ticks = [];
  if (historicalType === 'lastYAndYtd')
    for (let i = 0; i < 12; i++) {
      ticks.push(UTCDate.create(currentYear - 1, i, 1, 0, 0, 0).unixTime!);
    }

  for (let i = 0; i < 12; i++) {
    ticks.push(UTCDate.create(currentYear, i, 1, 0, 0, 0).unixTime!);
  }
  return ticks;
};

export const getFleetCIIChartDomain = (
  historicalType: string
): { min: number; max: number } => {
  return {
    min:
      historicalType === 'ytd'
        ? UTCDate.create(currentYear, 0, 1, 0, 0, 0).unixTime!
        : UTCDate.create(currentYear - 1, 0, 1, 0, 0, 0).unixTime!,
    max: UTCDate.create(currentYear, 11, 1, 0, 0, 0).unixTime!,
  };
};

export const getFleetCIIChartRangeValues = (
  dataValues: number[]
): {
  dataMin: number;
  dataMax: number;
  chartMax: number;
  chartMaxOffset: number;
} => {
  const maxCii = Math.max(...dataValues) || 0;
  // Bottom of line chart
  const dataMin = 0;
  // Top of line chart
  const dataMax = maxCii;

  const pixelsToUnits = dataMax / 275;

  // Top of chart (above reference areas)
  const chartMax = 305 * pixelsToUnits;
  const chartMaxOffset = 345 * pixelsToUnits;
  return {
    dataMin: dataMin < 0 ? 0 : dataMin,
    dataMax: dataMax,
    chartMax: chartMax,
    chartMaxOffset: chartMaxOffset,
  };
};

export const getBoundaryDateOfProjection = (ciiData: any[]) => {
  for (const element of ciiData) {
    if (!element.actual) {
      return element?.date;
    }
  }
  return null;
};

export const subDataToggleSelected = (dataToggleGroup: any) => {
  if (!dataToggleGroup) {
    return undefined;
  }

  const keys = Object.keys(dataToggleGroup);

  const filtered = keys.filter(function (key) {
    return dataToggleGroup[key];
  });
  return filtered[0];
};

export const xAxisTickCiiTrendFormatter = (unix: number) => {
  if (!unix) {
    return '';
  }
  const dateObject = new UTCDate(unix).formatMY();
  return dateObject;
};
