import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro';
import { VesselEvent } from '_gql/graphql';
import { Dayjs } from 'dayjs';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { IEvent } from '../models/events.model';
import { getEventTypeKeyByValue } from 'shared/utils/get-event-key-type-by-value.utils';

export class EventsMapper {
  private static stringToNumber(value?: string): number | null {
    if (value === undefined || value === null) return null;
    const converted = parseFloat(value);
    if (isNaN(converted)) return null;
    return converted;
  }

  private static numberToString(
    value: number | undefined | null,
    decimals?: number
  ): string {
    if (value === undefined || value === null || !value.toString) return '';
    return decimals !== undefined
      ? parseFloat(value.toString()).toFixed(decimals)
      : value.toString();
  }

  public static toDTO(event: IEvent): VesselEvent {
    // For all date ranges, except for when the start date and end date are both the same day,
    // the date range seems to implicitly cast to our custom UTC DateRange object.
    // When the start date and end date are both the same day then the date range stays as the Mui DateRange object.
    // So we are controlling for that specific scenario below.
    let startDate = event.dateRange.startDate;
    let endDate = event.dateRange.endDate;
    if (startDate === undefined && endDate === undefined) {
      const dateRange = event.dateRange as unknown as MuiDateRange<Dayjs>;
      startDate = new UTCDate(dateRange[0]).startOfDay;
      endDate = new UTCDate(dateRange[1]).endOfDay;
    }
    return {
      id: event.id ?? null,
      bunkerPrice: this.stringToNumber(event.bunkerPrice),
      company: event.company ?? null,
      diverCost: this.stringToNumber(event.diverCost),
      startDate: startDate!,
      endDate: endDate!,
      eventName: event.eventName ?? null,
      eventType: event.eventType ?? null,
      location: event.location ?? null,
      notes: event.comments ?? null,
      portAndOtherCosts: this.stringToNumber(event.portAndOtherCosts),
      totalOosPeriod: this.stringToNumber(event.totalOOSPeriod),
      vesselImo: event.vesselImo ?? null,
      vesselId: event.vesselId ?? null,
      dailyFuelSavings: this.stringToNumber(event.dailyFuelSavingsMT),
      dailyFuelSavingsDollar: this.stringToNumber(
        event.dailyFuelSavingsDollars
      ),
      paybackPeriod: this.stringToNumber(event.paybackPeriod),
      achievedImprovement: this.stringToNumber(event.achievedImprovement),
      daysToRecover: this.stringToNumber(event.daysToRecover),
      totalEventCost: this.stringToNumber(event.totalEventCost),
    };
  }

  public static toDomain(dto: VesselEvent): IEvent {
    return {
      id: dto.id ?? '',
      eventName: dto.eventName ?? '',
      eventType: dto.eventType ?? '',
      dateRange: new DateRange(dto.startDate!, dto.endDate!, true),
      location: dto.location ?? '',
      company: dto.company ?? '',
      portAndOtherCosts: this.numberToString(dto.portAndOtherCosts, 2),
      totalEventCost: this.numberToString(dto.totalEventCost, 2),
      paybackPeriod: this.numberToString(dto.paybackPeriod),
      daysToRecover: this.numberToString(dto.daysToRecover),
      dailyFuelSavingsDollars: this.numberToString(
        dto.dailyFuelSavingsDollar,
        2
      ),
      dailyFuelSavingsMT: this.numberToString(dto.dailyFuelSavings),
      achievedImprovement: this.numberToString(dto.achievedImprovement, 0),
      diverCost: this.numberToString(dto.diverCost, 2),
      bunkerPrice: this.numberToString(dto.bunkerPrice, 2),
      totalOOSPeriod: this.numberToString(dto.totalOosPeriod),
      comments: dto.notes ?? '',
      vesselImo: dto.vesselImo ?? '',
      vesselId: dto.vesselId ?? '',
    };
  }

  public static formatCreate(dto: VesselEvent) {
    const eventType = getEventTypeKeyByValue(dto.eventType?.toString());
    return {
      bunkerPrice: dto.bunkerPrice,
      company: dto.company,
      diverCost: dto.diverCost,
      startDate: dto.startDate?.toString(),
      endDate: dto.endDate?.toString(),
      eventName: dto.eventName,
      eventType,
      location: dto.location,
      notes: dto.notes,
      portAndOtherCosts: dto.portAndOtherCosts,
      totalOosPeriod: dto.totalOosPeriod,
      vesselImo: dto.vesselImo,
      vesselId: dto.vesselId,
    };
  }

  public static formatUpdate(dto: VesselEvent) {
    const eventType = getEventTypeKeyByValue(dto.eventType?.toString());
    return {
      id: dto.id,
      bunkerPrice: dto.bunkerPrice,
      company: dto.company,
      diverCost: dto.diverCost,
      startDate: dto.startDate?.toString(),
      endDate: dto.endDate?.toString(),
      eventName: dto.eventName,
      eventType,
      location: dto.location,
      notes: dto.notes,
      portAndOtherCosts: dto.portAndOtherCosts,
      totalOosPeriod: dto.totalOosPeriod,
      vesselImo: dto.vesselImo,
      vesselId: dto.vesselId,
    };
  }
}
