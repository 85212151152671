import { useMsal } from '@azure/msal-react';
import { useTheme } from '@mui/material';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';

/**
 * Renders a sign out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  const theme = useTheme();

  const handleLogout = () => {
    instance.logout();
  };

  return (
    <IconButton
      size='large'
      aria-label='account of current user'
      aria-controls='menu-appbar'
      aria-haspopup='true'
      onClick={() => handleLogout()}
      color='inherit'
      sx={{ p: 0 }}
    >
      <LogoutIcon sx={{ mr: 1, color: theme.palette.text.disabled }} />
      <Typography variant='menuItem' component='div' noWrap={true}>
        Sign Out
      </Typography>
    </IconButton>
  );
};
