import { DownloadListVessel } from 'routes/reports/components/generateReport/download-common';

export interface Grouping {
  groupingOrder: number;
  groupingType: string; // Enum industry, organization, custom
  groupingDescription: string; // Vessel Type - IMO Vesel Type
  groupingCategoryName: string; // Group 1
  groupingName: string; // i.e. Refridgerated Cargo Carrier
  id: string;
}

export interface DropdownGroupingCategory {
  groupingDisplayName: string; // uses field groupingCategoryName
  id: string;
  groupingOrder: number;
  isChecked: boolean;
  isDisabled: boolean;
  groupings: DropdownGroup[];
}

function concat(arrays: any[]) {
  return [].concat(...arrays);
}
function intersection(arrays: any[]) {
  return concat(arrays).filter((e) => arrays.every((a) => a.includes(e)));
}

export interface DropdownGroup {
  groupingName: string; // uses field groupingName
  id: string;
  isChecked: boolean;
  isDisabled: boolean;
  imoNumbers: string[];
  // added nullable vessels to provide vessel information for Report vessel dropdown
  vessels?: DownloadListVessel[];
}

export const handleGroupingsUpdate = (
  category: DropdownGroupingCategory,
  vesselGroupings: DropdownGroupingCategory[]
) => {
  const categoryIndex = vesselGroupings.findIndex((g) => g.id === category.id);
  const newGroupings = [...vesselGroupings];
  newGroupings[categoryIndex] = category;

  const newSelectedGroupings: string[] = [];
  let selectedImoNumbers: string[] = [];
  const selectedImoCategories: { [key: string]: string[] } = {};

  const selectedOptions: DropdownGroupingCategory[] = [];
  newGroupings.forEach((groupingCategory) => {
    selectedImoCategories[groupingCategory.id] = [];
    if (groupingCategory.isChecked) {
      newSelectedGroupings.push(groupingCategory.groupingDisplayName);
      selectedOptions.push(groupingCategory);
    } else {
      groupingCategory.groupings.forEach((subGrouping) => {
        if (subGrouping.isChecked) {
          newSelectedGroupings.push(subGrouping.groupingName);
          selectedOptions.push(groupingCategory);
        }
      });
    }

    groupingCategory.groupings.forEach((g) => {
      if (g.isChecked) {
        selectedImoCategories[groupingCategory.id]?.push(...g.imoNumbers);
      }
    });
  });

  // check if any filter has been selected
  if (
    newGroupings.flatMap((x) => x.groupings).filter((x) => x.isChecked)
      .length === 0
  ) {
    // get all Imos
    selectedImoNumbers = Array.from(
      new Set(
        newGroupings.flatMap((x) => x.groupings).flatMap((x) => x.imoNumbers)
      )
    );
  } else {
    const imoArray = [];
    for (const [, value] of Object.entries(selectedImoCategories)) {
      if (Array.isArray(value) && value.length > 0) imoArray.push(value);
    }

    selectedImoNumbers = intersection(imoArray);

    // When no intersectioned vesselIMOs detected => vesselImo=[] - returns all vessels, and we need return 0 vessels providing fake IMO.
    if (selectedOptions.length !== 0 && selectedImoNumbers.length === 0) {
      selectedImoNumbers = ['xxx'];
    }

    selectedImoNumbers = selectedImoNumbers.filter(
      (item, index) => selectedImoNumbers.indexOf(item) === index
    );
  }

  const { filteredGroupings, selectedVessels } = filterDropdownGroupingData(
    newGroupings,
    selectedImoNumbers
  );

  return {
    filteredGroupings, // Groupings results after after disabling unwanted groupings
    newSelectedGroupings, // Array of strings, used to generate display string
    selectedOptions, // Array of selected Groupings, used purely to pacify MUI's Select
    selectedImoNumbers, // Array of strings, the primary output of the VesselGroupings dropdown
    selectedVessels, // Array of Vessels so that the name/imo are available on one object (Reports Page)
  };
};

const filterDropdownGroupingData = (
  groupings: DropdownGroupingCategory[],
  selectedImoNumbers: string[]
) => {
  const filteredGroupings = [...groupings];
  const selectedVessels: DownloadListVessel[] = [];
  const noGroupingsChecked = filteredGroupings.every(
    (c) => !c.isChecked && c.groupings.every((g) => !g.isChecked)
  );

  const addedImos: string[] = [];

  filteredGroupings.forEach((groupingCategory) => {
    groupingCategory.groupings.forEach((grouping) => {
      // Add all vessels that match selectedImoNumbers to selectedVessels
      grouping.vessels?.forEach((v) => {
        if (
          selectedImoNumbers.includes(v.imoNumber as string) &&
          !addedImos.includes(v.imoNumber)
        ) {
          addedImos.push(v.imoNumber);
          selectedVessels.push(v);
        }
      });

      // toggle disabled state of groupings based on selectedImoNumbers
      if (
        (selectedImoNumbers.length === 0 && noGroupingsChecked) ||
        grouping.imoNumbers.some((r) => selectedImoNumbers.includes(r))
      ) {
        grouping.isDisabled = false;
      } else {
        grouping.isDisabled = true;
      }
    });
  });
  return { filteredGroupings, selectedVessels };
};
