import { gql } from '@apollo/client';

export const GET_REPORT_ISSUES = gql`
  query GetReportIssues(
    $vesselImo: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vessels(where: { imoNumber: { eq: $vesselImo } }) {
      id
      name
      imoNumber
      reports(startDate: $startDate, endDate: $endDate) {
        vesselReports(where: { criticalIssuesTotal: { gt: 0 } }) {
          id
          vesselImo
          generatedReportId
          reportedDate
          criticalIssuesTotal
          reportType
        }
      }
    }
  }
`;
