import { useQuery } from '@apollo/client';
import { GET_GROUP_PENALTY_CHART } from '../queries/group-penalty-chart.query';
import { DateRange } from 'shared/utils/date-utc-helper';
import { DropdownGroupingCategory } from 'shared/components/vesselGroupingsDropdown/models/vesselGroupings.viewModel';
import { Query } from '_gql/graphql';

export function useGetGlobalGroupPenaltyData(
  dateRange: DateRange | null,
  globalGroups: DropdownGroupingCategory[] | undefined
) {
  const globalGroupSelection = {
    globalVesselGroups: globalGroups,
  };

  return useQuery<Query>(GET_GROUP_PENALTY_CHART, {
    skip: !dateRange?.getDateRangeStruct,
    variables: {
      ...dateRange?.getDateRangeStruct,
      globalGroupSelection,
    },
  });
}
