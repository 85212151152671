import React, { useContext, useEffect } from 'react';
import { VesselDataSummary as VesselDataSummaryCards } from '../../components/vesselDataSummary/vessel-data-summary';
import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import { useLocation, useParams } from 'react-router-dom';
import { VesselDataOverview } from '../../components/vesselDataOverview/vessel-data-overview';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { VesselDataFilterProps } from 'routes/vessel-data-monitor/components/vesselDataOverview/table/util/vessel-data-overview-filter';
import { useVesselSummaryScores } from 'routes/vessel-data-monitor/services/vessel-summary.get.service';
import { VesselSummaryData } from 'routes/vessel-data-monitor/components/vesselDataSummary/vessel-data-summary.viewModel';
import {
  failedScoreText,
  overallScoreText,
  passedScoreText,
} from 'routes/vessel-data-monitor/common/common';

import { NavBarContext } from 'shared/models/navBar.context.model';
import { StatusKpiCardProps } from 'shared/components/status-kpi-card/status-kpi-card.component';
import { KpiStatus } from '_gql/graphql';
import { useScrollToAnchor } from '../../hooks/scrollToAnchor.hook';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { DateRange } from 'shared/utils/date-utc-helper';

export const constants = {
  VDM_PAGE_TITLE: 'Vessel Data Monitor: Vessel Overview',
  VDS_SECION_TITLE: 'Vessel Data Quality Summary',
  VDO_SECTION_TITLE: 'Vessel Data Quality',
};

export function VesselDataSummary() {
  const location = useLocation();
  const { id } = useParams();
  const { utcDateRange } = useContext(NavBarContext);
  const vesselIMO = id;
  const { loading, error, data } = useVesselSummaryScores(
    vesselIMO,
    utcDateRange ?? new DateRange()
  );

  const { setVesselName } = useContext(TopNavigationContext);

  const locationHash = location.hash.split('?')[0] || '';
  const locationPostHash = location.hash.split('?')[1] || null;
  const preFilter =
    locationPostHash !== null ? locationPostHash.split(',') : [];

  const [vesselDataFilter, setVesselDataFilter] =
    React.useState<string[]>(preFilter);

  const title = data?.vessels?.[0]?.name ?? '';

  useEffect(() => {
    if (title && title !== '') {
      setVesselName(title);
    }
  }, [title]);

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newVesselDataFilter: string[]
  ) => {
    event.preventDefault();
    setVesselDataFilter(newVesselDataFilter);
  };

  const vesselDataOverviewFilter: VesselDataFilterProps = {
    vesselDataFilter: vesselDataFilter,
    handleFilterChange,
  };

  const rows = data?.vessels?.[0]?.reports?.vesselReports ?? [];

  /*
  41585. Per Bob I am modifying to use the UTCDate's date object. It should be rewritten or we should add getTime/getMonth/getFullYear/etc properties
  to UTCDate - however this is a fast fix with minimal risk for now. We will revisit in the future.
  */
  const sortedRows =
    rows.length > 0
      ? [...rows].sort(
          (a, b) =>
            (b.reportedDate?.unixTime ?? 0) - (a.reportedDate?.unixTime ?? 0)
        )
      : [];

  const filteredData =
    vesselDataFilter.length === 0 //[]
      ? sortedRows
      : sortedRows.filter((row) => {
          if (!row.isValid) {
            const rowItemFailureTypes = [
              row.emissionDataFailed ? 'fail-emission' : '',
              row.nonEmissionDataFailed ? 'fail-non-emission' : '',
            ];
            const valuetoReturn = vesselDataFilter.some((filter) =>
              rowItemFailureTypes.includes(filter)
            );
            return valuetoReturn;
          }
          return vesselDataFilter.includes(row.isValid ? 'passed' : 'failed');
        });

  const {
    overallPassScore,
    overallFailScore,
    overallPassScorePercentage,
    status,
  } = VesselSummaryData(data);

  useScrollToAnchor({ anchor: locationHash, loading, error, data });

  const overallPassedScoreCardColor =
    status === KpiStatus.Unknown
      ? 'unknown'
      : status === KpiStatus.Error
      ? 'error'
      : status === KpiStatus.Warning
      ? 'warning'
      : 'success';

  const overallPassedScoreCardProps: StatusKpiCardProps = {
    kpiData: {
      status: overallPassedScoreCardColor,
      primaryValue: `${
        overallPassScorePercentage === undefined
          ? '---'
          : overallPassScorePercentage
      }`,
      unit: '%',
    },
    title: 'Overall Passed Score',
    showTooltip: true,
    tooltipText: overallScoreText,
  };
  const passedCardProps: StatusKpiCardProps = {
    kpiData: {
      status: `${overallPassScore === undefined ? 'unknown' : 'success'}`,
      primaryValue: `${overallPassScore ?? '---'}`,
    },
    title: 'Passed',
    showTooltip: true,
    tooltipText: passedScoreText,
  };
  const failedCardProps: StatusKpiCardProps = {
    kpiData: {
      status: `${overallFailScore === undefined ? 'unknown' : 'error'}`,
      primaryValue: `${overallFailScore ?? '---'}`,
    },
    title: 'Flagged',
    showTooltip: true,
    tooltipText: failedScoreText,
  };
  return (
    <PageWrapper>
      <PageHeader title={title + ` ${constants.VDM_PAGE_TITLE}`} />
      <VesselDataSummaryCards
        failedCardProps={failedCardProps}
        passedCardProps={passedCardProps}
        overallPassedScoreCardProps={overallPassedScoreCardProps}
        error={error}
        loading={loading}
      />
      <VesselDataOverview
        vesselDataOverviewFilter={vesselDataOverviewFilter}
        filteredData={filteredData}
        error={error}
        loading={loading}
      />
    </PageWrapper>
  );
}
