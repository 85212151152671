import { useContext, useState } from 'react';
import { Section } from 'shared/components/section/section.component';
import { AlertTable } from './components/alert-table/alert-table.component';
import { AlertActivity } from './components/alert-activity/alert-activity.component';
import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { AlertSummary } from './components/alert-summary/alert-summary.component';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';

export const constants = {
  ALERT_PAGE_TITLE: 'Manage Alerts',
  ALERT_ACTIVITY_TITLE: 'Alerts Activity',
  ALERT_SUMMARY_TITLE: 'Alerts Summary',
  ALERT_TABLE_NAME: 'Alerts Table',
};

export const AlertsRoute = () => {
  const { imoNumbers, utcDateRange } = useContext(NavBarContext);
  const [filter, setFilter] = useState('active');
  const [refreshSummary, setRefreshSummary] = useState<UTCDate>();

  return (
    <PageWrapper>
      <PageHeader title={constants.ALERT_PAGE_TITLE} />
      <Section
        title={constants.ALERT_ACTIVITY_TITLE}
        exportSection={false}
        childComponent={
          <AlertActivity
            vesselImoNumbers={imoNumbers}
            dateRange={utcDateRange}
          />
        }
      />
      <Section
        title={constants.ALERT_SUMMARY_TITLE}
        exportSection={true}
        exportOptions={{
          includePDF: false,
          alertType: 'All',
          includeCardData: true,
        }}
        childComponent={<AlertSummary forceUpdate={refreshSummary} />}
      />
      <Section
        title={constants.ALERT_TABLE_NAME}
        id={constants.ALERT_TABLE_NAME}
        exportSection={true}
        exportOptions={{ includePDF: false, alertType: filter }}
        preventUnmount={true}
        childComponent={
          <AlertTable
            vesselImoNumbers={imoNumbers}
            dateRange={utcDateRange ?? new DateRange()}
            filter={filter}
            setFilter={setFilter}
            refreshSummaryCallBack={() => setRefreshSummary(new UTCDate())}
          />
        }
      />
    </PageWrapper>
  );
};
