import { MapDrawerBodyLayout } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer-body-layout';
import { usePortInfoFetchingForDrawer } from 'shared/components/esri-map/services/useDataFetchingForDrawer';
import { Skeleton, Box, Typography } from '@mui/material';
import mapmarker from 'assets/map-marker.svg';

export const MapDrawerPortComponent = () => {
  const { data, loading }: any = usePortInfoFetchingForDrawer();

  return !data || loading ? (
    <Skeleton
      sx={{ mt: 3, height: '30vh' }}
      variant='rounded'
      animation='pulse'
      data-testid={'loading-skeleton'}
    ></Skeleton>
  ) : (
    <>
      <MapDrawerBodyLayout title='Port Details' hasBoundary={false}>
        <Box
          sx={{
            m: '16px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box component='img' src={mapmarker} sx={{ pr: 1 }} />
          <Box sx={{ flexGrow: 2 }}>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Country</Typography>
              <Typography variant='subtitle2'>{data.Port_Country}</Typography>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 2 }}>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>UN/LOCODE</Typography>
              <Typography variant='subtitle2'>{data.UNLOCODE}</Typography>
            </Box>
          </Box>
        </Box>
      </MapDrawerBodyLayout>
    </>
  );
};
