import { Typography, Box } from '@mui/material';

export const NoIssuesFoundMessage = () => {
  return (
    <>
      <Typography variant='alertTitle' color='#ABCBAD'>
        No Issues Found in Report
      </Typography>
      <Box>
        <Typography variant='body2' color='#ABCBAD'>
          No required emissions regulation data has been flagged.
        </Typography>
        <Typography variant='body2' color='#ABCBAD'>
          Click below to download.
        </Typography>
      </Box>
    </>
  );
};
