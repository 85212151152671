import { gql } from '@apollo/client';

export const GET_VESSEL_FOULING_TREND_DATA = gql`
  query IVchartsQueryFouling(
    $vesselImo: String!
    $vesselId: String!
    $dateRange: DateRangeInput
  ) {
    vesselEvents(
      vesselId: $vesselId
      vesselImo: $vesselImo
      dateRange: $dateRange
    ) {
      id
      vesselId
      vesselImo
      eventName
      eventType
      startDate
      endDate
      location
      company
      portAndOtherCosts
      diverCost
      totalOosPeriod
      notes
      paybackPeriod
      bunkerPrice
      achievedImprovement
      dailyFuelSavings
      dailyFuelSavingsDollar
      daysToRecover
      totalEventCost
    }

    vesselPerformanceIndicators(vesselImo: $vesselImo, dateRange: $dateRange) {
      vessel {
        id
        imoNumber
        name
        vesselGrouping {
          groupingName
          groupingCategoryName
        }
      }
      #Fouling Resistance (Updated to Products)
      foulingResistance {
        timeSeriesValues {
          timestamp
          rahFoulingPercent #Output
          rswCalmWaterResistance #Expected
          chartDetail {
            mePower
            sog
            stw
            meanDraft
            beaufortScale
            fuelMGOEq
          }
          isMissingData
          isValid
        }
        thresholdChartBands {
          baseLine
          low
          medium
          high
        }
      }
    }
  }
`;

export const GET_VESSEL_POWER_TREND_DATA = gql`
  query IVchartsQueryPower(
    $vesselImo: String!
    $vesselId: String!
    $dateRange: DateRangeInput
  ) {
    vesselEvents(
      vesselId: $vesselId
      vesselImo: $vesselImo
      dateRange: $dateRange
    ) {
      id
      vesselId
      vesselImo
      eventName
      eventType
      startDate
      endDate
      location
      company
      portAndOtherCosts
      diverCost
      totalOosPeriod
      notes
      paybackPeriod
      bunkerPrice
      achievedImprovement
      dailyFuelSavings
      dailyFuelSavingsDollar
      daysToRecover
      totalEventCost
    }

    vesselPerformanceIndicators(vesselImo: $vesselImo, dateRange: $dateRange) {
      vessel {
        id
        imoNumber
        name
        vesselGrouping {
          groupingName
          groupingCategoryName
        }
      }
      # Delta Power (Still in Modules)
      deltaPower {
        timeSeriesValues {
          timestamp
          deltaPower #Output
          powerExpected #Expected
          chartDetail {
            mePower
            sog
            stw
            meanDraft
            beaufortScale
            fuelMGOEq
          }
          isMissingData
          isValid
        }
        thresholdChartBands {
          baseLine
          low
          medium
          high
        }
      }
    }
  }
`;

export const GET_VESSEL_SPEED_TREND_DATA = gql`
  query IVchartsQuerySpeed(
    $vesselImo: String!
    $vesselId: String!
    $dateRange: DateRangeInput
  ) {
    vesselEvents(
      vesselId: $vesselId
      vesselImo: $vesselImo
      dateRange: $dateRange
    ) {
      id
      eventName
      eventType
      startDate
      endDate
    }

    vesselPerformanceIndicators(vesselImo: $vesselImo, dateRange: $dateRange) {
      vessel {
        id
        imoNumber
        name
        vesselGrouping {
          groupingName
          groupingCategoryName
        }
      }
      # Delta Speed (Still in Modules)
      deltaSpeed {
        timeSeriesValues {
          timestamp
          deltaSpeed #Output
          velocityExpected #Expected
          chartDetail {
            mePower
            sog
            stw
            meanDraft
            beaufortScale
            fuelMGOEq
          }
          isMissingData
          isValid
        }
        thresholdChartBands {
          baseLine
          low
          medium
          high
        }
      }
    }
  }
`;
export const GET_VESSEL_MESFOC_TREND_DATA = gql`
  query IVchartsQueryMESfoc(
    $vesselImo: String!
    $vesselId: String!
    $dateRange: DateRangeInput
  ) {
    vesselEvents(
      vesselId: $vesselId
      vesselImo: $vesselImo
      dateRange: $dateRange
    ) {
      id
      eventName
      eventType
      startDate
      endDate
    }

    vesselPerformanceIndicators(vesselImo: $vesselImo, dateRange: $dateRange) {
      vessel {
        id
        imoNumber
        name
        vesselGrouping {
          groupingName
          groupingCategoryName
        }
      }
      # Delta Speed (Still in Modules)
      mesfoc {
        timeSeriesValues {
          timestamp
          sfocDeviationPercent #Output
          sfoCexpected #Expected
          chartDetail {
            mePower
            sog
            stw
            meanDraft
            beaufortScale
            fuelMGOEq
          }
          isMissingData
          isValid
        }
        thresholdChartBands {
          baseLine
          low
          medium
          high
        }
      }
    }
  }
`;
