import { ServiceResult } from 'shared/models/serviceResult.model';
import { UserMapper } from '../mappers/user.mapper';
import { useGetUserData } from '../repositories/user.repository';
import { UserDomain } from '../models/user.model';
import { useMsal } from '@azure/msal-react';

export const useGetUser = (): ServiceResult<UserDomain> => {
  const { instance } = useMsal();
  const response = useGetUserData();
  const accountInfo = instance.getActiveAccount();

  const user = response?.data?.user;
  const loading = response?.loading ?? false;
  const error = response?.error;

  return {
    data: UserMapper.toDomain(user ?? undefined, accountInfo ?? undefined),
    loading,
    error,
  };
};
