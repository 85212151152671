import { Datapoint, IAlert } from '../models/alert.model';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { UTCDate } from 'shared/utils/date-utc-helper';

export const TooltipFormatter = (value: ValueType, name: NameType) => {
  const formattedName = name === 'alerts' ? 'Alerts' : name;
  return [value, formattedName];
};

export const getAlertActivityData = (
  alerts: IAlert[],
  startDate: UTCDate,
  endDate: UTCDate
) => {
  const alertDailyTotals: Datapoint[] = [];

  alerts.sort(
    (a: IAlert, b: IAlert) =>
      (a.reportedOnTimestamp ?? 0) - (b.reportedOnTimestamp ?? 0)
  );

  alerts.forEach((alert) => {
    const utcDate = new UTCDate(alert?.reportedOnTimestamp ?? 0);

    const datapointKey = utcDate.formatYMD();
    const friendlyDate = utcDate.formatDMY();
    alertDailyTotals.push({
      key: datapointKey,
      alerts: alert?.reportedOnTotal ?? 0,
      friendlyDate: friendlyDate,
      timestamp: alert?.reportedOnTimestamp,
    });
  });

  return appendDateRangeItems(alertDailyTotals, startDate, endDate);
};

const appendDateRangeItems = (
  alerts: Datapoint[],
  startDate: UTCDate,
  endDate: UTCDate
) => {
  const firstAlertTimestamp = alerts[0]?.timestamp ?? 0;
  const lastAlertTimestamp = alerts[alerts.length - 1]?.timestamp ?? 0;
  const startDateTimestamp = startDate.startOfDay?.unixTime;
  const endDateTimestamp = endDate.startOfDay?.unixTime;

  if (firstAlertTimestamp !== startDateTimestamp) {
    alerts.unshift({
      key: startDate.formatYMD(),
      alerts: 0,
      friendlyDate: startDate.formatDMY(),
      timestamp: startDateTimestamp,
    });
  }

  if (lastAlertTimestamp !== endDateTimestamp) {
    alerts.push({
      key: endDate.formatYMD(),
      alerts: 0,
      friendlyDate: endDate.formatDMY(),
      timestamp: endDateTimestamp,
    });
  }

  return alerts;
};
