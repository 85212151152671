import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { theme } from 'styles/theme';

const componentTheme = createTheme({
  ...theme,
  palette: {
    background: {
      default: 'rgba(239, 239, 240, 0.07)',
    },
    secondary: {
      main: '#4DE8E3',
      dark: '#1D262F',
      light: '#EFEFF0',
    },
  },
});

export const Tooltip = ({
  active,
  payload,
  label,
  chartType,
}: TooltipProps<ValueType, NameType> & { chartType: string }) => {
  if (!active || !payload?.length || payload === undefined || !label)
    return null;

  const value = payload[0]?.value;
  const deviation =
    payload[0]?.payload?.deviation || payload[0]?.payload?.ciiDeviation;
  const labelDate = new UTCDate(label).formatDMY();

  return (
    <ThemeProvider theme={componentTheme}>
      <Box
        display='flex'
        flexDirection='column'
        sx={{
          backgroundColor: 'secondary.light',
          borderRadius: '5px',
          alignItems: 'center',
          textAlign: 'center',
          padding: '8px',
        }}
      >
        <Typography variant='caption' sx={{ color: 'secondary.dark' }}>
          {`${chartType}: ${value}`}
        </Typography>
        {deviation !== null && deviation !== undefined && (
          <Typography variant='caption' sx={{ color: 'secondary.dark' }}>
            {`Deviation: ${deviation}%`}
          </Typography>
        )}
        <Typography variant='caption' sx={{ color: 'secondary.dark' }}>
          {/* TODO: Add date formatter */}
          {labelDate}
        </Typography>
      </Box>
    </ThemeProvider>
  );
};
