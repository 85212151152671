import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { KpiChartItem } from './fleet-kpi-chart.model';

export const fullDateRangeChartData = (
  data: KpiChartItem[] | undefined,
  dateRange: DateRange | null
): KpiChartItem[] => {
  const dataToReturn: KpiChartItem[] = [];

  if (data == null) {
    return dataToReturn;
  }

  data = data?.filter((x) => Object.keys(x).length > 1);
  let currentDate = dateRange?.startDate ?? new UTCDate();
  const endDate = dateRange?.endDate ?? new UTCDate();
  while (currentDate < endDate) {
    const currentDateValue = currentDate.formatDMY();
    const dataForDay = data.find((x) => x['date'] === currentDateValue);
    if (dataForDay) dataToReturn.push(dataForDay);
    else dataToReturn.push({ date: currentDateValue });

    currentDate = new UTCDate(currentDate.dateDayjs?.add(1, 'day'));
  }

  return dataToReturn;
};
