import { Skeleton, Box, Typography, Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { MapDrawerBodyLayout } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer-body-layout';
import { ProgressBar } from 'shared/components/esri-map/map-drawer/components/progress-bar.component';
import { useGetActiveVoyageInfoForDrawerService } from 'routes/voyage-monitor/service/active-voayge-info-for-drawer.get.service';
import { WayPointCard } from 'shared/components/esri-map/map-drawer/components/way-point-card.component';
const buttonStyle = {
  color: 'text.primary',
  borderRadius: '8px',
  border: '1px solid rgba(255, 255, 255, 0.87)',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.12)',
  textTransform: 'none',
};

export const MapDrawerVesselComponent = () => {
  const { data, loading }: any = useGetActiveVoyageInfoForDrawerService();

  const navigate = useNavigate();

  const handleClickDetailViewBtn = () => {
    navigate(ROUTES.vesselVoyage.path.replace(':id', `${data.imoNumber}`));
  };

  const { id } = useParams();
  const isVesselVoyagePage = () => {
    if (id) {
      return true;
    }
    return false;
  };

  const currentLegSubtitle = data
    ? `Leg ${data.legInfo.currentLeg}/${data.legInfo.totalLeg.length}`
    : '';

  const currentLegProgress = () => {
    if (data) {
      return Math.round(
        (data.legInfo.currentLeg / data.legInfo.totalLeg.length) * 100
      );
    }
    return 0;
  };

  const wayPointTextStyle = {
    fontSize: '12px',
    fontWeight: 400,
    color: 'text.secondary',
    display: 'inline',
    fontFeatureSettings: "'clig' off, 'liga' off",
    lineHeight: '143%',
    letterSpacing: '0.17px',
  };

  return !data || loading ? (
    <Skeleton
      sx={{ mt: 3, height: '30vh' }}
      variant='rounded'
      animation='pulse'
      data-testid={'loading-skeleton'}
    ></Skeleton>
  ) : (
    <>
      <MapDrawerBodyLayout
        title='Current Leg'
        subtitle={currentLegSubtitle}
        hasBoundary={isVesselVoyagePage() ? true : false}
      >
        <Box
          sx={{
            m: '16px',
            mt: '0',
            display: 'flex',
            padding: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '12px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Typography variant='subtitle1'>{data.origin}</Typography>
              <ArrowForwardIcon
                fontSize='small'
                sx={{ color: 'rgba(255, 255, 255, 0.56)', m: '0 4px' }}
              />
              <Typography variant='subtitle1'>
                {data.nextDestinationLocation}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ProgressBar variant='determinate' value={currentLegProgress()} />
              <Typography variant='subtitle1'>
                {' '}
                {currentLegProgress()}%{' '}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            m: '0px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ flexGrow: 2 }}>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Location</Typography>
              <Typography variant='subtitle2'>{data.location}</Typography>
            </Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Position</Typography>
              <Typography variant='subtitle2'>{data.position}</Typography>
            </Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Heading</Typography>
              <Typography variant='subtitle2'>{data.heading}°</Typography>
            </Box>
            <Box>
              <Typography variant='subtitle1'>
                ATD (Last Depart. Loc.)
              </Typography>
              <Typography variant='subtitle2'>
                {data.departureTimeFromLastDepartureLocation}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 2 }}>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Status</Typography>
              <Typography variant='subtitle2'>{data.status}</Typography>
            </Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Position Received</Typography>
              <Typography variant='subtitle2'>
                {data.lastUpdatedTime} Ago
              </Typography>
            </Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Voyage Number</Typography>
              <Typography variant='subtitle2'>{data.voyageNumber}</Typography>
            </Box>
            <Box>
              <Typography variant='subtitle1'>Estimated End of Leg </Typography>
              <Typography variant='subtitle2'>---</Typography>
            </Box>
          </Box>
        </Box>
      </MapDrawerBodyLayout>
      {!isVesselVoyagePage() ? (
        <Box sx={{ m: '24px' }}>
          <Button
            variant='outlined'
            startIcon={<OpenInNewIcon />}
            sx={buttonStyle}
            onClick={handleClickDetailViewBtn}
          >
            Detailed Vessel View
          </Button>
        </Box>
      ) : (
        <>
          <MapDrawerBodyLayout title='Voyage Detail' hasBoundary={true}>
            <Box
              sx={{
                m: '0px 24px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Box sx={{ flexGrow: 2, width: '40%' }}>
                <Box sx={{ mb: '16px' }}>
                  <Typography variant='subtitle1'>Origin</Typography>
                  <Typography variant='subtitle2'>
                    {data.origin}
                    <br />({data.status})
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='subtitle1'>
                    Estimated Time Of Arrival
                  </Typography>
                  <Typography variant='subtitle2'>
                    {data.estimatedTimeArrival}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box sx={{ flexGrow: 2, width: '30%' }}>
                <Box sx={{ mb: '16px' }}>
                  <Typography variant='subtitle1'>Destination</Typography>
                  <Typography variant='subtitle2'>
                    {data.nextDestinationLocation}
                    <br />({data.status})
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='subtitle1'>Distance</Typography>
                  <Typography variant='subtitle2'>
                    {data.distance} nm
                  </Typography>
                </Box>
              </Box>
            </Box>
          </MapDrawerBodyLayout>
          <MapDrawerBodyLayout title='Waypoints'>
            <Box
              sx={{
                m: '16px',
                mt: '0',
              }}
            >
              {data.waypoints.map((waypoint: any, idx: number) => (
                <WayPointCard
                  key={idx + 1}
                  index={waypoint.waypointNumber}
                  location={waypoint.position}
                >
                  <Box
                    sx={{
                      m: '16px',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ textAlign: 'center', mb: '12px' }}
                    >
                      {waypoint.distance} nm from port
                    </Typography>
                    <ProgressBar
                      variant='determinate'
                      value={80}
                      sx={{ mb: '24px' }}
                    />
                    <Box
                      sx={{
                        borderBottom: '1px solid #FFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '32px',
                      }}
                    >
                      <Typography sx={wayPointTextStyle}>SOG</Typography>
                      <Typography
                        sx={{ ...wayPointTextStyle, fontWeight: 300 }}
                      >
                        {waypoint.SOG}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: '1px solid #FFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '32px',
                      }}
                    >
                      <Typography sx={wayPointTextStyle}>Course</Typography>
                      <Typography
                        sx={{ ...wayPointTextStyle, fontWeight: 300 }}
                      >
                        {waypoint.course}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: '1px solid #FFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '32px',
                      }}
                    >
                      <Typography sx={wayPointTextStyle}>Swell</Typography>
                      <Typography
                        sx={{ ...wayPointTextStyle, fontWeight: 300 }}
                      >
                        {waypoint.swell}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: '1px solid #FFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '32px',
                      }}
                    >
                      <Typography sx={wayPointTextStyle}>Wave</Typography>
                      <Typography
                        sx={{ ...wayPointTextStyle, fontWeight: 300 }}
                      >
                        {waypoint.wave}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: '1px solid #FFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '32px',
                      }}
                    >
                      <Typography sx={wayPointTextStyle}>Currents</Typography>
                      <Typography
                        sx={{ ...wayPointTextStyle, fontWeight: 300 }}
                      >
                        {waypoint.currents}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: '1px solid #FFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '32px',
                      }}
                    >
                      <Typography sx={wayPointTextStyle}>Wind</Typography>
                      <Typography
                        sx={{ ...wayPointTextStyle, fontWeight: 300 }}
                      >
                        {waypoint.wind}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: '1px solid #FFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '32px',
                      }}
                    >
                      <Typography sx={wayPointTextStyle}>BF Scale</Typography>
                      <Typography
                        sx={{ ...wayPointTextStyle, fontWeight: 300 }}
                      >
                        {waypoint.BFScale}
                      </Typography>
                    </Box>
                  </Box>
                </WayPointCard>
              ))}
            </Box>
          </MapDrawerBodyLayout>
        </>
      )}
    </>
  );
};
