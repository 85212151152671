import { Box, FormHelperText, FormHelperTextProps } from '@mui/material';
import { EmergencyHomeIcon } from 'assets/emergency-home-icon';
import { theme } from 'styles/theme';
import { ErrorMessageIconStyles } from './inputStyles';

interface InputHelperTextProps extends FormHelperTextProps {
  errorMessage: string | undefined;
}

export const InputHelperText = (props: InputHelperTextProps) => {
  const { sx, errorMessage } = props;
  return (
    <FormHelperText variant='standard' sx={{ display: 'flex', ...sx }}>
      <Box
        component='span'
        sx={{
          color: theme.palette.error.light,
          display: 'flex',
          flexGrow: 1,
          width: 0,
          fontSize: '12px',
        }}
      >
        <EmergencyHomeIcon sx={ErrorMessageIconStyles} />
        {errorMessage}
      </Box>
    </FormHelperText>
  );
};
