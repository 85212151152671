import { Box } from '@mui/system';
import { AlertMapper } from 'routes/alerts/mappers/alert.mapper';
import { IAlert, IAlertComment } from 'routes/alerts/models/alert.model';
import {
  AlertStatus,
  isAlertStatusClosed,
  isAlertStatusOpen,
} from 'routes/alerts/services/alert-status';

import {
  setAlertIsRead,
  setAlertIsUnread,
  setAlertIsArchived,
  setAlertIsUnarchived,
  setAlertStatus,
} from './alert-table.viewModel';

import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import CancelIcon from '@mui/icons-material/Cancel';
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { theme } from 'styles/theme';

export const AlertToolBar = ({
  gridData,
  selectedAlerts,
  filter,
  mutateFunction,
  refreshSummaryCallBack,
}: {
  gridData: IAlert[];
  selectedAlerts: string[];
  filter: string;
  mutateFunction: any;
  refreshSummaryCallBack: () => void;
}) => {
  const getUpdatingAlertsList = (
    id: string,
    func: (alert: IAlert, data: any) => IAlert,
    arg: string | IAlertComment
  ) => {
    const alertIndex = gridData.findIndex((x) => x.alertId === id);
    const alert = gridData[alertIndex];

    if (alert) {
      const domainAlert = func.apply(alert, [alert, arg]);
      if (arg && arg !== 'select') {
        const dtoAlert = AlertMapper.toDTO(domainAlert);

        // we don't need to update the grid data, we just need to remove it from display
        if (arg === 'archived' || arg === 'unarchived') {
          const index = gridData.findIndex((obj) => obj.alertId === id);
          if (index > -1) {
            gridData.splice(index, 1);
          }
        }
        return dtoAlert;
      }
    }
    return null;
  };

  const databaseUpdates = (
    func: (alert: IAlert, data: any) => IAlert,
    arg: string | IAlertComment
  ) => {
    const dtoAlerts: {
      id: string;
      status: string;
      reportedOn: string | undefined;
      comments: IAlertComment[];
      isRead: boolean;
      isArchived: boolean;
    }[] = [];
    selectedAlerts.forEach((x) => {
      const dtoAlert = getUpdatingAlertsList(x, func, arg);
      dtoAlert && dtoAlerts.push(dtoAlert);
    });
    mutateFunction({ variables: { alerts: dtoAlerts } })?.then(() => {
      refreshSummaryCallBack();
    });
  };

  const handleMarkAsReadClick = () => {
    databaseUpdates(setAlertIsRead, 'read');
  };

  const handleMarkAsUnreadClick = () => {
    databaseUpdates(setAlertIsUnread, 'unread');
  };

  const handleArchiveSelectedClick = () => {
    databaseUpdates(setAlertIsArchived, 'archived');
  };

  const handleUnarchiveSelectedClick = () => {
    databaseUpdates(setAlertIsUnarchived, 'unarchived');
  };

  const handleCloseSelectedClick = () => {
    databaseUpdates(setAlertStatus, AlertStatus.Closed);
  };

  const handleReOpenSelectedClick = () => {
    databaseUpdates(setAlertStatus, AlertStatus.Open);
  };

  gridData.forEach((x) => {
    x.isSelected = selectedAlerts.includes(x.alertId);
  });

  //DisplayMarkAsUnread
  const displayMarkAsUnread = gridData.some((x) => {
    return (
      x.isSelected &&
      x.isRead &&
      x.isSelected === selectedAlerts.includes(x.alertId)
    );
  });

  const displayReopen = gridData.some((x) => {
    return (
      x.isSelected &&
      isAlertStatusClosed(x.status) &&
      x.isSelected === selectedAlerts.includes(x.alertId)
    );
  });

  const displayClosed = gridData.some((x) => {
    return (
      x.isSelected &&
      isAlertStatusOpen(x.status) &&
      x.isSelected === selectedAlerts.includes(x.alertId)
    );
  });

  const displayMarkAsRead = gridData.some((x) => {
    return (
      x.isSelected &&
      !x.isRead &&
      x.isSelected === selectedAlerts.includes(x.alertId)
    );
  });

  if (selectedAlerts.length === 0) return <></>;

  return (
    <Box
      sx={{
        py: 2,
        backgroundColor: theme.background.page,
        position: 'sticky',
        top: '64px',
        zIndex: 10,
      }}
    >
      {filter === 'active' && (
        <>
          {displayClosed && (
            <SecondaryButton
              sx={{ mr: 1.5 }}
              variant='outlined'
              startIcon={<CancelIcon />}
              onClick={handleCloseSelectedClick}
            >
              Close
            </SecondaryButton>
          )}
          {displayReopen && (
            <SecondaryButton
              sx={{ mr: 1.5 }}
              variant='outlined'
              startIcon={<DraftsIcon />}
              onClick={handleReOpenSelectedClick}
            >
              Reopen
            </SecondaryButton>
          )}
          <SecondaryButton
            sx={{ mr: 1.5 }}
            variant='outlined'
            startIcon={<ArchiveIcon />}
            onClick={handleArchiveSelectedClick}
          >
            Archive
          </SecondaryButton>
          {displayMarkAsRead && (
            <SecondaryButton
              sx={{ mr: 1.5 }}
              variant='outlined'
              startIcon={<DraftsIcon />}
              onClick={handleMarkAsReadClick}
            >
              Mark As Read
            </SecondaryButton>
          )}
          {displayMarkAsUnread && (
            <SecondaryButton
              sx={{ mr: 1.5 }}
              variant='outlined'
              startIcon={<MarkunreadIcon />}
              onClick={handleMarkAsUnreadClick}
            >
              Mark As Unread
            </SecondaryButton>
          )}
        </>
      )}
      {filter === 'archived' && (
        <SecondaryButton
          variant='outlined'
          startIcon={<UnarchiveIcon />}
          onClick={handleUnarchiveSelectedClick}
        >
          Unarchive
        </SecondaryButton>
      )}
    </Box>
  );
};
