import { createContext } from 'react';

export interface IContext {
  isAuthenticated: boolean;
  user: {
    name: string | undefined;
    tenantId: string | undefined;
  };
}

export const defaultContext: IContext = {
  isAuthenticated: false,
  user: {
    name: undefined,
    tenantId: undefined,
  },
};

export const AppContext = createContext<IContext>(defaultContext);
