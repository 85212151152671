import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Modal, SvgIcon, SxProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { IAlertComment, AlertAction } from '../../models/alert.model';
import { AlertComment } from './alert-comment.component';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import { AlertDetailButtons } from './alert-detail-buttons.component';
import { theme } from 'styles/theme';
import { ReactComponent as AlertWarningIcon } from 'assets/alert-warning-icon.svg';
import { useGetUser } from 'shared/services/user.service';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { v4 as uuidv4 } from 'uuid';

export interface AlertDetailProps {
  status: string;
  isArchived: boolean;
  comments: IAlertComment[];
  handler: (action: AlertAction, data: IAlertComment) => void;
}

export const AlertDetail = (props: AlertDetailProps) => {
  const { data: userData } = useGetUser();
  const { status, comments, isArchived, handler } = props;
  const [commentValue, setCommentValue] = useState('');
  const [editingCommentId, setEditingCommentId] = useState<string>('');
  const [isCommentError, setIsCommentError] = useState<boolean>(false);
  const [displaySaveModal, setDisplaySaveModal] = useState(false);
  const [action, setAction] = useState(AlertAction.None);
  const [data, setData] = useState({} as IAlertComment);

  useEffect(() => {
    const commentLength = commentValue.length;
    if (commentLength >= 400) {
      setIsCommentError(true);
    } else {
      setIsCommentError(false);
    }
  }, [commentValue]);

  const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '452px',
    bgcolor: theme.background.component,
    borderRadius: '8px',
    py: 3,
    px: 3,
  };

  const handleSaveClick = () => {
    if (!commentValue) {
      return;
    }

    const isEdit = editingCommentId !== '';

    const existingId = isEdit ? editingCommentId : uuidv4();

    // Whole new editing flow where we don't push a new comment, we just update the existing one
    if (isEdit) {
      const commentFound = comments.find(
        (comment) => comment.id === existingId
      );
      if (commentFound) {
        commentFound.text = commentValue;
        handler(AlertAction.EditComment, commentFound);
      }
    } else {
      const newComments = comments?.slice() || [];
      if (existingId !== '') {
        const newComment: IAlertComment = {
          id: existingId,
          date: new UTCDate().toString(),
          text: commentValue,
          authorName: userData?.fullName,
          userId: userData?.id,
        };

        newComments.push(newComment);
        handler(AlertAction.NewComment, newComment);
      }
    }

    setCommentValue('');
    setEditingCommentId('');
  };

  const deleteComment = (id: string) => {
    const commentToDelete = comments.find((x) => x.id === id);
    if (commentToDelete) {
      handler(AlertAction.DeleteComment, commentToDelete);
    }
  };

  const editComment = (id: string) => {
    const comment = comments.find((x) => x.id === id);
    if (comment) {
      setCommentValue(comment.text);
      setEditingCommentId(id);
    }
  };

  const handleOpen = () => setDisplaySaveModal(true);
  const handleClose = () => setDisplaySaveModal(false);

  const handleDetailEvent = (action: AlertAction) => {
    if (commentValue === '') {
      handler(action, data);
      return;
    }
    setAction(action);
    setData(data);
    handleOpen();
  };

  const handleSaveOverride = () => {
    setCommentValue('');
    handleClose();
    handler(action, data);
  };

  const textFieldStyle: SxProps = {
    mr: 1.5,
    '& .MuiFormHelperText-root': {
      width: '366px',
      display: 'block',
      textAlign: 'right',
    },
    '& .MuiFormLabel-root': {
      position: 'absolute',
      pt: '8px',
      fontSize: '14px',
      color: theme.palette.text.secondary,
      transformOrigin: 'center',
    },
    '& .MuiInputBase-root': {
      mr: 1.5,
      mt: 0.5,
      pt: 1.5,
      width: '366px',
      minHeight: '48px',
      justifyContent: 'flex-end',
      borderRadius: '6px',
      paddingLeft: '18px',
      paddingRight: '18px',
      backgroundColor: theme.alerts.inputBackground,
    },
    '& .MuiInputBase-root.Mui-error': {
      mr: 1.5,
      width: '366px',
      minHeight: '48px',
      border: '2px solid',
      borderRadius: '8px',
      borderColor: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.palette.error.light,
    },
  };

  return (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.background.default,
        borderRadius: '6px',
      }}
    >
      <Box sx={{ m: 3, minWidth: '464px' }}>
        <Typography variant='h4' sx={{ mb: 4 }}>
          Activity
        </Typography>
        <Box sx={{ mb: 3 }}>
          <TextField
            error={isCommentError}
            helperText={
              <Typography
                variant='caption'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: 'small',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  {isCommentError ? (
                    <SvgIcon>
                      <AlertWarningIcon />
                    </SvgIcon>
                  ) : (
                    ''
                  )}
                  {isCommentError
                    ? 'Comment cannot exceed 400 digits/characters'
                    : ''}
                </Box>
                {`${commentValue.length}/400`}
              </Typography>
            }
            variant='standard'
            label={'Add a comment'}
            multiline={true}
            value={commentValue}
            sx={textFieldStyle}
            inputProps={{ maxLength: 400 }}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            onChange={(newComment) => {
              setCommentValue(newComment.target.value);
            }}
          />
          <SecondaryButton
            variant='outlined'
            sx={{ mt: 0.4 }}
            onClick={handleSaveClick}
          >
            Save
          </SecondaryButton>
        </Box>
      </Box>
      <Box sx={{ m: 3, minWidth: '300px', maxWidth: '1060px' }}>
        {comments.map((c) => (
          <AlertComment
            key={c.id}
            id={c.id}
            date={new UTCDate(c.date).formatDMYHMZ(true, false, true)}
            authorName={c.authorName}
            userId={c.userId}
            text={c.text}
            handleDelete={() => deleteComment(c.id)}
            handleEdit={() => editComment(c.id)}
          />
        ))}
      </Box>
      <AlertDetailButtons
        status={status}
        isArchived={isArchived}
        handler={handleDetailEvent}
      />
      {displaySaveModal && (
        <Modal open={displaySaveModal} onClose={handleClose}>
          <Box sx={modalStyle}>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 12,
                top: 16,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' sx={{ mb: 3 }}>
              Unsaved Comment
            </Typography>
            <Typography variant='body1'>
              Are you sure you want to {action} this alert?
            </Typography>
            <Typography variant='body1' sx={{ mb: 3 }}>
              Your comment will not be saved.
            </Typography>
            <Box
              sx={{
                mb: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <SecondaryButton
                variant='outlined'
                sx={{ mr: 1.5 }}
                onClick={handleClose}
              >
                Go Back
              </SecondaryButton>

              <Button
                variant='contained'
                onClick={handleSaveOverride}
                sx={{
                  fontSize: '16px',
                  height: '40px',
                  textTransform: 'capitalize',
                  py: 1,
                }}
              >
                {action} Alert
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};
