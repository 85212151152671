import { Chip } from '@mui/material';

// import React from 'react';
import { MapDrawerBodyLayout } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer-body-layout';
import { Box } from '@mui/material';

export const MapDrawerAlertComponent = (props: any) => {
  const alertChipStyle = {
    display: 'inline-flex',
    height: '24px',
    padding: '3px 4px',
    alignItems: 'flex-start',
    flexShrink: 0,
    borderRadius: '100px',
    background: '#F44336',
    color: '#000000',
    fontFeatureSettings: "'clig' off, 'liga' off",
    /* components/chip-label */
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.16px',
  };

  const showAlert = props.activeAlertCount > 0 ? true : false;
  let alertText = '1 New Alert';
  if (showAlert && props.activeAlertCount > 1) {
    alertText = props.activeAlertCount + ' New Alerts';
  }

  return showAlert ? (
    <>
      <MapDrawerBodyLayout hasBoundary={false}>
        <Box
          sx={{
            m: '16px 24px',
          }}
        >
          <Chip sx={alertChipStyle} label={alertText} />
        </Box>
      </MapDrawerBodyLayout>
    </>
  ) : (
    <></>
  );
};
