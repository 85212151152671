import { Vessel as GQLVessel, KpiStatus } from '_gql/graphql';
import { Vessel } from '../components/fleetDataComparison/table/util/fleet-data-comparison-table.util';

export class FleetSummaryMapper {
  static fromDTO(dto: GQLVessel | undefined): Vessel {
    return {
      vesselId: dto?.imoNumber ?? '',
      vesselName: dto?.name ?? '',
      overallPassScorePercentage:
        dto?.reports?.overallPassScorePercentage ?? undefined,
      overallPassScore: dto?.reports?.overallPassScore ?? undefined,
      overallFailScore: dto?.reports?.overallFailScore ?? undefined,
      kpiStatus: dto?.reports?.status ?? KpiStatus.Unknown,
      sisterClass: '---',
      vesselType: '---',
      vesselGroup: '---',
    };
  }
}
