import { gql } from '@apollo/client';

export const GET_FLEET_PERFORMANCE_DATA = gql`
  query FleetPerformanceQuery(
    $vesselImos: [String!]
    $dateRange: DateRangeInput
  ) {
    fleetPerformanceIndicators(vesselImos: $vesselImos, dateRange: $dateRange) {
      vesselPerformanceStatistics {
        vessel {
          id
          imoNumber
          name
          vesselGrouping {
            groupingOrder
            groupingType
            groupingDescription
            groupingCategoryName
            groupingName
            caaList {
              type
              referenceAngle
              caaValue
            }
          }
        }
        lastCleaning
        #Fouling Resistance (Updated to Products)
        foulingResistance {
          value {
            rahFoulingPercent
            status
          }
        }
        # Delta Speed (Still in Modules)
        deltaSpeed {
          value {
            deltaSpeed
            status
          }
        }
        # Delta Power (Still in Modules)
        deltaPower {
          value {
            deltaPower
            status
          }
        }
        # MEFOC
        mesfoc {
          value {
            sfocDeviationPercent
            status
          }
        }
        # Delta Power Daily Fuel Penalty
        # deltaPowerDailyFuelPenaltySum = Total
        deltaPowerFuelPenalty {
          value {
            deltaPowerDailyFuelPenalty
            dailyStatus
            deltaPowerDailyFuelPenaltySum
            totalStatus
          }
        }
        # Delta Power Daily CO2 Penalty
        # deltaPowerDailyCO2PenaltySum = Total
        deltaPowerDailyCO2Penalty {
          value {
            deltaPowerDailyCO2Penalty
            dailyStatus
            deltaPowerDailyCO2PenaltySum
            totalStatus
          }
        }
      }
    }
  }
`;
