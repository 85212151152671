import { ServiceResult } from 'shared/models/serviceResult.model';
import { useGetDataLatestUpdatedDate } from '../repositories/dataLatestUpdatedDate.repository';
import { UTCDate } from 'shared/utils/date-utc-helper';

export const useGetDataLatestDate = (): ServiceResult<UTCDate> => {
  const response = useGetDataLatestUpdatedDate();
  const loading = response?.loading ?? false;
  const error = response?.error;

  return {
    data: response?.data?.dataLatestUpdatedDate ?? undefined,
    loading,
    error,
  };
};
