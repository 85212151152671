import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { theme } from 'styles/theme';

const hoverColor = theme.palette.action.hover;
const focusedColor = theme.palette.action.focus;

export const SecondaryButtonSizeable = styled(Button)(() => {
  return {
    backgroundColor: 'transparent',
    color: theme.alerts.ctaColor,
    borderRadius: '6px',
    borderWidth: '1.4px',
    borderColor: theme.alerts.ctaColor,
    fontWeight: 400,
    textAlign: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: '6px',
      borderWidth: '1.4px',
      borderColor: theme.palette.text.primary,
    },
    '&:focus': { bgcolor: focusedColor },
    '&:focus:hover': { bgcolor: hoverColor },
    '&.Mui-focusVisible': { backgroundColor: focusedColor },
    ':disabled': {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.text.disabled,
    },
  };
});
