import { Scalars } from '_gql/graphql';
import { KpiData } from 'shared/components/status-kpi-card/status-kpi-card.component';
export interface IVesselKpiDomain {
  aer: KpiData;
  ciiRating: KpiData;
  ciiYearToDate: KpiData;
  ciiForecast: KpiData;
  eeoi: KpiData;
  euaAgainstFleet: KpiData;
  euaAgainstPlanned: KpiData;
  fuelEuGHGIntensity: KpiData;
  complianceBalanceFleet: KpiData;
  wellToTank: number;
  tankToWake: number;
  windRewardFactor: number;
  ghgMrvTotal: Scalars['Float'];
  ghgMrvFuelEmissions: KeyValuePairOfStringAndDouble[];
  ghgMrvFuelSlippageTotal: Scalars['Float'];
  complianceBalance: number;
  fuelEUPenalty: KpiData;
}

export type VesselKpiDomain = IVesselKpiDomain;

export type KeyValuePairOfStringAndDouble = {
  __typename?: 'KeyValuePairOfStringAndDouble';
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type KeyValuePairOfEmissionConfiguration = {
  __typename?: 'KeyValuePairOfEmissionConfiguration';
  key: Scalars['String'];
  value: {
    display: string;
    color: string;
    unit: string;
  };
};

export enum EmissionsType {
  CO2 = 'CO2',
  N2O = 'N2O',
  CH4 = 'CH4',
}

export const FuelSlipKey = 'Fuel Slip';
