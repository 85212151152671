import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { theme } from 'styles/theme';

export const CircularLoading = (props: {
  height?: string;
  backgroundColor?: string;
}) => (
  <Box
    height={props.height || '100vh'}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: props.backgroundColor ?? theme.background.page,
    }}
  >
    <CssBaseline />
    <CircularProgress />
  </Box>
);
