import { useEffect } from 'react';
import { useGetVesselCurrentPosition } from '../repositories/vesselCurrentPosition.get.repository';

export const useVesselCurrentPositionService = (interval: number) => {
  const { loading, error, data, refetch } = useGetVesselCurrentPosition();

  useEffect(() => {
    const timer = setInterval(() => {
      refetch();
    }, interval);

    return () => clearInterval(timer);
  }, [refetch, interval]);

  return { loading, error, data };
};
