import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SvgIcon from '@mui/material/SvgIcon';
import { NavBarContext } from 'shared/models/navBar.context.model';

export type SideNavigationItemProps = {
  itemIcon: typeof SvgIcon;
  text: string;
  path: string;
  callback: (path: string) => void;
  selectedPath: string;
  open: boolean;
};
export const SideNavigationItem: React.FC<SideNavigationItemProps> = ({
  itemIcon: ItemIcon,
  text,
  path,
  callback: handleListItemClick,
  selectedPath,
  open,
}) => {
  const { alertCount } = useContext(NavBarContext);
  const [alertCountNotifaction, setAlertCountNotifaction] =
    useState(alertCount);

  useEffect(() => {
    setAlertCountNotifaction(alertCount);
  }, [alertCount]);

  const theme = useTheme();
  const iconColor = theme.palette.text.disabled;
  const hoverColor = theme.palette.action.hover;
  const focusedColor = theme.palette.action.focus;
  const selectedColor = theme.palette.action.selected;

  const listItemProps: SxProps = {
    '&.MuiListItem-padding': open
      ? { pt: 3, pr: 0, pb: 0, pl: 2.25 }
      : { pt: 3, pr: 2.25, pb: 0, pl: 2.25 },
    '& .MuiListItemIcon-root': { minWidth: 36 },
  };

  const buttonProps: SxProps = {
    '&.MuiListItemButton-gutters': { p: 0.75 },
    '&:hover': { bgcolor: hoverColor },
    '&:focus:hover': { bgcolor: hoverColor },
    '&.Mui-selected': { backgroundColor: selectedColor },
    '&.Mui-focusVisible': { backgroundColor: focusedColor },
    '&.Mui-selected:hover': { backgroundColor: hoverColor },
    '&.Mui-selected:focus': { backgroundColor: selectedColor },
    '&.Mui-selected:hover:focus': { backgroundColor: hoverColor },
  };

  const newAlerts = text === 'Alerts' && alertCountNotifaction > 0;
  const ChipStyleSx: SxProps = {
    color: theme.alerts.chipTextColor,
    fontSize: '13px',
    fontWeight: 400,
    height: '24px',
    backgroundColor: theme.alerts.chipBackgroundColor,
    textTransform: 'capitalize',
    ml: 2,
  };

  return (
    <ListItem
      key={path}
      component={RouterLink}
      to={path}
      tabIndex={-1}
      sx={listItemProps}
    >
      <ListItemButton
        disableRipple
        selected={selectedPath === path}
        onClick={() => handleListItemClick(path)}
        sx={buttonProps}
      >
        <ListItemIcon>
          <ItemIcon sx={{ color: iconColor }} />
        </ListItemIcon>
        {open && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ListItemText
              primary={text}
              primaryTypographyProps={{ variant: 'menuItem' }}
              sx={{ m: 0 }}
            />
            {newAlerts && (
              <Chip label={alertCountNotifaction + ' New'} sx={ChipStyleSx} />
            )}
          </Box>
        )}
      </ListItemButton>
    </ListItem>
  );
};
