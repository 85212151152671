import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Typography from '@mui/material/Typography';
import { IAlertComment } from '../../models/alert.model';
import { theme } from 'styles/theme';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useGetUser } from 'shared/services/user.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const menuStyle = {
  width: 32,
  height: 32,
  backgroundColor: 'transparent',
};
const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 100,
      height: 100,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

export const AlertComment = (props: IAlertComment) => {
  const { id, date, text, authorName, userId } = props;
  const { data: User } = useGetUser();

  const isUserComment = userId === User?.id;
  const main = theme.palette.primary.main;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      className='alert-comment-container'
      sx={{ display: 'flex', justifyContent: 'flex-start' }}
    >
      <Box
        key={id}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justfiyContent: 'space-between',
          mb: 2.5,
        }}
      >
        <Avatar
          sx={{
            mr: 2,
            height: '40px',
            width: '40px',
            backgroundColor: theme.alerts.avatarBackground,
          }}
        >
          <ChatBubbleOutlineIcon sx={{ fontSize: '24px', color: main }} />
        </Avatar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='alertDetailLabel'
            sx={{
              pt: 0.5,
            }}
          >
            <Box
              display='inline-flex'
              fontWeight='bold'
              sx={{ marginRight: '8px' }}
            >
              {authorName}
            </Box>
            <Box display='inline'>{date}</Box>
          </Typography>
          <Typography
            variant='alertDetailComment'
            sx={{
              pt: 0.25,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
      {isUserComment && (
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2, height: '32px', width: '32px' }}
          aria-controls={open ? 'comment-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={menuStyle}>
            <MoreHorizIcon />
          </Avatar>
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        id='comment-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={props.handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary='Edit' />
        </MenuItem>
        <MenuItem onClick={props.handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </Box>
  );
};
