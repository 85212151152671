import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CiiProjectionsFormMapper } from '../mappers/cii-projections.form.mapper';
import {
  CiiProjectionsFormModel,
  defaultValues,
} from '../models/forms/cii-projections.form.model';
import { ProjectionConditionsDomain } from '../models/vessel-cii-rating.model';
import {
  ciiProjectionsSchema,
  useYupValidationResolver,
} from './cii-projectionsForm.validation';

export const useCiiProjectionsForm = (
  projectionConditions: ProjectionConditionsDomain | undefined
) => {
  const formValues = CiiProjectionsFormMapper.toModel(projectionConditions);
  const resolver = useYupValidationResolver(ciiProjectionsSchema);
  const form = useForm<CiiProjectionsFormModel>({
    resolver,
    mode: 'onBlur',
    defaultValues,
  });

  const [formInitialized, setFormInitialized] = useState(false);

  // Once our data has resolved, reset the form with values from network call
  useEffect(() => {
    if (projectionConditions !== undefined && !formInitialized) {
      form.reset(formValues);
      setFormInitialized(true);
    }
  }, [projectionConditions, formInitialized, form, formValues]);

  const onUpdate = (
    updatedConditions: ProjectionConditionsDomain | undefined
  ) => {
    const formValues = CiiProjectionsFormMapper.fromUpdate(updatedConditions);
    form.reset(formValues, { keepDirty: true });
  };

  return {
    form,
    onUpdate,
  };
};
