import { useDrawingArea, useXScale, useYScale } from '@mui/x-charts-pro';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { useEffect } from 'react';
import { theme } from 'styles/theme';
//import { D3Scale  } from '@mui/x-charts/models/axis';

export const AXIS_BANDS_ID = 'x-axis-bands';

export type AreaDefinition = {
  x: number;
  y: number;
  color: string;
};
export type AreaBand = {
  type: 'area';
  axisId: string;
  areas: AreaDefinition[];
  showLine?: boolean;
  lineColor?: string;
  backgroundColor?: string;
};

export type BandData = {
  y: number;
  color: string;
};

export function DrawBackground(
  props: Readonly<{
    bands?: Array<BandData | AreaBand>;
    callback?: (x: number) => void;
  }>
) {
  const xAxisScale = useXScale(AXIS_BANDS_ID) as ScaleLinear<any, any>;
  const offset = xAxisScale.invert(-10) - xAxisScale.invert(0);
  const offsetChange = offset !== undefined;
  useEffect(() => {
    requestAnimationFrame(() => {
      if (props.callback !== undefined) {
        props.callback(offset);
      }
    });
  }, [offsetChange]);

  return <DrawBands items={props.bands ?? []} />;
}

const getDeltas = (arr: Array<number>) => {
  if (arr.length < 2) return [];
  return arr.slice(1).map((val, i) => val - (arr[i] ?? 0));
};

const DrawBands = ({ items }: { items: (BandData | AreaBand)[] }) => {
  const firstItem = items?.[0];
  if (firstItem && 'areas' in firstItem) {
    return AreaBands({ items: items as AreaBand[] });
  }

  return HorizontalBands({ items: items as BandData[] });
};

const HorizontalBands = ({ items }: { items: BandData[] }) => {
  const { left, top, width } = useDrawingArea();
  const yAxisScale = useYScale() as ScaleLinear<any, any>;
  const yOrigins = [top, ...items.map((item) => yAxisScale(item.y) ?? 0)];
  const heights = getDeltas(yOrigins);

  return (
    <>
      {/* Draw the horizontal bands  */}
      {items.map((item, ix) => {
        const yOrigin = yOrigins[ix];
        const height = heights[ix];
        return (
          <rect
            key={item.y + '-' + ix}
            x={left}
            y={yOrigin}
            width={width}
            height={height}
            style={{ fill: item.color, stroke: 'none' }}
            data-testid={`band-${ix}`} // Add test id for testing
          />
        );
      })}

      {/* Create the horizontal gradient effect */}
      <LinearGradient />
    </>
  );
};

const AreaBands = ({ items }: { items: AreaBand[] }) => {
  const { left, top, width, height } = useDrawingArea();

  const firstItem = items?.[0];
  const scale = useXScale(firstItem?.axisId) as ScaleBand<any>;
  if (items.length === 0) return null;

  const background = (
    <rect
      key={'background'}
      x={left}
      y={top}
      width={width}
      height={height}
      style={{
        fill: firstItem?.backgroundColor ?? 'transparent',
        stroke: 'none',
      }}
      data-testid={'band-background'} // Add test id for testing
    />
  );

  // Draw the horizontal bands
  const bands = items.map((item) => {
    const areaBands = GetAreaBands(scale, item);
    if (!areaBands) return null;

    const line = GetPolyline(scale, item);
    if (!line) return null;

    return {
      band: (
        <>
          {background}
          {areaBands}
        </>
      ),
      polyline: line,
    };
  });

  return (
    <>
      {bands[0]?.band}
      <LinearGradient />
      {bands[0]?.polyline}
    </>
  );
};

// Draw the gradient background
const LinearGradient = () => {
  const { left, top, width, height } = useDrawingArea();
  return (
    <>
      <linearGradient id='gradient-bg'>
        <stop offset='0%' stopColor='#222' stopOpacity={0.7} />
        <stop
          offset='33%'
          stopColor={theme.background.component}
          stopOpacity={0.95}
        />
        <stop
          offset='66%'
          stopColor={theme.background.component}
          stopOpacity={0.95}
        />
        <stop offset='100%' stopColor='#222' stopOpacity={0.7} />
      </linearGradient>
      <rect
        key={'band-gradient'}
        x={left + 10}
        y={top}
        width={width - 20}
        height={height}
        style={{ fill: 'url(#gradient-bg)', stroke: 'none' }}
        data-testid='gradient-bg' // Add test id for the gradient background
      />
    </>
  );
};

const getBandPositionX = (scale: ScaleBand<any>, x: number) => {
  const bandIx = scale.domain().findIndex((d) => d >= new Date(x));
  const range = scale.range();
  const step = scale.step();

  if (bandIx === 0) {
    return range[0];
  }
  const result = Math.max(bandIx * step + range[0] + step / 2, range[0]);
  return Math.round(result);
};

const GetPolyline = (scale: ScaleBand<any>, band: AreaBand) => {
  const { left, top, width } = useDrawingArea();
  const right = left + width;
  const yAxisScale = useYScale() as ScaleLinear<any, any>;
  const getX = (n: number) => getBandPositionX(scale, n);

  let line = '';
  let nx = 0;
  let ny = 0;
  const puntos = band;
  if (puntos?.areas.length === 0) return null;

  band.areas.forEach((p, ix) => {
    const posX = getX(p.x);
    nx = ix === 0 ? Math.min(posX, left) : posX;
    if (ix > 0) {
      line += `${nx},${ny} `;
    }

    ny = yAxisScale(p.y) ?? top;
    if (ny < top) ny = top;

    line += `${nx},${ny} `;
  });
  line += `${right},${ny} `;

  return (
    <polyline
      points={line}
      stroke={band.lineColor ?? theme.colors?.white}
      strokeWidth={1}
      fill='none'
    />
  );
};

const GetAreaBands = (scale: ScaleBand<any>, band: AreaBand) => {
  const { left, top, width, height } = useDrawingArea();
  const right = left + width;
  const bottom = top + height;
  const yAxisScale = useYScale() as ScaleLinear<any, any>;

  const result: JSX.Element[] = [];
  const areas = band.areas;
  const getX = (n: number) => getBandPositionX(scale, n);

  areas?.forEach((p, ix) => {
    const posX = getX(p.x);
    const nx = ix === 0 ? Math.min(posX, left) : posX;

    let ny = yAxisScale(p.y) ?? 0;
    if (ny < top) ny = top;

    const nextP = areas[ix + 1];
    if (nextP) {
      const barWidth = getX(nextP.x) - nx;
      const width = Math.max(barWidth, 0);
      const rect = (
        <rect
          key={band.type + '-' + p.y + '-' + ix}
          x={nx}
          y={ny}
          width={width}
          height={bottom - ny}
          style={{ fill: p.color, stroke: 'none' }}
          data-testid={`band-${p.y}-${ix}`} // Add test id for testing
        />
      );

      result.push(rect);
    } else {
      // this is the last point
      const width = Math.max(right - nx, 0);
      const rect = (
        <rect
          key={band.type + '-' + p.y + '-' + ix}
          x={nx}
          y={ny}
          width={width}
          height={bottom - ny}
          style={{ fill: p.color, stroke: 'none' }}
          data-testid={`band-${p.y}-${ix}`} // Add test id for testing
        />
      );
      result.push(rect);
    }
  });
  return result;
};
