import { useMutation, useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import {
  GET_ALERTS,
  GET_ALERT_CHART_DATA,
  GET_ALERT_COUNT,
  UPDATE_ALERT,
  UPDATE_ALERTS,
} from '../queries/alerts.query';
import { AlertStatus } from '../services/alert-status';
import { DateRange } from 'shared/utils/date-utc-helper';
import { GridSortDirection } from '@mui/x-data-grid-pro';

export const useGetAlertData = (
  vesselImoNumbers: string[] | undefined,
  dateRange: DateRange,
  pageSize?: number,
  pageNumber?: number,
  orderBy?: string,
  sortDirection?: GridSortDirection,
  activeAlertsOnly?: boolean,
  containsQueryTerm?: string
) => {
  const alertStatuses = activeAlertsOnly
    ? [AlertStatus.Open, AlertStatus.Closed]
    : null;
  const dateRangeAsString = dateRange.dateRangeAsString;
  return useQuery<Query>(GET_ALERTS, {
    skip: !vesselImoNumbers || !dateRangeAsString,
    variables: {
      vesselImoNumbers: vesselImoNumbers ?? [],
      ...dateRangeAsString,
      pageSize: pageSize ?? null,
      pageNumber: pageNumber ?? null,
      orderBy: orderBy ?? null,
      sortDirection: sortDirection ?? null,
      alertStatuses: activeAlertsOnly !== undefined ? alertStatuses : null,
      isArchived:
        activeAlertsOnly !== undefined
          ? activeAlertsOnly
            ? false
            : true
          : null,
      containsQueryTerm: containsQueryTerm ?? '',
    },
  });
};

export const useGetAlertChartData = (
  vesselImoNumbers: string[] | undefined,
  dateRange: DateRange
) => {
  const dateRangeAsString = dateRange.dateRangeAsString;
  return useQuery<Query>(GET_ALERT_CHART_DATA, {
    skip: !vesselImoNumbers || !dateRangeAsString,
    variables: {
      vesselImoNumbers: vesselImoNumbers ?? [],
      ...dateRangeAsString,
    },
  });
};

export const useUpdateAlertData = () => {
  return useMutation(UPDATE_ALERT);
};

export const useGetAlertCountData = (params: {
  dateRange: DateRange;
  vesselImoNumbers: string[] | undefined;
  alertStatuses: string[] | undefined;
  isArchived: boolean | undefined;
  isRead: boolean | undefined;
}) => {
  const dateRangeAsString = params.dateRange.dateRangeAsString;
  return useQuery<Query>(GET_ALERT_COUNT, {
    skip: !dateRangeAsString,
    variables: {
      ...dateRangeAsString,
      alertStatuses: params.alertStatuses,
      isArchived: params.isArchived,
      isRead: params.isRead,
      vesselImoNumbers: params.vesselImoNumbers,
    },
  });
};

export const useUpdateAlertsData = () => {
  return useMutation(UPDATE_ALERTS);
};
