import {
  AccountInfo,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from './config';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0] ?? null);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const account = event.payload;
      msalInstance.setActiveAccount(account as AccountInfo);
    }
  });
});

msalInstance
  .handleRedirectPromise()
  .then(() => {
    console.info('msal: loginRedirect success');
  })
  .catch((error) => {
    console.error('msal: loginRedirect failure', error);
  });
