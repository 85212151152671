import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useGetAllVesselGroupings } from 'shared/components/vesselGroupingsDropdown/services/vesselGroupings.get.services';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import {
  DropdownGroup,
  DropdownGroupingCategory,
} from '../vesselGroupingsDropdown/models/vesselGroupings.viewModel';
import {
  GroupingFilterTitle,
  GroupingFilterSubTitle,
  InitializeSubgroups,
  UpdateCurrentSubgroups,
  FilterGroups,
  MAX_SUBGROUPS as MAX,
} from './grouping-filter.view-model';
import { SelectStyles, MenuProps } from './grouping-filter.styles';
import { theme } from 'styles/theme';

export interface GroupingFilterProps {
  callback: (ids: string[]) => void;
}

export const GroupingFilter = (props: GroupingFilterProps) => {
  const { callback } = props;
  const [currentGlobalFilterVesselGroup, setCurrentGlobalFilterVesselGroup] =
    useState<string>('');
  const [currentGroupName, setCurrentGroupName] = useState<string>('');
  const [currentSubgroups, setCurrentSubgroups] = useState<DropdownGroup[]>([]);
  const { vesselGroup, vesselGroups } = useContext(TopNavigationContext);
  const response = useGetAllVesselGroupings();

  const allVesselGroups = response.data ?? [];
  const globalFilterVesselGroup = vesselGroup ?? ([] as string[]);
  const globalFilterNames = globalFilterVesselGroup.toString().split(',');
  const displayGroups = FilterGroups(globalFilterNames, allVesselGroups);

  useEffect(() => {
    const groupings = vesselGroups[0]?.groupings;
    const ids = groupings?.filter((x) => x.isChecked).map((x) => x.id) ?? [];
    callback(ids);
  }, [vesselGroups]);

  if (response.loading) {
    return (
      <Skeleton
        sx={{ mt: 3, height: '50vh' }}
        variant='rounded'
        animation='pulse'
      ></Skeleton>
    );
  }

  if (response.error) {
    return (
      <Box>
        <p>Error Occurred</p>
        <p>{response.error.message}</p>
      </Box>
    );
  }

  const handleChange = (event: SelectChangeEvent<typeof currentSubgroups>) => {
    const {
      target: { value },
    } = event;
    const values = value as Array<string | DropdownGroup>;
    const name = values.filter((x) => typeof x === 'string').toString();
    const subgroups = UpdateCurrentSubgroups(name, currentSubgroups, MAX);
    setCurrentSubgroups(subgroups);
    const selectedSubgroupIds = subgroups
      .filter((x) => x.isChecked)
      .map((x) => x.id);
    callback(selectedSubgroupIds);
  };

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const groupName = event.target.textContent;
    const vesselGroupSame =
      globalFilterVesselGroup.toString() === currentGlobalFilterVesselGroup;

    if (!groupName || (groupName === currentGroupName && vesselGroupSame)) {
      return;
    }

    if (!vesselGroupSame) {
      setCurrentGlobalFilterVesselGroup(globalFilterVesselGroup.toString());
    }

    const subgroups = InitializeSubgroups(groupName, displayGroups, MAX);
    setCurrentSubgroups(subgroups);
    setCurrentGroupName(groupName);
    const selectedSubgroupIds = subgroups
      .filter((x) => x.isChecked)
      .map((x) => x.id);
    callback(selectedSubgroupIds);
  };

  return (
    <Box sx={{ ml: 1 }}>
      <Typography variant='body1' sx={{ mb: 2 }}>
        {GroupingFilterTitle}
      </Typography>
      <Typography
        variant='body2'
        sx={{ mb: 4, color: theme.palette.text.secondary }}
      >
        {GroupingFilterSubTitle}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          mb: 2.5,
        }}
      >
        {displayGroups.map((group: DropdownGroupingCategory) => (
          <FormControl key={group.id} sx={{ mr: 1.5, mb: 1.5 }}>
            <Select
              multiple
              displayEmpty
              value={currentSubgroups}
              onChange={handleChange}
              onFocus={handleFocus}
              input={<OutlinedInput />}
              renderValue={() => group.groupingDisplayName}
              sx={SelectStyles}
              MenuProps={MenuProps}
              IconComponent={KeyboardArrowDownSharpIcon}
            >
              {currentSubgroups.map((subgroup) => (
                <MenuItem
                  key={subgroup.groupingName}
                  value={subgroup.groupingName}
                  disabled={subgroup.isDisabled}
                >
                  <Checkbox checked={subgroup.isChecked} sx={{ pl: 0 }} />
                  <ListItemText
                    primary={subgroup.groupingName}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </Box>
    </Box>
  );
};
