import { useState, useEffect, useCallback, useContext } from 'react';
import * as vesselDataService from 'shared/services/vesselData.service';
import { VesselLayer } from '../utils/vesselLayer';
import { ConvertLatToDMS, ConvertLongToDMS } from 'shared/utils/map-utils';

import { getPortData } from '../utils/PortsLayer';
import { SearchType } from '../utils/constants';

import { MapViewContext } from 'shared/models/mapView.context.model';

interface DataFetchingResult<T> {
  data: T | null;
  loading: boolean;
  searchType: SearchType | undefined;
  error: Error | null;
}

export const useDataFetchingForDrawer = <T,>(): DataFetchingResult<T> => {
  const [searchType, setSearchType] = useState<SearchType | undefined>(
    undefined
  );
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any | null>(null);
  const { searchInfo, view } = useContext(MapViewContext);

  // fetch function for vessel and port
  const fetchData = useCallback(
    async (id: string | undefined, searchType: SearchType | undefined) => {
      try {
        if (id) {
          if (searchType === SearchType.vessel) {
            const response = vesselDataService.useGetVesselInfo(id);
            const vesselData = response.data;
            const vesselDataFromMap = await VesselLayer.getVesselData(view, id);
            vesselData.imoNumber = vesselDataFromMap?.attributes.VesselImo;
            vesselData.vesselName = vesselDataFromMap?.attributes.VesselName;
            vesselData.position =
              ConvertLatToDMS(vesselDataFromMap?.attributes.geometry.Latitude) +
              ' ' +
              ConvertLongToDMS(
                vesselDataFromMap?.attributes.geometry.Longitude
              );
            vesselData.heading = vesselDataFromMap?.attributes.Heading;
            const data = vesselData as T;
            setData({ ...data });
          } else {
            const portLayer = await getPortData(view, id);
            const data = portLayer?.attributes as T;
            setData({ ...data });
          }
        } else {
          throw Error('');
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [view]
  );

  useEffect(() => {
    setData(null);
    setLoading(true);
    fetchData(searchInfo.searchId, searchInfo.searchType);
    setSearchType(searchInfo.searchType);
  }, [fetchData, searchInfo]);

  return { data, loading, error, searchType };
};

export const useDrawerHeaderTitle = () => {
  const { searchInfo, view } = useContext(MapViewContext);
  const [searchType, setSearchType] = useState<SearchType | undefined>(
    undefined
  );
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  // fetch function for vessel and port
  const fetchData = useCallback(
    async (id: string | undefined, searchType: SearchType | undefined) => {
      try {
        if (id) {
          const headerData: any = {};

          if (searchType === SearchType.vessel) {
            const vesselDataFromMap = await VesselLayer.getVesselData(view, id);
            headerData.title1 = vesselDataFromMap?.attributes.VesselName;
            headerData.title2 = vesselDataFromMap?.attributes.imoType;
            headerData.title3 = vesselDataFromMap?.attributes.VesselImo;

            setData({ ...headerData });
          } else {
            const portLayer = await getPortData(view, id);
            headerData.title1 = portLayer?.attributes.Port_Name;
            setData({ ...headerData });
          }
        } else {
          throw Error('');
        }
      } catch (error) {
        setError(error);
      }
    },
    [view]
  );

  useEffect(() => {
    fetchData(searchInfo.searchId, searchInfo.searchType);
    setSearchType(searchInfo.searchType);
  }, [fetchData, searchInfo]);

  return { data, error, searchType };
};

export const usePortInfoFetchingForDrawer = <T,>(): DataFetchingResult<T> => {
  const [searchType, setSearchType] = useState<SearchType | undefined>(
    undefined
  );
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any | null>(null);
  const { searchInfo, view } = useContext(MapViewContext);

  // fetch function for vessel and port
  const fetchData = useCallback(
    async (id: string | undefined, searchType: SearchType | undefined) => {
      try {
        if (id) {
          if (searchType === SearchType.port) {
            const portLayer = await getPortData(view, id);
            const data = portLayer?.attributes as T;
            setData({ ...data });
          }
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [view]
  );

  useEffect(() => {
    setLoading(true);
    fetchData(searchInfo.searchId, searchInfo.searchType);
    setSearchType(searchInfo.searchType);
  }, [fetchData, searchInfo]);

  return { data, loading, error, searchType };
};
