import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  makeVar,
} from '@apollo/client';
import { TypePolicy } from './query.type-policy';
import { scalarTypePolicies } from '../_gql/graphql';

const uri = process.env['REACT_APP_GRAPHQL_URL'] ?? '';

const httpLink = createHttpLink({
  uri: uri,
});

export const selectedAlertsVar = makeVar([] as string[]);

// We have to merge the Policies for Query so the merge of both Policy collections doesn't stomp on the first collection.
if (TypePolicy['Query']) {
  TypePolicy['Query'].fields = {
    ...TypePolicy['Query']?.fields,
    ...scalarTypePolicies['Query'].fields,
  };
}

export const createApolloClient = (headersLink: ApolloLink) =>
  new ApolloClient({
    link: headersLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: { ...scalarTypePolicies, ...TypePolicy },
    }),
    connectToDevTools: true,
  });
