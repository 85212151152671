import { gql } from '@apollo/client';

export const GET_VESSELS_CURRENT_POSITION = gql`
  query GetVesselsWithCurrentPosition {
    vessels {
      id
      name
      imoNumber
      imoType
      currentPosition {
        id
        latitude
        longitude
        heading
      }
    }
  }
`;
