import { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { createApolloClient } from 'client/apollo.client';
import { createHeadersLink } from 'client/apollo.link';
import { CircularLoading } from 'shared/components/circular-loading/circular-loading.component';
import { default as useIsAuthenticated } from 'shared/context/useCustomIsAuthenticated';

export const ApolloWrapper = (props: {
  children: JSX.Element;
}): JSX.Element => {
  const [token, setToken] = useState('');
  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isLoading = isAuthenticated && token === '';

  useEffect(() => {
    const user = accounts[0];
    if (user) {
      instance
        .acquireTokenSilent({
          scopes: [],
          account: user,
        })
        .then((response) => {
          setToken(response.idToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
          }
        });
    }
  }, [accounts, instance]);

  if (isLoading) {
    return <CircularLoading />;
  }

  const headersLink = createHeadersLink(token);
  const client = createApolloClient(headersLink);
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
