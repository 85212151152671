import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { Section } from 'shared/components/section/section.component';
import { VoyageMonitorMap } from './voyage-monitor-map';

export function VoyageMonitorRoute() {
  return (
    <PageWrapper data-testid={'voyage-monitor-section'}>
      <PageHeader title='Active Voyage Monitor' />
      <Section
        title='Active Vessel Information'
        childComponent={<VoyageMonitorMap />}
        preventUnmount
      />
    </PageWrapper>
  );
}
