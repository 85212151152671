import { UTCDate } from 'shared/utils/date-utc-helper';

export const getYAxisTicks = (dataMin: number, dataMax: number): number[] => {
  const dataMaxCeil = Math.ceil(dataMax ?? 0);
  const ticks = [];
  ticks.push(0);
  if (dataMaxCeil >= 50) {
    const range = Math.ceil(dataMaxCeil / 20);
    for (let i = 0; i <= 20; i++) {
      const tickValue = i * range;
      if (tickValue > dataMin && tickValue <= dataMax) {
        ticks.push(tickValue);
      }
    }
  } else if (dataMaxCeil > 10) {
    for (let i = 0; i <= dataMaxCeil / 2; i++) {
      const tickValue = i * 2;
      if (tickValue > dataMin && tickValue <= dataMax) {
        ticks.push(tickValue);
      }
    }
  } else {
    for (let i = 0; i < dataMaxCeil * 2; i++) {
      const tickValue = i;
      if (tickValue > dataMin && tickValue <= dataMax) {
        ticks.push(tickValue);
      }
    }
  }
  return ticks;
};

export const getXAxisTicks = (): number[] => {
  const currentYear = new UTCDate().year!;
  const ticks = [];
  for (let i = 0; i < 12; i++) {
    ticks.push(UTCDate.create(currentYear, i, 1, 0, 0, 0).unixTime!);
  }
  ticks.push(UTCDate.create(currentYear, 11, 31, 0, 0, 0).unixTime!);
  return ticks;
};
