import { SxProps } from '@mui/material/styles';
import { theme } from 'styles/theme';
import {
  isAlertStatusClosed,
  isAlertStatusOpen,
} from '../../services/alert-status';

const chipColor = (rowStatus: string, isArchived: boolean) =>
  isAlertStatusOpen(rowStatus) && !isArchived
    ? theme.alerts.status.open
    : isAlertStatusClosed(rowStatus) && !isArchived
    ? theme.alerts.status.closed
    : theme.alerts.status.archived;

const chipStatus = (isRead: boolean, hasError: boolean | undefined) => {
  if (hasError) {
    return theme.palette.error.main;
  }

  if (isRead) {
    return '';
  }

  return theme.alerts.status.unread;
};

export const ChipLabel = (rowStatus: string, isArchived: boolean) => {
  return isAlertStatusOpen(rowStatus) && !isArchived
    ? 'open'
    : isAlertStatusClosed(rowStatus) && !isArchived
    ? 'closed'
    : 'archived';
};

export const ChipStyle = (rowStatus: string, isArchived: boolean) => {
  return {
    color: theme.alerts.chipTextColor,
    fontSize: '13px',
    fontWeight: 400,
    height: '32px',
    width: '76px',
    backgroundColor: chipColor(rowStatus, isArchived),
    textTransform: 'capitalize',
  } as SxProps;
};

export const CellTextStyle = (rowIsRead: boolean) => {
  return {
    p: 1,
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: rowIsRead ? 400 : 800,
  } as SxProps;
};

export const StatusStyle = (isRead: boolean, hasError: boolean | undefined) => {
  return {
    borderColor: theme.background.page,
    p: '3px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    backgroundColor: chipStatus(isRead, hasError),
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    width: '10px',
  } as SxProps;
};
