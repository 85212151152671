import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { SyncDisabled } from '@mui/icons-material';
import { theme } from 'styles/theme';
import { Box } from '@mui/material';

export function UnableToLoadRoute() {
  return (
    <PageWrapper data-testid={'unable-to-load-section'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '85vh',
          overflow: 'hidden',
          flexDirection: 'column',
        }}
      >
        <SyncDisabled
          fontSize={'large'}
          sx={{ color: theme.palette.text.disabled, marginBottom: '12px' }}
        />
        <Box>Unable to load page</Box>
      </Box>
    </PageWrapper>
  );
}
