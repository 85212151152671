import { SxProps } from '@mui/material/styles';
import { theme } from 'styles/theme';

export const FormControlStyles: SxProps = {
  minWidth: '357px',
  width: '357px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '6px',
  pl: 0,
  ml: 4,
  '& .MuiInputLabel-shrink': {
    pt: '12px !important',
    pl: 0,
    fontSize: '14px',
    left: '-10px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiFormLabel-root': {
    letterSpacing: '0.15px',
    pt: '2px',
    pl: 0,
    color: theme.palette.text.secondary,
    transformOrigin: 'center',
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '&.Mui-error': {
      color: theme.palette.text.secondary,
    },
  },
};

export const SelectStyles: SxProps = {
  minWidth: '357px',
  width: '357px',
  color: theme.palette.text.primary,
  pt: 1,
  pr: 1,
  border: 'none',
  '& .MuiSelect-select': {
    pb: '5.5px',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.colors.blueGray,
  },
  '& .MuiSvgIcon-root': { color: theme.palette.text.primary, mr: 1 },
};
