import Cloud from '@mui/icons-material/Cloud';
import Collections from '@mui/icons-material/Collections';
import Error from '@mui/icons-material/Error';
import Folder from '@mui/icons-material/Folder';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MonitorHeart from '@mui/icons-material/MonitorHeart';
import SpaceDashboard from '@mui/icons-material/SpaceDashboard';
import ViewModule from '@mui/icons-material/ViewModule';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ABSDelta from 'assets/abs-delta-logo.svg';
import logo from 'assets/side-nav-logo.svg';
import productName from 'assets/side-nav-productName.svg';
import { useContext, useEffect, useState } from 'react';
import { ROUTES } from 'router/router';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { PermissionModule } from 'shared/models/user.model';
import { theme } from 'styles/theme';
import { useGetAlertCount } from '../../../routes/alerts/services/alerts.service';
import { CollapsibleDrawer } from './collapsibleDrawer.component';
import { RestrictedModule } from './restrictedModule.component';
import { SideNavButton } from './sideNavigationButton.component';
import { SideNavigationItem } from './sideNavigationItem.component';
import { useLocation } from 'react-router-dom';
import { AlertStatus } from '../../../routes/alerts/services/alert-status';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';

type SideNavProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};
export const SideNavigation: React.FC<SideNavProps> = ({ open, setOpen }) => {
  const [selectedItemPath, setSelectedItemPath] = useState('');
  const path = useLocation();
  const modulePath = path?.pathname.split('/', 2).join('/');
  if (selectedItemPath !== modulePath) setSelectedItemPath(modulePath);

  const { setAlertCount } = useContext(NavBarContext);
  const alertDataResponse = useGetAlertCount({
    dateRange: new DateRange(new UTCDate('1/1/2000'), new UTCDate()),
    alertStatuses: [AlertStatus.Open],
    isArchived: false,
    isRead: false,
  });

  const count = alertDataResponse.data;

  useEffect(() => {
    setAlertCount(count);
  }, [count, setAlertCount]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (path: string) => {
    setSelectedItemPath(path);
  };

  const paperProps: SxProps = {
    backgroundColor: theme.background.component,
    overflowX: 'hidden',
    boxSizing: 'border-box',
  };

  const appMenuItems = [
    {
      name: 'Dashboard',
      path: ROUTES.dashboard.path,
      icon: SpaceDashboard,
      requiredModule: null,
    },
    {
      name: 'Environmental Monitor',
      path: ROUTES.environmentalMonitor.path,
      icon: Cloud,
      requiredModule: PermissionModule.EnvironmentalMonitor,
    },
    {
      name: 'Vessel Performance',
      path: ROUTES.vesselPerformance.path,
      icon: Collections,
      requiredModule: PermissionModule.VesselPerformanceMonitor,
    },
    {
      name: 'Voyage Monitor',
      path: ROUTES.voyageMonitor.path,
      icon: MonitorHeart,
      requiredModule: PermissionModule.VoyagePlanningTracking,
    },
    {
      name: 'Vessel Data Monitor',
      path: ROUTES.vesselDataMonitor.path,
      icon: ViewModule,
      requiredModule: PermissionModule.VesselDataMonitor,
    },
    {
      name: 'Alerts',
      path: ROUTES.alerts.path,
      icon: Error,
      requiredModule: null,
    },
    {
      name: 'Reports',
      path: ROUTES.reports.path,
      icon: Folder,
      requiredModule: null,
    },
  ];

  return (
    <CollapsibleDrawer
      variant='permanent'
      PaperProps={{ sx: paperProps }}
      open={open}
      theme={theme}
    >
      <Box sx={{ mt: 0, overflow: 'auto', overflowX: 'hidden' }}>
        {open ? (
          <>
            <Box component='div' sx={{ mt: 3, ml: 2, height: '40px' }}>
              <Box component='img' alt='ABS Logo' src={logo} />
            </Box>
            <Box component='div' sx={{ ml: 2, height: '30px' }}>
              <Box component='img' alt='My Digital Fleet' src={productName} />
            </Box>
          </>
        ) : (
          <Box component='div' sx={{ mt: 3, mx: 2, height: '40px' }}>
            <Box
              component='img'
              sx={{ padding: '0 8px 16px' }}
              alt='ABS Delta Logo'
              src={ABSDelta}
            />
          </Box>
        )}

        <List>
          {appMenuItems.map((item) => (
            <RestrictedModule
              key={item.path}
              requiredModule={item.requiredModule}
              childComponent={
                <SideNavigationItem
                  callback={handleListItemClick}
                  selectedPath={selectedItemPath}
                  itemIcon={item.icon}
                  text={item.name}
                  path={item.path}
                  open={open}
                />
              }
            />
          ))}
        </List>
      </Box>
      <Box sx={{ textAlign: 'end', width: '100%', pt: 3 }}>
        {open ? (
          <SideNavButton
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={handleDrawerClose}
            open={open}
          >
            Collapse
          </SideNavButton>
        ) : (
          <SideNavButton
            startIcon={<KeyboardArrowRightIcon />}
            onClick={handleDrawerOpen}
            open={open}
          >
            Expand
          </SideNavButton>
        )}
      </Box>
    </CollapsibleDrawer>
  );
};
