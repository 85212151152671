import { ToggleButton, styled } from '@mui/material';
import { TEXT } from 'shared/constants/text';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';
import { IVoyageComparisonLegDomain } from '../models/voyage-comparison.model';
import { ApolloError } from '@apollo/client';
import { UTCDate } from 'shared/utils/date-utc-helper';

export function createAlignmentMap(headers: Header[]): {
  [key: string]: string;
} {
  const map: { [key: string]: string } = {};
  headers.forEach((header) => {
    map[header.field] = header.alignment;
  });
  return map;
}

type StyledToggleButtonProps = {
  isselected: boolean;
  selectedcolor: string;
};

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>(
  (props) => {
    return {
      textTransform: 'none',
      border: '0',
      borderRadius: `${typography.shape.borderRadius}px`,
      fontWeight: 500,
      height: '36px',
      boxShadow: props.isselected
        ? '0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)'
        : 'none',
      '&.MuiToggleButtonGroup-grouped': {
        backgroundColor: props.isselected
          ? props.selectedcolor
          : 'rgba(255, 255, 255, 0.1)',
        textTransform: 'none',
        borderRadius: `${typography.shape.borderRadius}px`,
        '&:not(:first-of-type)': {
          margin: '0px',
          borderRadius: `${typography.shape.borderRadius}px`,
        },
        '&:first-of-type': {
          borderRadius: `${typography.shape.borderRadius}px`,
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          margin: '0px',
          borderRadius: `${typography.shape.borderRadius}px`,
          opacity: '1.0',
        },
        '&.Mui-selected .MuiTypography-root': {
          opacity: '1.0',
        },
        '&.Mui-selected  .MuiSvgIcon-root': {
          opacity: '1.0',
        },
        '&.Mui-selected + &.Mui-selected': {
          borderRadius: `${typography.shape.borderRadius}px`,
        },
      },
    };
  }
);

export enum SortableTypes {
  NUMBER = 'number',
  STRING = 'string',
  UTCDATE = 'UTCDate',
}

export type SortDirection = 'asc' | 'desc';

export type VoyageComparisonTableProps = {
  vesselName: string | undefined;
  data: IVoyageComparisonLegDomain[];
  sortBy: string;
  sortDirection: SortDirection;
  alignmentMap: { [key: string]: string };
  headers: Header[];
  handleSort: (field: string, sortableType: SortableTypes) => void;
  error: ApolloError | undefined;
};

export type Header = {
  field: string;
  title: string;
  alignment: 'left' | 'right' | 'center';
  borderRight: string;
  minWidth: string;
  paddingLeft: string;
  sortableType: SortableTypes;
  displayZero: boolean;
};

export const headers: Header[] = [
  {
    field: 'legNumber',
    title: 'Leg #',
    alignment: 'left',
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '78px',
    paddingLeft: '',
    sortableType: SortableTypes.STRING,
    displayZero: true,
  },
  {
    field: 'calculations.cii.value',
    title: 'CII Rating',
    alignment: 'center',
    borderRight: '',
    minWidth: '',
    paddingLeft: '38px',
    sortableType: SortableTypes.STRING,
    displayZero: false,
  },
  {
    field: 'calculations.cii.delta',
    title: 'CII Δ',
    alignment: 'right',
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: false,
  },
  {
    field: 'calculations.cii.deviation',
    title: 'CII %',
    alignment: 'center',
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '',
    paddingLeft: '40px',
    sortableType: SortableTypes.NUMBER,
    displayZero: false,
  },
  {
    field: 'calculations.eeoi.value',
    title: `EEOI<br/>(${TEXT.FORMULAS.EEOI})`,
    alignment: 'right',
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.STRING,
    displayZero: false,
  },
  {
    field: 'calculations.eeoi.delta',
    title: 'EEOI Δ',
    alignment: 'right',
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: false,
  },
  {
    field: 'calculations.eeoi.deviation',
    title: 'EEOI %',
    alignment: 'center',
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '',
    paddingLeft: '40px',
    sortableType: SortableTypes.NUMBER,
    displayZero: false,
  },
  {
    field: 'calculations.aer.value',
    title: `AER<br/>(${TEXT.FORMULAS.AER})`,
    alignment: 'right',
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: false,
  },
  {
    field: 'calculations.aer.delta',
    title: 'AER Δ',
    alignment: 'right',
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: false,
  },
  {
    field: 'calculations.aer.deviation',
    title: 'AER %',
    alignment: 'center',
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '',
    paddingLeft: '40px',
    sortableType: SortableTypes.NUMBER,
    displayZero: false,
  },
  {
    field: 'departurePort',
    title: 'Departure Port',
    alignment: 'left',
    borderRight: '',
    minWidth: '150px',
    paddingLeft: '',
    sortableType: SortableTypes.STRING,
    displayZero: false,
  },
  {
    field: 'departureDate',
    title: 'Departure Date',
    alignment: 'left',
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '120px',
    paddingLeft: '',
    sortableType: SortableTypes.UTCDATE,
    displayZero: false,
  },
  {
    field: 'arrivalPort',
    title: 'Arrival Port',
    alignment: 'left',
    borderRight: '',
    minWidth: '150px',
    paddingLeft: '',
    sortableType: SortableTypes.STRING,
    displayZero: false,
  },
  {
    field: 'arrivalDate',
    title: 'Arrival Date',
    alignment: 'left',
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '120px',
    paddingLeft: '',
    sortableType: SortableTypes.UTCDATE,
    displayZero: false,
  },
  {
    field: 'distance',
    alignment: 'right',
    title: `Distance (${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`,
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: true,
  },
  {
    field: 'speed',
    alignment: 'right',
    title: `Speed (${TEXT.UNIT_MEASUREMENT.KNOTS})`,
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: true,
  },
  {
    field: 'fuelConsumed',
    alignment: 'right',
    title: `Fuel Consumed (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: true,
  },
  {
    field: 'co2Emissions',
    alignment: 'right',
    title: `Total CO2 (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: true,
  },
  {
    field: 'calculations.eua.euCo2',
    alignment: 'right',
    title: 'EUA CO2',
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: true,
  },
  {
    field: 'calculations.eua.voyageType',
    alignment: 'right',
    title: 'EU Voyage',
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.STRING,
    displayZero: true,
  },
  {
    field: 'calculations.eua.liability',
    alignment: 'right',
    title: 'EUA Liability',
    borderRight: '',
    minWidth: '',
    paddingLeft: '',
    sortableType: SortableTypes.NUMBER,
    displayZero: true,
  },
];

export const sortVoyageComparison = (
  data: IVoyageComparisonLegDomain[],
  sortKey: string,
  direction: 'asc' | 'desc'
): IVoyageComparisonLegDomain[] => {
  const header = headers.find((h) => h.field === sortKey);
  if (!header) {
    return data;
  }

  return sortList(data, sortKey, header.sortableType, direction);
};

const fetchValue = (
  item: IVoyageComparisonLegDomain,
  sortKey: string
): string | number | UTCDate | null => {
  switch (sortKey) {
    case 'calculations.cii.value':
      return item.calculations?.cii?.value?.fieldValue;
    case 'calculations.cii.delta':
      return item.calculations?.cii?.delta?.fieldValue;
    case 'calculations.eeoi.value':
      return item.calculations?.eeoi?.value?.fieldValue;
    case 'calculations.eeoi.delta':
      return item.calculations?.eeoi?.delta?.fieldValue;
    case 'calculations.aer.value':
      return item.calculations?.aer?.value?.fieldValue;
    case 'calculations.aer.delta':
      return item.calculations?.aer?.delta?.fieldValue;
    case 'calculations.eua.euCo2':
      return item.calculations.eua?.euCo2?.fieldValue;
    case 'calculations.eua.liability':
      return item.calculations.eua?.liability?.fieldValue;
    case 'calculations.eua.voyageType':
      return item.calculations.eua?.voyageType?.fieldValue;
    case 'calculations.cii.deviation':
      return item.calculations?.cii?.deviation?.fieldValue;
    case 'calculations.eeoi.deviation':
      return item.calculations?.eeoi?.deviation?.fieldValue;
    case 'calculations.aer.deviation':
      return item.calculations?.aer?.deviation?.fieldValue;
    default: {
      const field = item[sortKey as keyof IVoyageComparisonLegDomain];
      if (
        typeof field === 'object' &&
        field !== null &&
        'fieldValue' in field
      ) {
        return field.fieldValue;
      }
      return null;
    }
  }
};

const convertValue = (
  value: string | number | UTCDate | null,
  sortType: SortableTypes
): string | number | UTCDate | null => {
  if (sortType === SortableTypes.NUMBER) {
    return Number(value);
  } else if (sortType === SortableTypes.UTCDATE) {
    return new UTCDate(value as string);
  }
  return value;
};

const compareValues = (
  aValue: string | number | UTCDate | null,
  bValue: string | number | UTCDate | null,
  direction: 'asc' | 'desc'
): number => {
  if (aValue === null && bValue === null) return 0;
  if (aValue === null) return direction === 'asc' ? -1 : 1;
  if (bValue === null) return direction === 'asc' ? 1 : -1;

  if (aValue < bValue) return direction === 'asc' ? -1 : 1;
  if (aValue > bValue) return direction === 'asc' ? 1 : -1;

  return 0;
};

export const sortList = (
  list: IVoyageComparisonLegDomain[] | undefined,
  sortKey: string,
  sortType: SortableTypes,
  direction: 'asc' | 'desc'
): IVoyageComparisonLegDomain[] => {
  return [...(list ?? [])].sort((a, b) => {
    const aValue = fetchValue(a, sortKey);
    const bValue = fetchValue(b, sortKey);

    const convertedAValue = convertValue(aValue, sortType);
    const convertedBValue = convertValue(bValue, sortType);

    return compareValues(convertedAValue, convertedBValue, direction);
  });
};
