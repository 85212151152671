import { Box, Modal } from '@mui/material';
import { Section } from 'shared/components/section/section.component';

const modalStyle = {
  width: '100vw',
  height: '100vh',
  padding: '30px',
};

const style = {
  width: '94vw',
  maxWidth: '1800px',
  bgcolor: '#1D262F',
  borderRadius: '8px',
  border: '1px solid #555',
  padding: '24px',
  margin: 'auto',

  height: '94vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
};

export const SequencesModal = ({
  open,
  children,
}: {
  open: boolean;

  children?: React.ReactNode;
}) => {
  return (
    <Modal open={open} sx={modalStyle}>
      <Box sx={style}>
        <Section
          id={'PlannedItinerary'}
          title={'Planned Itinerary'}
          preventUnmount
          expandable={false}
          exportSection
          exportOptions={{
            includePDF: false,
            includeCardData: true,
            introductoryRows: {
              includeVesselName: true,
              includeDateRange: false,
              includeVesselGroup: false,
            },
            filenameOptions: {
              includeVesselName: true,
              includeDateRange: false,
              includeVesselGroup: false,
            },
          }}
          childComponent={<Box sx={{ mb: '120px' }}>{children}</Box>}
          sx={{ overflow: 'hidden' }}
        />
      </Box>
    </Modal>
  );
};
