import CircleIcon from '@mui/icons-material/Circle';
import { FixedTimeline } from 'assets/fixed-timeline';
import { useParams } from 'react-router-dom';
import {
  useGetVesselKPIs,
  useGetVoyageComparison,
} from 'routes/environmental-monitor/services/vessel.service';
import {
  CustomBarRectangleItemType,
  EuaChartData,
  GenerateBarItem,
  GenerateChartData,
  MapNAVoyageComparisonToDatapoints,
  MapVoyageComparisonToDatapoints,
} from 'routes/environmental-monitor/view-models/eua-ratings-chart.viewModel';
import { theme } from 'styles/theme';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { TEXT } from 'shared/constants/text';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import {
  BarSeriesType,
  ChartsReferenceLine,
  LineSeriesType,
} from '@mui/x-charts-pro';
import { ChartKeyItem } from 'shared/components/chart-key/chart-key.component';
import {
  getTooltipDataIndex,
  MuiChartContainerWithLegend,
  tooltipContentProps,
} from 'shared/components/mui-chart/container.component';
import { nameof } from 'shared/components/datagrid';
import { Box, Grid, Typography } from '@mui/material';
import { formatNumber } from 'shared/utils/float-utils';

export type EUATrendChartProps = {
  date: Date | undefined;
  inOutBarItem: number;
  withinBarItem: number;
  planned: number;
  ytdTotal: number;
  value: number;
};

export type ChartLegend = {
  label: string;
  color: string;
};
export const yAxisTitle = 'EUA CO₂ (t)';

export function EUAChart() {
  const { id } = useParams();
  const startDate = new UTCDate().startOfYear!;
  const endDate = new UTCDate().endOfDay!;
  const dateRange = new DateRange(startDate, endDate);

  const newEuaData = useGetVoyageComparison(id, dateRange);
  const vesselKpis = useGetVesselKPIs(id, dateRange);
  const euaPlannedValue =
    parseInt(vesselKpis.data?.euaAgainstPlanned?.tertiaryValue ?? '') || null;
  const newInOut = newEuaData.data?.legs?.filter((x) => {
    return (
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() ===
      'IN/OUT'
    );
  });
  const newWithin = newEuaData.data?.legs?.filter((x) => {
    return (
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() ===
      'WITHIN'
    );
  });
  const newTotal = newEuaData.data?.legs?.filter((x) => {
    return (
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() !==
      NA_VALUE_INDICATOR
    );
  });
  const newNa = newEuaData.data?.legs?.filter((x) => {
    return (
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() !==
        'IN/OUT' &&
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() !==
        'WITHIN'
    );
  });
  const inOutValues =
    MapVoyageComparisonToDatapoints(startDate, newInOut!) ?? [];
  const withinValues =
    MapVoyageComparisonToDatapoints(startDate, newWithin!) ?? [];
  const naValues = MapNAVoyageComparisonToDatapoints(startDate, newNa!) ?? [];
  const ciiData = MapVoyageComparisonToDatapoints(startDate, newTotal!) ?? [];
  const error = newEuaData.error;
  const loading = newEuaData.loading;
  const chartDataRaw: EuaChartData = {
    rawData: ciiData,
    inOutData: GenerateBarItem(inOutValues, CustomBarRectangleItemType.InOut),
    withinData: GenerateBarItem(
      withinValues,
      CustomBarRectangleItemType.Within
    ),
    naData: GenerateBarItem(naValues, CustomBarRectangleItemType.NA),
    planned: euaPlannedValue,
  };
  let stopsJson = vesselKpis.data?.euaAgainstPlanned.quaternaryValue;
  stopsJson =
    stopsJson === TEXT.ERROR_MESSAGES.EMPTY_DASHES ? undefined : stopsJson;
  const stopsParsed = stopsJson ? JSON.parse(stopsJson) : null;
  const stops = stopsParsed
    ? [stopsParsed.warn ?? 31, stopsParsed.error ?? 61]
    : [31, 61];
  const chartData: EuaChartData = GenerateChartData(chartDataRaw);

  const chartProps = nameof<EUATrendChartProps>;

  const EUAWithinEU: ChartKeyItem = {
    id: 1,
    name: 'EUA Within EU',
    fill: theme.vesselPerformance.charts.secondaryPurple,
  };

  const EUAInOutEU: ChartKeyItem = {
    id: 2,
    name: 'EUA In/Out EU',
    fill: theme.colors?.cyan,
  };

  const YTDEua: ChartKeyItem = {
    id: 3,
    name: 'YTD EUA',
    fill: theme.colors?.aRating,
  };

  const PlannedEua: ChartKeyItem = {
    id: 4,
    name: 'Planned EUA',
    fill: theme.colors.magenta,
  };

  const seriesDate: Array<LineSeriesType | BarSeriesType> = [
    {
      type: 'line',
      dataKey: chartProps('ytdTotal'),
      label: YTDEua.name,
      highlightScope: { highlighted: 'item', faded: 'global' },
      color: YTDEua.fill,
    },
    {
      type: 'bar',
      dataKey: chartProps('inOutBarItem'),
      label: EUAInOutEU.name,
      color: EUAInOutEU.fill,
    },
    {
      type: 'bar',
      dataKey: chartProps('withinBarItem'),
      label: EUAWithinEU.name,
      color: EUAWithinEU.fill,
    },
  ];

  const bands = [
    {
      y: (stops[1] * (chartData.yMax ?? 0)) / 100,
      color: theme.colors?.eRating,
    },
    {
      y: (stops[0] * (chartData.yMax ?? 0)) / 100,
      color: theme.colors?.bRating,
    },
    { y: 0, color: theme.colors?.aRating },
  ];

  const yearFormatter = (date: Date) => {
    return new UTCDate(date).formatM();
  };

  const chartDataset: EUATrendChartProps[] = Array.from(
    chartData.data ?? [],
    (p) => {
      return {
        date: new UTCDate(p.key).date,
        inOutBarItem: p.inOutBarItem ?? 0,
        withinBarItem: p.withinBarItem ?? 0,
        planned: p.planned ?? 0,
        value: p.value ?? 0,
        ytdTotal: p.ytdTotal ?? 0,
      };
    }
  );
  const plannedLegend: ChartLegend = {
    label: PlannedEua.name,
    color: PlannedEua.fill,
  };

  const getEuaInOutPercentage = (
    inOutBarItem: number,
    payloadValue: number
  ) => {
    const percentageValue = ((inOutBarItem / payloadValue) * 100).toFixed(0);
    const formattedPercentageForDisplay = formatNumber(percentageValue, 0);
    if (Number.isNaN(Number(formattedPercentageForDisplay))) {
      return '';
    }
    return `(${formattedPercentageForDisplay}%)`;
  };

  const customTooltipContent = (
    props: tooltipContentProps,
    chartDataset: EUATrendChartProps[]
  ) => {
    const index = getTooltipDataIndex(props);
    if (index === undefined) return null;
    const data = chartDataset[index];

    const euaInOutPercentageBarItem = getEuaInOutPercentage(
      data?.inOutBarItem ?? 0,
      data?.value ?? 1
    );

    let xAxisValue;
    if ('axisValue' in props) {
      xAxisValue = props.axisValue;
    }
    const activeMonth = new UTCDate(xAxisValue).formatM();
    return (
      <Grid container sx={{ width: '200px' }}>
        <Grid item xs={9}>
          <CircleIcon
            sx={{ m: 0, fontSize: '12px', color: theme.colors.magenta }}
          />{' '}
          {PlannedEua.name} :
        </Grid>
        <Grid item xs={3}>
          <Typography align='right'>
            {formatNumber(data?.planned, 0)}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <CircleIcon
            sx={{ m: 0, fontSize: '12px', color: theme.colors?.aRating }}
          />{' '}
          YTD EUA Total:
        </Grid>
        <Grid item xs={3}>
          <Typography align='right'>
            {formatNumber(data?.ytdTotal, 0)}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <CircleIcon
            sx={{
              m: 0,
              fontSize: '12px',
              color: theme.vesselPerformance.charts.secondaryPurple,
            }}
          />{' '}
          EUA Within:
        </Grid>
        <Grid item xs={3}>
          <Typography align='right'>
            {formatNumber(data?.withinBarItem, 0)}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <CircleIcon
            sx={{ m: 0, fontSize: '12px', color: theme.colors?.cyan }}
          />{' '}
          EUA In/Out:
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ textAlign: 'right' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <span>{formatNumber(data?.inOutBarItem, 0)}</span>
              {euaInOutPercentageBarItem ? (
                <span style={{ marginLeft: '5px' }}>
                  {euaInOutPercentageBarItem}
                </span>
              ) : null}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9}>
          {' '}
          {activeMonth} EUA Total:
        </Grid>
        <Grid item xs={3}>
          <Typography align='right'>{formatNumber(data?.value, 0)}</Typography>
        </Grid>
      </Grid>
    );
  };
  const tooltipContent = (props: tooltipContentProps) =>
    customTooltipContent(props, chartDataset);

  const renderContent = () => {
    return (
      <Box component={'section'} sx={{ pl: 1, mb: 1 }}>
        <MuiChartContainerWithLegend
          background={{ bands: bands }}
          margin={{ right: 40 }}
          loading={loading}
          error={error}
          series={seriesDate}
          dataset={chartDataset}
          tooltip={{ trigger: 'axis', content: tooltipContent }}
          xAxis={[
            {
              dataKey: chartProps('date'),
              tickNumber: chartDataset.length,
              scaleType: 'band',
              tickPlacement: 'middle',
              tickLabelStyle: { angle: -35 },
              valueFormatter: yearFormatter,
            },
          ]}
          yAxis={[{ label: yAxisTitle, min: 0, max: chartData.yMax ?? 0 }]}
          additionalLegend={[plannedLegend]}
        >
          <ChartsReferenceLine
            y={chartData.yMax ?? 0}
            labelStyle={{ fontSize: '12px' }}
            lineStyle={{ stroke: PlannedEua.fill, strokeWidth: 2 }}
          />
        </MuiChartContainerWithLegend>
      </Box>
    );
  };

  return (
    <CardContainer
      title={vesselPageTitles.vesselEUATrends}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      {renderContent()}
    </CardContainer>
  );
}
