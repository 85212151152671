import { FieldValidation, ValidationType } from '_gql/graphql';
import { IFieldValidation } from '../models/field-validation.model';
import { isNumeric } from 'shared/utils/float-utils';

export class VesselReportTileMapper {
  public static fromDTO(
    dto: FieldValidation | undefined
  ): IFieldValidation | null {
    return {
      fieldName: this.fieldNameFormatter(dto?.fieldName),
      fieldValue:
        dto?.fieldValue && dto?.fieldValue !== ''
          ? this.fieldValueFromNameFormatter(dto?.fieldName, dto?.fieldValue)
          : '---',
      isEmission: dto?.isEmission ?? false,
      validation: this.validationFromDTO(dto?.validation),
      notValidatedForReport:
        (dto?.fieldValue === null || dto?.fieldValue === '') &&
        dto?.validation === null,
    };
  }

  private static fieldValueFromNameFormatter(
    fieldName: (string | null) | undefined,
    fieldValue: string
  ): string {
    if (!isNumeric(fieldValue)) return fieldValue;

    switch (fieldName) {
      case 'WindSpeedRel':
      case 'ReportDuration':
      case 'CargoQuantity':
      case 'LFO Consumed by M/E':
      case 'LFO Consumed by A/E':
      case 'DO Consumed by A/E':
        return Number(fieldValue).toFixed(2);
      default:
        return fieldValue;
    }
  }

  private static fieldNameFormatter(
    fieldName: (string | null) | undefined
  ): string {
    if (!fieldName) {
      return '';
    }

    switch (fieldName) {
      case 'VesselName':
        return 'Vessel Name';
      case 'ImoNumber':
        return 'IMO Number';
      case 'PositionLatDegreeMinute':
        return 'Position LAT Degree Minute';
      case 'STW':
        return 'Speed through water (Knots)';
      case 'SOG':
        return 'Speed over ground (Knots)';
      case 'MainEngineRpm':
        return 'Main Engine RPM (rpm)';
      case 'MEP_KW':
        return 'Main Engine Power (kW)';
      case 'PropellerSlip':
        return 'Slip (%)';
      case 'MCRPercentage':
        return 'MCR (%)';
      case 'Heading':
        return 'Heading (Degree)';
      case 'ForeDraft':
        return 'Draft Fore (m)';
      case 'AftDraft':
        return 'Draft Aft (m)';
      case 'MeanDraft':
        return 'Draft (m)';
      case 'VoyageNumber':
        return 'Voyage Number';
      case 'DeparturePortCode':
        return 'Departure Port Code';
      case 'DeparturePortName':
        return 'Departure Port Name';
      case 'EstArrivalTime':
        return 'Estimated Arrival Time';
      case 'EstDepartureTime':
        return 'Estimated Departure Time';
      case 'DestinationPort':
        return 'Destination Port';
      case 'ObservedDistance':
        return 'Observed Distance (NM)';
      case 'RemainingDistance':
        return 'Remaining Distance (NM)';
      case 'OrderedSpeed':
        return 'CP/Order Speed (Knots)';
      case 'CargoQuantity':
        return 'Cargo Quantity (MT)';
      case 'SteamingTime':
        return 'Steaming Time (hours)';
      case 'WindTrue':
        return 'Wind True (Degree or Cardinal)';
      case 'WindDirectionTrue':
        return 'Wind Direction True (Degree or Cardinal)';
      case 'WindRel':
        return 'Wind Rel (Degree or Cardinal)';
      case 'WindDirectionRel':
        return 'Wind Direction Rel (Degree or Cardinal)';
      case 'WindSpeedTrue':
        return 'Wind Speed True (Knots)';
      case 'WindSpeedRel':
        return 'Wind Speed Rel (Knots)';
      case 'WaveHeight':
        return 'Wave Height (m)';
      case 'WavePeriod':
        return 'Wave Period (s)';
      case 'SeaCurrentDirection':
        return 'Current Direction (Degree or Cardinal)';
      case 'SeaTrue':
        return 'Sea True (Degree or Cardinal)';
      case 'SeaDirectionTrue':
        return 'Sea Direction True (Degree or Cardinal)';
      case 'SeaRel':
        return 'Sea Rel (Degree or Cardinal)';
      case 'SeaDirectionRel':
        return 'Sea Direction Rel (Degree or Cardinal)';
      case 'SwellTrue':
        return 'Swell True (Degree or Cardinal)';
      case 'SwellDirectionTrue':
        return 'Swell Direction True (Degree or Cardinal)';
      case 'SwellRel':
        return 'Swell Rel (Degree or Cardinal)';
      case 'SwellDirectionRel':
        return 'Swell Direction Rel (Degree or Cardinal)';
      case 'BeaufortScale':
        return 'Wind Force (BN)';
      case 'ReportDuration':
        return 'Report Duration';
      case 'LocationStatus':
        return 'Activity (Operational mode/Location Status)';
      case 'EngineDistance':
        return 'Engine Distance';
      case 'DistanceToNextWaypoint':
        return 'Distance to Next Waypoint';
      case 'FinishedWithEngineDateTime':
        return 'Finished with Engine Date/Time';
      case 'StandByEngineDateTime':
        return 'Standby Engine Date/Time';
      case 'VoyageLegNumber':
        return 'Voyage Leg Number';
      case 'DepartureTime':
        return 'Departure Datetime';
      case 'ArrivalTime':
        return 'Arrival Datetime';
      case 'ArrivalPortCode':
        return 'Arrival Port Code';
      case 'ArrivalPortName':
        return 'Arrival Port Name';
      case 'ReqPlannedArrivalTime':
        return 'Require/Planned Estimated Arrival Datetime';
      case 'OrderedRpm':
        return 'Instructed/Ordered RPM';
      case 'LoadingCondition':
        return 'Loading Condition';
      case 'StorageFactor':
        return 'Storage Factor';
      case 'CargoDensity':
        return 'Cargo Density';
      case 'Course':
        return 'Course';
      case 'Trim':
        return 'Trim';
      case 'DateTimeOffset':
        return 'Date Time Offset';
      default:
        return fieldName;
    }
  }

  private static validationFromDTO(
    dtos: (ValidationType | null) | undefined
  ): ValidationType | null {
    if (dtos?.code && dtos?.reason) {
      return { code: dtos.code, reason: dtos.reason };
    } else {
      return null;
    }
  }
}
