import {
  ConvertLatToDMS,
  ConvertLongToDMS,
  CalculateTimeDiff,
} from 'shared/utils/map-utils';

export class ActiveVoyageInfoForDashboardDrawerMapper {
  public static fromDTO(dto: any): any {
    const activeVoyageInfo: any = {};

    activeVoyageInfo.imoNumber = dto.imoNumber ?? '---';
    activeVoyageInfo.vesselName = dto.name ?? '---';

    activeVoyageInfo.status = dto?.activeVoyage?.status ?? '---';
    activeVoyageInfo.location = dto?.activeVoyage?.location ?? '---';
    activeVoyageInfo.position =
      ConvertLatToDMS(dto?.currentPosition?.latitude ?? '') +
      ' ' +
      ConvertLongToDMS(dto?.currentPosition?.longitude ?? '');

    activeVoyageInfo.heading = dto?.currentPosition?.heading ?? '---';
    activeVoyageInfo.lastUpdatedTime = CalculateTimeDiff(
      dto?.currentPosition?.timestamp
    );

    activeVoyageInfo.voyageNumber = dto?.activeVoyage?.voyageNumber ?? '---';
    // activeVoyageInfo.legInfo = dto.legInfo ?? this.generateLegsInfo();
    activeVoyageInfo.activeAlertCount = dto.alerts?.total ?? 0;
    activeVoyageInfo.imoType = dto.imoType;

    return activeVoyageInfo;
  }

  // // This is for generating mock data for the UI
  // private static generateLegsInfo() {
  //   const mockLegsInfo = {
  //     currentLeg: 8,
  //     totalLeg: [
  //       'Tokyo, Japan',
  //       'Shanghai, China',
  //       'Hong Kong, China',
  //       'Singapore',
  //       'Rotterdam, Netherlands',
  //       'Hamburg, Germany',
  //       'Antwerp, Belgium',
  //       'London, UK',
  //       'New York, USA',
  //       'Norfolk, USA',
  //       'Savannah, USA',
  //       'Miami, USA',
  //       'Houston, USA',
  //       'Los Angeles, USA',
  //       'Oakland, USA',
  //       'Tokyo, Japan',
  //     ],
  //     legOrigin: 'Leg Origin',
  //     legDestination: 'Leg Destination',
  //   };

  //   return mockLegsInfo;
  // }
}
