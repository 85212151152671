import { useContext, useEffect, useState } from 'react';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import {
  MapViewContext,
  MapViewType,
} from 'shared/models/mapView.context.model';
import { PortThemes, PortThemesName } from './symbols';
import { SearchWidget } from './searchWidget';

export const PORTS_LAYER_ID = 'PortsLayerId';

export async function getPortData(view: MapViewType | null, UNLOCODE: string) {
  if (!view) return;

  const geoJSONLayer = view.map.findLayerById(PORTS_LAYER_ID) as GeoJSONLayer;
  if (!geoJSONLayer) return;

  const query = geoJSONLayer.createQuery();
  query.where = `UNLOCODE = '${UNLOCODE}'`;
  query.outFields = ['*'];

  const response = await geoJSONLayer.queryFeatures(query);
  return response?.features.at(0);
}

export const PortsLayer = ({
  data,
}: {
  data: string[];
  theme?: PortThemesName;
}) => {
  const { view } = useContext(MapViewContext);
  const [geoJSONLayer, setGeoJSONLayer] = useState<GeoJSONLayer | undefined>();

  useEffect(() => {
    const geojsonLayer = new GeoJSONLayer({
      id: PORTS_LAYER_ID,
      title: 'Ports',
      opacity: 0.8,
      visible: false,
      editingEnabled: true,
      url: '/portpolygons.json',
      copyright: 'ABS Ports',
      objectIdField: 'Port_PlaceId',
      renderer: PortThemes.dark.PortRenderer,
      fields: [
        { name: 'Port_PlaceId', type: 'integer' },
        { name: 'Port_Name', type: 'string' },
        { name: 'Port_Country', type: 'string' },
        { name: 'geometry', alias: 'geometry', type: 'geometry' },
        { name: 'UNLOCODE', alias: 'UNLOCODE', type: 'string' },
      ],
      outFields: ['Port_PlaceId', 'Port_Name', 'UNLOCODE'],
    });

    setGeoJSONLayer(geojsonLayer);

    return () => {
      console.log('Removing layer');
    };
  }, []);

  useEffect(() => {
    if (!view || !geoJSONLayer) return;

    view.map.add(geoJSONLayer, 1);
    const searchWidget: any = view.ui.find(SearchWidget.SEARCH_WIDGET_ID);
    if (searchWidget) {
      if (!SearchWidget.SourceExist(view, PORTS_LAYER_ID)) {
        searchWidget.sources.add({
          layer: geoJSONLayer,
          searchFields: ['Port_Name'],
          displayField: 'Port_Name',
          exactMatch: false,
          outFields: ['Port_Name', 'Port_Country', 'geometry', 'UNLOCODE'],
          name: 'Ports',
        });
      }
    }
  }, [view, geoJSONLayer]);

  // called when the 'data' has changed
  useEffect(() => {
    if (!view || !geoJSONLayer || !data) return;

    geoJSONLayer.queryFeatures().then(({ features }) => {
      const validFeatures = features.filter((x) => x.attributes.UNLOCODE);
      const invalidFeatures = features.filter((x) => !x.attributes.UNLOCODE);
      const notmatchFeatures = validFeatures.filter(
        (x) => !data.includes(x.attributes.UNLOCODE)
      );
      const deleteFeatures = invalidFeatures.concat(notmatchFeatures);

      geoJSONLayer
        .applyEdits({
          deleteFeatures: deleteFeatures,
        })
        .then(() => {
          geoJSONLayer.visible = true;
        });
    });
  }, [view, geoJSONLayer, data]);

  return <></>;
};
