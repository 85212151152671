import dayjs from 'dayjs';
import { VesselEvent } from '_gql/graphql';
import { EventsMapper } from '../mappers/events.mapper';
import { IEvent } from '../models/events.model';
import {
  useCreateEventData,
  useDeleteEventData,
  useGetEventData,
  useUpdateEventData,
} from '../repositories/events.repository';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetEvents = (
  vesselId: string | undefined,
  vesselImo: string | undefined,
  dateRange: DateRange | null
) => {
  const getVesselEventsResponse = useGetEventData(
    vesselId,
    vesselImo,
    dateRange
  );

  const isLoading = getVesselEventsResponse?.loading;
  const error = getVesselEventsResponse?.error;

  if (isLoading) {
    return { eventLoading: true, eventError: null, eventsData: null };
  }

  if (error) {
    return { eventLoading: false, eventError: error, eventsData: null };
  }

  if (
    getVesselEventsResponse === null ||
    getVesselEventsResponse.data === undefined ||
    getVesselEventsResponse.data === null ||
    getVesselEventsResponse.data.vesselEvents === null ||
    getVesselEventsResponse.data.vesselEvents === undefined
  ) {
    return { eventLoading: false, eventError: null, eventsData: null };
  }

  const events =
    getVesselEventsResponse.data.vesselEvents &&
    getVesselEventsResponse.data.vesselEvents.map((x: VesselEvent) =>
      EventsMapper.toDomain(x)
    );

  events?.sort((a: IEvent, b: IEvent) => {
    return dayjs(b.dateRange.startDate?.dateDayjs).diff(
      dayjs(a.dateRange.startDate?.dateDayjs)
    );
  });

  return {
    eventLoading: false,
    eventError: null,
    eventsData: events,
  };
};

export const useCreateEvent = (
  vesselId: string | undefined,
  vesselImo: string | undefined,
  dateRange: DateRange | null
) => {
  const [mutation, { error, loading, data }] = useCreateEventData(
    vesselId,
    vesselImo,
    dateRange
  );

  return {
    mutation,
    error,
    loading,
    data,
  };
};

export const useUpdateEvent = () => {
  const [mutation, { error, loading, data }] = useUpdateEventData();

  return {
    mutation,
    error,
    loading,
    data,
  };
};

export const useDeleteEvent = (
  vesselId: string | undefined,
  vesselImo: string | undefined,
  dateRange: DateRange | null
) => {
  const [mutation, { error, loading, data }] = useDeleteEventData(
    vesselId,
    vesselImo,
    dateRange
  );

  return {
    mutation,
    error,
    loading,
    data,
  };
};
