const GRAMS_CO2_PER_DEAD_WEIGHT_NAUTICAL_MILE = 'gCO₂/DWT\u2011NM'; // \u2011 = dash -, use code to avoid line-wrapping
const GRAMS_CO2_PER_TON_NAUTICAL_MILE = 'gCO₂/t\u2011NM'; // \u2011 = dash -, use code to avoid line-wrapping

export const TEXT = {
  UNIT_MEASUREMENT: {
    GRAMS_CO2_PER_DEAD_WEIGHT_NAUTICAL_MILE,
    KNOTS: 'kn',
    METRIC_TONNES_PER_DAY: 't/day',
    METRIC_TONNES: 't',
    METRIC_TONNES_MRV: 't,mrv',
    NAUTICAL_MILE: 'NM',
    PERCENTAGE: '%',
    HOUR: 'hr',
    GRAMS_CO2_EQ_PER_MEGAJOULE: 'gCO₂eq/MJ',
    GRAMS_CO2_EQ: 'gCO₂eq',
    METRIC_TONNES_CO2_EQ: 'tCO₂eq',
  },
  FORMULAS: {
    EEOI: GRAMS_CO2_PER_TON_NAUTICAL_MILE,
    AER: GRAMS_CO2_PER_DEAD_WEIGHT_NAUTICAL_MILE,
    CII: GRAMS_CO2_PER_DEAD_WEIGHT_NAUTICAL_MILE,
  },
  CURRENCY: {
    DOLLAR: '$',
    EURO: '€',
  },
  VESSEL_PERFORMANCE: {
    EVENTS_TABLE_DELETE_MODAL: {
      TITLE: 'Delete Event?',
      ACTION_TEXT: 'Are you sure you want to delete this event?',
      MESSAGE_TEXT: 'This action cannot be undone.',
      SECONDARY_BUTTON_TEXT: 'Cancel',
      PRIMARY_BUTTON_TEXT: 'Delete',
    },
    GROUPING_FILTER_CHART: {
      MAX_SUBGROUPS: 10,
      TITLE: 'Choose a Vessel Group to compare data points.',
      SUBTITLE:
        'You may select up to ten (10) subgroups at a time within a Vessel Group. Vessel Groups shown below are determined by your global Vessel Group filter selection in the top navigation bar.',
    },
  },
  ERROR_MESSAGES: {
    NO_DATA: 'No data to display',
    LOADING_DATA: 'Unable to load data',
    NO_DATA_ACTION:
      'Try adjusting your global vessel group filters or date range selection',
    LOADING_DATA_ACTION: 'Try refreshing the page',
    EMPTY_DASHES: '---',
  },
};
