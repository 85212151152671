import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/system/Box';

export interface ILoadingComponentProps {
  height: string;
}

export function LoadingComponent(props: ILoadingComponentProps) {
  const STYLE_PROPS = {
    height: props.height,
    width: '100%',
    margin: 0,
    marginTop: 3,
  };

  return (
    <Box sx={STYLE_PROPS} data-testid='loading-component'>
      <Skeleton
        sx={{ height: '100%', width: '100%' }}
        variant='rounded'
        animation='pulse'
      ></Skeleton>
    </Box>
  );
}
