import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import {
  GET_VESSEL_FOULING_TREND_DATA,
  GET_VESSEL_MESFOC_TREND_DATA,
  GET_VESSEL_POWER_TREND_DATA,
  GET_VESSEL_SPEED_TREND_DATA,
} from '../queries/vessel-trend-chart.query';
import { DateRange } from 'shared/utils/date-utc-helper';

export function useGetVesselPerformanceTrendFoulingData(
  dateRange: DateRange | null,
  vesselImo: string,
  vesselId: string
) {
  const getDateRangeStruct = dateRange?.getDateRangeStruct;
  return useQuery<Query>(GET_VESSEL_FOULING_TREND_DATA, {
    skip: !getDateRangeStruct || !vesselImo || !vesselId,
    variables: {
      ...getDateRangeStruct,
      vesselImo,
      vesselId,
    },
  });
}

export function useGetVesselPerformanceTrendPowerData(
  dateRange: DateRange | null,
  vesselImo: string,
  vesselId: string
) {
  const getDateRangeStruct = dateRange?.getDateRangeStruct;
  return useQuery<Query>(GET_VESSEL_POWER_TREND_DATA, {
    skip: !getDateRangeStruct || !vesselImo || !vesselId,
    variables: {
      ...getDateRangeStruct,
      vesselImo,
      vesselId,
    },
  });
}

export function useGetVesselPerformanceTrendSpeedData(
  dateRange: DateRange | null,
  vesselImo: string,
  vesselId: string
) {
  const getDateRangeStruct = dateRange?.getDateRangeStruct;
  return useQuery<Query>(GET_VESSEL_SPEED_TREND_DATA, {
    skip: !getDateRangeStruct || !vesselImo || !vesselId,
    variables: {
      ...getDateRangeStruct,
      vesselImo,
      vesselId,
    },
  });
}
export function useGetVesselPerformanceTrendSfocData(
  dateRange: DateRange | null,
  vesselImo: string,
  vesselId: string
) {
  const getDateRangeStruct = dateRange?.getDateRangeStruct;
  return useQuery<Query>(GET_VESSEL_MESFOC_TREND_DATA, {
    skip: !getDateRangeStruct || !vesselImo || !vesselId,
    variables: {
      ...getDateRangeStruct,
      vesselImo,
      vesselId,
    },
  });
}
