import {
  Box,
  FormControl,
  InputLabel,
  Select,
  SelectProps,
  SxProps,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectStyles } from './input-styles';
import { ExpandMore } from '@mui/icons-material';
import { theme } from 'styles/theme';
import { MDFFormHelperText } from './form-helper-text.component';

interface FormSelectProps
  extends Omit<SelectProps, 'onChange' | 'onBlur' | 'value'> {
  name: string;
  label: string;
}

// Select field built for Event Modal
export const FormSelect = (props: FormSelectProps) => {
  const { control } = useFormContext();
  const { name, label, required = false, sx, children } = props;

  const getStyles = (error: boolean): SxProps => ({
    ...SelectStyles,
    ...{
      border: `1px solid ${error ? theme.palette.error.main : 'transparent'}`,
    },
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <Box sx={{ width: '100%', ...sx }}>
            <FormControl sx={getStyles(!!error)}>
              <InputLabel id={`${name}-label`} shrink required>
                {label}
              </InputLabel>
              <Select
                label={label}
                id={name}
                labelId={`${name}-label`}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                required={required}
                variant='standard'
                size='small'
                fullWidth={true}
                IconComponent={ExpandMore}
                error={!!error}
              >
                {children}
              </Select>
            </FormControl>
            {error && <MDFFormHelperText errorMessage={error.message} />}
          </Box>
        );
      }}
    />
  );
};
