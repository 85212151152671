import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';

// define the vessel icon size on the map
const vesselIconSize = 20;

// vessel icon symbol
const vesselIcon = new SimpleMarkerSymbol({
  path: 'M14.5,29 23.5,0 14.5,9 5.5,0z',
  color: '#ffffff',
  outline: {
    color: [0, 0, 0, 0.7],
    width: 0.5,
  },
  angle: 180,
  size: vesselIconSize,
});

export { vesselIconSize, vesselIcon };
