import { Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { Section } from 'shared/components/section/section.component';
import { VesselDataMonitorSummaryProps } from '../fleetDataComparison/table/util/fleet-data-comparison-table.util';
import { StatusKpiCard } from 'shared/components/status-kpi-card/status-kpi-card.component';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { constants } from 'routes/vessel-data-monitor/vessel-data-monitor.route';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { StatusCardError } from 'shared/components/status-card/status-card-error.component';
import { TEXT } from 'shared/constants/text';
import { ExportableSections } from 'shared/utils/valid-sections';

export const FleetDataSummary: React.FC<VesselDataMonitorSummaryProps> = ({
  error,
  loading,
  overallPassedScoreCardProps,
  passedCardProps,
  failedCardProps,
  filteredData,
}) => {
  const { setCardData } = useContext(ExcelExportContext);

  const cardData = {
    overallPassedScoreCardProps,
    passedCardProps,
    failedCardProps,
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setCardData(cardData);
  }, [overallPassedScoreCardProps, passedCardProps, failedCardProps]);

  if (loading || filteredData === undefined) {
    return <KpiCardSkeleton kpiCards={[{}, {}, {}]} />;
  }

  let gridRows: EmotionJSX.Element;
  if (error) {
    gridRows = (
      <>
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
          <StatusCardError
            message={`Unable to load ${overallPassedScoreCardProps.title}`}
            subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
          />
        </Grid>
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
          <StatusCardError
            message={`Unable to load ${passedCardProps.title}`}
            subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
          />
        </Grid>
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
          <StatusCardError
            message={`Unable to load ${failedCardProps.title}`}
            subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
          />
        </Grid>
      </>
    );
  } else {
    gridRows = (
      <>
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
          <StatusKpiCard {...overallPassedScoreCardProps} />
        </Grid>
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
          <StatusKpiCard {...passedCardProps} />
        </Grid>
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
          <StatusKpiCard {...failedCardProps} />
        </Grid>
      </>
    );
  }

  return (
    <Section
      title={constants.FDS_SECTION_TITLE}
      id={ExportableSections['VDM-Fleet Data Quality Summary']}
      exportSection={true}
      exportOptions={{ includePDF: false, includeCardData: true }}
      childComponent={
        <Grid container spacing={3} sx={{ marginTop: 0 }}>
          {gridRows}
        </Grid>
      }
    />
  );
};
