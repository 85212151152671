import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ColorBar } from './cii-ratings-color-bar.component';
import { theme } from 'styles/theme';
import { CiiBoundaries } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import { generateBoundaryChartData } from '../../../view-models/cii-ratings-chart.viewModel';

export const LeftColorBar = (props: {
  ciiBoundaries: CiiBoundaries | undefined;
  dataMin: number;
  dataMax: number;
  chartMax: number;
}) => {
  const ciiBoundaries = props.ciiBoundaries;

  if (!ciiBoundaries) {
    return null;
  }

  const ciiBoundariesChartData = generateBoundaryChartData(
    ciiBoundaries,
    props.dataMax,
    props.chartMax
  );
  return (
    <ResponsiveContainer width='99%' height={415}>
      <BarChart
        data={ciiBoundariesChartData}
        margin={{ top: 20, bottom: 60, right: 25, left: 45 }}
      >
        <XAxis
          dataKey={'positionLeft'}
          textAnchor='end'
          domain={[0, 1]}
          type='number'
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='maxValue'
          tickLine={false}
          tick={false}
          tickMargin={10}
          type='number'
          domain={[props.dataMin, props.chartMax]}
          axisLine={false}
          dx={-12}
          width={75}
        />

        <Bar
          dataKey='aValue'
          stackId='a'
          fill={theme.colors?.aRating}
          shape={<ColorBar dataKey='aValue' />}
        ></Bar>
        <Bar
          dataKey='bValue'
          stackId='a'
          fill={theme.colors?.bRating}
          shape={<ColorBar dataKey='bValue' />}
        ></Bar>
        <Bar
          dataKey='cValue'
          stackId='a'
          fill={theme.colors?.cRating}
          shape={<ColorBar dataKey='cValue' />}
        ></Bar>
        <Bar
          dataKey='dValue'
          stackId='a'
          fill={theme.colors?.dRating}
          shape={<ColorBar dataKey='dValue' />}
        ></Bar>
        <Bar
          dataKey='eValue'
          stackId='a'
          fill={theme.colors?.eRating}
          shape={<ColorBar dataKey='eValue' />}
        ></Bar>
        <Bar
          dataKey='topBarValue'
          stackId='a'
          fill={theme.colors.blueGray}
          shape={<ColorBar dataKey='topBarValue' />}
        ></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
