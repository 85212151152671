import { ServiceResult } from 'shared/models/serviceResult.model';
import { useCalculatePlannedItineraryData } from '../repositories/plannedItinerary.repository';
import {
  IPlannedItinerary,
  IPlannedItineraryResults,
} from '../models/plannedItinerary.model';
import { PlannedItineraryResultsMapper } from '../mappers/plannedItinerary.mapper';
import { DateRange } from 'shared/utils/date-utc-helper';
import { ApolloResults } from './fleet.service';

export const useCalculatePlannedItinerary = (
  vesselId: string | undefined,
  dateRange: DateRange | null,
  plannedItinerary: IPlannedItinerary | undefined
): ServiceResult<IPlannedItineraryResults> => {
  const response = useCalculatePlannedItineraryData(
    vesselId,
    dateRange,
    plannedItinerary
  );

  const loading = response?.loading;
  if (loading)
    return ApolloResults.loadingResultObject as ServiceResult<IPlannedItineraryResults>;

  const error = response?.error;
  if (error)
    return ApolloResults.errorResultObject(
      error
    ) as ServiceResult<IPlannedItineraryResults>;
  if (!response.data)
    return ApolloResults.emptyResultObject as ServiceResult<IPlannedItineraryResults>;

  let mappedData: IPlannedItineraryResults | undefined = undefined;
  if (response?.data) {
    mappedData = PlannedItineraryResultsMapper.toDomain(
      response?.data?.vessel?.plannedItineraryCalculations ?? undefined,
      plannedItinerary
    );
  }

  return {
    data: mappedData,
    loading,
    error,
    refetch: response?.refetch,
  };
};
