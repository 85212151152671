import { GridPinnedPosition, GridRenderCellParams } from '@mui/x-data-grid-pro';

import {
  GridColumn,
  GridColumnDef,
  GridCustomHeaderClass,
  GridRAGColumn,
  nameof,
} from 'shared/components/datagrid';
import { TEXT } from 'shared/constants/text';
import { Link, IconButton, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { RenderCellRAG } from 'shared/components/datagrid/DataGrid.component';
import { IPlannedItineraryResults } from 'routes/environmental-monitor/models/plannedItinerary.model';
import { formatLargeNumber, formatNumber } from 'shared/utils/float-utils';
import { MDFTooltip } from 'shared/components/tooltip/tooltip.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';

const SMALL_CELL_WIDTH = 135;
const BIG_CELL_WIDTH = 180;

// Commenting out tooltips until calculations can be done.
// const generateTooltipContent = (
//   data: IPlannedItineraryResults,
//   type: keyof IPlannedItineraryResults
// ) => {
//   switch (type) {
//     case 'itineraryName':
//       return (
//         <Box>
//           <Typography
//             variant='subtitle2'
//             gutterBottom
//             sx={{
//               textAlign: 'left',
//               fontWeight: 'bold',
//               textDecoration: 'underline',
//             }}
//           >
//             Operational Profile Values
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             Duration at Sea (%): {data.durationAtSea}
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             Duration in Port (%): {data.durationInPort}
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             Idle Duration (%): {data.idleDuration}
//           </Typography>
//           <Typography
//             variant='body2'
//             gutterBottom
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             Maneuvering Duration (%): {data.maneuveringDuration}
//           </Typography>
//           <Typography
//             variant='subtitle2'
//             gutterBottom
//             sx={{
//               textAlign: 'left',
//               fontWeight: 'bold',
//               textDecoration: 'underline',
//             }}
//           >
//             Loading Condition Values
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             Time Laden (%): {data.timeLaden}
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             Time Ballast (%): {data.timeBallast}
//           </Typography>
//         </Box>
//       );
//     case 'totalCO2':
//       return (
//         <Box>
//           <Typography
//             variant='subtitle2'
//             gutterBottom
//             sx={{
//               textAlign: 'left',
//               fontWeight: 'bold',
//               textDecoration: 'underline',
//             }}
//           >
//             Granular Distribution Across Operational Profiles
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             CO2 at Sea (%): {data.co2AtSea}
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             CO2 in Port (%): {data.co2InPort}
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             CO2 Idle (%): {data.co2Idle}
//           </Typography>
//           <Typography
//             variant='body2'
//             gutterBottom
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             CO2 Maneuvering (%): {data.co2Maneuvering}
//           </Typography>
//           <Typography
//             variant='subtitle2'
//             gutterBottom
//             sx={{
//               textAlign: 'left',
//               fontWeight: 'bold',
//               textDecoration: 'underline',
//             }}
//           >
//             Granular Distribution Across Loading Condition
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             CO2 Laden (%): {data.co2Laden}
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             CO2 Ballast (%): {data.co2Ballast}
//           </Typography>
//         </Box>
//       );
//     case 'totalDistance':
//       return (
//         <Box>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             Average Speed Laden (kn): {data.avgSpeedLaden}
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             Average Speed Ballast (kn): {data.avgSpeedBallast}
//           </Typography>
//         </Box>
//       );
//     case 'cii':
//       return (
//         <Box>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             YTD CII estimated: {formatNumber(data.ytdCii, 2)}
//           </Typography>
//           <Typography
//             variant='body2'
//             sx={{ textAlign: 'left', fontWeight: 'bold' }}
//           >
//             EOY CII estimated: {formatNumber(data.eoyCii, 2)}
//           </Typography>
//         </Box>
//       );
//     default:
//       return null;
//   }
// };

export const ghgMrvTooltip = ({
  ch4Mrv,
  co2Mrv,
  fuelSlippage,
  estGhgMrv,
  n2OMrv,
}: IPlannedItineraryResults) => {
  const data = [
    {
      label: 'EU CO₂ (t,mrv)',
      value: formatNumber(co2Mrv, 2),
    },
    {
      label: 'EU CH₄ (t,mrv)',
      value: formatNumber(ch4Mrv, 2),
    },
    {
      label: 'EU N₂O (t,mrv)',
      value: formatNumber(n2OMrv, 2),
    },
    {
      label: 'EU Fuel Slip (t,mrv)',
      value: formatNumber(fuelSlippage, 2),
    },
    {
      label: 'Est. YTD GHGmrv\n(tCO₂eq)',
      value: formatNumber(estGhgMrv, 2),
    },
  ];
  return (
    <Grid container direction='column'>
      {data.map(({ label, value }, index) => (
        <Grid key={index} container direction={'row'} spacing={2}>
          <Grid
            item
            width={140}
            sx={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}
          >
            {label}
          </Grid>
          <Grid item xs sx={{ textAlign: 'right' }}>
            {value}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export function getColumnsDefinition(
  featureFlags: {
    [key: string]: boolean;
  },
  handleNameClick: (row: IPlannedItineraryResults) => void,
  handleDeleteClick: (row: IPlannedItineraryResults) => void
): GridColumnDef[] {
  const columns = nameof<IPlannedItineraryResults>; // get the properties from the model

  const ghgMrv_plannedItinerary_FeatureFlagEnabled =
    featureFlags[FEATURE_FLAG.GHG_MRV_PLANNED_ITINERARY];

  const vesselOverviewGhgIntensityWttColumnEnabled =
    featureFlags[
      FEATURE_FLAG
        .VESSEL_OVERVIEW_GHG_INTENSITY_WTW_COLUMN_VESSEL_PLANNED_ITINERARY_GRID
    ];

  const vesselOverviewComplianceBalanceColumnEnabled =
    featureFlags[
      FEATURE_FLAG
        .VESSEL_OVERVIEW_COMPLIANCE_BALANCE_COLUMN_VESSEL_PLANNED_ITINERARY_GRID
    ];

  const vesselOverviewFuelEuPenaltyColumnEnabled =
    featureFlags[
      FEATURE_FLAG
        .VESSEL_OVERVIEW_FUEL_EU_PENALTY_COLUMN_VESSEL_PLANNED_ITINERARY_GRID
    ];

  const commonSection: GridColumnDef[] = [
    GridColumn({
      field: columns('itineraryName'),
      type: 'string',
      minWidth: BIG_CELL_WIDTH,
      align: 'left',
      headerAlign: 'left',
      pinPosition: GridPinnedPosition.left,
      renderCell: (params) => (
        <div data-testid='operationalProfileValues'>
          {/* <MDFTooltip
            CSSProps={{ paddingRight: '5px' }}
            tooltipText={generateTooltipContent(params.row, 'itineraryName')}
          ></MDFTooltip> */}

          <Link
            className='MuiDataGrid-cellContent'
            onClick={() => handleNameClick(params.row)}
          >
            {params.value}
          </Link>
        </div>
      ),
    }),
    GridColumn({
      field: columns('departurePort.Port_Name'),
      headerName: 'First Port',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      minWidth: BIG_CELL_WIDTH,
      valueField: columns('departurePort.Port_Name'),
    }),
    GridColumn({
      field: columns('arrivalPort.Port_Name'),
      headerName: 'Final Port',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      minWidth: BIG_CELL_WIDTH,
      valueField: columns('arrivalPort.Port_Name'),
    }),
    GridColumn({
      field: columns('totalDistance'),
      headerName: `Total Distance (${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`,
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
      renderCell: (params) => (
        <>
          {/* <MDFTooltip
            CSSProps={{ paddingRight: '5px' }}
            tooltipText={generateTooltipContent(params.row, 'totalDistance')}
          ></MDFTooltip> */}
          {formatNumber(params.value, 2)}
        </>
      ),
    }),
    GridColumn({
      field: columns('totalCO2'),
      headerName: `Total CO₂ (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
      renderCell: (params) => (
        <>
          {/* <MDFTooltip
            CSSProps={{ paddingRight: '5px' }}
            tooltipText={generateTooltipContent(params.row, 'totalCO2')}
          ></MDFTooltip> */}
          {formatNumber(params.value, 2)}
        </>
      ),
    }),
    GridColumn({
      field: columns('estimatedCii'),
      headerName: 'Estimated \nCII ',
      noDecimals: 2,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridRAGColumn({
      field: columns('ciiRating'),
      headerName: 'CII \nRating',
      type: 'string',
      minWidth: SMALL_CELL_WIDTH,
      renderCell: (params) => (
        <>
          {/* <MDFTooltip
            CSSProps={{ paddingRight: '5px' }}
            tooltipText={generateTooltipContent(params.row, 'cii')}
          ></MDFTooltip> */}
          <RenderCellRAG {...params}></RenderCellRAG>
        </>
      ),
    }),
    GridColumn({
      field: columns('ciiDelta'),
      headerName: 'CII \nΔ',
      noDecimals: 2,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridRAGColumn({
      field: columns('ciiDeviation'),
      headerName: 'CII \n%',
      type: 'string',
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('eeoi'),
      headerName: `EEOI \n(${TEXT.FORMULAS.EEOI})`,
      noDecimals: 2,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('eeoiDelta'),
      headerName: 'EEOI \nΔ',
      noDecimals: 2,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridRAGColumn({
      field: columns('eeoiDeviation'),
      headerName: 'EEOI \n%',
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('eua'),
      headerName: 'EUA \nLiability',
      noDecimals: 2,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('euaDelta'),
      headerName: 'EUA \nΔ',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridColumn({
      field: columns('euaDeviation'),
      headerName: 'EUA \nAgainst Planned (%)',
      minWidth: SMALL_CELL_WIDTH,
    }),
  ];
  let result = [...commonSection];
  if (ghgMrv_plannedItinerary_FeatureFlagEnabled === true) {
    const ghgMrvSection: GridColumnDef[] = [
      GridColumn({
        field: columns('ghgMrv'),
        headerName:
          'GHGmrv \n(' + TEXT.UNIT_MEASUREMENT.METRIC_TONNES_CO2_EQ + ',TtW)',
        minWidth: SMALL_CELL_WIDTH,
        renderCell: (params) => (
          <>
            {formatLargeNumber(params.value)}
            <MDFTooltip
              CSSProps={{ paddingRight: '5px' }}
              tooltipText={ghgMrvTooltip(
                params.row as IPlannedItineraryResults
              )}
            />
          </>
        ),
        noDecimals: 2,
      }),
      GridColumn({
        field: columns('ghgMrvRemarks'),
        headerName: 'GHG Mrv Remarks',
        type: 'string',
        headerAlign: 'right',
        align: 'right',
        valueField: columns('ghgMrvRemarks'),
        minWidth: BIG_CELL_WIDTH,
        visibility: 'export-only',
        cellClassName: GridCustomHeaderClass.PreLine,
      }),
    ];
    result = result.concat(ghgMrvSection);
  }

  const fuelEuSection: GridColumnDef[] = [
    GridRAGColumn({
      field: columns('ghgStatus.value'),
      valueField: columns('ghgStatus.value'),
      statusField: columns('ghgStatus.status'),
      type: 'number',
      headerName:
        'GHG Intensity \n(' +
        TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE +
        ',\nWtW)',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
      visibility: vesselOverviewGhgIntensityWttColumnEnabled
        ? 'visible'
        : 'hidden',
    }),
    GridColumn({
      field: columns('complianceBalance'),
      headerName:
        'Compliance \nBalance \n(' + TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ + ')',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
      renderCell: (params) => (
        <>
          {/* <MDFTooltip
            CSSProps={{ paddingRight: '5px' }}
            tooltipText={generateTooltipContent(params.row, 'totalDistance')}
          ></MDFTooltip> */}
          {formatNumber(params.value, 2)}
        </>
      ),
      visibility: vesselOverviewComplianceBalanceColumnEnabled
        ? 'visible'
        : 'hidden',
    }),
    GridColumn({
      field: columns('fuelEUPenalty'),
      headerName: 'FuelEU \nPenalty \n(' + TEXT.CURRENCY.EURO + ')',
      minWidth: SMALL_CELL_WIDTH,
      renderCell: (params) => (
        <>
          {/* <MDFTooltip
            CSSProps={{ paddingRight: '5px' }}
            tooltipText={generateTooltipContent(params.row, 'totalDistance')}
          ></MDFTooltip> */}
          {formatLargeNumber(params.value)}
        </>
      ),
      noDecimals: 2,
      visibility: vesselOverviewFuelEuPenaltyColumnEnabled
        ? 'visible'
        : 'hidden',
    }),
  ];

  result = result.concat(fuelEuSection);

  const editSection: GridColumnDef[] = [
    GridColumn({
      field: 'delete',
      headerName: 'Actions',
      visibility: 'screen-only',
      minWidth: SMALL_CELL_WIDTH,
      renderCell: (params: GridRenderCellParams<IPlannedItineraryResults>) => {
        return (
          <IconButton
            aria-label='delete'
            onClick={() => handleDeleteClick(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    }),
  ];
  result = result.concat(editSection);
  return result;
}
