import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import { GET_DATA_LATEST_UPDATED_DATE } from 'shared/queries/DataLatestUpdatedDate.query';

export function useGetDataLatestUpdatedDate() {
  try {
    return useQuery<Query>(GET_DATA_LATEST_UPDATED_DATE);
  } catch (error) {
    console.log(error);
    return null;
  }
}
