import { ReactElement } from 'react';
import { PermissionModule } from 'shared/models/user.model';
import { Root } from '../routes/root/root.route';
import { UnableToLoadRoute } from '../routes/root/unable.to.load.route';
import { useGetFeatureFlag } from 'shared/services/featureFlag.service';
import { LoadingComponent } from 'shared/components/loading/loading.component';

export interface RestrictedRouterProps {
  requiredModule: PermissionModule | null;
  childComponent: ReactElement;
}
interface RestrictedRouterWithModuleProps extends RestrictedRouterProps {
  requiredModule: PermissionModule;
}

const UnableToLoadRouteWithRoot = () => {
  return <Root childComponent={<UnableToLoadRoute />} />;
};

const RestrictedRouterWithModule = (props: RestrictedRouterWithModuleProps) => {
  const { data, loading } = useGetFeatureFlag(props.requiredModule);

  if (data?.isEnabled) return <>{props.childComponent}</>;
  if (loading) return <LoadingComponent height='100vh' />;
  return <UnableToLoadRouteWithRoot />;
};

export const RestrictedRouter = (props: RestrictedRouterProps) => {
  if (!props.requiredModule) return <>{props.childComponent}</>;
  return (
    <RestrictedRouterWithModule
      requiredModule={props.requiredModule}
      childComponent={props.childComponent}
    />
  );
};
