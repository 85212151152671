import { Typography, Box } from '@mui/material';

export const DataValidationsFoundMessage = () => {
  return (
    <>
      <Typography variant='alertTitle'>
        Data Validation Issue(s) Found in Report
      </Typography>
      <Box>
        <Typography variant='body2' color='#EDACAC'>
          Required emissions regulation data has been flagged.
        </Typography>
        <Typography variant='body2' color='#EDACAC'>
          Click "View Details" below to review.
        </Typography>
      </Box>
    </>
  );
};
