import { KpiMonthlyRatingAggregation } from '_gql/graphql';
import { fleetCIIHistoricalType } from '../view-models/cii-ratings-chart.viewModel';
import { FleetCiiDataPoint } from '../models/vessel-cii-rating.model';
import { UTCDate } from 'shared/utils/date-utc-helper';

export class FleetCiiRatingsDataMapper {
  static toDomain(
    data: Array<KpiMonthlyRatingAggregation>
  ): FleetCiiDataPoint[] {
    return toDataPoint(data);
  }

  static categorizingByYear(data: Array<KpiMonthlyRatingAggregation>): {
    [fleetCIIHistoricalType.YTD]: KpiMonthlyRatingAggregation[];
    [fleetCIIHistoricalType.LAST_YEAR_AND_YTD]: KpiMonthlyRatingAggregation[];
  } {
    const currentYear = new UTCDate().year!;
    const categorizedData: {
      [fleetCIIHistoricalType.YTD]: KpiMonthlyRatingAggregation[];
      [fleetCIIHistoricalType.LAST_YEAR_AND_YTD]: KpiMonthlyRatingAggregation[];
    } = {
      [fleetCIIHistoricalType.YTD]: [],
      [fleetCIIHistoricalType.LAST_YEAR_AND_YTD]: [],
    };

    data.forEach((element: KpiMonthlyRatingAggregation) => {
      if (element.year === currentYear - 1) {
        categorizedData.lastYAndYtd.push(element);
      } else if (element.year === currentYear) {
        categorizedData.ytd.push(element);
      }
    });
    return categorizedData;
  }
}

const toDataPoint = (data: Array<KpiMonthlyRatingAggregation>) => {
  return (
    data?.map((info: KpiMonthlyRatingAggregation) => ({
      date: UTCDate.create(info.year, info.month - 1, 1).unixTime ?? 0,
      actual: info.actual ?? false,
      A: info.ratingCount.a ?? 0,
      B: info.ratingCount.b ?? 0,
      C: info.ratingCount.c ?? 0,
      D: info.ratingCount.d ?? 0,
      E: info.ratingCount.e ?? 0,
    })) ?? []
  );
};
