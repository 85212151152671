import { Box, StandardTextFieldProps, SxProps, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from 'styles/theme';
import { InputHelperText } from './inputHelperText.component';
import { InputStyles } from './inputStyles';

interface StyledTextFieldProps extends StandardTextFieldProps {
  name: string;
  label: string;
}

export const StyledTextField = (props: StyledTextFieldProps) => {
  const { control } = useFormContext();
  const { label, name, multiline = false, required = false, sx } = props;

  const getStyles = (error: boolean): SxProps => ({
    ...InputStyles,
    ...{
      border: `1px solid ${error ? theme.palette.error.main : 'transparent'}`,
    },
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <Box sx={{ width: '100%', ...sx }}>
            <TextField
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              required={required}
              sx={getStyles(!!error)}
              variant='standard'
              multiline={multiline}
              fullWidth
              error={!!error}
              label={label}
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              disabled={props.disabled as boolean}
            />
            {error && (
              <InputHelperText
                sx={{ mt: '2px' }}
                errorMessage={error.message}
              />
            )}
          </Box>
        );
      }}
    />
  );
};
