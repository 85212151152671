import { Box, ToggleButton } from '@mui/material';
import { FC } from 'react';
import { CiiDataToggleButtonProps } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import ButtonCircle from 'shared/components/button-circle/button-circle.component';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';

const CiiDataToggleButton: FC<CiiDataToggleButtonProps> = ({
  circleFill,
  selectedColor,
  title,
  name,
  isSelected,
  onClick,
}) => {
  const handleToggle = () => {
    onClick(name, !isSelected);
  };

  return (
    <ToggleButton
      value={title}
      onClick={handleToggle}
      sx={{
        textTransform: 'none',
        paddingY: `${typography.spacing['1']}px`,
        paddingX: `${typography.spacing['2']}px`,
        background: isSelected
          ? selectedColor
          : theme.environmentalMonitor.toggleButton.backgroundColor,
        boxShadow: isSelected
          ? theme.environmentalMonitor.toggleButton.boxShadow
          : 'none',
        border: '0',
        borderRadius: `${typography.shape.borderRadius}px`,
        color: theme.palette.primary.contrastText,
        fontWeight: 500,
        marginRight: '1px',
      }}
      data-testid={`${name}-toggle-button`}
    >
      <div>
        {typeof circleFill === 'string' ? (
          <ButtonCircle fill={circleFill} border={selectedColor} />
        ) : (
          <ButtonCircle
            fill={circleFill.inner}
            border={circleFill.border}
            dashed={circleFill.borderStyle === 'dashed'}
          />
        )}
      </div>
      <Box sx={{ paddingLeft: `${typography.spacing['1']}px` }}>{title}</Box>
    </ToggleButton>
  );
};

export default CiiDataToggleButton;
