import { TypePolicies } from '@apollo/client';
import { selectedAlertsVar } from './apollo.client';
import { ProjectionMetrics } from '_gql/graphql';

export const TypePolicy: TypePolicies = {
  Vessel: {
    fields: {
      calculations: {
        merge: true,
      },
      kpis: {
        merge: true,
      },
    },
  },
  VesselCalculations: {
    fields: {
      cii: {
        merge: true,
      },
    },
  },
  Cii: {
    fields: {
      conditions: {
        merge(existing = [], incoming: ProjectionMetrics[]) {
          return [...existing, ...incoming];
        },
      },
    },
  },
  Query: {
    fields: {
      fleetPerformanceIndicators: {
        merge: true,
      },
      vesselPerformanceIndicators: {
        merge: true,
      },
      isSelected: {
        read() {
          return selectedAlertsVar();
        },
      },
    },
  },
};
