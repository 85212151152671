import { Skeleton } from '@mui/material';

// import React from 'react';
import { MapDrawerBodyLayout } from 'shared/components/esri-map/map-drawer/map-drawer-layout/map-drawer-body-layout';
import { useGetActiveVoyageInfoForDrawerDashboardService } from 'routes/dashboard/services/voyage-info-for-drawer.get.service';
import { Box, Typography } from '@mui/material';
import { MapDrawerAlertComponent } from './alert-component';

export const MapDrawerVesselComponent = () => {
  const { data, loading } = useGetActiveVoyageInfoForDrawerDashboardService();

  // const currentLegSubtitle = data
  //   ? `Leg ${data.legInfo.currentLeg}/${data.legInfo.totalLeg.length}`
  //   : '';

  return !data || loading ? (
    <Skeleton
      sx={{ mt: 3, height: '30vh' }}
      variant='rounded'
      animation='pulse'
      data-testid={'loading-skeleton'}
    ></Skeleton>
  ) : (
    <>
      <MapDrawerAlertComponent
        activeAlertCount={data.activeAlertCount}
      ></MapDrawerAlertComponent>
      <MapDrawerBodyLayout
        // title={'Current Leg - ' + currentLegSubtitle}
        hasBoundary={true}
      >
        <Box
          sx={{
            m: '0px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ flexGrow: 2 }}>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Location</Typography>
              <Typography variant='subtitle2'>{data.location}</Typography>
            </Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Position</Typography>
              <Typography variant='subtitle2'>{data.position}</Typography>
            </Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Heading</Typography>
              <Typography variant='subtitle2'>{data.heading}°</Typography>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 2 }}>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Status</Typography>
              <Typography variant='subtitle2'>{data.status}</Typography>
            </Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Position Received</Typography>
              <Typography variant='subtitle2'>
                {data.lastUpdatedTime}
              </Typography>
            </Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant='subtitle1'>Voyage Number</Typography>
              <Typography variant='subtitle2'>{data.voyageNumber}</Typography>
            </Box>
          </Box>
        </Box>
      </MapDrawerBodyLayout>
    </>
  );
};
