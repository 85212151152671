import Basemap from '@arcgis/core/Basemap';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import Expand from '@arcgis/core/widgets/Expand';

import { PortThemes } from './symbols';
import { ReferenceLayer } from './ReferenceLayer';
import { PORTS_LAYER_ID } from './PortsLayer';
import { BaseMapType } from './constants';
import { MapViewType } from 'shared/models/mapView.context.model';

// basemaps used for the BaseMap Gallery Widget
function basemapsSource() {
  return [
    getBasemap(BaseMapType.dark),
    getBasemap(BaseMapType.imagery),
    getBasemap(BaseMapType.oceans),
  ];
}

export function getBasemap(basemapType: BaseMapType) {
  let basemap: Basemap;
  switch (basemapType) {
    case BaseMapType.dark: {
      basemap = Basemap.fromId('arcgis-dark-gray');
      break;
    }
    case BaseMapType.imagery: {
      basemap = Basemap.fromId('arcgis-imagery');
      break;
    }
    case BaseMapType.oceans: {
      basemap = Basemap.fromId('arcgis-oceans');
      break;
    }
  }

  basemap.title = basemapType;
  return basemap;
}

export const addBaseMapGallery = (view: MapViewType) => {
  // BaseMaps List
  const basemapGallery = new BasemapGallery({
    view: view,
    source: basemapsSource(),
    container: document.createElement('div'),
  });

  function getPortRenderer(newLayer: any) {
    switch (newLayer.title) {
      case BaseMapType.dark: {
        return PortThemes.dark.PortRenderer;
      }
      case BaseMapType.oceans: {
        return PortThemes.light.PortRenderer;
      }
      case BaseMapType.imagery: {
        return PortThemes.imagery.PortRenderer;
      }
    }

    return PortThemes.dark.PortRenderer;
  }

  basemapGallery.watch('activeBasemap', function (newLayer) {
    // Reference Layer Visibility
    const isRefLayerVisible = view.map?.findLayerById(
      ReferenceLayer.REFERENCE_LAYER_ID
    )?.visible;
    if (
      newLayer &&
      newLayer.referenceLayers.items?.length &&
      newLayer.referenceLayers.items[0]
    ) {
      newLayer.referenceLayers.items[0].visible = isRefLayerVisible;
    }
    // Port Layer Renderer Change
    const PortLayer = view.map?.findLayerById(PORTS_LAYER_ID) as GeoJSONLayer;
    if (PortLayer && newLayer) {
      PortLayer.renderer = getPortRenderer(newLayer);
    }
  });
  // Basemaps List button
  const bgExpand = new Expand({
    view: view,
    content: basemapGallery,
    group: 'top-right-group',
  });
  // Add the expand instance to the ui
  view.ui.add(bgExpand, 'top-right');
};
