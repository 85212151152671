import { Close, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { RequiredAsterisk } from 'shared/components/required-asterisk/requiredAsterisk.component';
import { EventModalValues } from './models/events.model';
import { EventModalForm } from './event-modal-form.component';
import { WarningMessage } from 'shared/components/warning-message/warning-message';

interface EventModalProps {
  editEvent?: boolean; // Boolean used to indicate whether this Modal is meant to be an Add or Edit
  values?: EventModalValues;
}

export const EventModal = (props: EventModalProps) => {
  const { editEvent, values } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [hasSaveErrors, setHasSaveErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '900px',
    maxHeight: '900px',
    bgcolor: theme.background.component,
    borderRadius: '8px',
    border: 'none',
    outline: 'none',
  };

  const LoadingOverlay = () => (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: theme.validatedModal.loadingOverlayBackground,
        borderRadius: 'inherit',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );

  return (
    <Box>
      {editEvent ? (
        <IconButton
          color='default'
          sx={{ alignSelf: 'flex-start' }}
          onClick={handleOpen}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          variant='contained'
          disableElevation
          sx={{ ml: 2, mr: 1, textTransform: 'none' }}
        >
          Add Event +
        </Button>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          {isLoading && <LoadingOverlay />}
          <Box sx={{ p: 3 }}>
            <Box sx={{ pb: 1.5 }}>
              <Box sx={{ height: '24px', display: 'flex', width: '100%' }}>
                <Typography sx={{ fontSize: '20px' }}>
                  {editEvent ? 'Edit' : 'Add'} Event
                </Typography>
                <IconButton
                  sx={{
                    width: '24px',
                    height: '24px',
                    marginLeft: 'auto',
                  }}
                  onClick={handleClose}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
            {hasSaveErrors && (
              <WarningMessage
                sx={{ py: 1.5 }}
                title='Unable to Save Event'
                details='Please ensure all required fields are filled out.'
              />
            )}
            <Box
              sx={{
                color: theme.palette.text.secondary,
                py: 1.5,
                fontSize: '12px',
              }}
            >
              <RequiredAsterisk /> Indicates a required field
            </Box>
            <EventModalForm
              editEvent={!!editEvent}
              closeCallback={handleClose}
              errorCallback={setHasSaveErrors}
              loadingCallback={setIsLoading}
              values={values}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
