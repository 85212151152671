import {
  AerKpi,
  CiiKpi,
  EeoiKpi,
  EuaAgainstFleetKpi,
  EuaAgainstPlannedKpi,
  FuelEuKpi,
  GhgMrvKpi,
  Scalars,
  VesselKpis,
} from '_gql/graphql';
import { formatFloat, formatNumber } from 'shared/utils/float-utils';
import { toStatusEnum } from 'shared/utils/status-utils';
import {
  IVesselKpiDomain,
  KeyValuePairOfEmissionConfiguration,
  KeyValuePairOfStringAndDouble,
  VesselKpiDomain,
  FuelSlipKey,
  EmissionsType,
} from '../models/vessel-kpi.model';
import { TEXT } from 'shared/constants/text';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { KpiData } from 'shared/components/status-kpi-card/status-kpi-card.component';
import { PieChartValueOptions } from 'shared/components/piechart-straightangle/piechart';
import { IFleetKpiDomain } from '../models/fleet-kpi.model';
import { Status } from '../models/gql/vessel-kpi-gql.model';

type GhgMrvSummaryChart = {
  data: PieChartValueOptions[];
  status: Status;
};

const formatWithUnit = (value: any, unit: string) => {
  const result = formatNumber(value);
  if (result === TEXT.ERROR_MESSAGES.EMPTY_DASHES) return result;
  return result + unit;
};

export const formatWithPercentage = (value: any) => formatWithUnit(value, '%');

export class VesselKPIDataMapper {
  static fromDTO(dto: VesselKpis | undefined): VesselKpiDomain {
    return {
      aer: VesselKPIDataMapper.toAerDomain(dto?.aer ?? undefined),
      ciiRating: VesselKPIDataMapper.toCiiDomain(dto?.cii ?? undefined),
      ciiYearToDate: VesselKPIDataMapper.toCiiDomain(dto?.ciiYtd ?? undefined),
      ciiForecast: VesselKPIDataMapper.toCiiDomain(dto?.ciiEoy ?? undefined),
      eeoi: VesselKPIDataMapper.toEeoiDomain(dto?.eeoi ?? undefined),
      euaAgainstFleet: VesselKPIDataMapper.toEuaAgainstFleetDomain(
        dto?.euaAgainstFleet ?? undefined
      ),
      euaAgainstPlanned: VesselKPIDataMapper.toEuaAgainstPlannedDomain(
        dto?.euaAgainstPlanned ?? undefined
      ),
      fuelEuGHGIntensity: VesselKPIDataMapper.toFuelEuVesselGHGIntensityDomain(
        dto?.fuelEU ?? undefined
      ),
      wellToTank: formatNumber(dto?.fuelEU?.wellToTank, 2),
      tankToWake: formatNumber(dto?.fuelEU?.tankToWake, 2),
      windRewardFactor: formatNumber(dto?.fuelEU?.windRewardFactor, 2),
      ghgMrvTotal: VesselKPIDataMapper.toGhgMrvTotalDomain(
        dto?.ghgMrv ?? undefined
      )!,
      ghgMrvFuelEmissions: VesselKPIDataMapper.toGhgMrvFuelEmissions(
        dto?.ghgMrv ?? undefined
      )!,
      ghgMrvFuelSlippageTotal: VesselKPIDataMapper.toGhgMrvFuelSlipDomain(
        dto?.ghgMrv ?? undefined
      )!,
      complianceBalance: formatNumber(dto?.fuelEU?.complianceBalance),
      complianceBalanceFleet:
        VesselKPIDataMapper.toComplianceBalanceFleetDomain(
          dto?.fuelEU ?? undefined
        ),
      fuelEUPenalty: VesselKPIDataMapper.toFuelEuPenaltyDomain(
        dto?.fuelEU ?? undefined
      ),
    };
  }

  private static toAerDomain(dto: AerKpi | undefined): KpiData {
    return {
      primaryValue: formatWithPercentage(dto?.deviation),
      secondaryValue: formatFloat(dto?.attained ?? 0, false),
      tertiaryValue: formatFloat(dto?.required ?? 0, false),
      quaternaryValue: TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      status: toStatusEnum(dto?.status),
    };
  }

  private static toCiiDomain(dto: CiiKpi | undefined): KpiData {
    let quaternaryValue = formatWithPercentage(dto?.deviation);
    let primaryValue = dto?.ciiRating ?? NA_VALUE_INDICATOR;
    if (primaryValue === NA_VALUE_INDICATOR || primaryValue === '') {
      primaryValue = TEXT.ERROR_MESSAGES.EMPTY_DASHES;
      quaternaryValue = TEXT.ERROR_MESSAGES.EMPTY_DASHES;
    }
    return {
      primaryValue: primaryValue,
      secondaryValue: formatFloat(dto?.attained ?? 0, false),
      tertiaryValue: formatFloat(dto?.required ?? 0, false),
      quaternaryValue: quaternaryValue,
      status: toStatusEnum(dto?.status),
    };
  }

  private static toEeoiDomain(dto: EeoiKpi | undefined): KpiData {
    return {
      primaryValue: formatWithPercentage(dto?.deviation),
      secondaryValue: formatFloat(dto?.actual ?? 0, false),
      tertiaryValue: formatFloat(dto?.target ?? 0, false),
      quaternaryValue: TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      status: toStatusEnum(dto?.status),
    };
  }

  private static toEuaAgainstFleetDomain(
    dto: EuaAgainstFleetKpi | undefined
  ): KpiData {
    return {
      primaryValue: `${
        dto?.value === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.value
          ? dto?.value?.toFixed() + '%'
          : NA_VALUE_INDICATOR
      }`,
      secondaryValue:
        dto?.vessel === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.vessel
          ? dto?.vessel?.toFixed()
          : NA_VALUE_INDICATOR,
      tertiaryValue:
        dto?.fleet === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.fleet
          ? dto?.fleet?.toFixed()
          : NA_VALUE_INDICATOR,
      quaternaryValue: '',
      status: toStatusEnum(dto?.status),
    };
  }

  private static toEuaAgainstPlannedDomain(
    dto: EuaAgainstPlannedKpi | undefined
  ): KpiData {
    return {
      primaryValue:
        dto?.value === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.value
          ? dto?.value?.toFixed()?.toString() + '%'
          : NA_VALUE_INDICATOR,
      secondaryValue:
        dto?.value === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.vesselEuaLiability
          ? dto?.vesselEuaLiability.toFixed()
          : NA_VALUE_INDICATOR,
      tertiaryValue:
        dto?.vesselEuaPlannedValue?.toString() ??
        TEXT.ERROR_MESSAGES.EMPTY_DASHES, //this is the eua planned value for the vessel
      quaternaryValue:
        JSON.stringify(dto?.thresholds) ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      status: toStatusEnum(dto?.status),
    };
  }

  private static toFuelEuVesselGHGIntensityDomain(
    dto: FuelEuKpi | undefined
  ): KpiData {
    return {
      primaryValue: dto?.ghgIntensity
        ? formatNumber(dto?.ghgIntensity, 2)
        : TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      secondaryValue: dto?.complianceBalance
        ? formatNumber(dto?.complianceBalance)
        : TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      tertiaryValue: formatNumber(dto?.fuelPenalty),
      status: toStatusEnum(dto?.ghgIntensityStatus),
    };
  }

  public static toGhgMrvTotalDomain(
    dto: GhgMrvKpi | undefined
  ): Scalars['Float'] | undefined {
    const sum = dto?.ghgByYear?.map((v) => v.value).reduce((a, b) => a + b, 0);
    return sum;
  }

  public static toGhgMrvFuelEmissions(
    dto: GhgMrvKpi | undefined
  ): KeyValuePairOfStringAndDouble[] {
    const retValue: KeyValuePairOfStringAndDouble[] = [];
    const uniqueEmissions = Array.from(
      new Set(dto?.emissionsByYear?.map((item) => item.key.emission))
    );
    uniqueEmissions?.forEach((emission) =>
      retValue.push({
        key: emission!,
        value: VesselKPIDataMapper.toGhgMrvByEmissionDomain(dto, emission!)!,
      })
    );
    return retValue;
  }

  private static toGhgMrvByEmissionDomain(
    dto: GhgMrvKpi | undefined,
    emission: string
  ): Scalars['Float'] | undefined {
    const sum: Scalars['Float'] | undefined = dto?.emissionsByYear
      ?.filter((v) => v.key.emission === emission)
      .map((v) => v.value)
      .reduce(function (a: Scalars['Float'], b: Scalars['Float']) {
        return a + b;
      });
    return sum;
  }
  public static toGhgMrvFuelSlipDomain(
    dto: GhgMrvKpi | undefined
  ): Scalars['Float'] | undefined {
    const sum = dto?.fuelSlippageByYear
      ?.map((v) => v.value)
      .reduce((a, b) => a + b, 0);
    return sum;
  }

  public static toGhgMrvSummaryChartDomain(
    data: IVesselKpiDomain | IFleetKpiDomain | undefined
  ): GhgMrvSummaryChart {
    const retValue: PieChartValueOptions[] = [];

    const configByKey: KeyValuePairOfEmissionConfiguration[] = [
      {
        key: EmissionsType.CO2,
        value: { display: 'CO₂', color: '#8F80CF', unit: 't' },
      },
      {
        key: EmissionsType.N2O,
        value: { display: 'N₂O', color: '#327286', unit: 't' },
      },
      {
        key: EmissionsType.CH4,
        value: { display: 'CH₄', color: '#449BB4', unit: 't' },
      },
      {
        key: FuelSlipKey,
        value: { display: 'Fuel Slip', color: '#9AB8C1', unit: 't' },
      },
    ];
    configByKey
      .filter((config) => config.key !== FuelSlipKey)
      .forEach((x) => {
        const value = data?.ghgMrvFuelEmissions.filter(
          (v) => v.key === x.key
        )[0]?.value;
        retValue.push({
          name: x.value.display,
          value: value,
          color: x.value.color,
          unit: x.value.unit,
        });
      });

    const selection = configByKey.filter((v) => v.key === FuelSlipKey)[0]
      ?.value;

    retValue.push({
      name: selection?.display ?? '',
      value: data?.ghgMrvFuelSlippageTotal ?? 0,
      color: selection?.color ?? '',
      unit: selection?.unit ?? '',
    });

    const ghgMrvSummaryChart: GhgMrvSummaryChart = {
      data: retValue,
      status:
        retValue.reduce((sum, el) => (sum += el.value ?? 0), 0) > 0
          ? Status.graph
          : Status.none,
    };

    return ghgMrvSummaryChart;
  }

  private static toComplianceBalanceFleetDomain(
    dto: FuelEuKpi | undefined
  ): KpiData {
    return {
      primaryValue: formatWithPercentage(
        dto?.complianceBalanceAgainstFleetPercentage
      ),
      secondaryValue: formatNumber(dto?.complianceBalance ?? 0),
      tertiaryValue: formatNumber(dto?.fleetComplianceBalance ?? 0),
      quaternaryValue: TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      status: toStatusEnum(dto?.complianceBalanceAgainstFleetStatus),
    };
  }

  private static toFuelEuPenaltyDomain(dto: FuelEuKpi | undefined): KpiData {
    return {
      primaryValue: formatWithPercentage(
        dto?.fuelPenaltyAgainstFleetPercentage
      ),
      secondaryValue: formatNumber(dto?.fuelPenalty),
      tertiaryValue: formatNumber(dto?.fleetFuelPenalty),
      quaternaryValue: TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      status: dto?.fuelPenaltyAgainstFleetPercentage
        ? Status.graph
        : Status.none,
    };
  }
}
