import { useQuery } from '@apollo/client';
import { Query } from '_gql/graphql';
import {
  GET_FEATURE_FLAG,
  GET_FEATURE_FLAGS,
} from '../queries/featureFlag.query';

export function useFeatureFlagData(featureFlag: string) {
  try {
    return useQuery<Query>(GET_FEATURE_FLAG, {
      variables: { featureFlag: featureFlag },
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

// New function for querying multiple feature flags
export function useFeatureFlagsData(featureFlags: string[]) {
  try {
    return useQuery<Query>(GET_FEATURE_FLAGS, {
      variables: { featureFlags: featureFlags },
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}
