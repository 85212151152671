import { Component } from 'react';
import { BarProps } from 'recharts';

export class ColorBar extends Component<BarProps> {
  override render() {
    const { x, y, height, fill } = this.props;
    return (
      <rect
        data-testid='color-bar'
        x={x}
        y={y}
        width={8}
        height={height}
        fill={fill}
      />
    );
  }
}
