import {
  FormControl,
  ToggleButtonGroup,
  Typography,
  ToggleButton,
} from '@mui/material';
import { FC } from 'react';
import ButtonCircle from 'shared/components/button-circle/button-circle.component';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';
import { FilterValue } from './filter.hook';

export interface FilterProps {
  activeFilters: FilterValue[];
  handleToggle: (filterValues: FilterValue[]) => void;
}

export const RAGFilter: FC<FilterProps> = ({ activeFilters, handleToggle }) => {
  return (
    <FormControl
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '20px',
        alignItems: 'baseline',
        marginBottom: `${typography.spacing['4']}px`,
      }}
    >
      <Typography
        sx={{
          fontSize: typography.fontSize['5'],
          fontWeight: typography.fontWeights['roboto-4'],
          color: theme.palette.primary.contrastText,
          mr: '12px',
        }}
      >
        Filter by:
      </Typography>
      <ToggleButtonGroup
        color='primary'
        value={activeFilters}
        onChange={(_, value) => handleToggle(value)}
        aria-label='filter button group'
        sx={{
          gap: '16px',
          mt: '20px',
          display: 'flex',
        }}
      >
        <ToggleButton
          value={FilterValue.ON_TARGET}
          selected={activeFilters.includes(FilterValue.ON_TARGET)}
        >
          <ButtonCircle
            fill={theme.palette.success.main}
            border={theme.palette.success.main}
          />
          <Typography
            variant='menuItem'
            sx={{
              color: theme.palette.primary.contrastText,
              fontSize: typography.fontSize['5'],
              paddingLeft: `${typography.spacing['1']}px`,
            }}
          >
            On-Target
          </Typography>
        </ToggleButton>

        <ToggleButton
          value={FilterValue.AT_RISK}
          selected={activeFilters.includes(FilterValue.AT_RISK)}
        >
          <ButtonCircle
            fill={theme.palette.warning.main}
            border={theme.palette.warning.main}
          />
          <Typography
            variant='menuItem'
            sx={{
              color: theme.palette.primary.contrastText,
              fontSize: typography.fontSize['5'],
              paddingLeft: `${typography.spacing['1']}px`,
            }}
          >
            At Risk
          </Typography>
        </ToggleButton>

        <ToggleButton
          value={FilterValue.WARNING}
          selected={activeFilters.includes(FilterValue.WARNING)}
        >
          <ButtonCircle
            fill={theme.palette.error.main}
            border={theme.palette.error.main}
          />
          <Typography
            variant='menuItem'
            sx={{
              color: theme.palette.primary.contrastText,
              fontSize: typography.fontSize['5'],
              paddingLeft: `${typography.spacing['1']}px`,
            }}
          >
            Warning
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </FormControl>
  );
};
