import { gql } from '@apollo/client';

export const GET_GROUP_PENALTY_CHART = gql`
  query GroupPenaltyChartQuery(
    $dateRange: DateRangeInput!
    $globalGroupSelection: GlobalGroupSelectionInput!
  ) {
    vesselGroupPenalties(
      dateRange: $dateRange
      globalGroupSelection: $globalGroupSelection
    ) {
      groupId
      groupName
      vesselGroupingPenaltyIndicators {
        timestamp
        averageCO2Penalty
        averageFuelPenalty
        vesselPenaltyIndicators {
          vesselImo
          fuelPenalty
          co2Penalty
        }
      }
    }
  }
`;
