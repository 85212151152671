import { styled } from '@mui/material';
import Toolbar, { ToolbarProps } from '@mui/material/Toolbar';

interface ToolbarPropsTests extends ToolbarProps {
  open?: boolean;
}

export const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ToolbarPropsTests>(({ theme }) => ({
  backgroundColor: theme.background.component,
  height: theme.topNavigationOptions.height,
}));
