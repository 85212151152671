import {
  VesselEvent,
  VesselPerformanceIndicators,
  VpmThresholdChartBands,
} from '_gql/graphql';
import {
  TrendEvent,
  TrendItem,
  TrendItemDetail,
  VesselPerformanceTrend,
  BoundaryChartData,
} from '../models/vessel-performance-trends.model';
import { formatNumberForDisplay } from 'shared/utils/display-utils';
import { getTrendLineData } from 'shared/utils/trend-chart-utils';
import { DateRange } from 'shared/utils/date-utc-helper';

export type SuperMaybe<T> = T | null | undefined;

export type VPI =
  | VesselPerformanceIndicators['foulingResistance']
  | VesselPerformanceIndicators['deltaPower']
  | VesselPerformanceIndicators['deltaSpeed']
  | VesselPerformanceIndicators['mesfoc'];

export type VesselTrendChartMapperParams = {
  vesselPerformanceIndicator: VPI;
  vesselEvents: SuperMaybe<VesselEvent[]>;
  maxValue: number;
  dateRange: DateRange | null;
};

export class VesselTrendChartMapper {
  public static foulingResistanceToDomain = (dto: {
    vesselEvents?: SuperMaybe<VesselEvent[]>;
    foulingResistance?: SuperMaybe<
      VesselPerformanceIndicators['foulingResistance']
    >;
    dateRange: DateRange | null;
  }): VesselPerformanceTrend => {
    const { foulingResistance, vesselEvents } = dto;

    const trendItems = this.mapFoulingResistanceTrendItems(foulingResistance);

    const maxValue = trendItems
      ? Math.max.apply(
          null,
          trendItems.map((x) => x.y)
        )
      : 0;

    const { boundaryChartData, referenceAreaData, events } =
      this.getSharedValues({
        vesselPerformanceIndicator: foulingResistance,
        vesselEvents,
        maxValue,
        dateRange: dto.dateRange,
      });

    const adjustedStartDate = dto.dateRange?.startDate;
    const adjustedEndDate = dto.dateRange?.endDate;
    const trendLineValues = getTrendLineData(
      trendItems,
      events,
      adjustedStartDate,
      adjustedEndDate,
      boundaryChartData[0]?.domainY
    );

    const performanceTrendData: VesselPerformanceTrend = {
      primary: this.filterChartItems(trendItems, false, false),
      missing: this.filterChartItems(trendItems, true, null),
      out: this.filterChartItems(trendItems, false, true),
      events: events,
      trends: trendLineValues,
      boundary: boundaryChartData,
      referenceArea: referenceAreaData,
    };

    return performanceTrendData;
  };

  public static deltaPowerToDomain = (dto: {
    vesselEvents?: SuperMaybe<VesselEvent[]>;
    deltaPower?: SuperMaybe<VesselPerformanceIndicators['deltaPower']>;
    dateRange: DateRange | null;
  }): VesselPerformanceTrend => {
    const { deltaPower, vesselEvents } = dto;

    const trendItems = this.mapDeltaPowerTrendItems(deltaPower);

    const maxValue = trendItems
      ? Math.max.apply(
          null,
          trendItems.map((x) => x.y)
        )
      : 0;

    const { boundaryChartData, referenceAreaData, events } =
      this.getSharedValues({
        vesselPerformanceIndicator: deltaPower,
        vesselEvents,
        maxValue,
        dateRange: dto.dateRange,
      });

    const adjustedStartDate = dto.dateRange?.startDate;
    const adjustedEndDate = dto.dateRange?.endDate;
    const trendLineValues = getTrendLineData(
      trendItems,
      events,
      adjustedStartDate,
      adjustedEndDate,
      boundaryChartData[0]?.domainY
    );

    const performanceTrendData: VesselPerformanceTrend = {
      primary: this.filterChartItems(trendItems, false, false),
      missing: this.filterChartItems(trendItems, true, null),
      out: this.filterChartItems(trendItems, false, true),
      events: events,
      trends: trendLineValues,
      boundary: boundaryChartData,
      referenceArea: referenceAreaData,
    };

    return performanceTrendData;
  };

  public static deltaSpeedToDomain = (dto: {
    vesselEvents: SuperMaybe<VesselEvent[]>;
    deltaSpeed: SuperMaybe<VesselPerformanceIndicators['deltaSpeed']>;
    dateRange: DateRange | null;
  }): VesselPerformanceTrend => {
    const { deltaSpeed, vesselEvents } = dto;

    const trendItems = this.mapDeltaSpeedTrendItems(deltaSpeed);

    const maxValue = trendItems
      ? Math.min.apply(
          null,
          trendItems.map((x) => x.y)
        )
      : 0;

    const { boundaryChartData, referenceAreaData, events } =
      this.getSharedValues({
        vesselPerformanceIndicator: deltaSpeed,
        vesselEvents,
        maxValue,
        dateRange: dto.dateRange,
      });

    const adjustedStartDate = dto.dateRange?.startDate;
    const adjustedEndDate = dto.dateRange?.endDate;
    const trendLineValues = getTrendLineData(
      trendItems,
      events,
      adjustedStartDate,
      adjustedEndDate,
      boundaryChartData[0]?.domainY
    );

    const performanceTrendData: VesselPerformanceTrend = {
      primary: this.filterChartItems(trendItems, false, false),
      missing: this.filterChartItems(trendItems, true, null),
      out: this.filterChartItems(trendItems, false, true),
      events: events,
      trends: trendLineValues,
      boundary: boundaryChartData,
      referenceArea: referenceAreaData,
      chartIsNegative: true,
    };

    return performanceTrendData;
  };

  public static mesfocToDomain = (dto: {
    vesselEvents: SuperMaybe<VesselEvent[]>;
    mesfoc: SuperMaybe<VesselPerformanceIndicators['mesfoc']>;
    dateRange: DateRange | null;
  }): VesselPerformanceTrend => {
    const { mesfoc, vesselEvents } = dto;

    const trendItems = this.mapMesfocTrendItems(mesfoc);

    const maxValue = trendItems
      ? Math.max.apply(
          null,
          trendItems.map((x) => x.y)
        )
      : 0;

    const { boundaryChartData, referenceAreaData, events } =
      this.getSharedValues({
        vesselPerformanceIndicator: mesfoc,
        vesselEvents,
        maxValue,
        dateRange: dto.dateRange,
      });

    const adjustedStartDate = dto.dateRange?.startDate;
    const adjustedEndDate = dto.dateRange?.endDate;

    const trendLineValues = getTrendLineData(
      trendItems,
      events,
      adjustedStartDate,
      adjustedEndDate,
      boundaryChartData[0]?.domainY
    );

    const performanceTrendData: VesselPerformanceTrend = {
      primary: this.filterChartItems(trendItems, false, false),
      missing: this.filterChartItems(trendItems, true, null),
      out: this.filterChartItems(trendItems, false, true),
      events: events,
      trends: trendLineValues,
      boundary: boundaryChartData,
      referenceArea: referenceAreaData,
    };

    return performanceTrendData;
  };

  private static getSharedValues(params: VesselTrendChartMapperParams) {
    const { vesselPerformanceIndicator, vesselEvents, maxValue, dateRange } =
      params;
    const { baseLine, thresholdLow, thresholdMedium, thresholdHigh } =
      this.getThresholdChartBands(
        vesselPerformanceIndicator?.thresholdChartBands
      );

    const chartMax =
      thresholdHigh > 0 // positive chart
        ? Math.ceil(maxValue < thresholdHigh ? maxValue : thresholdHigh)
        : Math.floor(maxValue > thresholdHigh ? maxValue : thresholdHigh);

    const lowBandValue = thresholdLow - baseLine;
    const midBandValue = thresholdMedium - thresholdLow;
    const highBandValue = chartMax - thresholdMedium;

    let boundaryChartData: BoundaryChartData[];
    if (thresholdHigh > 0) {
      boundaryChartData = [
        {
          positionLeft: 0,
          positionRight: 1,
          domainY: [baseLine, chartMax],
          maxValue: chartMax,
          aKey: 'A',
          aValue: lowBandValue,
          bKey: 'B',
          bValue: midBandValue,
          cKey: 'C',
          cValue: highBandValue,
        },
      ];
    } else {
      boundaryChartData = [
        {
          positionLeft: 0,
          positionRight: 1,
          domainY: [chartMax, baseLine],
          maxValue: baseLine,
          aKey: 'A',
          aValue: lowBandValue,
          bKey: 'B',
          bValue: midBandValue,
          cKey: 'C',
          cValue: highBandValue,
        },
      ];
    }

    const referenceAreaData = [
      {
        position: 'left',
        aValue: lowBandValue,
        bValue: midBandValue,
        cValue: highBandValue,
      },
      {
        position: 'right',
        aValue: lowBandValue,
        bValue: midBandValue,
        cValue: highBandValue,
      },
    ];

    const eventsValues = vesselEvents ?? [];
    const events: TrendEvent[] = eventsValues.map((event) => ({
      id: event.id ?? '',
      x:
        dateRange?.startDate?.unixTime! > event.startDate?.unixTime!
          ? dateRange?.startDate?.unixTime
          : event.startDate?.unixTime,
      x2:
        dateRange?.endDate?.unixTime! < event.endDate?.unixTime!
          ? dateRange?.endDate?.unixTime
          : event.endDate?.unixTime,
      eventName: event.eventName ?? '',
      eventType: event.eventType ?? '',
    }));

    return {
      boundaryChartData,
      referenceAreaData,
      events,
    };
  }

  private static getThresholdChartBands(
    thresholdChartBands: SuperMaybe<VpmThresholdChartBands>
  ) {
    const baseLine = thresholdChartBands?.baseLine ?? 0;
    const thresholdLow = thresholdChartBands?.low ?? 30;
    const thresholdMedium = thresholdChartBands?.medium ?? 60;
    const thresholdHigh = thresholdChartBands?.high ?? 100;
    return { baseLine, thresholdLow, thresholdMedium, thresholdHigh };
  }

  private static validateThreshold(
    value: number,
    min: number,
    max: number,
    isNegative?: boolean
  ) {
    if (isNegative) {
      if (value >= max && value <= min) {
        const isOutlier = false;
        const actualY = value;
        return { isOutlier, actualY };
      } else {
        const isOutlier = true;
        if (value < max) {
          const actualY = max;
          return { isOutlier, actualY };
        }
        const actualY = min;
        return { isOutlier, actualY };
      }
    }

    if (value >= min && value <= max) {
      const isOutlier = false;
      const actualY = value;
      return { isOutlier, actualY };
    } else {
      const isOutlier = true;
      if (value < min) {
        const actualY = min;
        return { isOutlier, actualY };
      }
      const actualY = max;
      return { isOutlier, actualY };
    }
  }

  private static filterChartItems(
    trendItems: TrendItem[],
    isMissingData: boolean | null,
    isOutlier: boolean | null
  ): TrendItem[] {
    return trendItems.filter(
      (item) =>
        item.isMissingData === (isMissingData ?? item.isMissingData) &&
        item.isOutlier === (isOutlier ?? item.isOutlier)
    );
  }

  private static mapFoulingResistanceTrendItems(
    foulingResistance: VesselPerformanceIndicators['foulingResistance']
  ): TrendItem[] {
    const trendItems = [];
    const timeSeriesValues = foulingResistance?.timeSeriesValues ?? [];
    const { baseLine, thresholdHigh } = this.getThresholdChartBands(
      foulingResistance?.thresholdChartBands
    );
    for (const i in timeSeriesValues) {
      const item = timeSeriesValues[i];
      if (!item) break;
      const value = item.rahFoulingPercent ?? 0;
      const { actualY } = this.validateThreshold(
        value,
        baseLine,
        thresholdHigh
      );
      const x = item.timestamp?.unixTime!;
      const y = actualY;
      const detail: TrendItemDetail = {
        expected: formatNumberForDisplay(
          (item.rswCalmWaterResistance ?? 0) / 1000
        ),
        expectedUnit: 'kN',
        mePower: formatNumberForDisplay(item.chartDetail.mePower),
        sog: formatNumberForDisplay(item.chartDetail.sog),
        stw: formatNumberForDisplay(item.chartDetail.stw),
        meanDraft: formatNumberForDisplay(item.chartDetail.meanDraft),
        beaufortScale: item.chartDetail.beaufortScale,
        fuelMGOEq: formatNumberForDisplay(item.chartDetail.fuelMGOEq),
      };
      const isMissingData = item.isMissingData;

      trendItems.push({
        x: x,
        y: y,
        value: value,
        isMissingData: isMissingData,
        isOutlier: !item.isValid,
        detail: detail,
      });
    }
    return trendItems;
  }

  private static mapDeltaPowerTrendItems(
    deltaPower: VesselPerformanceIndicators['deltaPower']
  ): TrendItem[] {
    const trendItems = [];
    const timeSeriesValues = deltaPower?.timeSeriesValues ?? [];
    const { baseLine, thresholdHigh } = this.getThresholdChartBands(
      deltaPower?.thresholdChartBands
    );
    for (const i in timeSeriesValues) {
      const item = timeSeriesValues[i];
      if (!item) break;
      const value = item.deltaPower ?? 0;
      const { actualY } = this.validateThreshold(
        value,
        baseLine,
        thresholdHigh
      );
      const x = item.timestamp?.unixTime!;
      const y = actualY;
      const detail: TrendItemDetail = {
        expected: formatNumberForDisplay(item.powerExpected),
        expectedUnit: 'kW',
        mePower: formatNumberForDisplay(item.chartDetail.mePower),
        sog: formatNumberForDisplay(item.chartDetail.sog),
        stw: formatNumberForDisplay(item.chartDetail.stw),
        meanDraft: formatNumberForDisplay(item.chartDetail.meanDraft),
        beaufortScale: item.chartDetail.beaufortScale,
        fuelMGOEq: formatNumberForDisplay(item.chartDetail.fuelMGOEq),
      };
      const isMissingData = item.isMissingData;

      trendItems.push({
        x: x,
        y: y,
        value: value,
        isMissingData: isMissingData,
        isOutlier: !item.isValid,
        detail: detail,
      });
    }

    return trendItems;
  }

  private static mapDeltaSpeedTrendItems(
    deltaSpeed: VesselPerformanceIndicators['deltaSpeed']
  ): TrendItem[] {
    const trendItems = [];
    const timeSeriesValues = deltaSpeed?.timeSeriesValues ?? [];
    const { baseLine, thresholdHigh } = this.getThresholdChartBands(
      deltaSpeed?.thresholdChartBands
    );

    for (const i in timeSeriesValues) {
      const item = timeSeriesValues[i];
      if (!item) break;
      const value = item.deltaSpeed ?? 0;
      const { actualY } = this.validateThreshold(
        value,
        baseLine,
        thresholdHigh,
        true
      );
      const x = item.timestamp?.unixTime!;
      const y = actualY;
      const detail: TrendItemDetail = {
        expected: formatNumberForDisplay(item.velocityExpected),
        expectedUnit: 'kn',
        mePower: formatNumberForDisplay(item.chartDetail.mePower),
        sog: formatNumberForDisplay(item.chartDetail.sog),
        stw: formatNumberForDisplay(item.chartDetail.stw),
        meanDraft: formatNumberForDisplay(item.chartDetail.meanDraft),
        beaufortScale: item.chartDetail.beaufortScale,
        fuelMGOEq: formatNumberForDisplay(item.chartDetail.fuelMGOEq),
      };
      const isMissingData = item.isMissingData;

      trendItems.push({
        x: x,
        y: y,
        value: value,
        isMissingData: isMissingData,
        isOutlier: !item.isValid,
        detail: detail,
      });
    }

    return trendItems;
  }
  private static mapMesfocTrendItems(
    mesfoc: VesselPerformanceIndicators['mesfoc']
  ): TrendItem[] {
    const trendItems = [];
    const timeSeriesValues = mesfoc?.timeSeriesValues ?? [];
    const { baseLine, thresholdHigh } = this.getThresholdChartBands(
      mesfoc?.thresholdChartBands
    );
    for (const i in timeSeriesValues) {
      const item = timeSeriesValues[i];
      if (!item) break;
      const value = item.sfocDeviationPercent ?? 0;
      const { actualY } = this.validateThreshold(
        value,
        baseLine,
        thresholdHigh
      );
      const x = item.timestamp?.unixTime!;
      const y = actualY;
      const detail: TrendItemDetail = {
        expected: formatNumberForDisplay(item.sfoCexpected),
        expectedUnit: 'g/kWh',
        mePower: formatNumberForDisplay(item.chartDetail.mePower),
        sog: formatNumberForDisplay(item.chartDetail.sog),
        stw: formatNumberForDisplay(item.chartDetail.stw),
        meanDraft: formatNumberForDisplay(item.chartDetail.meanDraft),
        beaufortScale: item.chartDetail.beaufortScale,
        fuelMGOEq: formatNumberForDisplay(item.chartDetail.fuelMGOEq),
      };
      const isMissingData = item.isMissingData;

      trendItems.push({
        x: x,
        y: y,
        value: value,
        isMissingData: isMissingData,
        isOutlier: !item.isValid,
        detail: detail,
      });
    }
    return trendItems;
  }

  public static formatNegativeBoundaryChartData(data: BoundaryChartData[]) {
    return data.map((d) => {
      const upperBound = d.domainY[0] ?? -90;
      return {
        ...d,
        domainY: [d.domainY[1], upperBound],
        maxValue: upperBound,
      };
    });
  }
}
