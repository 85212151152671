import { gql } from '@apollo/client';
export const GET_VESSEL_EXCEL_REPORT = gql`
  query VesselReportExcelData(
    $startDate: DateTime!
    $endDate: DateTime!
    $reportType: String!
    $vesselIMO: String!
  ) {
    vesselReportExcel(
      reportType: $reportType
      vesselImo: $vesselIMO
      startDate: $startDate
      endDate: $endDate
    ) {
      filename
      encodedExcelFile
    }
  }
`;
