import { useQuery } from '@apollo/client';
import { GET_VESSEL_SUMMARY_SCORES } from '../queries/vessel-summary.query';
import { Query } from '_gql/graphql';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useGetVesselSummaryScores = (
  vesselIMO: string | undefined,
  dateRange: DateRange
) => {
  const dateRangeAsString = dateRange.dateRangeAsString;
  const query = useQuery<Query>(GET_VESSEL_SUMMARY_SCORES, {
    skip: !(vesselIMO && dateRangeAsString),
    variables: {
      vesselIMO,
      ...dateRangeAsString,
    },
  });
  return query;
};
