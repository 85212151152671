import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { useGetAlertCount } from '../../services/alerts.service';
import { WarningAmberOutlined } from '@mui/icons-material';
import { AlertStatus } from '../../services/alert-status';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';

export const AlertTableWarning = (props: {
  vesselImoNumbers: string[] | undefined;
  dateRange: DateRange;
}) => {
  const { vesselImoNumbers, dateRange } = props;

  const defaultAlertCount = useGetAlertCount({
    dateRange: new DateRange(new UTCDate().startOfYear, new UTCDate()),
    alertStatuses: [AlertStatus.Open],
    isRead: false,
    isArchived: false,
  });

  const currentAlertCount = useGetAlertCount({
    vesselImoNumbers,
    dateRange,
    alertStatuses: [AlertStatus.Open],
    isRead: false,
    isArchived: false,
  });

  const alertCountDiff =
    (defaultAlertCount?.data ?? 0) - (currentAlertCount?.data ?? 0);

  if (defaultAlertCount.error) {
    console.log('alert table warning error:', defaultAlertCount.error);
  }

  if (currentAlertCount.error) {
    console.log('alert table warning error:', currentAlertCount.error);
  }

  return (
    <>
      {alertCountDiff > 0 && (
        <Box
          sx={{
            display: 'flex',
            border: `1px solid ${theme.palette.warning.main}`,
            borderRadius: '4px',
            p: 1.5,
            marginTop: 3,
            marginBottom: 2,
          }}
        >
          <Box sx={{ pr: 1.5, py: '7px' }}>
            <WarningAmberOutlined sx={{ color: theme.palette.warning.main }} />
          </Box>
          <Box>
            <Typography
              component='div'
              variant='alertWarningTitle'
              sx={{
                mb: 0.5,
              }}
            >
              ({alertCountDiff}) New Alert{alertCountDiff > 1 && 's'} Hidden
            </Typography>
            <Typography component='div' variant='alertWarningSubtitle'>
              There are new alerts hidden due to the global filter selection.
              Adjust your filter selection(s) to show all alerts.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
