import { ToggleButton, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledToggleButtonVesselDataOverview = styled(ToggleButton)(
  () => ({
    textTransform: 'none',
    border: 0,
    gap: '8px',
    '&.MuiToggleButtonGroup-grouped': {
      padding: '8px 16px',
      borderRadius: '6px',
      '&:not(&.Mui-selected)': {
        backgroundColor: `${theme.colors.white}08`,
        borderRadius: '6px',

        '&:hover': {
          backgroundColor: `${theme.colors.white}35`,
          borderRadius: '6px',
        },
      },
      '&.Mui-selected': {
        borderRadius: '6px',
        '&:nth-of-type(1)': {
          backgroundColor: `${theme.colors.aRating}35`,
          '&.Mui-selected:hover': {
            backgroundColor: `${theme.colors.aRating}70`,
          },
        },
        '&:not(:nth-of-type(1))': {
          backgroundColor: `${theme.colors.dRating}35`,
          '&.Mui-selected:hover': {
            backgroundColor: `${theme.colors.dRating}70`,
          },
        },
        '.MuiChip-root': {
          opacity: '1',
        },
        '.MuiTypography-root': {
          color: theme.palette.text.primary,
        },
      },
    },
  })
);
