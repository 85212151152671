import { Box } from '@mui/material';
import { theme } from 'styles/theme';

export const RequiredAsterisk = (props: { padLeft?: boolean }) => {
  return (
    <Box
      component='span'
      sx={{ color: theme.palette.error.light, pl: props.padLeft ? '3px' : '' }}
    >
      *
    </Box>
  );
};
